import React, { useEffect, useState, memo } from 'react'
import {
  Typography, Box, FormControlLabel, Checkbox, Grid
} from '@mui/material'
import stylesheet from '../detail/detail-container.stylesheet'
import helper from '../../../utils/helper'
import { ClassNameMap, withStyles } from '@mui/styles'
import { RootState, useAppThunkDispatch } from '../../../redux/store'
import { useSelector } from 'react-redux'
import { setPatientsTable } from '../../../redux/common-data-slice'
import IpatientsTable from '../../../model/patientTable'
import { checkCondition } from '../detail/common/condition-check'
interface IProps {
  name: string
  input: any
  stateKey: string
  filterCount: any
  classes: ClassNameMap
}

const FilterChunkComponent: React.FC<IProps> = ({
  name,
  input,
  stateKey,
  filterCount,
  classes,
}) => {
  const [data, setData] = useState(input)
  const dispatch = useAppThunkDispatch()
  const patientsTable = useSelector((state: RootState) => state.commonData.patientsTable)

  useEffect(() => {
    const temp = (patientsTable[stateKey as keyof IpatientsTable] || '').toString()

    setData(
      input?.map((m: any) =>
        (temp.split(',').includes(m.value))
          ? { ...m, checked: true }
          : { ...m, checked: false }
      )
    )
  }, [input, patientsTable])

  const optionChanged = (_key: string, _value: string) => {
    const temp = data?.map((e: any) => e.value === _value ? { ...e, checked: !e.checked } : { ...e })
    if (_key === 'Hospitals') {
      const hospitalFilter = temp.filter((i: any) => i.checked)?.map((i: any) => i.name)
      localStorage.setItem("atman-hospitals", (hospitalFilter)?.join(','))
    }
    dispatch(
      setPatientsTable({
        ...patientsTable,
        page: 1,
        limit: 25,
        [stateKey]: temp
          ?.filter((e: { checked: boolean }) => e.checked === true)
          ?.map((m: { value: any }) => m.value)
          .join(),
      })
    )
    setData(temp)
  }

  const clearChecked = () => {
    dispatch(
      setPatientsTable({ ...patientsTable, [stateKey]: '', page: 1, limit: 25 })
    )
    if (stateKey === 'hospitals')
      localStorage.setItem("atman-hospitals", '')

    setData(
      data?.map((e: any) => {
        return { ...e, checked: false }
      })
    )
  }

  return (
    <Box pt={1} pb={1} className={name === 'Hospitals' ? classes.filterBorderBottom : classes.filterChunkContainer}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Typography variant="body2"><strong>{name}</strong></Typography>
        </Grid>
        <Grid item>
          {data?.some((e: { checked: any }) => e.checked === true) === true ? (
            <Typography
              variant="subtitle2"
              className={classes.clearBtn}
              onClick={() => clearChecked()}
            >
              <small>Clear</small>
            </Typography>
          ) : (
            <Typography variant="subtitle2" className={classes.clearBtn}>
              {' '}<small>{``}</small>
            </Typography>
          )}
        </Grid>
      </Grid>
      <Box>
        {data?.map((e: any, i: number) => (
          <Grid key={i} container justifyContent="space-between" className={classes.filterChunkItems}>
            <Grid item>
              {name !== 'Patients with' ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      onChange={() => optionChanged(name, e.value)}
                      sx={{
                        color: e.color || 'default',
                      }}
                      className={classes.checkBoxStyle}
                      checked={e.checked}
                      name="checkedA"
                      size="small"
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {checkCondition(e.checked === true,
                        (<strong>{helper.stageName(e.name)}</strong>),
                        (helper.stageName(e.name)))
                      }
                    </Typography>
                  }
                />
              ) : (
                <Typography variant="body2">{helper.stageName(e.name)}</Typography>
              )}
            </Grid>
            <Grid item className={classes.whiteSpaceNowrap}>
              <Typography color="textSecondary">
                <small>{(filterCount?.[e.value] || filterCount?.[e.name]) ?? 'NA'}</small>
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  )
}

export default memo(withStyles(stylesheet)(FilterChunkComponent))
