import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface authState {
  loading: any;
  user: any;
  token: any;
}

const initialState: authState = {
  loading: true,
  user: null,
  token: undefined,
};

export const authSlice = createSlice({
  name: 'access_token',
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.token = action.payload.accessToken.value;
      state.user = null;
    },
  },

});

export const { setAccessToken } = authSlice.actions;
export default authSlice.reducer;
