import { Typography, Box, Button, Grid } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import { useState } from 'react';
import stylesheet from '../detail-container.stylesheet'

interface IProps {
  item: any
  compKey: string
  outerSubmit: any
  classes: ClassNameMap
}

const CommunicateToPatientComponent: React.FC<IProps> = ({
  item,
  compKey,
  outerSubmit,
  classes,
}) => {
  const [status, setStatus] = useState(item.is_communicated);
  const onBtnClick = (_param: boolean) => {
    setStatus(_param)
    outerSubmit(compKey, _param)
  }

  return (
    <Box className={classes.subModalBackground}>
      <Grid spacing={1} container>
        <Grid item sm={12}>
          <Typography variant="subtitle2">
            Have you finalised and communicated the program recommendation with patient?
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            size="small"
            onClick={() => onBtnClick(true)}
            className={status === true ? classes.dialogBtnActionSelected : classes.dialogBtnAction}
          >
            Yes
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            size="small"
            onClick={() => onBtnClick(false)}
            className={status === false ? classes.dialogBtnActionSelected : classes.dialogBtnAction}
          >
            No
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withStyles(stylesheet)(CommunicateToPatientComponent)
