import {
  Box, Button, ButtonGroup, Card, CardContent, CircularProgress, Grid, TextField,
  Typography, FormControl, Select, MenuItem, Checkbox
} from '@mui/material'
import { withStyles, ClassNameMap } from '@mui/styles'
import Autocomplete from '@mui/material/Autocomplete'
import React, { useEffect, useState } from 'react'
import { PatientService } from '../../../../services/patient.service'
import { checkCondition } from '../common/condition-check'
import Helper from '../../../../utils/helper'
import stylesheet from '../detail-container.stylesheet'
import ConfirmationDialogComponent from './confirmation-dialog.component'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { useUpdateDataAction } from '../../../../utils/update-details-hook'

interface IProps {
  medicineInfo: any
  handleClose: any
  input: any
  outer: any
  outerSubmit: any
  differently: boolean
  heading: any
  noDropDown: any
  uiResource: any
  writeMode: boolean
  action?: string
  patientId: any
  currentStage: any
  classes: ClassNameMap
}

const MedicationsComponent: React.FC<IProps> = ({
  action,
  medicineInfo,
  classes,
  handleClose,
  input,
  outer,
  outerSubmit,
  differently,
  heading,
  noDropDown,
  uiResource,
  patientId,
  currentStage,
  writeMode,
}) => {
  const [oneMedicine, setOneMedicine] = useState()
  const options = ['taking', 'not taking', 'taking differently']
  const [write, setWrite] = useState(writeMode)
  const [currentOption, setCurrentOption] = useState(checkCondition(action === 'edit', medicineInfo?.intake_regularity, 'taking'))
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [, setSaveDisable] = useState(medicineInfo ? false : true)
  const [confirmContent, setConfirmContent] = useState('');
  const { updatePatientData } = useUpdateDataAction(patientId)

  const initialValues = medicineInfo
    ? {
      medicineData: { id: '', name: '' },
      dose: medicineInfo.c_schedule.dose || '',
      num_units: medicineInfo.pu_order?.num_units,
      unit: medicineInfo.pu_order?.unit,
      route: medicineInfo.pu_order?.route,
      frequency: medicineInfo.pu_order?.frequency,
      prescription_taken: medicineInfo.prescription_taken,
      schedule_name: medicineInfo?.c_schedule?.name
    }
    : {
      medicineData: { id: '', name: '' },
      dose: '',
      num_units: '',
      unit: '',
      route: '',
      frequency: '',
      prescription_taken: false,
      schedule_name: ''
    }
  const [values, setValues] = useState(initialValues as any)
  const [FOOD_QUALIFIER, setFoodQualifier] = useState() as any
  const [MEDICATION_TIMINGS, setMedicineTiming] = useState() as any
  const [dosageInfo, setDosageInfo] = useState(medicineInfo?.dosage_info || [] as any)
  const [query, setQuery] = useState('')
  const [actionList, setActionList] = useState([] as any);
  const selectedProgram = useSelector((state: RootState) => state.commonData.selectedProgram)

  const onBtnChange = (_input: string) => {
    setCurrentOption(_input)
    if (action === 'edit') {
      if (_input === 'not taking') {
        setWrite(false)
      } else {
        setWrite(true)
      }
    }
  }

  const handleInputChange = (e: any) => {
    setValues({ ...values, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value })
    checkForm(e.target.name)
  }

  const handleDosageUpdate = (e: any) => {
    const name = e.target.name
    if (name === 'food_qualifier') {
      let foodQualifier: string = e.target.value
      setFoodQualifier(foodQualifier)
      if (MEDICATION_TIMINGS) {
        setDosageInfo([...dosageInfo, { foodQualifier, MEDICATION_TIMINGS }])
        setFoodQualifier('')
        setMedicineTiming('')
      }
    }
    if (name === 'medication_timings') {
      let medicationTimings: string = e.target.value
      setMedicineTiming(medicationTimings)
      // if (FOOD_QUALIFIER) {
      // setDosageInfo([...dosageInfo, { medicationTimings, FOOD_QUALIFIER }])
      setDosageInfo([{ medicationTimings }])

      setFoodQualifier('')
      setMedicineTiming('')
      // }
    }
  }
  const [different] = useState(differently)
  const patientService = new PatientService()
  const [open, setOpen] = React.useState(false)
  const [medicine, setMedicine] = useState([] as any)
  const [loading, setLoading] = useState(false)

  const debounce = (func: any, delay: any) => {
    let debounceTimer: any;
    return function () {
      const context = func
      const args = arguments
      clearTimeout(debounceTimer)
      debounceTimer
        = setTimeout(() => func.apply(context, args), delay)
    }
  }

  useEffect(() => {
    let active = true;
    if (active && query.length >= 2) {
      try {
        setLoading(true)
        patientService.getPrescriptionUnit(query).then((response: any) => {
          setMedicine(response.data)
          setLoading(false)
        })
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }
    return () => {
      active = false;
    };
  }, [query]);

  const onBtnCancel = (mode: string) => {
    outer(input.id, mode)
  }

  const onBtnSubmit = (_mode: string, _typeOut: string) => {
    if (!medicineInfo) {
      if (!oneMedicine) {
        return
      }
    }
    medicineInfo
      ? outerSubmit(values, _mode, _typeOut, medicineInfo, dosageInfo, currentOption)
      : outerSubmit(values, oneMedicine, dosageInfo, currentOption, _typeOut, _mode)
    handleClose()
  }

  const checkForm = (name: string) => {
    let check = false
    let masterCheck = false
    Object.keys(values).forEach((key, _val) => {
      check = checkCondition(name == key, true, checkCondition(values[key], true, false))

      if (!check) {
        masterCheck = true
      }
    })
    setSaveDisable(masterCheck)
  }

  const notesBox = () => {
    return <Box mt={2}>
      <TextField
        id="notes-basic"
        label="Take notes here"
        variant="outlined"
        size="small"
        InputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15 } }}
        name="note"
        value={values.note}
        onChange={handleInputChange}
        multiline={true}
        rows="3"
        className={classes.fullWidth}
      />
    </Box>
  }

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    setConfirmContent('')
  }

  const confirmStatus = (param: boolean) => {
    if (param) {
      setConfirmOpen(false)
      const actions = confirmContent.split('/')
      let action = actions[0]
      let sub_action = actions[1]
      if (action === sub_action) sub_action = ''
      setLoading(true)
      const medPrg = (typeof medicineInfo?.programs === 'object') ? medicineInfo?.programs[0] : ''

      patientService.putPatientActions(patientId, medicineInfo.id, medPrg, action, sub_action, medicineInfo.care_plan_id)
        .then((_response: any) => {
          setLoading(false)
          // toast.success('Request processed successfully.', Helper.bottom_center())
          updatePatientData({ isMeasurement: true, isTreatments: true, })
        }).catch((error) => {
          setLoading(false)
          toast.error(Helper.getErrorText(error), Helper.bottom_center())
        })
    }
    handleClose()
  }

  return (<>
    <Grid container direction="column">
      <Box className={classes.medicationsBox}>
        <Card>
          <CardContent>
            <Grid
              container
              className={classes.medicationsBoxDisplay}
              direction="row"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Grid item>
                <Typography
                  className={classes.header2}
                  variant="body1"
                  style={{
                    lineHeight: '1',
                    color: '#080808',
                    marginBottom: different ? '-1.5rem' : '',
                  }}
                >
                  <strong>{heading}</strong>
                </Typography>
                <Grid
                  container
                  className={classes.checkBoxShadow}
                  direction="row"
                  alignItems="baseline"
                >
                  <Grid item xs={1}>
                    <Checkbox
                      onChange={handleInputChange}
                      checked={values.prescription_taken}
                      name="prescription_taken"
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant='subtitle2' color="textSecondary" className={classes.textLabel}>
                      Taken as needed
                    </Typography>
                  </Grid>
                </Grid>
                <Autocomplete
                  id="combo-box-demo"
                  open={open}
                  onOpen={() => {
                    setOpen(true)
                    setMedicine([])
                    setQuery('')
                  }}
                  onClose={() => {
                    setOpen(false)
                    setMedicine([])
                    setQuery('')
                  }}
                  options={medicine as any}
                  getOptionLabel={(option: any) => Helper.getRXnorm(option?.name, option?.rxcui)}
                  sx={{
                    display: noDropDown,
                    padding: '5px 0px'
                  }}
                  className={classes.autoCompleteDropDowns}
                  loading={loading}
                  value={oneMedicine}
                  onChange={(_event, newValue) => {
                    setOneMedicine(newValue)
                    setValues({
                      ...values,
                      unit: newValue?.unit || '',
                      route: newValue?.route || ''
                    })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      onChange={debounce((e: any) => {
                        setQuery(e.target.value)
                        if (e.target.value === '') {
                          setMedicine([])
                        }
                      }, 1000)}

                      label="Medicine Selection"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <TakingOptions
                  options={options}
                  isPrgMed={false}
                  onBtnChange={onBtnChange}
                  currentOption={currentOption}
                  classes={classes}
                />
                {notesBox()}
                {checkCondition(write,
                  <UpdateMedicine
                    medicineInfo={medicineInfo}
                    dosageInfo={dosageInfo}
                    handleDosageUpdate={handleDosageUpdate}
                    values={values}
                    classes={classes}
                    uiResource={uiResource}
                    handleInputChange={handleInputChange}
                  />,
                  <ViewMedicine
                    uiResource={uiResource}
                    medicineInfo={medicineInfo}
                    dosageInfo={dosageInfo}
                    classes={classes}
                  />
                )}
              </Grid>
            </Grid>
          </CardContent>
          {!medicineInfo?.isMedDialog &&
            <ButtonGroup
              className={classes.questionButtonStyle}
              size="small"
              variant="text"
              aria-label="Confirm"
            >
              {input?.isMedReconcile
                ? <Button
                  className={classes.questionButtonWidth1}
                  disabled={checkCondition(medicineInfo, false, !oneMedicine)}
                  onClick={() => onBtnSubmit('view', 'confirm')}
                >
                  <strong>{`Confirm`}</strong>
                </Button>
                :
                <Button
                  className={classes.questionButtonWidth}
                  disabled={checkCondition(medicineInfo, false, !oneMedicine)}
                  sx={{ borderTop: 'none !important' }}
                  onClick={() => onBtnSubmit('view', 'confirm')}
                >
                  <strong>{`Save & Confirm`}</strong>
                </Button>
              }
              {!input?.isMedReconcile &&
                <Button
                  disabled={checkCondition(medicineInfo, false, !oneMedicine)}
                  className={(true) ? classes.questionButtonWidth : classes.questionButtonWidth1}
                  sx={{ borderTop: 'none !important' }}
                  onClick={() => onBtnSubmit('view', 'save')}
                >
                  <strong>Save</strong>
                </Button>
              }
              <Button
                className={(true) ? classes.questionButtonWidth : classes.questionButtonWidth1}
                onClick={different ? () => onBtnCancel('view') : handleClose}
              >
                <strong>Cancel</strong>
              </Button>
            </ButtonGroup>
          }
        </Card>
      </Box>
    </Grid>
    {confirmOpen &&
      <ConfirmationDialogComponent
        open={confirmOpen}
        content={`Are you sure for action : ${confirmContent}`}
        handleConfirmClose={handleConfirmClose}
        confirmStatus={confirmStatus}
      />
    }
  </>
  )
}

interface WriteProps {
  classes: any
  values: any
  handleInputChange: any
  handleDosageUpdate: any
  dosageInfo: any
  uiResource: any
  medicineInfo: any
}

export const UpdateMedicine: React.FC<WriteProps> = ({
  classes,
  values,
  handleInputChange,
  handleDosageUpdate,
  uiResource,
  dosageInfo,
  medicineInfo,
}) => {
  return (
    <>
      <Grid container direction="row" alignItems="center">
        <Grid container direction="row" alignItems="center" justifyContent="space-between">
          <Grid item xs={3}>
            <Typography
              color="textSecondary"
              className={classes.tooltip1}
              variant="subtitle2"
            >
              No. Taken
            </Typography>
            <TextField
              variant="standard"
              id="standard-basic"
              size="small"
              value={values.num_units}
              onChange={handleInputChange}
              name="num_units"
              className={classes.medicationTextField}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography color="textSecondary" className={classes.tooltip1} variant="subtitle2">
              Unit
            </Typography>
            <Typography className={`${classes.tooltip1} ${classes.desc}`} variant="body2">
              {checkCondition(values.unit, values.unit, '-')}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography color="textSecondary" className={classes.tooltip1} variant="subtitle2">
              Route
            </Typography>
            <Typography className={`${classes.tooltip1} ${classes.desc}`} variant="body2">
              {checkCondition(values.route, values.route, '-')}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography color="textSecondary" className={classes.tooltip1} variant="subtitle2">
              Frequency
            </Typography>
            <FormControl className={classes.fullWidth}>
              <Select
                displayEmpty
                variant="standard"
                className={classes.selectEmpty}
                inputProps={{ 'aria-label': 'Without label' }}
                value={values.frequency}
                name="frequency"
                MenuProps={{ autoFocus: false }}
                onChange={handleInputChange}
              >
                {Object.keys(uiResource.MEDICATION_DOSAGE_FREQUENCY).map(
                  (_key, _index) => (
                    <MenuItem className={classes.formControlItemsPadding} value={_key}>
                      {uiResource.MEDICATION_DOSAGE_FREQUENCY[_key]}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ paddingTop: '0.5rem' }} justifyContent="space-between">
          <Grid item style={{ width: '30%' }}>
            <Typography
              color="textSecondary"
              className={classes.tooltip1}
              variant="subtitle2"
            >
              Dose time
            </Typography>
            <FormControl className={classes.formControlWidth150}>
              <Select
                displayEmpty
                variant="standard"
                className={classes.selectEmpty}
                inputProps={{ 'aria-label': 'Without label' }}
                value={dosageInfo[0]?.medicationTimings || ''}
                name="medication_timings"
                MenuProps={{ autoFocus: false }}
                onChange={handleDosageUpdate}
              >
                {Object.keys(uiResource.MEDICATION_TIMINGS).map(
                  (_key, index) => (
                    <MenuItem key={`does-time${index}`} className={classes.formControlItemsPadding} value={_key}>
                      {uiResource.MEDICATION_TIMINGS[_key]}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
interface ReadProps {
  medicineInfo: any
  uiResource: any
  dosageInfo: any
  classes: any
}

export const ViewMedicine: React.FC<ReadProps> = ({
  medicineInfo,
  uiResource,
  dosageInfo,
  classes,
}) => {
  return (
    <>
      <Grid container xs={12} direction="row" alignItems="center">
        <Grid container xs={12} direction="row" alignItems="center" justifyContent="space-between">
          <Grid item xs={3}>
            <Typography
              color="textSecondary"
              className={classes.tooltip1}
              variant="subtitle2"
            >
              No. Taken
            </Typography>
            <Typography
              color="textSecondary"
              className={classes.tooltip1}
              variant="subtitle2"
            >
              <strong>{checkCondition(medicineInfo.pu_order?.num_units, medicineInfo.pu_order?.num_units, '-')}</strong>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              color="textSecondary"
              className={classes.tooltip1}
              variant="subtitle2"
            >
              Unit
            </Typography>
            <Typography className={`${classes.tooltip1} ${classes.desc}`} variant="body2">
              {medicineInfo.pu_order?.unit}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography color="textSecondary" className={classes.tooltip1} variant="subtitle2">
              Route
            </Typography>
            <Typography className={`${classes.tooltip1} ${classes.desc}`} variant="body2">
              {medicineInfo?.pu_order?.route || ''}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              color="textSecondary"
              className={classes.tooltip1}
              variant="subtitle2"
            >
              Frequency
            </Typography>
            <Typography
              color="textSecondary"
              className={classes.tooltip1}
              variant="subtitle2"
            >
              <strong>{uiResource.MEDICATION_DOSAGE_FREQUENCY[medicineInfo.pu_order?.frequency] || '-'}</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary" className={classes.tooltip1} variant="subtitle2">
              Dose time
            </Typography>
            <Grid container direction="row" alignItems="center">
              {dosageInfo?.map((item: any, index: any) => (
                <Grid xs={4} key={`view-does-time-${index}`}>
                  <FormControl className={classes.formControlWidth150}>
                    <Select
                      disabled
                      variant="standard"
                      inputProps={{ 'aria-label': 'medication_timings_view' }}
                      value={item?.medicationTimings || ''}
                      name="medication_timings_view"
                      MenuProps={{ autoFocus: false }}
                    >
                      {Object.keys(uiResource.MEDICATION_TIMINGS).map(
                        (_key, index) => (
                          <MenuItem key={`does-time-view${index}`} className={classes.formControlItemsPadding} value={_key}>
                            {uiResource.MEDICATION_TIMINGS[_key]}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

interface OptionProps {
  options: any
  isPrgMed: any
  onBtnChange: any
  currentOption: any
  classes: ClassNameMap
}

export const TakingOptions: React.FC<OptionProps> = ({
  options,
  isPrgMed,
  onBtnChange,
  currentOption,
  classes,
}) => {

  return (
    <Box pt={1} pb={1} className={{ isPrgMed } ? classes.questionHeaderOtherMedSection : classes.questionHeaderSection}>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        {options.map((r: any, index: any) => (
          <Grid item>
            <Button
              variant="contained"
              key={`taking-btn-${index}`}
              name={r}
              size="small"
              onClick={(_e) => {
                onBtnChange(r)
              }}
              className={
                currentOption === r
                  ? [
                    classes.questionSubmitAnsButton,
                    classes.questionSubmitAnsButtonActive,
                  ].join(' ')
                  : classes.questionSubmitAnsButton
              }
            >
              <strong>
                {Helper.toCamelCase(r)}
              </strong>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default withStyles(stylesheet)(MedicationsComponent)
