import { TextField, Box, Grid, IconButton, Typography, Checkbox, FormControlLabel, FormControl, MenuItem, Select, Switch, ButtonGroup, Button, CircularProgress, Stack } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import { useEffect, useState } from 'react'
import stylesheet from '../detail-container.stylesheet'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { checkCondition } from '../common/condition-check'
import { grey } from "@mui/material/colors";
import moment from "moment";
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import AutoCompleteMedicine from '../medications/autocomplete-medicine.component'
import { usePrevious } from '../../../../utils/use-previousValue-hook'
import { PatientService } from '../../../../services/patient.service'
import Autocomplete from '@mui/material/Autocomplete'
import DatePickerComponent from '../../../../utils/datepicker.component'
import RestrictedMedComponent from '../medications/restricted-med.component'

const CustomSwitch = withStyles({
  switchBase: {
    color: grey[500],
    "&$checked": {
      color: '#3E3D3B'
    },
    "&$checked + $track": {
      backgroundColor: grey[200],
      border: `1px solid ${grey[500]}`,
    }
  },
  checked: {},
  track: {
    backgroundColor: grey[200],
    border: `1px solid ${grey[500]}`,
  }
})(Switch);

interface IProps {
  item: any
  outerSubmit: any
  outer: any
  compKey: string
  handleClose: any
  handleSubmit: any
  parentComp: string
  classes: ClassNameMap
}

const RejectRestrictComponent: React.FC<IProps> = ({
  item,
  outerSubmit,
  outer,
  compKey,
  handleClose,
  handleSubmit,
  parentComp = '',
  classes,
}) => {
  const uiResource = useSelector((state: RootState) => state.metaData.metadata)
  const [recordedDate, setRecordedDate] = useState(item?.date_recorded ? moment.unix(item?.date_recorded).format('YYYY-MM-DDTHH:mm') : '')
  const addButtons = useSelector((state: RootState) => state.addButtons);
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const patientService = new PatientService()
  const [dropdownOptions, setDropdownOptions] = useState([] as any)
  const [open, setOpen] = useState(false)
  const metaData = useSelector((state: RootState) => state.metaData.metadata)
  const [selectedOption, setSelectedOption] = useState(checkCondition(Object.keys(item).length, item.reaction_details, ''))
  const initialValues = {
    med_id: item?.med_id,
    med_type: item?.med_type,
    program_name: item?.program_name || '',
    propagation_status: item?.propagation_status || 'Full',
    qualified_status: item?.qualified_status ? item?.qualified_status : false,
    min_unacceptable_daily_dose: item?.min_unacceptable_daily_dose,
    min_unacceptable_daily_dose_apply: item?.min_unacceptable_daily_dose_apply,
    min_unacceptable_unit: item?.min_unacceptable_unit ?
      Object.entries(uiResource?.MEDICATION_UNITS).filter(f => f[1] === item.min_unacceptable_unit)[0][0] : '',
    max_acceptable_daily_dose: item?.max_acceptable_daily_dose,
    max_acceptable_unit: item?.max_acceptable_unit ?
      Object.entries(uiResource?.MEDICATION_UNITS).filter(f => f[1] === item.max_acceptable_unit)[0][0] : '',
    max_acceptable_daily_dose_apply: item?.max_acceptable_daily_dose_apply,
    notes: '',
    diagnosis: item?.reaction_details?.name
  }

  const [values, setValues] = useState(initialValues as any);
  const checkSubmit = checkCondition(values?.diagnosis !== '' && values.date_recorded !== '', true, false)
  const prevValues = usePrevious(values)

  const debounce = (func: any, delay: any) => {
    let debounceTimer: any
    return function () {
      const context = func
      const args = arguments
      clearTimeout(debounceTimer)
      debounceTimer = setTimeout(() => func.apply(context, args), delay)
    }
  }

  useEffect(() => {
    if (checkSubmit && (parentComp !== 'med_int' && parentComp !== 'med_eval')) {
      outerSubmit('med_restriction', values)
      outerSubmit(compKey, 'Restrict')
    }
  }, [values])

  useEffect(() => {
    if (query.length >= 2) {
      try {
        setLoading(true)
        patientService.getMedicationReactionBySearchDiagnosis(query).then((res: { data: any }) => {
          setDropdownOptions(res.data)
          setLoading(false)
        })
      } catch (error) {
        setLoading(false)
        console.error(error)
      }
    }
  }, [query])

  useEffect(() => {
    if (['Full', 'Class'].includes(values.propagation_status)) {
      if (values.qualified_status === true) {
        setValues({ ...values, qualified_status: false })
      }
    }
    if (prevValues.qualified_status === true && values.qualified_status === false) {
      const { min_unacceptable_daily_dose, min_unacceptable_unit, min_unacceptable_daily_dose_apply,
        max_acceptable_daily_dose, max_acceptable_unit, max_acceptable_daily_dose_apply, ...derivedObj } = values;
      setValues({ ...derivedObj })
    }
  }, [values.qualified_status, values.propagation_status])

  const handleInputChange = (e: any) => {
    let value
    let name = e.target.name;
    value = ['qualified_status'].includes(name) ? e.target.checked : e.target.value;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const onBtnClick = (_param: string) => {
    outer(_param)
  }

  const checkSaveDisabled = () => {
    return !(values.med_id && values?.diagnosis)
  }

  const medUnits = (_value: any, _name: string) => {
    return <FormControl className={classes.formControl}>
      <Select
        disabled={!values.qualified_status}
        variant='standard'
        displayEmpty
        className={classes.selectEmpty}
        inputProps={{ 'aria-label': 'Without label' }}
        value={_value}
        name={_name}
        onChange={handleInputChange}
      >
        {Object.keys(uiResource?.MEDICATION_UNITS).map(
          (_key: any, _index: any) => (
            <MenuItem className={classes.medicationUnits} value={_key}>
              {uiResource?.MEDICATION_UNITS[_key]}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  }
  const onCalendarChange = (e: any) => {
    setRecordedDate(e.target.value)
    setValues({
      ...values,
      date_recorded: moment(e.target.value).unix(),
    })

  }
  return (
    <>
      <Grid container direction="row" className={classes.programTargetContainer} sx={{ width: parentComp === 'med_int' ? '100%' : '50%' }} >
        <Grid item className={classes.fullWidth}>
          {Object.keys(item).length > 1 &&
            <Typography variant="subtitle2" className={classes.nameLineHeight}>
              <strong>{item?.medicine_details?.name}</strong>
            </Typography>
          }
          {(!['med_int', 'med_eval'].includes(parentComp)) && <>
            <IconButton
              size='small'
              className={classes.backIconRestrict}
              onClick={() => onBtnClick('Back')}
            >
              <KeyboardArrowLeftOutlinedIcon />
            </IconButton>
            <Typography display="inline" color="textSecondary" variant='h6'>
              <small>{`Restriction`}</small>
            </Typography></>}
          {parentComp === 'med_eval' && <Typography display="inline" color="textSecondary" variant='h6'>
            <small>{`Restriction`}</small>
          </Typography>
          }
          <Grid container spacing={1} flexDirection={'column'} sx={{ padding: '0.5rem 0.2rem' }}>
            <Grid item>
              <Stack direction="column">
                <Typography color="textSecondary" variant="subtitle2">Propagation</Typography>
                <FormControl className={classes.rrdFormControl}>
                  <Select
                    displayEmpty
                    variant="standard"
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'Propagation' }}
                    label='Propagation'
                    value={values.propagation_status}
                    name="propagation_status"
                    onChange={handleInputChange}
                  >
                    {Object.keys(metaData?.PROPAGATION_STATUS_TYPE)?.sort()?.map((item: any, i: any) =>
                      <MenuItem key={`menu-${i}`} value={`${item}`} className={classes.formControlItemsPadding}>
                        {metaData?.PROPAGATION_STATUS_TYPE[item]}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item className={classes.fullWidth}>
              {addButtons?.addControl.find(
                (e: { key: string }) => e.key === "MD-PRM"
              ).value || parentComp !== 'med_int' ? (
                <AutoCompleteMedicine
                  classes={classes}
                  propagation={values.propagation_status}
                  existingMed={Object.keys(item).length > 0 ? { id: item.med_id, med_type: item.med_type, name: item.name } : {}}
                  outer={(res: any) => {
                    setValues({
                      ...values,
                      med_id: res?.id,
                      med_type: res?.med_type
                    });
                  }}
                />
              ) : null}
            </Grid>
            <Grid item>
              <RestrictedMedComponent
                id={values?.med_id}
                medType={values?.med_type}
                propagation={values.propagation_status || ''}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                id="combo-box-demo"
                open={open}
                onOpen={() => { setOpen(true) }}
                onClose={() => { setOpen(false) }}
                options={dropdownOptions}
                getOptionLabel={(option: any) => option.name || ''}
                className={classes.autoCompleteDropDowns}
                loading={loading}
                value={selectedOption || ''}
                onChange={(_e, newValue: any) => {
                  if (newValue) {
                    setSelectedOption(newValue)
                    setValues({
                      ...values,
                      diagnosis: newValue.name
                    })
                  } else {
                    setValues({
                      ...values,
                      diagnosis: ''
                    })
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    onChange={debounce((e: any) => {
                      setQuery(e.target.value)
                    }, 1000)}
                    label="Search and select Reaction"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {checkCondition(
                            loading,
                            <CircularProgress color="inherit" size={20} />,
                            null
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <DatePickerComponent
                label="When did this reaction happen? *"
                variant="standard"
                type="datetime-local"
                cssClass={classes.datePicker}
                defaultValue={recordedDate}
                onCalendarChange={onCalendarChange}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={['Full', 'Class'].includes(values.propagation_status)}
                    value={values.qualified_status}
                    checked={values.qualified_status}
                    onChange={handleInputChange}
                    size="small"
                    name='qualified_status'
                    className={classes.checkBoxStyle}
                  />
                }
                label={
                  <Typography variant="subtitle2"
                    color={['Full', 'Class'].includes(values.propagation_status) ? 'gray' : 'inherit'}
                  >
                    Qualified restriction
                  </Typography>}
              />
            </Grid>
            <Grid item xs={12} id="left-container">
              <Typography color="textSecondary" variant="subtitle2">
                Min unacceptable total daily dose
              </Typography>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={3}>
                  <TextField
                    disabled={!values.qualified_status}
                    variant='standard'
                    id="standard-basic"
                    size="small"
                    value={values.min_unacceptable_daily_dose}
                    onChange={handleInputChange}
                    name="min_unacceptable_daily_dose"
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={2}>
                  {medUnits(values.min_unacceptable_unit, 'min_unacceptable_unit')}
                </Grid>
                <Grid>
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        disabled={!values.qualified_status}
                        checked={values.min_unacceptable_daily_dose_apply}
                        onChange={() => setValues({ ...values, min_unacceptable_daily_dose_apply: !values.min_unacceptable_daily_dose_apply })}
                      />}
                    label={<Typography variant='body2'>apply</Typography>}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} id="left-container" className={classes.totalDailyDose}>
              <Typography color="textSecondary" variant="subtitle2">
                Max acceptable total daily dose
              </Typography>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={3}>
                  <TextField
                    disabled={!values.qualified_status}
                    variant='standard'
                    id="standard-basic"
                    size="small"
                    value={values.max_acceptable_daily_dose}
                    onChange={handleInputChange}
                    name="max_acceptable_daily_dose"
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={2}>
                  {medUnits(values.max_acceptable_unit, 'max_acceptable_unit')}
                </Grid>
                <Grid>
                  <FormControlLabel control={<CustomSwitch disabled={!values.qualified_status}
                    checked={values.max_acceptable_daily_dose_apply}
                    onChange={() => setValues({ ...values, max_acceptable_daily_dose_apply: !values.max_acceptable_daily_dose_apply })}
                  />} label={<Typography variant='body2'>apply</Typography>} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary" variant="subtitle2">
                Program specific restriction
              </Typography>
              <Grid container direction="row" alignItems="center">
                <FormControl className={classes.rrdFormControl}>
                  <Select
                    displayEmpty
                    variant='standard'
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'Without label' }}
                    label='Program'
                    value={values.program_name}
                    name="program_name"
                    onChange={handleInputChange}
                  >
                    {['All', ...uiResource?.PROGRAMS.map((m: { name: any }) => m.name)]?.sort()?.map((_key: any) => (
                      <MenuItem className={classes.formControlItemsPadding} value={_key}>
                        {uiResource?.PROGRAMS?.find((i: any) => i.name === _key)?.display_name || _key}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Add note here"
                size="small"
                className={classes.fullWidth}
                multiline={true}
                rows="2"
                name="notes"
                onChange={handleInputChange}
                sx={{ paddingTop: '0.5rem' }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {parentComp === 'med_int' && <Box pt={1}>
        <Grid container direction="row" justifyContent="space-around" alignItems="center" className={classes.rrdButtons}>
          <ButtonGroup className={classes.questionButtonStyle} size="small" variant="text" aria-label=" button">
            <Button
              disabled={checkSaveDisabled()}
              onClick={() => handleSubmit(values, Object.keys(item).length > 0 ? 'update' : 'add', item)}
              className={classes.questionButtonWidth1}
            >
              <strong>Save</strong>
            </Button>
            <Button onClick={() => handleClose(item?.id)} className={classes.questionButtonWidth1}>
              <strong>Cancel</strong>
            </Button>
          </ButtonGroup>
        </Grid>
      </Box>}
    </>
  )
}
export default withStyles(stylesheet)(RejectRestrictComponent)