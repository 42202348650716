import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  currentCount,
  decrement,
  increment,
} from '../redux/confirmation-count-slice'

import { RootState, useAppThunkDispatch } from '../redux/store'

export const useCheckConfirmCount = (patientId: string) => {
  const baseKeys = ['measurements', 'conditions', 'medications']
  const dispatch = useAppThunkDispatch()
  const count = useSelector((state: RootState) => state.counter.count)

  const [value, setValue] = useState(count)
  useEffect(() => {
    setValue(count)
  }, [count])

  const setConfirmCount = (
    prevValue: boolean,
    currentValue: boolean,
    forceStageUpdate: boolean = false
  ) => {
    if (forceStageUpdate === true) {
      // dispachAPIs()
    } else {
      if (prevValue === true && currentValue === false) {
        dispatch(decrement())
        dispatch(currentCount()).then((res: any) => {
          if (res.payload === 0) {
            // dispachAPIs()
          }
        })
      } else if (prevValue === false && currentValue === true) {
        dispatch(increment())
      }
    }
  }
  return [value, setConfirmCount] as const
}
