import React, { useState, useEffect, memo, } from 'react'
import { Grid } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import moment from 'moment'
import CardLayoutComponent from '../common/card-layout.component'
import { RootState } from '../../../../redux/store'
import { useParams } from 'react-router-dom'
import stylesheet from '../detail-container.stylesheet'

interface IProps {
  input: any
  classes: ClassNameMap
}

const ICD10CodesComponent: React.FC<IProps> = ({ input, classes }) => {
  const [data, setData] = useState([] as any)
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const urlParams: any = useParams()
  const patientId: string = urlParams.patientId

  useEffect(() => {
    const stage = patientDetail?.conditions?.icd10_codes?.map((e: any) => { return { ...e, mode: 'view' } })
    setData(stage)
  }, [patientDetail])

  const formatConfirmDate = (save_timestamp: number, is_referred: boolean, referred_on: number) => {
    if (is_referred) {
      return moment.unix(referred_on).format('MM/DD/YY')
    } else if (save_timestamp) {
      return moment.unix(save_timestamp).format('MM/DD/YY')
    } else {
      return ''
    }
  }

  const setIcons = () => {
    return [{ iconType: 'more', callback: outer }]
  }

  const outer = (_id: any, _mode: string) => {
    // setData(onSetView(_id, _mode, data))
  }

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
      {data && data?.sort((a: any, b: any) => a?.display_name > b?.display_name ? 1 : -1)
        ?.map((d: any, i: any) => (
          <React.Fragment key={`icd10-${i}`}>
            {d.mode === 'view' && (
              <>
                <CardLayoutComponent
                  id={d.id}
                  name={d?.name}
                  leftHeader={`ICD10: ${d.icd_code}, ${d?.description || ","} HCC Code: ${d?.hcc_code || "N/A"}`}
                  rightHeader={''}
                  subHeader={''}
                  bottomText={formatConfirmDate(d?.save_timestamp, d?.is_referred, d?.referred_on)}
                  needs_confirmation={d.needs_confirmation || false}
                  needs_update={d.needs_update || false}
                  programs={d.programs}
                  icons={setIcons()}
                  onRefClick={() => null}
                  is_referred={d.is_referred || false}
                  is_overridden={false}
                  entityRef='icd10_codes'
                  patientId={patientId}
                  category={'conditions'}
                />
              </>
            )}
          </React.Fragment>
        ))}
    </Grid>
  )
}

export default withStyles(stylesheet)(memo(ICD10CodesComponent))
