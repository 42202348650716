import React, { useState, useRef, useEffect } from 'react'
import {
  Grid, LinearProgress, DialogTitle, DialogContent, DialogActions, Button, Dialog,
  Checkbox, Box, AppBar, Tabs, Tab, Typography,
} from '@mui/material'
import stylesheet from '../detail-container.stylesheet'
import { ClassNameMap, withStyles } from '@mui/styles'
import Draggable from "react-draggable"
import ConfirmationDialogComponent from '../common/confirmation-dialog.component'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import DialogTitleHeaderComponent from '../common/dialog-title-header.component'

interface IProps {
  open: boolean,
  item: any,
  handleDialogClose: any,
  handleSubmit: any,
  classes: ClassNameMap
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (<div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <>{children}</>}
  </div>
  )
}

const EditProblemDialogComponent: React.FC<IProps> = ({ open, item, handleDialogClose, handleSubmit, classes }) => {
  const isAddPrRef = useRef(null) as any
  const [isLoadingPrg, setIsLoadingPrg] = useState(true)
  const [currprg, setCurrPrg] = useState(item?.programs || '')
  const [removePrgEnrollment, setRemovePrgEnrollment] = useState(false)
  const [configItem, setConfigItem] = useState({
    focusTabIndex: 0,
    tabs: [
      { title: 'Evaluate conditions', index: 0, comp: 'NotesComponent_Key', props: {} },
      { title: 'Orders needed', index: 1, comp: 'ReferralComponent_Key', props: {} },
    ]
  })
  const [isProgram, setIsProgram] = useState(false)
  const [value, setValue] = useState(configItem?.focusTabIndex || 0 as number)
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)

  useEffect(() => {
    setTimeout(function () {
      setIsLoadingPrg(false)
    }, 600);
    setIsProgram(isPrgEnroll())
  })

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    if (configItem?.tabs) {
      let activeTab = configItem?.tabs && configItem.tabs.find((i: any, index: any) => index === newValue)

    }
    setValue(newValue)
  }

  const a11yProps = (index: number) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  const isPrgEnroll = () => {
    return (item?.programs !== undefined)
      ? Object.keys(item?.programs)?.length > 0 ? true : false
      : false
  }
  const handlePrgEnrollment = (e: any) => {
    if (e?.target?.checked === false) {
      setRemovePrgEnrollment(true)
    } else if (e?.target?.checked === true) {
      handleSubmit(item?.diagnosisPrg, true)
    }
  }

  const handleConfirmClose = () => setRemovePrgEnrollment(false)

  const confirmStatus = (param: boolean) => {
    setRemovePrgEnrollment(false)
    const eId = patientDetail?.program_enrollments?.filter((m: { program: any }) => m.program === item?.programs[0])?.[0]?.enrollment_id || null
    handleSubmit(eId, false)
  }

  const getPrgName = (prg: any) => patientDetail?.program_enrollments?.filter((m: { program: any }) => m.program === prg[0])?.[0]?.program_display_name || prg

  const renderProblemTabs = (tab: any) => {
    return <>
      {tab === 0
        ? <>
          <Grid container direction="column" className={classes.evalToxCard}>
            <Grid item>
              <Typography className={classes.evalBoxLabel} color="textSecondary" variant="body2">High priority</Typography>
            </Grid>
            <Grid item>
              <Grid className={classes.evalContent} container spacing={1}>
                <Grid item className={classes.fullWidth}>
                  <Grid container direction="row" className={classes.evalToxCard}>
                    <Grid item>
                      <Typography className={classes.evalBoxLabel} variant="body2" color="textSecondary">
                        {'Condition1'}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.toxicityEvalBoxVal}>
                        {`Iron Deficiency Anemia (Disorder) `}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" className={classes.evalToxCard}>
                    <Grid item>
                      <Typography className={classes.evalBoxLabel} variant="body2" color="textSecondary">
                        {'Condition2'}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.toxicityEvalBoxVal}>
                        {`Anxity`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="column" className={classes.evalToxCard}>
            <Grid item>
              <Typography className={classes.evalBoxLabel} color="textSecondary" variant="body2">Others</Typography>
            </Grid>
            <Grid item>
              <Grid className={classes.evalContent} container spacing={1}>
                <Grid item className={classes.fullWidth}>
                  <Grid container direction="row" className={classes.evalToxCard}>
                    <Grid item>
                      <Typography className={classes.evalBoxLabel} variant="body2" color="textSecondary">
                        {'Condition3'}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.toxicityEvalBoxVal}>
                        {`Bipolar Disorder`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" className={classes.evalToxCard}>
                    <Grid item>
                      <Typography className={classes.evalBoxLabel} variant="body2" color="textSecondary">
                        {'Condition4'}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.toxicityEvalBoxVal}>
                        {`Chronic kideny disease`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
        : (
          <Grid container direction="column" spacing={1} className={classes.evalToxCard}>
            <Grid item>
              <Grid container direction="column" className={classes.evalToxCard}>
                <Grid item>
                  <Typography className={classes.evalBoxLabel} color="textSecondary" variant="body2">Order1</Typography>
                </Grid>
                <Grid item>
                  <Grid className={classes.evalContent} container spacing={1}>
                    <Grid item className={classes.fullWidth}>
                      <Grid container direction="row" className={classes.evalToxCard}>
                        <Grid item>
                          <Typography className={classes.evalBoxLabel} variant="body2" color="textSecondary">
                            {'Details'}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.toxicityEvalBoxVal}>
                            {`sacubitril 49 MG / valsartan 51 MG Oral Tablet [Entresto] [1656351]`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" className={classes.evalToxCard}>
                        <Grid item>
                          <Typography className={classes.evalBoxLabel} variant="body2" color="textSecondary">
                            {'Details'}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.toxicityEvalBoxVal}>
                            {`28 ACTUAT tiotropium 0.0025 MG/ACTUAT Inhalation Spray [Spiriva] [1552009]`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction="column" className={classes.evalToxCard}>
                <Grid item>
                  <Typography className={classes.evalBoxLabel} color="textSecondary" variant="body2">Order2</Typography>
                </Grid>
                <Grid item>
                  <Grid className={classes.evalContent} container spacing={1}>
                    <Grid item className={classes.fullWidth}>
                      <Grid container direction="row" className={classes.evalToxCard}>
                        <Grid item>
                          <Typography className={classes.evalBoxLabel} variant="body2" color="textSecondary">
                            {'Details'}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.toxicityEvalBoxVal}>
                            {`furosemide 40 MG Oral Tablet [313988]`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" className={classes.evalToxCard}>
                        <Grid item>
                          <Typography className={classes.evalBoxLabel} variant="body2" color="textSecondary">
                            {'Details'}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.toxicityEvalBoxVal}>
                            {`bupropion hydrochloride 150 MG Extended Release Oral Tablet [993521]`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
    </>
  }
  return <>
    <Draggable ref={isAddPrRef} defaultPosition={{ x: 0, y: 0 }} handle='.edit-problem-dialog'>
      <Dialog
        id={item.title}
        fullWidth={true}
        maxWidth={isProgram ? 'md' : 'sm'}
        open={open}
        onClose={handleDialogClose}
        aria-labelledby={item.title}
        hideBackdrop
        disableScrollLock
        sx={{
          position: "absolute",
          left: '15%',
          top: '20%'
        }}
      >
        <DialogTitle className={classes.addPatientQuestionHeaderSection} classes={{ root: 'edit-problem-dialog' }}>
          <DialogTitleHeaderComponent
            title={item?.title}
            handleDialogClose={handleDialogClose}
          />
        </DialogTitle>
        <Grid item>
          {isLoadingPrg && <LinearProgress />}
        </Grid>
        <DialogContent className={isProgram ? classes.dialogContentStyle : classes.prbdialogContentStyle}>
          <Grid container spacing={1} className={classes.containerPadding}>
            <Grid item className={classes.subTitle}>
              <Checkbox
                color='primary'
                size={'small'}
                name='prgEnrollment'
                onClick={handlePrgEnrollment}
                value={isProgram}
                checked={isProgram} />
              Diagnose the problem {getPrgName(item?.programs)}
            </Grid>
          </Grid>
          {isProgram &&
            <div className={classes.root}>
              <AppBar position="static" color="transparent">
                <Tabs value={value} onChange={handleChange} variant="standard">
                  {configItem?.tabs?.map((c: any) => (
                    <Tab
                      key={`config-${c.title}`}
                      label={c.title}
                      {...a11yProps(c.index)}
                      disabled={false}
                    />
                  ))}
                </Tabs>
              </AppBar>
              <Grid className={classes.linearProgressContainer}>
                {/* {loading === true && <LinearProgress />} */}
                <Box className={classes.dialogHeaderBox}>
                  <div className={classes.dialogHeader} />
                  {configItem?.tabs?.map((c: any, i: any) => (
                    <Box pl={2} pr={2} key={`compset-${i}`}>
                      <TabPanel value={value} index={c.index}>
                        {renderProblemTabs(c.index)}
                      </TabPanel>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.containerPadding} justifyContent="flex-end" >
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                disabled //</Grid>={currprg === ''}
                //onClick={() => handleSubmit(currprg)}
                component="label"
              >
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setCurrPrg('')
                  handleDialogClose()
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Draggable>
    {removePrgEnrollment && (
      <ConfirmationDialogComponent
        open={removePrgEnrollment}
        content={`Are you sure to remove program enrollment?`}
        handleConfirmClose={handleConfirmClose}
        confirmStatus={confirmStatus}
      />
    )}
  </>
};

export default withStyles(stylesheet)(EditProblemDialogComponent)
