import {
  Autocomplete,
  Box,
  Chip,
  Divider,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React from 'react'
import stylesheet from '../detail-container.stylesheet'
import { ClassNameMap, withStyles } from '@mui/styles'
import { checkCondition } from '../common/condition-check'
import DatePickerComponent from '../../../../utils/datepicker.component'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'

interface IProps {
  nameError: boolean
  values: any
  handleInputChange: any
  validateInput: any
  classes: ClassNameMap
}

const PatientProfileDetailsComponent: React.FC<IProps> = ({
  nameError,
  values,
  handleInputChange,
  validateInput,
  classes,
}) => {
  const metaData = useSelector((state: RootState) => state.metaData.metadata)
  const prefix = ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr']
  const maritalStatus = ['Never Married', 'Married', 'Separated', 'Divorced', 'Windowed']
  const hospitals = (metaData?.HOSPITALS)

  return (
    <Box pl={5.5} pr={5} pb={1}>
      <Typography variant='subtitle2'>Device Details</Typography>
      <Box pt={1} pb={1}>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              MAC Address
            </Typography>
            <TextField
              variant="standard"
              fullWidth={true}
              value={values.bpm_mac_address}
              name="bpm_mac_address"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              Refresh Token
            </Typography>
            <TextField
              variant="standard"
              fullWidth={true}
              value={values.bpm_refresh_token}
              name="bpm_refresh_token"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">Pylo BP monitor</Typography>
            <TextField
              variant="standard"
              fullWidth={true}
              value={values?.pylo_bpm_imei || ''}
              name="pylo_bpm_imei"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">Pylo weighing scale</Typography>
            <TextField
              variant="standard"
              fullWidth={true}
              value={values?.pylo_weighing_scale_imei}
              name="pylo_weighing_scale_imei"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              MBI Id
            </Typography>
            <TextField
              variant="standard"
              fullWidth={true}
              value={values.mbi_id}
              name="mbi_id"
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ padding: '1rem 1rem 1rem 0rem' }}> <Divider /> </Box>
      <Box pt={1} pb={2}>
        <Typography variant='subtitle2'>Patient Profile</Typography>
        <Grid container spacing={2} justifyContent="space-between" className={classes.patientProfileContainer}>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              Username
            </Typography>
            <Typography variant="body2">
              {checkCondition(values.username !== '', values.username, '-')}
            </Typography>
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">Clinic Patient Id</Typography>
            <TextField
              fullWidth={true}
              variant="standard"
              value={values?.clinic_patient_id || ''}
              name="clinic_patient_id"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">MRN</Typography>
            <TextField
              fullWidth={true}
              variant="standard"
              value={values.MRN}
              name="MRN"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              Name Prefix
            </Typography>
            <Select
              fullWidth={true}
              variant="standard"
              displayEmpty
              className={classes.cardLabNotes}
              inputProps={{ 'aria-label': 'With label' }}
              value={values.name_prefix}
              name="name_prefix"
              onChange={handleInputChange}
            >
              {prefix.map((r) => (
                <MenuItem className={classes.desc} value={r}>{r}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              Given Name
            </Typography>
            <TextField
              fullWidth={true}
              variant="standard"
              placeholder="enter given name"
              value={values.given_name}
              name="given_name"
              onChange={handleInputChange}
            />
            {checkCondition(nameError,
              <Typography variant="body2" className={classes.errorText}>
                Given name can not be cleared
              </Typography>
            )}
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              Middle Name
            </Typography>
            <TextField
              fullWidth={true}
              variant="standard"
              value={values.middle_name}
              name="middle_name"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              Family Name
            </Typography>
            <TextField
              fullWidth={true}
              variant="standard"
              value={values.family_name}
              name="family_name"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              Sex/Gender
            </Typography>
            <Select
              fullWidth={true}
              variant="standard"
              displayEmpty
              className={classes.cardLabNotes}
              inputProps={{ 'aria-label': 'With label' }}
              value={values.c_sex}
              name="c_sex"
              onChange={handleInputChange}
            >
              <MenuItem value="Male" className={classes.desc}>Male</MenuItem>
              <MenuItem value="Female" className={classes.desc}>Female</MenuItem>
            </Select>
            {validateInput.includes('c_sex') &&
              <FormHelperText>
                <Typography style={{ fontSize: '0.75rem' }} component="span" color="error">Please enter gender.</Typography>
              </FormHelperText>
            }
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              Email
            </Typography>
            <TextField
              fullWidth={true}
              variant="standard"
              name="email"
              value={values.email}
              onChange={handleInputChange}
              error={validateInput.includes('email')}
              helperText={
                validateInput.includes('email')
                  ? 'Please enter valid email.'
                  : ''
              }
            />
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              Birthdate
            </Typography>
            <DatePickerComponent
              name="birthdate"
              variant="standard"
              type="date"
              cssClass={classes.cardLabNotes}
              inputPropCss={classes.cardLabDate}
              value={values.birthdate}
              onCalendarChange={handleInputChange}
            />
            {validateInput.includes('birthdate') &&
              <FormHelperText>
                <Typography style={{ fontSize: '0.75rem' }} component="span" color="error">Please enter birthdate.</Typography>
              </FormHelperText>
            }
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              Phone
            </Typography>
            <TextField
              fullWidth={true}
              variant="standard"
              value={values.phone}
              name="phone"
              onChange={handleInputChange}
              error={validateInput.includes('phone')}
              helperText={
                validateInput.includes('phone')
                  ? 'Please enter valid phone no.'
                  : ''
              }
            />
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              Communication Language
            </Typography>
            <TextField
              fullWidth={true}
              variant="standard"
              value={values.communication_language}
              name="communication_language"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              Marital Status
            </Typography>
            <Select
              fullWidth={true}
              variant="standard"
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ 'aria-label': 'With label' }}
              value={values.marital_status}
              name="marital_status"
              onChange={handleInputChange}
            >
              {maritalStatus.map((r) => (
                <MenuItem className={classes.desc} value={r}>{r}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              City
            </Typography>
            <TextField
              fullWidth={true}
              variant="standard"
              value={values.city}
              name="city"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              Country
            </Typography>
            <TextField
              fullWidth={true}
              variant="standard"
              value={values.country}
              name="country"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              Address1
            </Typography>
            <TextField
              fullWidth={true}
              variant="standard"
              value={values.address_line_1}
              name="address_line_1"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              Address2
            </Typography>
            <TextField
              fullWidth={true}
              variant="standard"
              value={values.address_line_2}
              name="address_line_2"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              Zip Code
            </Typography>
            <TextField
              fullWidth={true}
              variant="standard"
              value={values.zip_code}
              name="zip_code"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              Ethnicity
            </Typography>
            <Select
              fullWidth={true}
              variant="standard"
              disabled
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ 'aria-label': 'With label' }}
              value={values.ethnicity}
              name="ethnicity"
              onChange={handleInputChange}
            >
              <MenuItem className={classes.desc} value="Male">ethnicity1</MenuItem>
              <MenuItem className={classes.desc} value="Female">ethnicity2</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              Responsible Party First Name
            </Typography>
            <TextField
              fullWidth={true}
              variant="standard"
              value={values.responsible_party_first_name}
              name="responsible_party_first_name"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">
              Responsible Party Last Name
            </Typography>
            <TextField
              fullWidth={true}
              variant="standard"
              value={values.responsible_party_last_name}
              name="responsible_party_last_name"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">Hospital</Typography>
            <Select
              fullWidth={true}
              variant="standard"
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ 'aria-label': 'With label' }}
              value={values.hospital_name}
              name="hospital_name"
              onChange={handleInputChange}
            >
              {Object.keys(hospitals)?.map((key: any) => (
                <MenuItem className={classes.desc} value={key}>{hospitals[key]}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={5.9}>
            <Typography variant="subtitle2" color="textSecondary">Assigned users</Typography>
            <Autocomplete
              clearIcon={true}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: '0.1rem 0.1rem 0.1rem 0rem !important'
                }
              }}
              options={[]}
              freeSolo
              multiple
              value={values?.assigned_users || []}
              renderTags={(value: any, props: any) =>
                value?.map((option: any, index: any) => <Chip label={option} {...props({ index })} />)
              }
              renderInput={(params: any) => <TextField
                className={classes.desc}
                variant='standard'
                // placeholder='Type & press enter key to add' 
                {...params}
              />
              }
              onChange={(e: any, val: any) => handleInputChange('assigned_users', val)}
              onKeyUp={(e: any) => {
                if (e.target.value !== '')
                  handleInputChange('update_assigned_users', [...values?.assigned_users, e.target.value])
              }}
            />
            <Typography sx={{ fontSize: '0.75rem' }} component="span" color="grey">
              <i>Type & press enter key to add.</i>
            </Typography>
            {validateInput.includes('assigned_users') &&
              <FormHelperText>
                <Typography sx={{ fontSize: '0.75rem' }} component="span" color="error">Please enter valid email.</Typography>
              </FormHelperText>
            }
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default withStyles(stylesheet)(PatientProfileDetailsComponent)
