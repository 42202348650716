import React, { useState } from 'react'
import { ClassNameMap, withStyles } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import {
	Button, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid,
	IconButton, LinearProgress, TextField, Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { sanitizedData } from '../../../../utils/sanitize-html'

interface IProps {
	open: boolean
	handleConfirmClose: any
	confirmStatus: any
	content: any
	classes: ClassNameMap
}

const ProviderConfirmationDialogComponent: React.FC<IProps> = ({ open, handleConfirmClose, confirmStatus, content, classes }) => {
	const [isConfirm, setIsConfirm] = useState(false)
	const [isSubmit, setIsSubmit] = useState(false)
	const [notes, setNotes] = useState('')
	const { validateSanitizeInput } = sanitizedData()
	const [noteErr, setNoteErr] = useState({ error: false, msg: '' })

	const hadleConfirm = () => {
		setIsSubmit(true)
		confirmStatus(true, notes)
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNotes(event.target.value)
		setNoteErr({ error: false, msg: '' })
	}

	const sanitize = () => {
		const sanitizeData = validateSanitizeInput(notes)
		setNoteErr({ error: sanitizeData.error, msg: sanitizeData.msg })
	}

	return (
		<Dialog fullWidth={true}
			data-testid='confirmation-testId'
			maxWidth='sm'
			open={open}
			onClose={handleConfirmClose}
			aria-labelledby="max-width-dialog-title"
			sx={{
				position: "absolute",
				left: '10rem',
				top: '15rem'
			}}
		>
			<DialogTitle className={classes.questionHeaderSection}>
				<Grid container justifyContent="space-between">
					<Grid item>
						<Typography id="modal-modal-title" variant="h6">
							<strong>{`Confirmation`}</strong>
						</Typography>
					</Grid>
					<Grid item>
						<IconButton size="small" onClick={() => handleConfirmClose()}>
							<CloseIcon />
						</IconButton>
					</Grid>
				</Grid>
			</DialogTitle>
			{isSubmit && <LinearProgress />}
			<DialogContent sx={{ padding: '1rem !important' }}>
				<Typography variant="subtitle2">{content}</Typography>
				{isConfirm &&
					<>
						<CardContent sx={{ paddingLeft: '0px' }}>
							<TextField
								id="notes-basic"
								label="Start new notes here..."
								value={notes}
								variant="outlined"
								size="small"
								InputProps={{ style: { fontSize: 15 } }}
								InputLabelProps={{ style: { fontSize: 15 } }}
								onChange={handleChange}
								onBlur={sanitize}
								error={noteErr.error}
								helperText={noteErr.msg}
								multiline={true}
								minRows="3"
								className={classes.fullWidth} />
						</CardContent>
					</>
				}
			</DialogContent>
			<DialogActions className={classes.cardLabInfo}>
				{isConfirm
					? <Button
						variant="outlined"
						disabled={!notes}
						size="small"
						onClick={() => hadleConfirm()}
					>
						{`Submit`}
					</Button>
					: <Button
						variant="outlined"
						disabled={isConfirm}
						size="small"
						onClick={() => setIsConfirm(true)}
					>
						{`YES`}
					</Button>}
				<Button variant="outlined" size="small" onClick={() => handleConfirmClose()}>
					{isConfirm ? `Cancel` : `NO`}
				</Button>
			</DialogActions>
		</Dialog >
	)
}

export default withStyles(stylesheet)(ProviderConfirmationDialogComponent)