export const useReferralAction = () => {
  const onSetView = (_id: string, _mode: string, data: any) => {
    return data.map((el: any) => (el.id === _id ? { ...el, mode: _mode } : el))
  }

  const onDataSubmit = (_id: any, date: number, is_referred: boolean, referral_id: any, data: any) => {
    return data.map((el: any) =>
      el.id === _id
        ? { ...el, is_referred: is_referred, referral_id: referral_id, mode: 'view', referred_on: date }
        : el
    )
  }

  return {
    onSetView,
    onDataSubmit,
  }
}
