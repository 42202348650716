import React from 'react';
import { Grid, Typography } from '@mui/material';
import stylesheet from "../detail-container.stylesheet";
import CloseIcon from '@mui/icons-material/Close';
import { ClassNameMap, withStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { checkCondition } from './condition-check';
import Helper from '../../../../utils/helper';
import IngredientTooltip from './ingredient-tooltip'

interface IProps {
	item: any
	handleDialogClose: any
	showClose?: boolean
	classes: ClassNameMap
}

const DialogModalHeaderComponent: React.FC<IProps> = ({ item, handleDialogClose, showClose = true, classes }) => {
	const uiResource = useSelector((state: RootState) => state.metaData.metadata)
	const selectedPU = useSelector((state: RootState) => state.commonData.selectedPU)

	const formatMedDetails = (pu_rec: any) => {
		if (pu_rec && pu_rec !== null && Object.keys(pu_rec)?.length > 0) {
			const temp = (selectedPU && selectedPU.hasOwnProperty('name') && selectedPU?.name !== '')
				? selectedPU
				: pu_rec

			return Object.keys(temp).length > 0 ? `${temp?.num_units && temp.num_units} ${checkCondition(temp?.formulation_display_name, `${temp?.formulation_display_name},`, '')} ${checkCondition(temp?.route_display_name, `${temp?.route_display_name},`, '')} ${Helper.toLowerlCase(uiResource.MEDICATION_DOSAGE_FREQUENCY[temp.frequency])}` : ''
		} else {
			return ''
		}
	}

	const formatCurrent = (_d: any, pu_order: any) => {
		return Object.keys(pu_order).length ? `${pu_order?.num_units ? pu_order?.num_units : ''} ${checkCondition(pu_order?.formulation_display_name, `${pu_order?.formulation_display_name},`, '')} ${checkCondition(pu_order?.route_display_name, `${pu_order?.route_display_name},`, '')} ${Helper.toLowerlCase(uiResource.MEDICATION_DOSAGE_FREQUENCY[pu_order.frequency])} ` : ''
	}

	const formatSubHeader = (d: any) => {
		return (d.is_recommended) ?
			`${formatMedDetails(d?.pu_rec)}`
			:
			`${d.c_schedule?.dose} ${d.c_schedule?.unit}, ${Helper.toLowerlCase(uiResource.MEDICATION_DOSAGE_FREQUENCY[d.c_schedule?.frequency])}`
	}

	const getTitle = () => {
		const cuttTitle = checkCondition((selectedPU !== undefined && selectedPU.hasOwnProperty('name') && selectedPU?.name !== ''),
			Helper.getRXnorm(selectedPU.name, selectedPU?.rxcui),
			Helper.getRXnorm(checkCondition(item?.pu_med_name, item?.pu_med_name, (item?.name || '')), item?.rxnorm)
		)

		if ((item.titration_status)?.includes('discontinue') && (Object.keys(item.pu_order).length !== 0)) {
			const recomTitle = Helper.getRXnorm(item.pu_order?.name, item?.rxnorm)
			if (cuttTitle === recomTitle)
				return ''
		}
		else return cuttTitle
	}

	const getCurrentMed = (isRecom: Boolean) => {
		if (isRecom) {
			if ((item.titration_status)?.includes('discontinue')) {
				return <>
					<Typography variant='subtitle2'>
						{checkCondition(Object.keys(item.pu_order).length !== 0, item.pu_order?.name && `${item?.is_recommended && 'Stop '}${Helper.getRXnorm(item.pu_order?.name, item?.rxnorm)}`, '')}
					</Typography>
					<Typography variant='subtitle2' color="textSecondary"><small>
						{formatCurrent(item, item.pu_order)}</small>
					</Typography>
				</>
			}
		} else {
			return <>
				<Typography variant='subtitle2'>
					{checkCondition(Object.keys(item.pu_order).length !== 0, Helper.getRXnorm(item.pu_order?.name, item?.taking_med_rxnorm))}
				</Typography>
				<Typography variant='subtitle2' color="textSecondary"><small>
					{checkCondition((item.titration_status)?.includes('discontinue') || item?.isPrgMed, '', checkCondition(item.titration_status === 'initiate', 'Taking ', checkCondition(item.intake_started_on === null, 'Stop ', 'Taking ')))}
					{formatCurrent(item, item.pu_order)} <IngredientTooltip ingredients={item?.calc_dose} /></small>
				</Typography>
			</>
		}
	}

	return (<Grid container direction="row" justifyContent="space-between" alignItems="baseline">
		<Grid item xs={6}>
			{!item?.isOtherMed && <Typography variant='subtitle2'>Current</Typography>}
			{checkCondition(Object.keys(item.pu_order).length === 0,
				<Typography variant='subtitle2'> Not yet initiated</Typography>,
				getCurrentMed(false)
			)}
		</Grid>
		{checkCondition(Object.keys(item.pu_order).length === 0 && item.titration_status === 'initiate' && item?.is_recommended === true,
			<Grid item xs={5} >
				<Typography variant='subtitle2'>Recommendation</Typography>
				<Typography variant='subtitle2'>{getTitle()} </Typography>
				{(item?.titration_status)?.includes('discontinue') ?
					getCurrentMed(true)
					:
					<Typography variant='subtitle2'>
						<small>
							{checkCondition(item.c_rec_schedules?.length > 0 && formatSubHeader(item) !== '', 'Start ', '')}
							{formatSubHeader(item)}
						</small>
					</Typography>
				}
			</Grid>
		)}
		{checkCondition(item?.isPrgMed !== true && (item.titration_status !== 'initiate' && item.intake_started_on === null),
			<Grid item xs={5}>
				<Typography variant='subtitle2'>Recommendation</Typography>
				<Typography variant='subtitle2'>{getTitle()} </Typography>
				{(item.titration_status)?.includes('discontinue') ?
					getCurrentMed(true)
					:
					<Typography variant='subtitle2' color="textSecondary">
						<small>
							{checkCondition(item.c_rec_schedules?.length > 0 && formatSubHeader(item) !== '',
								checkCondition(item.titration_status === 'initiate_current',
									`Continue ${formatSubHeader(item)}`,
									`Start ${formatSubHeader(item)}`), 'Details not available')
							}
						</small>
					</Typography>
				}
			</Grid>
		)}
		{showClose === true &&
			<Grid item xs>
				<CloseIcon className={classes.closeIcon} onClick={handleDialogClose} />
			</Grid>
		}
	</Grid>
	)
}

export default withStyles(stylesheet)(DialogModalHeaderComponent);