import React from 'react';
import {
	Box, Button, ButtonGroup, Card, CardContent, Grid, Table, TableBody, TableCell,
	TableContainer, TableRow, Typography
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import stylesheet from "../detail-container.stylesheet";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

interface IProps {
	data: any,
	outer: any,
	classes: ClassNameMap
}

const schedules = [
	{
		"id": 1,
		"selected": true,
		"type": "preferred",
		"name": "Methyldope schedule 1",
		"conservative_mode": "most conservative",
		"starting_dose_1": "500mg",
		"starting_dose_2": "One Tablet By Month",
		"frequency_1": "Two Times Daily",
		"frequency_2": "Morning & Evening",
		"days_before_1": "7 days",
		"days_before_2": "",
		"note": "can be taken with or without food"
	},
	{
		"id": 2,
		"selected": false,
		"type": "alternate",
		"name": "Methyldope schedule 2",
		"conservative_mode": "moderate conservative",
		"starting_dose_1": "400mg",
		"starting_dose_2": "One Tablet By Month",
		"frequency_1": "Two Times Daily",
		"frequency_2": "Morning, afternoon & Evening",
		"days_before_1": "15 days",
		"days_before_2": "",
		"note": "can be taken with or without food"
	},
	{
		"id": 3,
		"selected": false,
		"type": "alternate",
		"name": "Methyldope schedule 3",
		"conservative_mode": "least conservative",
		"starting_dose_1": "800mg",
		"starting_dose_2": "One Tablet By Month",
		"frequency_1": "Two Times Daily",
		"frequency_2": "Morning ",
		"days_before_1": "15 days",
		"days_before_2": "",
		"note": "can be taken with or without food"
	},
]

const ScheduleCarePlan: React.FC<IProps> = ({ data, outer, classes }) => {
	const onBtnSubmit = (_id: string, _mode: string, _action: string, _date: Date | null) => {
		if (data.is_order_submitted) {
			outer(_id, _mode, 'start', _date)
		} else {
			outer(_id, _mode, _action, _date)
		}
	}

	const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date(data.date_recorded ?? new Date()));
	const [schedulesData, setSchedulesData] = React.useState(schedules);
	const [showAlternate, setShowAlternate] = React.useState(false);

	const onCardSelection = (_id: number) => {
		setSchedulesData(schedulesData.map((m: any) => m.id === _id ? { ...m, selected: true } : { ...m, selected: false }))
	}

	const showAlternateChange = () => {
		setShowAlternate(!showAlternate)
	}

	const tableFormation = (e: any) => {
		return <CardContent className={classes.scheduleCarePlanTable} >
			<TableContainer >
				<Table className={classes.table} aria-label="simple table">
					<TableBody>
						<TableRow>
							<TableCell colSpan={3} className={classes.tableCellEditTop}>
								<Grid container xs={12} direction="row" justifyContent="space-between" alignItems="baseline" className={classes.initialFlexwrap} >
									<Typography variant="subtitle2"> {e.name} </Typography>
									<Typography color="textSecondary" variant="body2"> <small>{e.conservative_mode}</small></Typography>
								</Grid>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell width="31%" className={classes.tableCellEditTopRB}>
								<Grid item>
									<Typography color="textSecondary" variant="subtitle2"><small>Starting dose</small> </Typography>
									<Typography variant="subtitle2"> {e.starting_dose_1}</Typography>
									<Typography color="textSecondary" variant="subtitle2"> <small>{e.starting_dose_2}</small></Typography>
								</Grid>
							</TableCell>
							<TableCell width="31%" className={classes.tableCellEditTopRB}>
								<Grid item>
									<Typography color="textSecondary" variant="subtitle2"><small>Frequency</small> </Typography>
									<Typography variant="subtitle2"> {e.frequency_1}</Typography>
									<Typography color="textSecondary" variant="subtitle2"><small> {e.frequency_2}</small></Typography>
								</Grid>
							</TableCell>
							<TableCell width="38%" className={classes.tableCellEditTop}>
								<Grid item>
									<Typography color="textSecondary" variant="subtitle2"><small>Days before next step</small> </Typography>
									<Typography variant="subtitle2"> {e.days_before_1}</Typography>
									<Typography color="textSecondary" variant="subtitle2"><small> {e.days_before_2}</small></Typography>
								</Grid>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell colSpan={3} className={classes.tableCellEditTop}>
								<Typography color="textSecondary" variant="body1"><small> {`* ${e.note}`}</small></Typography>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>

		</CardContent >
	}

	return (<>
		<Box mb={1} className={classes.fullWidth}>
			<Card variant="outlined" className={classes.scheduleCarePlanContainer}>
				<CardContent className={classes.zeroPadding} >
					<Box p={1} pt={2}>
						<Grid item  >
							<Typography variant="subtitle2" className={classes.nameLineHeight} >{`Select Schedule`}</Typography>
						</Grid>
						<Grid item className={classes.preferredScheduleCarePlan} >
							<Typography color="textSecondary" variant="subtitle2">{`Preferred schedule`}</Typography>
						</Grid>
					</Box>
					<Box p={1} pt={0} >
						{schedulesData.filter((f: any) => f.type === 'preferred').map((e: any) => <>
							<Card variant="outlined" onClick={() => onCardSelection(e.id)} className={[e.selected ? classes.selectedScheduleCardLayout : classes.grayScheduleCardLayout, classes.selectedScheduleCardMargin].join(' ')}>
								{tableFormation(e)}
							</Card>
						</>
						)}
						<Grid container xs={12} direction="row" justifyContent="flex-start" alignItems="center" className={classes.initialFlexwrap} >
							<div className={classes.pointerCursor} onClick={() => showAlternateChange()}>
								{showAlternate ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
							</div>
							<Typography className={classes.alternateSchedules}
								onClick={() => showAlternateChange()} color="textSecondary" variant="subtitle2">
								{`Alternate Schedules (${schedulesData.filter((f: any) => f.type === 'alternate').length})`}</Typography>
						</Grid>
						{showAlternate === true && <>{
							schedulesData.filter((f: any) => f.type === 'alternate').map((e: any) => <>
								<Card variant="outlined" onClick={() => onCardSelection(e.id)} className={[e.selected ? classes.selectedScheduleCardLayout : classes.grayScheduleCardLayout, classes.selectedScheduleCardMargin].join(' ')}>
									{tableFormation(e)}
								</Card>
							</>
							)
						}</>
						}
					</Box>
					<Box p={2} pt={1} className={[classes.questionHeaderSection, classes.questionHeaderSectionBg].join(' ')}>
						<Grid item  >
							<Typography variant="subtitle2" >Have you finalized and communicated the program schedule with patient?</Typography>
						</Grid>
					</Box>
					<Box>
						<Grid container xs={12} direction="row" justifyContent="space-around" alignItems="center">
							<ButtonGroup className={classes.questionButtonStyle} size="small" variant="text" aria-label="small outlined button group">
								<Button onClick={() => onBtnSubmit(data.id, 'view', 'save', selectedDate)} className={classes.twoButtonWidth}><strong>Yes</strong></Button>
								<Button onClick={() => onBtnSubmit(data.id, 'view', '', data.selected_date)} className={classes.twoButtonWidth}><strong>No</strong></Button>
							</ButtonGroup>
						</Grid>
					</Box>
				</CardContent>
			</Card>
		</Box>
	</>)
}

export default withStyles(stylesheet)(ScheduleCarePlan);