import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
    addControl: [
        { key: 'CO-OC', desc: 'Other Conditions', value: false },
        { key: 'MD-IMMD', desc: 'Other Medications', value: false },
        { key: 'MD-PRM', desc: 'Med Intolerances', value: false },
        { key: 'ME-PDI', desc: 'Program Checks', value: false },
        { key: 'ME-CS', desc: 'Clinical Scores', value: false },
        { key: 'PA-JOR', desc: 'Patient Journey', value: false },
        { key: 'MD-PRMT', desc: 'Monitors', value: false },
        { key: 'MD-LS', desc: 'Lifestyle Plans', value: false },
        { key: 'CO-ICD10', desc: 'ICD10 Codes', value: false },
    ]
}

export const currentStatus = createAsyncThunk(
    "currentStatus",
    async (arg: any, thunkAPI: any) => {
        return await Promise.resolve(thunkAPI.getState().addButtons.addControl.map((e: { key: any }) => e.key === arg.key ? { ...e, ...arg } : e))
    }
)

export const addControlSlice = createSlice({
    name: 'addControl',
    initialState,
    reducers: {
        setAddValue: (state, action: PayloadAction<any>) => {
            state.addControl = [...action.payload]
        },
        resetAddValues : (state) => {
            state.addControl = initialState.addControl
        },
    },
    extraReducers: (builder) => {
        builder.addCase(currentStatus.fulfilled, (state, action: PayloadAction<any>) => {
            state.addControl = action.payload
        })
    }
})

export const { setAddValue, resetAddValues } = addControlSlice.actions

export default addControlSlice.reducer