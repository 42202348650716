import React, { useState, ReactElement, useRef, useEffect } from 'react'
import { withStyles, ClassNameMap } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import {
	Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, Link, Typography,
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Draggable from "react-draggable"
import EmptyCardComponent from './empty-card.component';
import { PatientService } from '../../../../services/patient.service';
import { toast } from 'react-toastify';
import Helper from '../../../../utils/helper';

interface IProps {
	open: boolean
	handleClose: any
	title: string | ReactElement
	input: any
	classes: ClassNameMap
}

const MedEvidenceComponent: React.FC<IProps> = ({ open, handleClose, title, input, classes }) => {
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState({} as any)
	const conextRef = useRef(null) as any
	const patientService = new PatientService()

	useEffect(() => {
		getMedEvidenceData()
	}, [])

	const getMedEvidenceData = () => {
		setLoading(true)
		input.med_id && patientService.getMedEvidence(input.patientId, input.program, input.med_id)
			.then((res: any) => {
				setData(res?.data || {})
				setLoading(false)
			}).catch((error: any) => {
				toast.error(Helper.getErrorText(error), Helper.bottom_center())
				setLoading(false)
			})
	}

	const renderKeyVal = (title: any, url: any, pg: any) => {
		return <Grid container spacing={1} direction={"row"}>
			<Grid item>
				<Typography variant="subtitle2" className={classes.subHeaderTitle}>
					<Link href={url} target="_blank">{title}</Link>
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="body2" className={classes.evalBoxLabel} color="textSecondary">
					{`Page no`}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="body2" className={classes.evalBoxLabel}>{pg?.join(', ') || ''}</Typography>
			</Grid>
		</Grid>
	}

	const renderDetails = (item: any) => renderKeyVal(item.name, item.url, item.pages)

	return <>
		{open &&
			<Draggable ref={conextRef} defaultPosition={{ x: 80, y: 20 }} handle='.med-evidence-dialog'>
				<Dialog
					id={`education-id-${input.med_id}`}
					fullWidth={true}
					maxWidth="md"
					open={open}
					onClose={handleClose}
					hideBackdrop
					disableScrollLock
					sx={{
						position: "absolute",
						left: '15%',
						top: '12%'
					}}
				>
					<DialogTitle className={classes.contextualHelpHeader} classes={{ root: 'med-evidence-dialog' }}>
						<Grid container justifyContent="space-between">
							<Grid item>
								<Typography color="textSecondary">
									<small>{`Med evidences / Treatments`}</small>
								</Typography>
								<Typography id="modal-modal-title" variant="h6">
									<strong>{data?.pu_name || title}</strong>
								</Typography>
							</Grid>
							<Grid item>
								<IconButton size="small" onClick={() => handleClose()}>
									<CloseOutlinedIcon />
								</IconButton>
							</Grid>
						</Grid>
					</DialogTitle>
					{loading && <LinearProgress />}
					<DialogContent className={`${classes.contextualHelpContext} ${classes.zeroPadding}`}>
						<Box className={classes.contextualHelpBox}>
							<Grid container spacing={1.5} className={classes.contextualHelpContainer}>
								{!loading && Object.keys(data)?.map((item: any, i: any) => (
									<Grid className={classes.fullWidth} item key={`ms-evidenc-${i}`}>
										<Typography pb={0.4} variant="subtitle2" className={classes.subTitle}>{item}</Typography>
										<Box>{renderDetails(data[item])}</Box>
									</Grid>
								))}
								{!loading && Object.keys(data)?.length <= 0 &&
									<Grid className={classes.fullWidth} item><EmptyCardComponent /></Grid>
								}
							</Grid>
						</Box>
					</DialogContent>
				</Dialog>
			</Draggable>
		}
	</>
}

export default withStyles(stylesheet)(MedEvidenceComponent)