import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

export interface CounterState {
    count: number,
}

const initialState: CounterState = {
    count: -1,
}

function delay(current: any): Promise<any> {
    return Promise.resolve(current - 1);
}

export const delayedDecrement =
    createAsyncThunk(
        "delayedIncrement",
        async (arg, thunkAPI: any) => {
            const response = await delay(thunkAPI.getState().counter.count)
            return response
        }
    )

export const currentCount =
    createAsyncThunk(
        "currentCount",
        async (arg, thunkAPI: any) => {
            return await Promise.resolve(thunkAPI.getState().counter.count)
        }
    )

export const counterSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        increment: (state) => {
            state.count += 1
        },
        decrement: (state) => {
            state.count -= 1
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(delayedDecrement.fulfilled, (state, action: PayloadAction<any>) => {
            state.count = action.payload
        });
        builder.addCase(currentCount.fulfilled, (state, action: PayloadAction<any>) => {
            state.count = action.payload
        })
    }
})

// Action creators are generated for each case reducer function
export const { increment, decrement, setCount } = counterSlice.actions

export default counterSlice.reducer