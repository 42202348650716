import React from 'react';
import { Box, Grid } from '@mui/material';
import { withStyles } from '@mui/styles'
import stylesheet from "../../patients/detail/detail-container.stylesheet";

const MessageComponent: React.FC<any> = () => {
	return (
		<Box pt={1}>
			<Grid container justifyContent="flex-start" spacing={2} >
				<Grid item >
					{`Message`}
				</Grid>
			</Grid>
		</Box>
	)
}

export default withStyles(stylesheet)(MessageComponent);