import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PatientService } from '../services/patient.service';


export interface MedicationReactionState {
    medicationReaction: any,
    status: any
}

const patientService = new PatientService()

const initialState: MedicationReactionState = {
    medicationReaction: [],
    status: null
}

export const getMedicationReaction = createAsyncThunk(
    'medicationReaction/getMedicationReaction',
    async () => {
        const response = patientService.getMedicationReaction()
        return (await response)
    }
)

export const medicationReactionSlice = createSlice({
    name: 'medicationReaction',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMedicationReaction.pending, (state) => {
            state.status = 'loading'
        });
        builder.addCase(getMedicationReaction.fulfilled, (state, { payload }) => {
            state.status = 'success';
            state.medicationReaction = payload.data
        });
        builder.addCase(getMedicationReaction.rejected, (state) => {
            state.status = 'failed'
        });
    }

})

export default medicationReactionSlice.reducer