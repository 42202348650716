import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import moment from 'moment'
import { useSelector } from 'react-redux'
import stylesheet from '../detail-container.stylesheet'
import { PatientService } from '../../../../services/patient.service'
import CardLayoutComponent from '../common/card-layout.component'
import { RootState } from '../../../../redux/store'
import Helper from '../../../../utils/helper'
import EmptyCardComponent from '../common/empty-card.component'
import MedDetailsComponent from '../common/med-details.component'
interface IProps {
  patientInfo: any
  classes: ClassNameMap
}

const ImportedMedComponent: React.FC<IProps> = ({
  patientInfo,
  classes,
}) => {
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const uiResource = useSelector((state: RootState) => state.metaData.metadata)
  const [data, setData] = useState([] as any)
  const patientService = new PatientService()
  const [openFhirJson, setOpenFhirJson] = useState(false)
  const [medMoreDetails, setMedMoreDetails] = useState({} as any)
  const [medTitle, setMedTitle] = useState('')

  useEffect(() => {
    initialiseData()
  }, [patientDetail])

  const initialiseData = () => {
    let stage = []
    stage = patientDetail?.medications?.non_synced_medications?.map(
      (e: any) => {
        return { ...e, mode: 'view' }
      }
    )
    setData(stage)
  }

  const formatConfirmDate = (
    confirm_timestamp: any,
    save_timestamp: any,
    needs_confirmation: boolean,
    imported: boolean,
    is_referred: boolean,
    referred_on: number
  ) => {
    if (is_referred) {
      return moment.unix(referred_on).format('MM/DD/YY')
    } else if (needs_confirmation) {
      if (save_timestamp) {
        return moment.unix(save_timestamp).format('MM/DD/YY')
      } else {
        return ''
      }
    } else {
      if (confirm_timestamp) {
        return moment.unix(confirm_timestamp).format('MM/DD/YY')
      } else {
        return ''
      }
    }
  }

  const setIcons = (type: any) => {
    return [
      // { iconType: 'edit', callback: onSetDetail },
      // { iconType: 'description', callback: onNotesClick },
      { iconType: 'more', callback: {} },
    ]
  }

  const formatSubHeader = (d: any) => {
    const num_units = d.pu_order?.num_units ? d.pu_order?.num_units : ''
    const formulation = d.pu_order?.formulation_display_name ? `${d.pu_order?.formulation_display_name}` : ''
    const route = d.pu_order?.route_display_name ? `${d.pu_order?.route_display_name}` : ''
    const frequency = uiResource.MEDICATION_DOSAGE_FREQUENCY[d.pu_order?.frequency] ?
      uiResource.MEDICATION_DOSAGE_FREQUENCY[d.pu_order?.frequency] : ''
    const dosageInfo = (d?.dosage_info) && d?.dosage_info?.[0]?.medicationTimings
      ? uiResource.MEDICATION_TIMINGS[d?.dosage_info[0]['medicationTimings']]
      : ''
    const text = `${num_units}${formulation}${route}${frequency}` ? `${num_units} ${formulation} ${route} ${frequency} ${dosageInfo}` : 'Details not available'
    return <>{text}</>
  }

  const getMedMoreDetails = (pId: string, recId: string, medName: string, code: any) => {
    setMedTitle(`${medName} [${code}]`)

    patientService.getImportedMedMoreDetails(pId, recId)
      .then((response: any) => {
        setMedMoreDetails(response.data?.fhir_json);
      }).catch((err: any) => {
        console.error(err);
      });
    setOpenFhirJson(true)
  }

  const handleFhirJsonClose = () => setOpenFhirJson(false)

  const getMedCard = (data: any, type: any) => {
    return data?.map((d: any) => {
      return <React.Fragment key={`reconcile-med-${d.name}`}>
        {d.mode === 'view' && (
          <>
            <CardLayoutComponent
              id={d.id}
              name={d?.name}
              leftHeader={Helper.getRXnorm(d.pu_med_name ? d.pu_med_name : d.name, d?.rxnorm)}
              rightHeader={d.intake_regularity?.charAt(0).toUpperCase() + d.intake_regularity?.slice(1) || ''}
              subHeader={formatSubHeader(d)}
              bottomText={formatConfirmDate(
                d.recent_confirmation?.confirmed_on,
                d.date_recorded,
                d.needs_confirmation,
                d.source_type === 'imported' ? true : false,
                d.is_referred,
                d.referred_on
              )}
              needs_confirmation={d.needs_confirmation || undefined}
              needs_update={d.needs_update || undefined}
              icons={setIcons(type)}
              programs={d.programs || undefined}
              onRefClick={() => undefined}
              is_referred={d.is_referred}
              is_overridden={patientInfo?.isPrgMed ? d.is_overridden : undefined}
              onOverrideClick={() => { }}
              onMedDetailsClick={() => getMedMoreDetails(patientDetail.id, d.id, d.pu_med_name ? d.pu_med_name : d.name, d?.code)}
              entityRef={d?.is_quarantine ? 'med_reconcilation_quarantine' : d?.id !== null ? 'med_reconcilation_mapped' : 'med_reconcilation'}
              patientId={patientDetail.id}
              category={'medications'}
            />
          </>
        )}
      </React.Fragment>
    })
  }

  return (
    <>
      <Grid container direction="row">
        <Grid item className={classes.fullWidth}>{getMedCard(data, '')}</Grid>
        {data?.length == 0 && <EmptyCardComponent />}
      </Grid>
      {openFhirJson &&
        <MedDetailsComponent
          openFhirJson={openFhirJson}
          medTitle={medTitle}
          handleFhirJsonClose={handleFhirJsonClose}
          medMoreDetails={medMoreDetails}
        />
      }
    </>
  )
}

export default withStyles(stylesheet)(ImportedMedComponent)
