import sanitizeHtml from 'sanitize-html'

export const sanitizedData = () => {
  const sanitizeConf = {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'h1'],
    allowedAttributes: { a: ['href'] },
  }

  const validateSanitizeInput = (inputStr: any) => {
    const sanitizeData = sanitizeHtml(inputStr, sanitizeConf)
    if (inputStr !== sanitizeData) {
      return {
        value: inputStr,
        updatedValue: sanitizeData,
        error: true,
        msg: 'Please enter valid input.',
      }
    } else {
      return {
        value: inputStr,
        updatedValue: sanitizeData,
        error: false,
        msg: '',
      }
    }
  }

  const sanitizeInput = (inputStr: any) => {
    return sanitizeHtml(inputStr, sanitizeConf)
  }

  return {
    validateSanitizeInput,
    sanitizeInput,
  }
}
