import React, { useState } from 'react'
import {
	Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import DialogTitleHeaderComponent from './dialog-title-header.component'

interface IProps {
	open: boolean
	handleConfirmClose: any
	confirmStatus: any
	content: string
	classes: ClassNameMap
}

const ConfirmationDialogComponent: React.FC<IProps> = ({ open, handleConfirmClose, confirmStatus, content, classes }) => {
	const [isConfirm, setIsConfirm] = useState(false)

	const hadleConfirm = () => {
		setIsConfirm(true)
		confirmStatus(true)
	}

	return (
		<Dialog
			fullWidth={true}
			data-testid='confirmation-testId'
			maxWidth='sm'
			open={open}
			onClose={handleConfirmClose}
			aria-labelledby="max-width-dialog-title"
			onKeyUp={(e) => {
				if (e.key === 'Enter' && !isConfirm)
					hadleConfirm()
			}}
			sx={{
				position: "absolute",
				left: '14rem',
				top: '15rem'
			}}
		>
			<DialogTitle className={classes.questionHeaderSection}>
				<DialogTitleHeaderComponent
					title={'Confirmation'}
					handleDialogClose={handleConfirmClose}
				/>
			</DialogTitle>
			{isConfirm && <LinearProgress />}
			<DialogContent sx={{ padding: '1rem !important' }}>
				<Typography variant="subtitle2">{content}</Typography>
			</DialogContent>
			<DialogActions className={classes.cardLabInfo} >
				<Button
					variant="outlined"
					disabled={isConfirm}
					size="small"
					onClick={() => hadleConfirm()}
				>
					{`YES`}
				</Button>
				<Button variant="outlined" size="small" onClick={() => handleConfirmClose()}                >
					{`NO`}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default withStyles(stylesheet)(ConfirmationDialogComponent)