import React, { memo, useEffect, useState } from 'react';
import { Typography, FormControl, InputLabel, Select, MenuItem, ListSubheader, Grid, Box } from '@mui/material';
import stylesheet from "../detail-container.stylesheet";
import { ClassNameMap, withStyles } from '@mui/styles'
import mtz from 'moment-timezone';
import DatePickerComponent from '../../../../utils/datepicker.component'

interface IProps {
  dateValue: any
  onChangeEvent: any
  onTimeZoneChange: any
  disabled: boolean
  classes: ClassNameMap
}
let abbrDt: any[] = []
const DateTimeZoneComponent: React.FC<IProps> = ({ dateValue, onChangeEvent, onTimeZoneChange, disabled, classes }) => {
  const [tzList, setTzList] = useState([] as any)
  const [selectedTimeZone, setSelectedTimeZone] = useState('')
  const [selectedTimeZoneAbbr, setSelectedTimeZoneAbbr] = useState('')
  useEffect(() => {
    const currentTimeZone = mtz.tz.guess()
    const dt = [...mtz.tz.zonesForCountry('US', true).map(m => { return { ...m, abbr: mtz().tz(m.name).format('zz') } }),
    ...[{ name: 'Asia/Calcutta', offset: -330, abbr: 'IST' }]]
    abbrDt = Array.from(new Set(dt.map(m => m.abbr)))
    setTzList(dt)
    setSelectedTimeZone(currentTimeZone)
    if (currentTimeZone === 'Asia/Calcutta') {
      setSelectedTimeZoneAbbr('IST')
    } else {
      setSelectedTimeZoneAbbr(dt.find((f: { name: string }) => f.name === currentTimeZone)?.abbr || '')
    }
  }, [])

  useEffect(() => {
    onTimeZoneChange(selectedTimeZone)
  }, [onTimeZoneChange, selectedTimeZone])

  const onTimeZoneChangeFun = (e: any) => {
    if (e.target.value) {
      setSelectedTimeZone(e.target.value)
      setSelectedTimeZoneAbbr(tzList.find((f: { name: string }) => f.name === e.target.value)?.abbr)
    } else {
      return false
    }
  }

  const renderSelectGroup = (header: string) => {
    const items = tzList.filter((f: { abbr: any; }) => f.abbr === header).map((p: { name: any }) => {
      return (
        <MenuItem className={classes.desc} key={p.name} value={p.name}>
          {p.name}
        </MenuItem>
      );
    });
    return [<ListSubheader disableSticky><Typography variant="body1" className={classes.subTitle}>{header}</Typography></ListSubheader>, items];
  };

  return (
    <Grid container>
      <Grid item xs={10} className={classes.fullWidth}>
        <DatePickerComponent
          type="datetime-local"
          disabled={disabled}
          cssClass={classes.cardLabNotes}
          inputPropCss={classes.cardLabDate}
          value={dateValue}
          onCalendarChange={(e: any) => onChangeEvent(e)}
        />
        <Box sx={{ padding: '0.5rem 0.05rem 0.05rem 0.05rem' }}>
          <FormControl variant="standard" sx={{ m: 1, width: '100%' }}>
            <InputLabel id="timezone-label">{`TimeZone:  ${selectedTimeZoneAbbr}`}</InputLabel>
            <Select
              disabled={disabled}
              className={classes.cardLabNotes}
              defaultValue=""
              id="timezone"
              labelId="timezone-label"
              value={selectedTimeZone}
              onChange={onTimeZoneChangeFun}
            >
              {abbrDt?.map(p => renderSelectGroup(p))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  )
}

export default memo(withStyles(stylesheet)(DateTimeZoneComponent));