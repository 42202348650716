import React, { useState, useEffect, ReactElement, useRef } from 'react'
import { withStyles, ClassNameMap } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import {
	Dialog, DialogContent, DialogTitle, Grid, IconButton, LinearProgress,
	Typography, Box, Accordion, AccordionSummary, AccordionDetails, Card, CardContent, CircularProgress, Stack
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Draggable from "react-draggable"
import { PatientService } from '../../../../services/patient.service'
import EmptyCardComponent from './empty-card.component'
import ContextualHelpDataComponent from './contextual-help-data.component'
import ColorBubbleComponent from './color-bubble.component'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'

interface IProps {
	open: boolean
	handleClose: any
	id: any
	title: string | ReactElement
	category: string
	classes: ClassNameMap
}

const MedChoicesComponent: React.FC<IProps> = ({ open, handleClose, id, title, category, classes }) => {
	const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
	const [data, setData] = useState({} as any)
	const [loading, setLoading] = useState(false)
	const patientService = new PatientService()
	const conextRef = useRef(null) as any
	const [meds, setMeds] = useState([] as any)
	const [contextData, setContextData] = useState([] as any)


	useEffect(() => {
		getMedChoicesData()
	}, [])

	const getMedChoicesData = async () => {
		setLoading(true)
		patientService.getMedChoicesData(patientDetail?.id, id).then((res: any) => {
			const medRes = res.data
			if (Object.keys(medRes).length > 0) {
				setData(medRes)
				const medList = [] as any
				medRes.map((i: any) => {
					if (medList.indexOf(i.medclass_display_name) === -1) {
						medList.push(i.medclass_display_name)
					}
				})
				setMeds(medList)
			}
			setLoading(false)
		}).catch(() => setLoading(false))
	}


	const getContextualHelpData = (e: any, id: any) => {
		e.stopPropagation();
		e.preventDefault();

		(contextData.findIndex((i: any) => i === id) === -1)
			? setContextData([...contextData, id])
			: setContextData(contextData.filter((i: any) => i !== id))
	}

	const getCriteria = (title: any, criObj: any) => {
		return <Grid container spacing={0.4} direction="row" wrap="nowrap">
			<Grid item>
				<Typography variant="body2" className={classes.evalBoxLabel} color="#999999">{title}</Typography>
			</Grid>
			<Grid item>
				{(title.includes('Exclusion reason') || title.includes('Meets exclusion')) ?
					<Typography className={classes.evalBoxLabel} color={criObj ? 'red !important' : ''}>
						{criObj}
					</Typography>
					: getExlusionCriteria(criObj)
				}
			</Grid>
		</Grid>
	}

	const getExlusionCriteria = (criteriaObj: any) => {
		const dList = criteriaObj && Object.keys(criteriaObj)?.map((m: any, key: any) => {
			const { value, dependents } = criteriaObj[m]
			const depenList = dependents && Object.keys(dependents)?.map((key: any) => `${key}: ${dependents[key]}`).join(', ');
			return depenList
				? <Stack direction={'row'}>
					<Typography component={"span"} className={classes.evalBoxLabel}>{m}:&nbsp;</Typography>
					<Typography component={"span"} className={classes.evalBoxLabel}
						color={value ? 'red !important' : ''}>{JSON.stringify(value)} </Typography>
					<Typography component={"span"} className={classes.evalBoxLabel}>&nbsp;[{depenList}]</Typography>
					{(key + 1) < Object.keys(criteriaObj).length &&
						<Typography component={"span"} className={classes.evalBoxLabel}>{`,`}&nbsp;</Typography>
					}
				</Stack>
				: <Stack direction={'row'}>
					<Typography component={"span"} className={classes.evalBoxLabel}>{m}: {JSON.stringify(value)}</Typography>
					{(key + 1) < Object.keys(criteriaObj).length &&
						<Typography component={"span"} className={classes.evalBoxLabel}>{`,`}&nbsp;</Typography>
					}
				</Stack>
		})
		return <Stack direction={'row'}>{dList}</Stack>
	}

	const renderGroup = (key: any, index: any) => {
		const medInfo = data.filter((i: any) => i.medclass_display_name === key)
		const isPrgMed = medInfo?.some((i: any) => i?.program !== null)
		return <Box key={`med-choice-${key}`}>
			<Accordion square className={classes.zeroTopPadding} sx={{ backgroundColor: 'transparent !important' }}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.zeroPadding} id={`medication-${key}`}>
					<Typography variant="subtitle2" className={classes.subtitle2}>{key}
						{isPrgMed && medInfo.is_recommended &&
							<ColorBubbleComponent
								selectedProgramList={[(medInfo?.find((i: any) => i?.program !== null))?.program]}
								overridenProgramList={[]}
							/>
						}
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.zeroPadding}>
					<Grid container spacing={1} sx={{ paddingLeft: '0.8rem' }}>
						{medInfo?.map((item: any, index: any) => (
							<Grid key={`med-${index}`} item className={classes.calculatorCard}>{console.log(item)}
								<Typography
									key={item?.name}
									className={contextData.includes(item.id) ? classes.medChoiceActiveBtn : classes.medChoiceBtn}
									variant="body2"
									onClick={(e: any) => getContextualHelpData(e, item.id)}
									sx={(item?.program !== null && item?.is_recommended) ?
										{ color: '#F7AC40', fontWeight: '500 !important' } : {}}
								>
									{item?.name || '-'}
									{(item?.program !== null && item?.is_recommended) &&
										<ColorBubbleComponent selectedProgramList={[item?.program]} overridenProgramList={[]} />
									}
								</Typography>
								{(item?.meets_exclusion || item?.exclusion_reason || item?.exclusion_criteria) &&
									<Grid container spacing={0.4} direction="row" sx={{ padding: '0.2rem 1rem 0.2rem 0rem', width: 'max-content', overflowX: 'auto' }}>
										{item?.meets_exclusion &&
											<Grid item>
												{getCriteria(`Meets exclusion`, JSON.stringify(item?.meets_exclusion))}
											</Grid>
										}
										{item?.exclusion_reason &&
											<Grid item>
												{getCriteria(`Exclusion reason`, item?.exclusion_reason || '')}
											</Grid>
										}
										{item?.exclusion_criteria && Object.keys(item?.exclusion_criteria)?.length > 0 &&
											<Grid item>
												{getCriteria(`Exclusion criteria`, item?.exclusion_criteria)}
											</Grid>
										}
										{item?.taking_med_excl_criteria && Object.keys(item?.taking_med_excl_criteria)?.length > 0 &&
											<Grid item>
												{getCriteria(`Taking med exclusion criteria`, item?.taking_med_excl_criteria)}
											</Grid>
										}
									</Grid>
								}
								{contextData.includes(medInfo.id) &&
									<Card variant='outlined' className={classes.medChoiceCardContent}>
										<CardContent className={classes.zeroPadding}>
											<ContextualHelpDataComponent
												id={item.id}
												category={''}
												handleLoading={(flag: boolean) => null}
											/>
										</CardContent>
									</Card>
								}
							</Grid>
						))}
					</Grid>
				</AccordionDetails>
			</Accordion>
		</Box>
	}

	const renderMedChoiceList = () => {
		return <Box className={title === '' ? classes.medChoicesRecomBox : classes.medChoicesBox}>
			{Object.keys(data)?.length > 0
				? meds?.map((currItem: any, indx: any) => renderGroup(currItem, indx))
				: <Box pt={2}><EmptyCardComponent /></Box>
			}
		</Box>
	}
	return <>
		{open &&
			<>
				{title === ''
					? <>
						{loading
							? <CircularProgress className={classes.circularProgressBar} />
							: renderMedChoiceList()
						}
					</>
					: <Draggable ref={conextRef} defaultPosition={{ x: 80, y: 20 }} handle='.med-choices-dialog'>
						<Dialog
							id={`education-id-${id}`}
							fullWidth={true}
							maxWidth="md"
							open={open}
							onClose={handleClose}
							hideBackdrop
							disableScrollLock
							sx={{
								position: "absolute",
								left: '10rem',
								top: '1rem'
							}}
						>
							<DialogTitle className={classes.contextualHelpHeader} classes={{ root: 'med-choices-dialog' }}>
								<Grid container justifyContent="space-between">
									<Grid item>
										<Typography color="textSecondary">
											<small>{`${category} / Medication choices`}</small>
										</Typography>
										<Typography id="modal-modal-title" variant="h6">
											<strong>{`${title || 'Medication choices'}`}</strong>
										</Typography>
									</Grid>
									<Grid item>
										<IconButton size="small" onClick={() => handleClose()}>
											<CloseOutlinedIcon />
										</IconButton>
									</Grid>
								</Grid>
							</DialogTitle>
							{loading && <LinearProgress />}
							<DialogContent className={`${classes.contextualHelpContext} ${classes.zeroPadding}`}>
								{!loading && renderMedChoiceList()}
							</DialogContent>
						</Dialog >
					</Draggable>
				}
			</>
		}
	</>
}

export default withStyles(stylesheet)(MedChoicesComponent)