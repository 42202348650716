import React from 'react';
import { Box, Typography } from '@mui/material';
import { RouteComponentProps } from 'react-router-dom';
import { environment } from '../../environments/environment';

interface IProps extends RouteComponentProps {
  history: any;
  location: any;
  match: any;
}

const CorsErrorModalComponent: React.FC<IProps> = ({
  history,
  location,
  match,
}) => {
  // Build URL to "Trusted Origins" page in Admin panel
  const baseUrl = environment.okta.baseUrl;
  const hostParts = new URL(baseUrl).host.split('.');
  hostParts[0] += '-admin';
  const adminHost = hostParts.join('.');
  const corsAdminUrl = `https://${adminHost}/admin/access/api/trusted_origins`;

  // CORS error modal
  return (
    <Box padding={'5rem'}>
      <Typography variant='h6'>Okta Authentication Error</Typography>
      <Typography>
        Seems like logout API call resulted with CORS error.
      </Typography>
      <Typography>
        You may need to add your origin {window.location.origin} to list of
        trusted origins in your{' '}
        <a href={corsAdminUrl} target='_blank' rel='noreferrer'>
          Okta Administrator Dashboard
        </a>
      </Typography>
    </Box>
  );
};
export default CorsErrorModalComponent;
