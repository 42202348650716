import {
  Box, Button, Card, CardContent, TextField, Typography, Grid, ButtonGroup,
  CircularProgress, Divider, ListItemText, Checkbox, FormControl, Select, MenuItem
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import React, { useState, ReactElement, useEffect } from 'react'
import stylesheet from '../detail-container.stylesheet'
import ColorBubbleComponent from './color-bubble.component'
import { PatientService } from '../../../../services/patient.service'
import { toast } from 'react-toastify'
import Helper from '../../../../utils/helper'
import { useCurrentUser } from '../../../../utils/use-current-user'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import moment from 'moment';

interface IProps {
  entityRef: string
  input: any
  leftHeader: string
  rightHeader: string | ReactElement
  programs?: string[]
  subHeader?: string | ReactElement
  closeOverride: any
  onOverrideSubmit: any
  classes: ClassNameMap
}

const ManualOverrideComponent: React.FC<IProps> = ({
  entityRef,
  input,
  leftHeader,
  rightHeader,
  programs = [],
  subHeader,
  closeOverride,
  onOverrideSubmit,
  classes,
}) => {
  const [note, setNote] = useState('')
  const [loading, setLoading] = useState(false)
  const [overrideConfirm, setOverrideConfirm] = useState(false)
  const [resolveOverride, setResolveOverride] = useState(false)
  const [overridenPrograms, setoverridenPrograms] = useState<string[]>([]);
  const [resolveOverridenPrg, setResolveOverridenPrg] = useState<string[]>([]);
  const [prevOverridenPrograms, setPrevOverridenPrograms] = useState<string[]>([]);
  const [refData, setRefData] = useState([] as any)
  const patientService = new PatientService()
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const { currentRole } = useCurrentUser()

  useEffect(() => {
    if (input.overridden_programs?.length >= 1) {
      setPrevOverridenPrograms(input?.overridden_programs)
      setResolveOverridenPrg(input?.overridden_programs)
    }

    if (programs?.length === 1)
      setoverridenPrograms(programs)

    getOverrideNotes()
  }, [])

  const getOverrideNotes = () => {
    patientService.getOverrideNote(patientDetail?.id, entityRef, input.id)
      .then((res: any) => {
        setLoading(false)
        const dt = Object.keys(res?.data?.override_notes)?.map((m: any) => {
          return { created_on: m.timestamp, note: m.text, by: m.name }
        })
        setRefData(res?.data?.override_notes)
      })
      .catch((error: any) => {
        toast.error(Helper.getErrorText(error), Helper.bottom_center())
      })
  }

  const onCloseOverride = () => closeOverride(input.id)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setNote(event.target.value)

  const handleOverrideNotes = () => {
    setResolveOverride(true)
    setOverrideConfirm(false)
  }

  const getPrgName = (key: any) => {
    const prgObj = patientDetail.program_enrollments?.find((f: { program: any }) => f.program === key) || {}
    return prgObj.program_display_name
  }

  const onConfirm = (resolved: boolean = false) => {
    if (overrideConfirm) {
      handleAddOverride()
    } else if (resolveOverride) {
      handleRemoveOverride()
    } else {
      setOverrideConfirm(false)
      setResolveOverride(false)
    }
  }

  const handleAddOverride = () => {
    setLoading(true)
    let payload = {
      entity_ref: entityRef,
      entity_id: input.id,
      note: note,
      programs: overridenPrograms,
      author_role: currentRole
    }

    patientService
      .postOverrideValue(payload)
      .then((res: any) => {
        setLoading(false)
        const program_name: string = programs[0]
        const overridenPrograms = res?.data && Object.keys(res.data)
        const overridenOnDate = res?.data && res?.data[program_name] && res?.data[program_name][0]?.overriden_on
        onOverrideSubmit(input.id, overridenOnDate, true, overridenPrograms)
      })
      .catch((error: any) => {
        setLoading(false)
        toast.error(Helper.getErrorText(error), Helper.bottom_center())
      })
  }

  const handleRemoveOverride = () => {
    setLoading(true)
    const payload = {
      entity_ref: entityRef,
      entity_id: input.id,
      programs: resolveOverridenPrg
    }
    patientService.removeOverride(patientDetail?.id, payload).then((res: any) => {
      setLoading(false)
      if (res?.data?.message === 'success') {
        onOverrideSubmit(input.id, '', false, resolveOverridenPrg)
        // toast.success('Override removed successfully.', Helper.bottom_center())
      }
    }).catch((error: any) => {
      setLoading(false)
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    })
  }

  const handleChangeForPrograms = (event: any, isResolve = false) => {
    const { target: { value }, } = event
    const prgList = typeof value === 'string' ? value.split(',') : value;
    isResolve ? setResolveOverridenPrg(prgList) : setoverridenPrograms(prgList)
  }

  const getPrgLen = (data: any) => {
    const prgLen = (data?.program) ? 1 : (data?.programs?.length || 0)
    return input?.overridden_programs?.length === prgLen
  }

  return (<>
    <Box mb={1} className={classes.customCard}>
      <Card variant="outlined" className={classes.fullWidth}>
        <CardContent>
          <Grid container xs={12} direction="row" justifyContent="space-between" alignItems="baseline" className={classes.initialFlexwrap}>
            <Grid item>
              <Typography variant="subtitle2">
                {leftHeader}
                <ColorBubbleComponent
                  selectedProgramList={programs}
                  overridenProgramList={input?.overridden_programs}
                />
              </Typography>
            </Grid>
            <Grid item className={classes.subTitle}>
              <Typography align="right">
                {rightHeader}
              </Typography>
            </Grid>
          </Grid>
          {subHeader && (<>
            <Grid container xs={12} direction="row" alignItems="baseline" className={classes.initialFlexwrap}>
              <Grid item className={classes.subHeaderTitle}>
                <Typography color="textSecondary" variant="subtitle2">
                  {subHeader}
                </Typography>
              </Grid>
            </Grid>
          </>
          )}
          <Divider />
          {loading
            ? <Grid container direction="row" justifyContent="center" alignItems="center" >
              <Box p={1}> <CircularProgress size="2rem" /></Box>
            </Grid>
            : <Box pt={2} pb={1}>
              {!overrideConfirm && !resolveOverride &&
                <>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <Button
                        variant="text"
                        title='Override'
                        className={getPrgLen(input)
                          ? classes.expressModeBtn
                          : classes.activeBtn}
                        sx={{ paddingTop: '0px !important' }}
                        onClick={() => {
                          if (getPrgLen(input))
                            return null
                          else {
                            setOverrideConfirm(true)
                            setResolveOverride(false)
                          }
                        }}
                      >
                        {`Add Override`}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="text"
                        title='Remove Override'
                        className={!input?.overridden_programs.some((r: any) => ((input?.programs) || (input?.program))?.includes(r))
                          ? classes.expressModeBtn
                          : classes.activeBtn}
                        onClick={() => !input?.overridden_programs.some((r: any) => ((input?.programs) || (input?.program))?.includes(r))
                          ? null : handleOverrideNotes()}
                        sx={{ paddingTop: '0px !important' }}
                      >
                        Remove Override
                      </Button>
                    </Grid>
                  </Grid>
                  <Box sx={{ maxHeight: '15rem', overflowY: 'auto' }}>
                    {Object.keys(refData)?.map((r: any, key: any) =>
                      <Box p={1} key={`resolve-override-${key}`}>
                        <Typography className={classes.wrapNote} variant="subtitle2">
                          <small>{r}: {refData[r]['text']}</small>
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          <small>by {refData[r]?.author?.name}
                            {refData[r]['overridden_on'] !== undefined ?
                              moment.unix(refData[r]['overridden_on']).format('MMM DD, YYYY hh:mm a')
                              : ''
                            }
                          </small>
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </>
              }
              {resolveOverride &&
                <Box pb={1}>
                  <Typography pb={1} color="textSecondary" variant="subtitle2">Select programs to resolve override</Typography>
                  <FormControl className={classes.rrdFormControl}>
                    <Select
                      displayEmpty
                      variant="standard"
                      multiple
                      className={`${classes.selectEmpty} ${classes.desc}`}
                      inputProps={{ 'aria-label': 'resolveOverridenPrg' }}
                      label='Program'
                      value={resolveOverridenPrg}
                      name="resolveOverridenPrg"
                      renderValue={(selected) => (selected as string[]).join(', ')}
                      onChange={(e: any) => handleChangeForPrograms(e, true)}
                    >
                      {input?.overridden_programs?.map((_key: any) => (
                        <MenuItem className={classes.formControlItemsPadding} value={_key}
                          sx={{
                            '& .MuiTypography-root': {
                              fontSize: '0.8rem !important'
                            }
                          }}
                          disabled={input?.overridden_programs?.length === 1}
                        >
                          <Checkbox color='primary'
                            className={classes.formControlItemsPadding}
                            checked={resolveOverridenPrg.indexOf(_key) > -1}
                          />
                          <ListItemText primary={getPrgName(_key)} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              }
              {overrideConfirm && (
                <>
                  <Box mt={1}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label="Add note for overriding"
                      size="small"
                      className={`${classes.fullWidth} ${classes.desc}`}
                      multiline={true}
                      minRows="3"
                      value={note}
                      onChange={handleChange}
                    />
                  </Box>
                  <Box mt={1}>
                    <Grid container spacing={1} direction="row" className={classes.compactCardContent}>
                      <Grid item>
                        <Typography color="textSecondary" variant="subtitle2">Select Programs</Typography>
                        <FormControl className={classes.rrdFormControl}>
                          <Select
                            displayEmpty
                            variant="standard"
                            multiple
                            className={classes.selectEmpty}
                            inputProps={{ 'aria-label': 'Without label' }}
                            label='Program'
                            value={overridenPrograms}
                            name="program_name"
                            renderValue={(selected) => (selected as string[]).join(', ')}
                            onChange={(e: any) => handleChangeForPrograms(e, false)}
                          >
                            {programs?.map((_key: any) => (
                              <MenuItem
                                className={classes.formControlItemsPadding}
                                disabled={prevOverridenPrograms.indexOf(_key) > -1}
                                value={_key}
                                sx={{
                                  '& .MuiTypography-root': {
                                    fontSize: '0.8rem !important'
                                  }
                                }}
                              >
                                <Checkbox color='primary'
                                  className={classes.formControlItemsPadding}
                                  disabled={prevOverridenPrograms.indexOf(_key) > -1}
                                  checked={prevOverridenPrograms.indexOf(_key) > -1
                                    ? true
                                    : programs?.length == 1
                                      ? true
                                      : overridenPrograms.indexOf(_key) > -1} />
                                <ListItemText primary={getPrgName(_key)} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            </Box>
          }
        </CardContent >
        <Grid xs={12} direction="row" justifyContent="space-around" alignItems="center">
          <ButtonGroup className={classes.questionButtonStyle} size="small" variant="text" aria-label="button group">
            <Button
              disabled={(overrideConfirm && (note === '' || overridenPrograms.length <= 0)
                || (resolveOverride && resolveOverridenPrg?.length <= 0))
                || (!overrideConfirm && !resolveOverride)}
              onClick={() => onConfirm()}
              className={classes.twoButtonWidth}
            >
              <strong>{'Submit'}</strong>
            </Button>
            <Button onClick={onCloseOverride} className={classes.twoButtonWidth}>
              <strong> {'Cancel'}</strong>
            </Button>
          </ButtonGroup>
        </Grid>
      </Card>
    </Box>
  </>
  )
}

export default withStyles(stylesheet)(ManualOverrideComponent)
