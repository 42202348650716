import React from 'react';
import { Grid } from '@mui/material';
import { ClassNameMap, withStyles } from '@mui/styles'
import { RouteComponentProps, Link } from 'react-router-dom'
import stylesheet from '../patients/detail/detail-container.stylesheet'

interface IProps extends RouteComponentProps {
  history: any
  location: any
  match: any
  classes: ClassNameMap
}

const PageNotFoundComponent: React.FC<IProps> = ({
  history,
  location,
  match,
  classes

}) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.pnfContainer}
      data-testid='page-not-found-testId'
    >
      <Grid item>
        <h1>Page Not Found!</h1>
      </Grid>
      <Grid item>
        Looks like you have followed a broken link or <br />
        entered a URL that does not exists on this site.
      </Grid>
      <Grid item>
        Click here to go{' '}
        <Link to="/">
          <strong>Home</strong>
        </Link>
      </Grid>
    </Grid>
  )
}

export default withStyles(stylesheet)(PageNotFoundComponent);
