import React, { useState, useRef } from 'react'
import {
	AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress,
	List, ListItem, Tab, Tabs, Typography
} from '@mui/material'
import { ClassNameMap, withStyles, styled } from '@mui/styles'
import Draggable from 'react-draggable'
import moment from 'moment'
import stylesheet from '../detail-container.stylesheet'
import { PatientService } from '../../../../services/patient.service'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import EmptyCardComponent from './empty-card.component'
import DialogTitleHeaderComponent from './dialog-title-header.component';
import { toast } from 'react-toastify'
import Helper from '../../../../utils/helper'
import { useUpdateDataAction } from '../../../../utils/update-details-hook'

interface IProps {
	open: boolean
	handleClose: any
	input: any
	classes: ClassNameMap
}

interface StyledTabsProps {
	value: number
	onChange: (event: React.ChangeEvent<{}>, newValue: number) => void
	variant: "scrollable" | "standard" | "fullWidth" | undefined
}
type compSet = { title: string; index: number; comp: string; props: any }

interface TabPanelProps {
	children?: React.ReactNode
	index: any
	value: any
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props
	return (<div
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...other}
	>
		{value === index && <>{children}</>}
	</div>
	)
}

const ToxicityDialogComponent: React.FC<IProps> = ({ open, handleClose, input, classes }) => {
	const [data, setData] = useState(input as any)
	const [loading, setLoading] = useState(false)
	const patientService = new PatientService()
	const lifestyleRef = useRef(null) as any
	const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
	const { updatePatientData } = useUpdateDataAction(patientDetail.id)
	const [configItem] = useState({
		focusTabIndex: 0,
		tabs: [
			{ title: 'Evaluation', index: 0, comp: '', props: {} },
		]
	})
	const [value, setValue] = useState(configItem?.focusTabIndex || 0 as number)
	const handleChange = (event: any, newValue: React.SetStateAction<number>) => setValue(newValue)

	const a11yProps = (index: number) => {
		return {
			id: `scrollable-auto-tab-${index}`,
			'aria-controls': `scrollable-auto-tabpanel-${index}`,
		}
	}
	const getLabel = (val: any) => <Typography className={classes.evalBoxLabel} variant="body2" color="textSecondary">{val}</Typography>

	const handleConfirm = () => {
		setLoading(true)
		const payload = { entity_ref: 'patient_toxicity', entity_id: data.id, confirmed_by: patientDetail.id }
		patientService.PostConfirmations(payload).then((_response: any) => {
			setLoading(false)
			handleClose()
			updatePatientData({ isTreatments: true })
		}).catch((err: any) => {
			setLoading(true)
			toast.error(Helper.getErrorText(err), Helper.bottom_center())
		})
	}

	const renderToxList = (val: any) => <ListItem className={classes.puList}>
		<Typography className={classes.evalBoxVal}>{val}</Typography>
	</ListItem>


	const getCriteriaList = (criteriaObj: any, isValue: boolean) => criteriaObj && Object.keys(criteriaObj)?.map((m: any, key: any) => {
		const { value, dependents } = criteriaObj[m]
		const depenList = dependents && Object.keys(dependents)?.map((key: any) => `${key}: ${dependents[key]}`).join(', ')
		return value === isValue && <List className={classes.criteriaList}>
			{renderToxList(`${m} ${depenList && `: [${depenList}]`}`)}
		</List>
	})

	const getToxCriteriaByGroup = (criteriaObj: any) => {
		return (Object.keys(criteriaObj)?.length > 0) && <Grid container direction='row' spacing={2} className={classes.evalSubBox}>
			<Grid item xs={6}>
				<Typography className={classes.evalBoxVal}><u>True</u></Typography>
				{getCriteriaList(criteriaObj, true)}
			</Grid>
			<Grid item xs={6}>
				<Typography className={classes.evalBoxVal}><u>False</u></Typography>
				{getCriteriaList(criteriaObj, false)}
			</Grid>
		</Grid>
	}

	const renderToxDetails = () => {
		return Object.keys(data)?.length > 0 ?
			<Grid className={classes.evalContent} container spacing={1}>
				<Grid item className={classes.fullWidth}>
					<Grid container direction="row" className={classes.evalToxCard}>
						<Grid item>{getLabel('Last evaluation date')}</Grid>
						<Grid item>
							<Typography className={classes.toxicityEvalBoxVal}>{moment.unix(data?.evaluated_on).format('MMM DD, YYYY hh:mm a')}</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item className={classes.fullWidth}>
					<Grid container direction="row" className={classes.evalToxCard}>
						<Grid item>{getLabel('Status')}</Grid>
						<Grid item>
							<Typography className={classes.toxicityEvalBoxVal}>{data?.is_active ? 'Active' : 'Inactive'}</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item className={classes.fullWidth}>
					<Grid container direction="row" className={classes.evalToxCard}>
						<Grid item>{getLabel('Recommended plan')}</Grid>
						<Grid item>
							<Typography className={classes.toxicityEvalBoxVal}>{data?.recommended_plan_name || '-'}</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item className={classes.fullWidth}>
					<Grid item>{getLabel('Recommendation')}</Grid>
					<Grid item className={classes.evalSubBox}>
						<Typography className={classes.evalBoxVal}>{data?.recommendation_string || ''}</Typography>
					</Grid>
				</Grid>
				<Grid item className={classes.fullWidth}>
					<Grid container direction="column">
						<Grid item>{getLabel('Evaluation result')}</Grid>
						<Grid item>	{getToxCriteriaByGroup(data?.evaluation_results)}</Grid>
					</Grid>
				</Grid>
			</Grid>
			: <EmptyCardComponent />
	}

	return <>
		{open &&
			<Draggable ref={lifestyleRef} defaultPosition={{ x: 80, y: 0 }} handle='.tox-dialog'>
				<Dialog
					id={`tox-${input?.id}`}
					fullWidth={true}
					maxWidth="md"
					open={open}
					onClose={handleClose}
					hideBackdrop
					disableScrollLock
					sx={{
						position: "absolute",
						left: '14rem',
						top: '10rem'
					}}
				>
					<DialogTitle sx={{ padding: '15px 10px 0px 20px' }} classes={{ root: 'tox-dialog' }}>
						<DialogTitleHeaderComponent title={input?.medication?.name} handleDialogClose={handleClose} />
					</DialogTitle>
					<DialogContent className={classes.dialogContentStyle}>
						<Box mb={1}>
							<div className={classes.root}>
								<AppBar position="static" color="transparent">
									<Tabs value={value} onChange={handleChange} variant="standard">
										{configItem?.tabs?.map((c: any) => (<Tab
											key={`config-${c.title}`}
											label={c.title}
											{...a11yProps(c.index)}
											disabled={false}
										/>
										))}
									</Tabs>
								</AppBar>
								<Grid className={classes.linearProgressContainer}>
									{loading === true && <LinearProgress />}
									<Box className={classes.dialogHeaderBox} mb={1}>
										<div className={classes.dialogHeader} />
										{configItem?.tabs?.map((c: any, i: any) => (
											<Box pl={2} pr={2} key={`compset-${i}`} >
												<TabPanel value={value} index={c.index}>
													{i === 0 && renderToxDetails()}
												</TabPanel>
											</Box>
										))}
									</Box>
								</Grid>
							</div>
						</Box>
					</DialogContent>
					<DialogActions className={`${classes.btnContainer} ${classes.boxBorder}`}>
						<Grid container spacing={2} justifyContent="right" className={classes.containerPadding}>
							<Grid item>
								<Button
									disabled={loading || !data.needs_confirmation}
									onClick={() => handleConfirm()}
									variant="outlined"
									size='small'
								>
									<strong>{`Confirm`}</strong>
								</Button>
							</Grid>
							<Grid item>
								<Button disabled={loading} onClick={handleClose} variant="outlined" size='small'>
									<strong>Cancel</strong>
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>
			</Draggable>
		}
	</>
}

export default withStyles(stylesheet)(ToxicityDialogComponent)