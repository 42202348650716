import React, { useEffect, useMemo, useState, useRef } from 'react'
import {
  Button, DialogActions, DialogContent, DialogTitle, Tab, Tabs, Box, Grid, LinearProgress, Dialog, Typography
} from '@mui/material'
import stylesheet from '../detail-container.stylesheet'
import { ClassNameMap, makeStyles, withStyles } from '@mui/styles'
import DialogModalHeaderComponent from './dialog-modal-header.component'
import ScheduleCarePlanDialogComponent from '../care-plan/schedule-care-plan-dialog.component'
import DetailComponent from '../medications/detail-care-plan-dialog.component'
import RationaleComponent from '../medications/rationale-care-plan.component'
import TakingStatusComponent from '../care-plan/taking-status.component'
import ProviderDecisionComponent from '../care-plan/provider-decision.component'
import { PatientService } from '../../../../services/patient.service'
import CommunicateToPatinetComponent from '../care-plan/communicate-to-patinet.component'
import PatientStartedDateComponent from '../care-plan/patient-started-date.component'
import PatientMedStopDateComponent from '../care-plan/patient-med-stop-date.component'
import PatientCurrentStatusComponent from '../care-plan/patient-current-status.component'
import { toast } from 'react-toastify'
import Helper from '../../../../utils/helper'
import ToxicityComponent from '../care-plan/toxicty-care-plan.component'
import { resetSelectedPU, setCarePlanRejected, setSelectedCarePlan, setSelectedPU, setSelectedSchedule } from '../../../../redux/common-data-slice'
import { RootState, useAppThunkDispatch } from '../../../../redux/store'
import ConfirmationDialogComponent from './confirmation-dialog.component'
import { useSelector } from 'react-redux'
import MedHistoryComponent from '../care-plan/med-history.component'
import MedReferralComponent from '../care-plan/med-referral.component'
import NextStageComponent from '../care-plan/next-stage.component'
import Draggable from "react-draggable"
import NotesComponent from './notes.component'
import { environment } from '../../../../environments/environment'
import MedChoicesComponent from './med-choices.component'
import { useUpdateDataAction } from '../../../../utils/update-details-hook'

type compSet = { title: string; index: number; comp: string; props: any }
type actionSet = { title: string; returnFun: any }
type configSetType = {
  id: string
  role: string
  stage: string
  source_type: string
  actions: actionSet[]
  focusTabIndex: number
  tabs: compSet[]
}
interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

const styles = makeStyles((theme: any) => ({
  formControl: {
    minWidth: 100,
  },
  stageLabel: {
    fontSize: '0.8rem',
    paddingTop: '0px',
    fontWeight: 500,
  },
  nextActionSelect: {
    width: '5.5rem',
    paddingTop: '0px',
    fontSize: '0.8rem',
    '&:before, &:after, & .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
  select: {
    maxWidth: '9rem',
    width: '100%',
    paddingTop: '0px',
    fontSize: '0.8rem',
    '&:before, &:after, & .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
  icon: {
    fill: '#D87C4E',
  },
  actionMenu: {
    fontSize: '0.8rem',
    color: '#D87C4E',
    padding: '0.15rem 0.6rem',
  },
}))
interface IProps {
  open: boolean
  currentRole: string
  item: any
  configItem: configSetType
  handleDialogClose: any
  patientId: any
  classes: ClassNameMap
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

const BaseDialogModalComponent: React.FC<IProps> = ({
  open,
  currentRole,
  item,
  configItem,
  handleDialogClose,
  patientId,
  classes,
}) => {
  const [value, setValue] = useState(configItem.focusTabIndex)
  const [mapState, setMapState] = useState(new Map())
  const [loading, setLoading] = useState(false)
  const [ddlValue, setDdlValue] = useState(null as any);
  const [confirmContent, setConfirmContent] = useState('');
  const [stageActions, setStageActions] = useState([])
  const [isMedChoices, setIsMedChoices] = useState(false)
  const actionClasses = styles()
  const patientService = new PatientService()
  const dispatch = useAppThunkDispatch()
  const { updatePatientData } = useUpdateDataAction(patientId)
  const updateMap = (_key: string, _value: any) => {
    setMapState((map) => new Map(map.set(_key, _value)))
  }

  const metaData = useSelector(
    (state: RootState) => state.metaData.metadata
  )
  const baseMedRef = useRef(null) as any

  useEffect(() => {
    const selectedSch = item?.aa_rec || {}
    getProgramActions()
    dispatch(setSelectedSchedule(selectedSch))
    dispatch(setSelectedPU(item?.pu_rec || {}))
    dispatch(setSelectedCarePlan(item))
  }, [])

  const getProgramActions = () => {
    const currStageActions = Object.keys(metaData?.STAGE_RECOM_MED_ACTION_RESTRICTION)
      ?.filter((key: any) => (key === configItem?.stage))
      ?.reduce((obj: any, key: any) => metaData?.STAGE_RECOM_MED_ACTION_RESTRICTION[key], []);
    setStageActions(currStageActions)
  }

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    if (configItem?.tabs) {
      let activeTab = configItem?.tabs && configItem.tabs.find((i: any, index: any) => index === newValue)
      if (activeTab && activeTab?.comp !== 'ScheduleCarePlanComponent_Key') {
        dispatch(resetSelectedPU())
      }
    }
    setValue(newValue)
  }
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmSchedule, setConfirmSchedule] = useState(false)
  const selectedCarePlan = useSelector((state: RootState) => state.commonData.selectedCarePlan)
  const patientDetail: any = useSelector((state: RootState) => state.patientDetail.patientDetail)

  const handleConfirmClose = () => {
    setConfirmOpen(false)
    setConfirmContent('')
  }

  const handleScheduleConfirmClose = () => setConfirmSchedule(false)

  const getStageChange = (next_change_stage: any) => {
    setLoading(true)
    const enrollmentId = patientDetail?.program_enrollments.filter((m: { program: any }) =>
      m.program === item.program)?.[0]?.enrollment_id || null

    next_change_stage && enrollmentId && patientService.changeProgramStageByName(
      next_change_stage,
      enrollmentId
    ).then((res: any) => {
      if (res?.data?.message === 'stage changed failed')
        toast.error('Failed to change stage ', Helper.bottom_center())
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    })
  }

  const getCurrPrgStage = () => patientDetail?.program_enrollments?.find((p: any) => p.program === item?.program)?.current_stage || null

  const confirmStatus = (param: boolean) => {
    if (param) {
      setConfirmOpen(false)
      setDdlValue(confirmContent)
      let actions = confirmContent.split('/')
      let action = actions[0]
      let sub_action = actions[1]
      if (action === sub_action) sub_action = ''
      setLoading(true)

      patientService.putPatientActions(patientId, item.id, item.program, action, sub_action, item.care_plan_id)
        .then((_response: any) => {
          setLoading(false)
          const { success, next_change_stage } = _response?.data
          if (success) {
            //toast.success('Action processed successfully.', Helper.bottom_center());

            if (next_change_stage && next_change_stage.includes(getCurrPrgStage())) {
              updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
            }
            else
              getStageChange(next_change_stage)
          }
          handleDialogClose()
        }).catch((error) => {
          setLoading(false)
          toast.error('Failed to process action.', Helper.bottom_center())
        })
    }
  }

  const outerSubmit = (key: string, dt: any) => updateMap(key, dt)

  const outer = (_key: string) => {
    setMapState((prevState: any) => {
      prevState.delete(_key)
      return new Map(prevState)
    })
  }

  const returnComp = (_component: compSet) => {
    if (_component.comp === 'ScheduleCarePlanComponent_Key') {
      return React.createElement(
        ScheduleCarePlanDialogComponent,
        { ..._component.props, outerSubmit, compKey: 'c_rec_schedules', mapState },
        null
      )
    }
    if (_component.comp === 'NotesComponent_Key') {
      return React.createElement(
        NotesComponent,
        { ..._component.props, compKey: 'NotesComponent_Key' },
        null
      )
    }
    if (_component.comp === 'ReferralComponent_Key') {
      return React.createElement(MedReferralComponent, { ..._component.props, item: item })
    }
    if (_component.comp === 'DetailsComponent_Key') {
      return React.createElement(
        DetailComponent,
        { ..._component.props, details: item, outerSubmit, compKey: 'DetailsComponent_Key' },
        null
      )
    }
    if (_component.comp === 'RationaleComponent_Key') {
      return React.createElement(
        RationaleComponent,
        { ..._component.props, item: item, compKey: 'RationaleComponent_Key' },
        null
      )
    }
    if (_component.comp === 'ToxicityComponent_Key') {
      return React.createElement(
        ToxicityComponent,
        { ..._component.props, outerSubmit, item: item, configItem: configItem, compKey: 'ToxicityComponent_Key', mapState },
        null
      )
    }
    if (_component.comp === 'MedHistoryComponent_Key') {
      return React.createElement(
        MedHistoryComponent,
        { ..._component.props, item: item },
        null
      )
    }
  }

  const a11yProps = (index: number) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  const confirmScheduleStatus = async () => {
    let actioObj = configItem?.actions?.filter((i: any) => i.title === 'Submit')[0] || {}
    if (Object.keys(actioObj)?.length > 0)
      actionsButtonCall(actioObj)
  }

  const actionsButtonCall = async (c: any) => {
    if (c.title === 'Cancel') {
      c.returnFun()
    } else if (c.title === 'Submit') {
      if (mapState.has('c_rec_schedules') && !confirmSchedule) {
        if (JSON.stringify(mapState.get('c_rec_schedules')) !== JSON.stringify(item?.c_rec_schedules || [])) {
          setConfirmSchedule(true)
          return false
        }
      }

      try {
        if (confirmSchedule)
          setConfirmSchedule(false)

        if (mapState.has('med_restriction') && ((!mapState.get('med_restriction')?.diagnosis) || (!mapState.get('med_restriction')?.med_id))) {
          toast.error('Please select Medicine and Reaction', Helper.bottom_center())
          return null
        }
        setLoading(true)
        let responce: any
        if (mapState.get('approval_status') === 'Restrict' || mapState.get('stage_approval_status') === 'Restrict') {
          setLoading(true)
          patientService
            .postMedicationReaction(patientId, mapState.get('med_restriction'))
            .then((res: any) => {
              let _tempStatus = ''
              if (mapState.has('approval_status')) {
                _tempStatus = 'approval_status'
              } else if (mapState.has('stage_approval_status')) {
                _tempStatus = 'stage_approval_status'
              }
              outer(_tempStatus)
              const temp = {
                ...Object.fromEntries(mapState),
                [_tempStatus]: 'Rejected',
              }
              patientService
                .putOtherMedication(patientId, item.id, temp)
                .then((resp: any) => {
                  responce = resp
                  setLoading(false)
                  dispatch(setCarePlanRejected(true))
                  c.returnFun({ ...item, ...responce.data })
                }).catch((err) => {
                  setLoading(false)
                  toast.error(Helper.getErrorText(err), Helper.bottom_center())
                }
                )
            }).catch((err) => {
              setLoading(false)
              toast.error(Helper.getErrorText(err), Helper.bottom_center())
            }
            )
        } else {
          const temp = { ...Object.fromEntries(mapState) }
          const response = await patientService.putOtherMedication(patientId, item.id, temp)
          setLoading(false)
          if (response?.data?.order_status === false && response?.data?.source_system === 'ELATION' && response?.data?.intake_stopped_on) {
            setTimeout(() => {
              toast.warning("Medication could not get discontinued on Elation", Helper.bottom_center())
            }, 300);
          }
          updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
          c.returnFun({ ...item, ...response.data })
        }
      } catch (error) {
        setLoading(false)
        toast.error(Helper.getErrorText(error), Helper.bottom_center())
      }
    }
  }

  const getMappedBaseComponent = useMemo(() => {
    if (item.is_communicated === false) {
      return React.createElement(
        CommunicateToPatinetComponent,
        { item: item, outerSubmit: outerSubmit, compKey: 'is_communicated' },
        null
      )
    }
    else if (
      Helper.checkGroup(environment.okta.groups, currentRole) &&
      item.is_communicated === true &&
      item?.titration_status === ('discontinue, and initiate') &&
      (configItem.stage === 'med_initiation' || item?.care_plan_status === "toxicity_management") &&
      item.intake_stopped_on === null
    ) {
      return React.createElement(
        PatientMedStopDateComponent,
        { item: item, outerSubmit: outerSubmit, compKey: 'intake_stopped_on' },
        null
      )
    }
    else if (
      Helper.checkGroup(environment.okta.groups, currentRole) &&
      item.is_communicated === true &&
      item?.titration_status?.includes('discontinue') &&
      item?.titration_status !== ('discontinue, and initiate') &&
      item.intake_stopped_on === null
    ) {
      return React.createElement(
        PatientMedStopDateComponent,
        { item: item, outerSubmit: outerSubmit, compKey: 'intake_stopped_on' },
        null
      )
    }
    else if (
      item.is_communicated === true &&
      item.approval_status !== 'Approved' &&
      item?.titration_status !== ('discontinue, and initiate')
    ) {
      return React.createElement(
        ProviderDecisionComponent,
        {
          item: { ...item, stage: configItem.stage },
          outerSubmit: outerSubmit,
          compKey: 'approval_status',
          outer: outer,
          value: value,
          currentRole: currentRole
        },
        null
      )
    } else if (
      Helper.checkGroup(environment.okta.groups, currentRole) &&
      item.is_communicated === true &&
      item.approval_status === 'Approved' &&
      (configItem.stage === 'med_initiation' ||
        ((item?.titration_status).includes('prior dose')
          || (item?.titration_status).includes('lowest dose')
          || (item?.titration_status).includes('downtitrate')
        )
      ) && item.intake_started_on === null
    ) {
      return React.createElement(
        PatientStartedDateComponent,
        { item: item, outerSubmit: outerSubmit, compKey: 'intake_started_on' },
        null
      )
    } else if (
      Helper.checkGroup(environment.okta.groups, currentRole) &&
      item.is_communicated === true &&
      item.approval_status === 'Approved' &&
      item.intake_started_on &&
      !['med_evaluation', 'early_tox_check'].includes(configItem.stage)
    ) {
      return React.createElement(
        PatientCurrentStatusComponent,
        { item: item },
        null
      )
    }
    // Hided for now till next stage clear 
  }, [configItem.stage, currentRole, item, mapState])

  return <>
    {open && (
      <Draggable ref={baseMedRef} defaultPosition={{ x: 280, y: 30 }} handle='.med-care-plan-dialog'>
        <Dialog
          id={'med-care-plan-id'}
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={handleDialogClose}
          hideBackdrop
          disableScrollLock
          onKeyUp={(e) => {
            if (e.key === 'Enter' && !(
              !mapState.size || loading === true ||
              (mapState.get('stage_approval_status') === 'Rejected' && !mapState.get('med_action'))
            ))
              actionsButtonCall(configItem?.actions?.find((i: any) => i.title === "Submit"))
          }}
          aria-labelledby="med-care-plan-dialog"
        >
          <DialogTitle className={classes.dialogTitlePadding} classes={{ root: 'med-care-plan-dialog' }}>
            {React.createElement(
              DialogModalHeaderComponent,
              { item: { ...item, isOtherMed: true }, handleDialogClose: handleDialogClose },
              null
            )}
          </DialogTitle>
          <Tabs value={value} onChange={handleChange} variant="standard">
            {configItem?.tabs?.map((c: compSet) => (
              <Tab
                key={`config-${c.title}`}
                label={c.title}
                {...a11yProps(c.index)}
                disabled={false}
              />
            ))}
          </Tabs>
          {loading && <LinearProgress />}
          <div className={classes.dialogHeader} />
          <DialogContent className={classes.dialogContentStyle}>
            <div className={classes.root}>
              <Grid className={classes.linearProgressContainer}>
                <Box className={classes.dialogHeaderBox}>
                  {configItem?.tabs?.map((c: compSet, i: any) => (
                    <Box pl={2} pr={2} key={`compset-${i}`}>
                      <TabPanel value={value} index={c.index}>
                        {returnComp(c)}
                      </TabPanel>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </div>
          </DialogContent>
          <DialogActions className={classes.zeroPadding}>
            <Grid container flexDirection={'column'}>
              <Grid item className={classes.boxBorder}>
                {item.is_recommended === false && (
                  <TakingStatusComponent
                    item={item}
                    compKey={"intake_regularity"}
                    outerSubmit={outerSubmit}
                  />
                )}
                {item.is_recommended === true && getMappedBaseComponent}
                {selectedCarePlan?.evaluation_status &&
                  selectedCarePlan?.next_change_stage && (
                    <NextStageComponent
                      item={{ ...item, stage: configItem.stage }}
                      stageActions={stageActions}
                      outerSubmit={outerSubmit}
                      outer={outer}
                      classes={actionClasses}
                      compKey={"stage_approval_status"}
                    />
                  )}
                <Box pt={1}>
                  <Button sx={{ paddingLeft: '1rem' }}>
                    <Typography
                      className={classes.activeBtn}
                      variant="subtitle2"
                      onClick={() => setIsMedChoices(!isMedChoices)}
                    >Alternative recommendations
                    </Typography>
                  </Button>
                  {isMedChoices &&
                    <Box sx={{ padding: '0px', minHeight: '2rem', maxHeight: '15rem', overflow: 'auto' }}>
                      <MedChoicesComponent
                        open={isMedChoices}
                        handleClose={() => null}
                        id={item.care_plan_id}
                        title={''}
                        category={'Treatments'}
                      />
                    </Box>
                  }
                </Box>
              </Grid>
              <Grid item>
                <Grid container spacing={2} justifyContent="right" className={classes.containerPadding}>
                  {configItem?.actions?.map((c: actionSet, index: any) => (
                    <Grid key={`action-${index}`} item>
                      <Button
                        variant="outlined"
                        disabled={
                          (c.title === "Submit" && !mapState.size) ||
                          loading === true ||
                          (c.title === "Submit" && mapState.get('stage_approval_status') === 'Rejected' && !mapState.get('med_action'))
                        }
                        size="small"
                        onClick={() => actionsButtonCall(c)}
                      >
                        {c.title}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Draggable>
    )}
    {confirmOpen && (
      <ConfirmationDialogComponent
        open={confirmOpen}
        content={`Are you sure for action : ${confirmContent}`}
        handleConfirmClose={handleConfirmClose}
        confirmStatus={confirmStatus}
      />
    )}
    {confirmSchedule && (
      <ConfirmationDialogComponent
        open={confirmSchedule}
        content={`Are you sure you want to overlap schedule?`}
        handleConfirmClose={handleScheduleConfirmClose}
        confirmStatus={confirmScheduleStatus}
      />
    )}
  </>
}

export default withStyles(stylesheet)(BaseDialogModalComponent)