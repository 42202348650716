import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, } from '@mui/material';
import { ClassNameMap, withStyles } from '@mui/styles';
import moment from 'moment';
import stylesheet from '../detail-container.stylesheet';
import CardLayoutComponent from '../common/card-layout.component';
import HistoryPDIComponent from "./history-pdi.component";
import NotesComponent from "../common/notes.component";
import { useSelector } from 'react-redux';
import { RootState, useAppThunkDispatch } from "../../../../redux/store";
import ReferralComponent from '../common/referral-card.component'
import { useParams } from 'react-router-dom'
import { useReferralAction } from '../../../../utils/referral-hook'
import { setOtherMeasurementsStore } from '../../../../redux/other-measurement-slice'
import EmptyCardComponent from "../common/empty-card.component"
import MeasurementCardComponent from './measurement-card.component';

interface IProps {
  input: any;
  classes: ClassNameMap;
}

const OtherMeasurmentComponent: React.FC<IProps> = ({ input, classes }) => {
  const [data, setData] = useState([] as any);
  const [selectedNotes, setSelectedNotes] = useState([] as number[]);
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const dispatch = useAppThunkDispatch()
  const urlParams: any = useParams()
  const patientId: string = urlParams.patientId

  useEffect(() => {
    initialiseData(patientDetail?.measurements?.others)
  }, [patientDetail]);


  const initialiseData = (othersData: any) => {
    const stage = othersData?.map((e: any) => {
      return { ...e, mode: 'view' };
    });
    setData(stage);
  };

  const outer = (id: any, mode: string) => {
    setData(data.map((el: any) => (el.id === id ? { ...el, mode: mode } : el)))
  }

  const { onSetView, onDataSubmit } = useReferralAction()
  const onSetHistory = (_id: string) => outer(_id, 'history');
  const onSetEdit = (_id: string) => outer(_id, "edit")

  const subHeaderCal = (d: any) => {
    if (d?.value_range?.min === null && d?.value_range?.max === null)
      return d?.unit ? d.unit : ''
    let vrmin = ''
    let vrmax = ''
    if (d?.value_range?.min !== null) vrmin = d?.value_range?.min
    if (d?.value_range?.max !== null) vrmax = d?.value_range?.max
    return `${vrmin}-${vrmax} ${d?.unit ? d.unit : ''}`
  }


  const setIcons = (source_type: string) => {
    return input.isActionItems ? []
      : [
        { iconType: "edit", callback: onSetEdit },
        { iconType: "visible", callback: onSetHistory },
        { iconType: "description", callback: onNotesClick },
        { iconType: "more", callback: {} },
      ]
  }

  const bottomTextCal = (d: any) => {
    if (d.is_referred)
      return moment.unix(d.referred_on).format("MM/DD/YY")
    else
      return d.date_recorded ? moment.unix(d.date_recorded).format("MM/DD/YY") : ''
  };

  const onNotesClick = (_id: any) => {
    if (selectedNotes.includes(+_id)) {
      setSelectedNotes([...selectedNotes.filter((f) => f !== +_id)]);
    } else {
      setSelectedNotes([...selectedNotes, +_id]);
    }
  };

  const rightHeaderCal = (d: any) => {
    return (
      <Typography variant="body1" className={classes.desc}
        sx={{ paddingTop: '2px !important' }}
        color={(!d.value_range?.min && !d.value_range?.max)
          ? "initial"
          : (+d.value ?? 0) >= d.value_range?.min &&
            (+d.value ?? 0) <= d.value_range?.max
            ? "initial"
            : "red"
        }
        align="right"
      >
        <strong>{d.value ? d.value : "-"}</strong>
      </Typography>
    );
  };

  const onRefClick = (_id: any, _mode: string) => {
    setData(onSetView(_id, _mode, data))
  }

  const onRefSubmit = (_id: any, date: number, is_referred: boolean, referral_id: any) => {
    const updatedData = onDataSubmit(_id, date, is_referred, referral_id, data)
    setData(updatedData)
    dispatch(setOtherMeasurementsStore(updatedData))
  }

  const getTitle = (d: any) => {
    const tagName = (d?.tag || d?.tag_detail)
      ? `${d?.tag_detail?.tag_display_name || d?.tag_detail?.tag_name || d?.tag}`
      : ''
    return (tagName !== d.display_name && tagName !== '')
      ? `${d.display_name} (${tagName})`
      : d.display_name
  }

  return (
    <Grid container direction='row'>
      {data && [...data]
        ?.sort((a: any, b: any) => (a?.display_name).toLowerCase() > (b?.display_name).toLowerCase() ? 1 : -1)
        ?.map((d: any) => (
          <React.Fragment key={`om-${d.name}`}>
            {(d.mode === 'view') && (
              <>
                <CardLayoutComponent
                  id={d.id}
                  name={d?.name}
                  leftHeader={getTitle(d)}
                  rightHeader={rightHeaderCal(d)}
                  subHeader={subHeaderCal(d)}
                  bottomText={bottomTextCal(d)}
                  needs_confirmation={d.needs_confirmation || undefined}
                  needs_update={d.needs_update || undefined}
                  icons={setIcons(d?.source_type)}
                  onRefClick={() => onRefClick(d.id, 'ref')}
                  is_referred={d.is_referred}
                  is_overridden={d.is_overridden || undefined}
                  entityRef='measurement_others'
                  patientId={patientId}
                  category={'measurements'}
                />
                {selectedNotes.includes(d.id) && (
                  <NotesComponent
                    entityId={d.id}
                    entityRef={"measurement"}
                    cancelBtn={true}
                    onCancel={onNotesClick}
                  >
                    Notes
                  </NotesComponent>
                )}
              </>
            )}
            {d.mode === "history" && (
              <Grid container xs={12} direction="column">
                <Box className={classes.fullWidth}>
                  <HistoryPDIComponent
                    input={{ ...d, ref: 'other' }}
                    name={getTitle(d)}
                    outer={outer}
                    patientId={patientId}
                  />
                </Box>
              </Grid>
            )}
            {['edit'].includes(d.mode) &&
              <Box pt={1} pb={1} className={classes.fullWidth}>
                <MeasurementCardComponent
                  input={d.mode === 'edit_history'
                    ? data.find((el: any) => (el.id === d.id))?.historyData || {}
                    : data.find((el: any) => (el.id === d.id))
                  }
                  mode={d.mode}
                  outer={outer}
                />
              </Box>
            }
            {d.mode === 'ref' && (
              <ReferralComponent
                entityRef='measurement'
                input={d}
                patientId={patientId}
                leftHeader={getTitle(d)}
                rightHeader={rightHeaderCal(d)}
                subHeader={subHeaderCal(d)}
                programs={d.programs}
                closeRef={() => onRefClick(d.id, 'view')}
                onRefSubmit={onRefSubmit}
              />
            )}
          </React.Fragment>
        ))}
      {data?.length <= 0 && <EmptyCardComponent />}
    </Grid>
  );
};

export default withStyles(stylesheet)(OtherMeasurmentComponent);
