import React, { useState, useRef, useEffect } from 'react'
import {
  Grid, LinearProgress, IconButton, DialogTitle, DialogContent, Dialog, Typography,
} from '@mui/material'
import stylesheet from '../detail-container.stylesheet'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ClassNameMap, withStyles } from '@mui/styles'
import Draggable from "react-draggable"
import MedReconcilateComponent from '../care-plan/med-reconcilate.component'

interface IProps {
  open: boolean,
  item: any,
  handleDialogClose: any,
  classes: ClassNameMap
}

const MedReconciliationDialogComponent: React.FC<IProps> = ({ open, item, handleDialogClose, classes }) => {
  const medRef = useRef(null) as any
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(function () {
      setIsLoading(false)
    }, 700);
  })

  return (
    <Draggable ref={medRef} defaultPosition={{ x: 0, y: 0 }} handle='.imported-med-dialog'>
      <Dialog
        id={item.title}
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleDialogClose}
        aria-labelledby={item.title}
        hideBackdrop
        //disableBackdropClick={true}
        disableScrollLock
        sx={{
          position: "absolute",
          left: '18%',
        }}
      >
        <DialogTitle className={classes.addPatientQuestionHeaderSection} classes={{ root: 'imported-med-dialog' }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography color="textSecondary"><small>{`Imported`}</small> </Typography>
              <Typography id="modal-modal-title" variant="h6"><strong>{item?.title}</strong></Typography>
            </Grid>
            <Grid item>
              <IconButton size="small" onClick={handleDialogClose}>
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        {isLoading && <LinearProgress />}
        <DialogContent className={classes.contextualHelpContext}>
          {!isLoading &&
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <MedReconcilateComponent
                  key='reconcilation_Key'
                  dataObj={{}}
                  outerSubmit={handleDialogClose}
                  compKey='reconcilation_Key'
                  mapState={{}}
                />
              </Grid>
            </Grid>
          }
        </DialogContent>
      </Dialog>
    </Draggable>
  );
};

export default withStyles(stylesheet)(MedReconciliationDialogComponent)
