import React, { useState, useRef } from 'react'
import {
	AppBar, Box, Button, createStyles, Dialog, DialogContent, DialogTitle, Grid, LinearProgress,
	Tab, Tabs, TextField, Typography
} from '@mui/material'
import { ClassNameMap, withStyles, styled } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable'
import { Theme, toast } from 'react-toastify'
import moment from 'moment'
import stylesheet from '../detail-container.stylesheet'
import { PatientService } from '../../../../services/patient.service'
import Helper from '../../../../utils/helper'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import EmptyCardComponent from './empty-card.component'
import ConfirmationDialogComponent from './confirmation-dialog.component'
import MonitorEvaluationComponent from '../medications/monitor-evaluation.component'
import DialogTitleHeaderComponent from './dialog-title-header.component';

interface IProps {
	open: boolean
	handleClose: any
	handleAction: any
	input: any
	classes: ClassNameMap
}

interface StyledTabsProps {
	value: number
	onChange: (event: React.ChangeEvent<{}>, newValue: number) => void
	variant: "scrollable" | "standard" | "fullWidth" | undefined
}
type compSet = { title: string; index: number; comp: string; props: any }

interface TabPanelProps {
	children?: React.ReactNode
	index: any
	value: any
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props
	return (<div
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...other}
	>
		{value === index && <>{children}</>}
	</div>
	)
}

const LifestyleDialogComponent: React.FC<IProps> = ({ open, handleClose, handleAction, input, classes }) => {
	const [data, setData] = useState(input as any)
	const [loading, setLoading] = useState(false)
	const patientService = new PatientService()
	const lifestyleRef = useRef(null) as any
	const [isStopLifestyle, SetIsStopLifestyle] = useState(false)
	const [noDay, setNoDay] = useState('')
	const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
	const [configItem] = useState({
		focusTabIndex: 0,
		tabs: [
			{ title: 'Information', index: 0, comp: 'NotesComponent_Key', props: {} },
			// { title: 'Evaluation', index: 1, comp: 'ReferralComponent_Key', props: {} },
		]
	})
	const [value, setValue] = useState(configItem?.focusTabIndex || 0 as number)
	const [daysError, setDaysError] = useState('')
	const handleConfirmLifestyleClose = () => SetIsStopLifestyle(false)

	const confirmLifestyle = () => {
		handleConfirmLifestyleClose()
		setLoading(true)
		input?.id &&
			patientService.stopLifestyleDetails(patientDetail.id, input?.id, { end_date: moment(new Date()).unix() })
				.then((res: any) => {
					res?.data?.lifestyle
						? handleAction(input?.id)
						: handleAction()
				})
				.catch((err: any) => {
					toast.error(Helper.getErrorText(err), Helper.bottom_center())
				})
				.finally(() => {
					setLoading(false)
					handleClose()
				})
	}

	const handleInputChange = (e: any) => {
		const noDays = e.target.value
		setNoDay(noDays)
		if (!/^[0-9\b]+$/.test(noDays))
			setDaysError('Please enter an integer.')
		else if (noDays < 1)
			setDaysError('No of days should be atleast 1.')
		else
			setDaysError('')
	}

	const extendLifestyle = () => {
		setLoading(true)
		noDay && patientService.extendLifestyle(patientDetail.id, input?.id, { extended_day_count: noDay })
			.then((res: any) => {
				setData(res?.data?.lifestyle)
				setNoDay('')
			})
			.catch((err: any) => toast.error(Helper.getErrorText(err), Helper.bottom_center()))
			.finally(() => setLoading(false))
	}

	const renderExtendLifestyle = () => {
		return <Grid container spacing={0} direction='row' className={classes.contextualHelpContainer}>
			<Grid item xs={2}><Typography paddingTop={1} variant="subtitle2">{`Extend lifestyle plan`}</Typography></Grid>
			<Grid item xs={2} md={1}>
				<TextField
					id="no-Day"
					type="number"
					placeholder='Days'
					variant="standard"
					value={noDay}
					sx={{
						width: '3.5rem',
						paddingTop: '0.3rem'
					}}
					InputProps={{
						inputProps: {
							max: 100,
							min: 1,
						}
					}}
					onChange={handleInputChange}
					name="noDay"
					className={classes.desc}
					error={Boolean(daysError)}
				/>
			</Grid>
			<Grid item xs={7}>
				<Button
					variant="outlined"
					disabled={parseInt(noDay) > 0 ? false : true}
					onClick={extendLifestyle}
				>
					Save
				</Button>
			</Grid>
			{Boolean(daysError) &&
				<Grid item className={classes.extendLifestyleErr}>
					<Typography align='left' variant="body2" color="error">{daysError}</Typography>
				</Grid>
			}
		</Grid>
	}

	const renderKeyVal = (title: any, val: any) => {
		return <>
			<Grid item xs={2}><Typography variant="subtitle2">{title}</Typography></Grid>
			<Grid item xs={title === 'Type' ? 7 : 10}>
				{val
					? ['Measurements', 'Surveys'].includes(title)
						?
						<Typography display="block" component="span" variant="body2">
							{Object.keys(val)?.length > 0 ? val.map((item: any) => item).join(', ') : '-'}
						</Typography>
						: <Typography variant="body2">{val}</Typography>
					: <Typography variant="body2">-</Typography>
				}
			</Grid>
			{title === 'Type' &&
				<Grid item xs={3} sx={{ paddingTop: "initial", paddingLeft: '3rem !important' }}>
					{data?.type !== 'completed' &&
						<Button onClick={() => SetIsStopLifestyle(true)} variant="outlined" size="small">
							Stop Lifestyle Plan
						</Button>
					}
				</Grid>
			}
		</>
	}

	const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
		// if (configItem?.tabs) {
		// 	let activeTab = configItem?.tabs && configItem.tabs.find((i: any, index: any) => index === newValue)
		// }
		setValue(newValue)
	}

	const a11yProps = (index: number) => {
		return {
			id: `scrollable-auto-tab-${index}`,
			'aria-controls': `scrollable-auto-tabpanel-${index}`,
		}
	}

	const getLifestyleDetails = () => {
		return Object.keys(data)?.length > 0 ?
			<>
				<Grid container spacing={1} direction='row' className={classes.contextualHelpContainer}>
					{renderKeyVal('Type', data?.type)}
					{renderKeyVal('Due date text', data?.due_dt_text)}
				</Grid>
				{data?.type !== 'completed' && renderExtendLifestyle()}
			</>
			: <EmptyCardComponent />
	}

	return <>
		{open &&
			<Draggable ref={lifestyleRef} defaultPosition={{ x: 80, y: 0 }} handle='.lifestyle-dialog'>
				<Dialog
					id={`${input.title}-${input?.id}`}
					fullWidth={true}
					maxWidth="md"
					open={open}
					onClose={handleClose}
					hideBackdrop
					disableScrollLock
					onKeyUp={(e) => {
						if (e.key === 'Enter' && (parseInt(noDay) > 0))
							extendLifestyle()
					}}
					sx={{
						position: "absolute",
						left: '14rem',
						top: '10rem'
					}}
				>
					<DialogTitle sx={{ padding: '15px 10px 0px 20px' }} classes={{ root: 'lifestyle-dialog' }}>
						<DialogTitleHeaderComponent
							title={data?.display_name || data?.name}
							handleDialogClose={handleClose}
						/>
					</DialogTitle>
					<DialogContent className={classes.dialogContentStyle}>
						<Box mb={1}>
							<div className={classes.root}>
								<AppBar position="static" color="transparent">
									<Tabs value={value} onChange={handleChange} variant="standard">
										{configItem?.tabs?.map((c: any) => (<Tab
											key={`config-${c.title}`}
											label={c.title}
											{...a11yProps(c.index)}
											disabled={false}
										/>
										))}
									</Tabs>
								</AppBar>
								<Grid className={classes.linearProgressContainer}>
									{loading === true && <LinearProgress />}
									<Box className={classes.dialogHeaderBox}>
										<div className={classes.dialogHeader} />
										{configItem?.tabs?.map((c: any, i: any) => (
											<Box pl={2} pr={2} key={`compset-${i}`}>
												<TabPanel value={value} index={c.index}>
													{i === 0
														? getLifestyleDetails()
														: <MonitorEvaluationComponent item={input} />
													}
												</TabPanel>
											</Box>
										))}
									</Box>
								</Grid>
							</div>
						</Box>
					</DialogContent>
				</Dialog>
			</Draggable>
		}
		{isStopLifestyle &&
			<ConfirmationDialogComponent
				open={isStopLifestyle}
				content='Are you sure to stop this lifestyle plan?'
				handleConfirmClose={handleConfirmLifestyleClose}
				confirmStatus={confirmLifestyle}
			/>
		}
	</>
}

export default withStyles(stylesheet)(LifestyleDialogComponent)