import { Action, configureStore, ThunkDispatch } from '@reduxjs/toolkit'
import counterReducer from './confirmation-count-slice'
import metaDataReducer from './meta-data-slice'
import patientDetailReducer from './patient-detail-slice'
import programConditionReducer from './program-condition-slice'
import stageChangeReducer from './stage-change-slice'
import addControlReducer from './add-control-slice'
import authReducer from './auth-slice'
import commonDataSlice from './common-data-slice'
import programChecksReducer from './program-checks-slice'
import programTargetsReducer from './program-targets-slice'
import programCalculatorsReducer from './program-calculator-slice'
import multiProgramConfirmSlice from './multi-program-confirmation-slice'
import medicationReactionReducer from './medication-reaction-slice'
import { useDispatch } from 'react-redux'
import otherMeasurementsReducer from './other-measurement-slice'
import calculatedPSCReducer from './psc-calculated-slice'
import calculatedICD10Reducer from './calculate-icd10-slice'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    patientDetail: patientDetailReducer,
    metaData: metaDataReducer,
    programCondition: programConditionReducer,
    stageChange: stageChangeReducer,
    addButtons: addControlReducer,
    auth: authReducer,
    programChecks: programChecksReducer,
    programTargets: programTargetsReducer,
    programCalculators: programCalculatorsReducer,
    otherMeasurements: otherMeasurementsReducer,
    commonData: commonDataSlice,
    multiProgramConfirm: multiProgramConfirmSlice,
    medicationReaction: medicationReactionReducer,
    calculatedPSC: calculatedPSCReducer,
    calculatedIcd10: calculatedICD10Reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = ThunkDispatch<RootState, void, Action>
export const useAppThunkDispatch = () => useDispatch<AppDispatch>()
