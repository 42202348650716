import React, { useEffect, useState } from 'react'
import stylesheet from '../detail-container.stylesheet'
import {
  Box, Button, Card, CardContent, Grid, Typography, CircularProgress, List, ListItem
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { RootState, useAppThunkDispatch } from '../../../../redux/store'
import CardLayoutComponent from '../common/card-layout.component'
import NotesComponent from '../common/notes.component'
import DeleteMedComponent from '../common/delete-med.component'
import moment from 'moment'
import { PatientService } from '../../../../services/patient.service'
import { useCheckConfirmCount } from '../../../../utils/use-checkconfirmationcount-hook'
import { useDispatchAPIs } from '../../../../utils/use-dispatchApis-hook'
import ReferralComponent from '../common/referral-card.component'
import { useReferralAction } from '../../../../utils/referral-hook'
import ScheduleCarePlanComponent from './schedule-care-plan.component'
import BaseDialogModalComponent from '../common/base-dialog-modal.component'
import { useCurrentUser } from '../../../../utils/use-current-user'
import { compsConfig } from '../../../../utils/dynamic-comps'
import ProgramSubmedicationsComponent from './program-submedication.component'
import LongMenu from '../care-plan/recommendation.component'
import { setSelectedCarePlan } from '../../../../redux/common-data-slice'
import Helper from '../../../../utils/helper'
import OtherMedicationsComponent from './other-medications.component'
import { checkCondition } from '../common/condition-check'
import IngredientTooltip from '../common/ingredient-tooltip'
import MedDetailsComponent from '../common/med-details.component'
import { environment } from '../../../../environments/environment'
import { useUpdateDataAction } from '../../../../utils/update-details-hook'

interface IProps {
  patientInfo: any
  classes: ClassNameMap
}

const ProgramMedicationsComponent: React.FC<IProps> = ({
  patientInfo,
  classes,
}) => {
  const [, setState] = useState('')
  const [data, setData] = useState([] as any)
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const [selectedNotes, setSelectedNotes] = useState([] as number[])
  const patientService = new PatientService()
  const patientId: string = patientDetail?.id
  const [loading, setLoading] = useState(false)
  const [isCaptureBaseline, setIsCaptureBaseline] = useState({ status: false, id: '' })
  const [IsOrderPlaced, setIsOrderPlaced] = useState({ status: false, id: '' })
  const [instructions, setInstructions] = useState([] as any)
  const [currentOption, setCurrentOption] = useState('')
  const [openFhirJson, setOpenFhirJson] = useState(false)
  const [medMoreDetails, setMedMoreDetails] = useState({} as any)
  const [medTitle, setMedTitle] = useState('')
  const { currentRole } = useCurrentUser()
  const onBtnChange = (_input: string) => setCurrentOption(_input)
  const [, setCount] = useCheckConfirmCount(patientDetail.id)
  const { setProgramConfirmCount } = useDispatchAPIs(patientDetail.id)
  const uiResource = useSelector((state: RootState) => state.metaData.metadata)
  const selectedProgram = useSelector((state: RootState) => state.commonData.selectedProgram)
  const program_enrollments = useSelector((state: RootState) => state.patientDetail.patientDetail?.program_enrollments || [])
  const { onSetView, onDataSubmit } = useReferralAction()
  const [open, setOpen] = useState(false)
  const [configItem, setConfigItem] = useState({} as any)
  const [currentItem, setCurrentItem] = useState({} as any)
  const multiProgramConfirm = useSelector((state: RootState) => state.multiProgramConfirm)
  const selectedCarePlan = useSelector((state: RootState) => state.commonData.selectedCarePlan)
  const rejected = useSelector((state: RootState) => state.commonData.carePlanRejected)
  const dispatch = useAppThunkDispatch()
  const [selectedProgramObj, setSelectedProgramObj] = useState({} as any)
  const { updatePatientData } = useUpdateDataAction(patientDetail.id)

  useEffect(() => {
    if (!patientInfo?.isActionItems)
      dispatch(setSelectedCarePlan({}))
  }, [])

  useEffect(() => {
    if (rejected)
      updatePatientData({ isMeasurement: true, isTreatments: true })
  }, [rejected])

  useEffect(() => {
    const stage = patientDetail?.current_stage_name ?? ''
    setState(stage)
    initialiseData()
  }, [patientDetail])

  const handleDialogClose = () => {
    setOpen(false)
    dispatch(setSelectedCarePlan({}))
  }

  const initialiseData = () => {
    const temp = patientDetail?.medications?.care_plans
    let _data = temp
      .filter((f: any) => f.medications.length > 0)
      .map((e: { medications: any[]; program: any }) => {
        return {
          ...e,
          medications: e.medications.map((m) => {
            return { ...m, program: e.program, mode: 'view' }
          }),
        }
      })
      .map((c: { medications: any }) => c.medications)
      .flat()

    if (patientInfo?.isActionItems === true)
      _data = _data?.filter((i: { needs_update: any, needs_confirmation: any, program: any }) => (i.needs_confirmation === true || i.needs_update === true) && i.program === patientInfo?.actionProgram)

    setData(_data)
    setInstructions(
      temp.map((e: { program: any; instructions: any }) => {
        return { program: e.program, instructions: e.instructions }
      })
    )
    setSelectedProgramObj(
      selectedProgram.map((e: any) => {
        const carePlanObj = patientDetail?.medications?.care_plans?.find((f: { program: any }) => f.program === e)
        return {
          program: e,
          isRef: false,
          care_plan_id: carePlanObj?.care_plan_id,
          is_referred: carePlanObj?.is_referred,
          referral_id: carePlanObj?.referral_id || null
        }
      })
    )
  }

  const handleClose = (_id: any, _mode: any) => {
    setData(
      data.map((el: any) => (el.id === _id ? { ...el, mode: _mode } : el))
    )
  }

  const formatRightHeader = (d: any) => {
    if (d.is_recommended) {
      const isAllCaptureBaselineCount = (data?.filter((i: any) => i.is_recommended
        && i.is_communicated
        && i.approval_status !== 'Approved'
        && i.is_ordered === false
        && ['data_intake', 'med_baseline'].includes(getStageString(i.programs[0]))
        && !(i.titration_status?.includes('discontinue') && i.need_update)
      ) || [])?.length || 0

      const approvedCount = (data?.filter((i: any) => i.is_recommended
        && i.is_communicated
        && i.approval_status === 'Approved'
        && i.program === d.program
        && i.is_ordered === false
        && ['data_intake', 'med_baseline'].includes(getStageString(i.programs[0]))
        && !(i.titration_status?.includes('discontinue') && i.need_update)
      ) || [])?.length || 0

      const prgPendingCount = multiProgramConfirm.find((m) => m.program === d.programs[0])?.pending || 0

      if (d.is_order_submitted) {
        return ''
      } else if (d.is_baselined === true && d.is_ordered === false) {
        return (
          <Button
            onClick={() => onBtnSubmitOrder(d.id)}
            disabled={d.is_ordered === true || patientInfo?.isActionItems}
            style={d.is_ordered === false ? { backgroundColor: 'white', color: '#FF7015' } : { backgroundColor: 'white', }}
            variant="contained"
            size="small"
          >
            {IsOrderPlaced?.status && IsOrderPlaced?.id === d.id
              ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <small style={{ whiteSpace: 'nowrap', textTransform: 'none' }}>Order Placed</small>
              )}
          </Button>
        )
      }
      else if (d.is_communicated && d.approval_status === 'Approved'
        && d.is_ordered === false
        && ['data_intake', 'med_baseline'].includes(getStageString(d.programs[0]))
        && ((prgPendingCount === approvedCount && isAllCaptureBaselineCount === 0
        ))
      ) {
        return (
          <Button
            onClick={() => onBtnCaptureBaseline(d.id, d)}
            disabled={patientInfo?.isActionItems}
            style={{ backgroundColor: 'white', color: '#FF7015' }}
            variant="contained"
            size="small"
          >
            {isCaptureBaseline?.status && isCaptureBaseline?.id === d.id
              ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <small style={{ whiteSpace: 'nowrap', textTransform: 'none' }}>Capture Baseline</small>
              )}

          </Button>
        )
      } else if (['med_evaluation', 'maintenance_surveillance', 'early_tox_check']
        .includes(program_enrollments.find((f: { program: any }) => f.program === d.programs[0])?.current_stage)) {
        return `${checkCondition(d?.evaluation_status !== null, d?.evaluation_status?.replaceAll('_', ' '), '')}`
      } else {
        return ''
      }
    } else {
      return (
        <Typography variant="body1">
          {' '}
          <strong>
            {d.intake_regularity.charAt(0).toUpperCase() +
              d.intake_regularity.slice(1)}
          </strong>
        </Typography>
      )
    }
  }

  const onBtnSubmitOrder = (_id: string) => {
    setIsOrderPlaced({ status: true, id: _id })
    updateMedication(_id.toString(), { order_date: moment(new Date()).unix(), })
      .then((res: any) => {
        updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
      }).catch((err) => setIsOrderPlaced({ status: false, id: '' }))
  }

  const onBtnCaptureBaseline = (_id: string, d: any) => {
    setIsCaptureBaseline({ status: true, id: _id })
    const eId = program_enrollments?.find((f: { program: any }) => f.program === d.programs[0])?.enrollment_id || ''
    eId !== '' && patientService.captureBaseline(eId).then((_res: any) => {
      updatePatientData({ isMeasurement: true, isTreatments: true })
    })
      .catch((err: any) => {
        console.error(err)
        setIsCaptureBaseline({ status: false, id: '' })
      })
  }

  const formatMedDetails = (pu_rec: any[], dosage_info: any[]) => {
    return checkCondition(Object.keys(pu_rec).length > 0, getMedDetails(pu_rec, dosage_info), '')
  }

  const getMedDetails = (temp: any, dosage_info: any) => {
    const number = temp?.num_units || ''
    const formulation = temp?.formulation_display_name || ''
    const route = temp?.route_display_name || ''
    const frequency = uiResource.MEDICATION_DOSAGE_FREQUENCY[temp?.frequency] || ''
    // const directions = temp?.directions?.init || ''
    const dosageInfo = (dosage_info) && dosage_info?.[0]?.medicationTimings
      ? uiResource.MEDICATION_TIMINGS[dosage_info[0]['medicationTimings']]
      : ''
    const text = `${number}${formulation}${route}${frequency}` ? `${number} ${formulation} ${route} ${Helper.toLowerlCase(frequency)} ${dosageInfo}` : 'Details not available'
    return `${text}`
  }

  const formatSubHeader = (_d: any) => {
    const sub = checkCondition(_d.intake_started_on === null, 'Start', 'Taking')
    const titrate = checkCondition(_d.intake_started_on !== null && !_d.titration_status.includes('discontinue'), 'Taking', 'Stop')

    if (_d?.pu_order && _d.pu_order !== undefined && Object.keys(_d.pu_order).length > 0) {
      const initiateMed = getMedDetails(_d.pu_order, _d?.dosage_info)
      if (_d.titration_status === 'initiate_current') {
        const text = `continue ${getMedDetails(_d.pu_order, _d?.dosage_info)}`
        return <>{text}<IngredientTooltip ingredients={_d.calc_dose} /></>
      } else {
        const text = _d.titration_status === 'initiate'
          ? `${sub} ${initiateMed}`
          : `${titrate} ${getMedDetails(_d.pu_order, _d?.dosage_info)}`
        return <>{text}<IngredientTooltip ingredients={_d.calc_dose} /></>
      }
    } else {
      if (Object.keys(_d.pu_rec)?.length > 0 && _d.titration_status != 'initiate_current') {
        const puOrderMedDetails = getMedDetails(_d?.pu_order, _d?.dosage_info)
        const initiateMed = checkCondition(_d.intake_started_on === null, formatMedDetails(_d.pu_rec, _d?.dosage_info), puOrderMedDetails)
        const text = checkCondition(_d.titration_status === 'initiate', `${sub} ${initiateMed}`, `${titrate} ${puOrderMedDetails}`)
        return <>{text}<IngredientTooltip ingredients={_d.calc_dose} /></>
      } else {
        return 'Details not available'
      }
    }
  }

  const formatSubHeader1 = (d: any) => {
    if (Object.keys(d.pu_rec)?.length > 0) {
      if (d.titration_status != 'initiate_current') {
        const medDetails = checkCondition(d.intake_started_on === null && d.pu_rec !== undefined, formatMedDetails(d.pu_rec, d?.dosage_info), '')
        const text = checkCondition(d.intake_started_on === null && d.pu_rec !== undefined, `Start ${medDetails}`, '')
        return <>{text}</>
      }
    } else {
      return 'Details not available'
    }
  }

  const formatConfirmDate = (d: any) => Helper.dateChecking(d.recommendation_message) // d.recommendation_message

  const setIcons = (_d: any) => {
    return [
      { iconType: 'edit', callback: onSetEdit },
      { iconType: 'more', callback: {} },
    ]
  }

  const onNotesClick = (_id: any) => {
    (selectedNotes.includes(+_id))
      ? setSelectedNotes([...selectedNotes.filter((f) => f !== +_id)])
      : setSelectedNotes([...selectedNotes, +_id])
  }

  const getInnerProps = (t: any, _item: any) => {
    if (t.comp === 'ScheduleCarePlanComponent_Key') {
      return { ...t, props: { data: _item, outer: outerSubmit } }
    } else if (t.comp === 'ReferralComponent_Key') {
      return {
        ...t,
        props: {
          entityRef: 'medication',
          input: _item,
          patientId: patientId,
          leftHeader: Helper.getRXnorm(_item.name, _item?.rxnorm),
          rightHeader: formatRightHeader(_item),
          subHeader: formatSubHeader(_item),
          programs: _item.programs,
          closeRef: () => onRefClick(_item.id, 'view'),
          onRefSubmit: onRefSubmit,
        },
      }
    } else if (t.comp === 'NotesComponent_Key') {
      return {
        ...t,
        props: {
          entityId: _item.id,
          entityRef: 'medication',
          onCancel: onNotesClick,
        },
      }
    } else {
      return { ...t, props: {} }
    }
  }

  const mainOuterSubmit = (dt: any) => {
    handleDialogClose()
  }

  const onSetEdit = async (_id: number) => {
    const temp = data.map((el: any) =>
      el.id === _id ? { ...el, mode: 'edit' } : el
    )
    let item = await temp.find((f: any) => f.id === _id)
    const carePlanStatus = patientDetail?.medications?.care_plans?.find((f: { program: any }) => f.program === item?.program)?.plan_status || ''
    item = { ...item, 'care_plan_status': carePlanStatus }

    const current_stage = program_enrollments.filter((e: { program: any }) => e.program === item.program)[0].current_stage || ''

    const _tempCompConfig = item.is_recommended ?
      compsConfig.find((f: any) => Helper.checkGroup(currentRole, f?.role) && f.stage === current_stage)
      : compsConfig.find((f: { id: string }) => f.id === 'NOT_REC')

    const compsSet = _tempCompConfig?.tabs.map((t) => getInnerProps(t, item))

    const _actionsSet = _tempCompConfig?.actions.map((a: any) => {
      if (a.title === 'Cancel') {
        return { ...a, returnFun: handleDialogClose }
      } else if (a.title === 'Submit') {
        return { ...a, returnFun: mainOuterSubmit }
      } else {
        return { ...a }
      }
    })

    setConfigItem({
      ..._tempCompConfig,
      source_type: item.source_type,
      actions: _actionsSet,
      tabs: compsSet,
    })
    setCurrentItem(item)
    setOpen(true)
    // setData(temp)
  }

  useEffect(() => {
    if (Object.keys(selectedCarePlan).length > 0) {
      setData(
        data.map((el: any) =>
          el.id === selectedCarePlan.id
            ? {
              ...selectedCarePlan,
              care_plan: selectedCarePlan?.care_plan,
              programs: el.programs,
              program: el.program,
              mode: 'view',
            }
            : el
        )
      )
    }
  }, [selectedCarePlan])

  const updateMedication = async (_id: string, payload: any) => {
    try {
      return await patientService.putOtherMedication(patientDetail.id, _id, payload)
    } catch (error) {
      console.error(error)
    }
  }

  const confirmMedicine = (
    _typeOut: any,
    medicinedata: any,
    _mode: any
  ) => {
    if (_typeOut === 'confirm') {
      const payload = {
        entity_ref: 'medication',
        entity_id: medicinedata.id,
        confirmed_by: patientInfo.id,
      }
      patientService.PostConfirmations(payload).then((_response: any) => {
        /* do nothing */
      }).catch((err: any) => {
        console.error(err)
      })
    }
  }
  const outerSubmit = (
    _id: number,
    _mode: string,
    _res: string,
    _date: Date | null
  ) => {
    const temp = data.find((f: any) => f.id === _id)
    if (_res === 'save') {
      const _payload = {
        communication_date: moment(new Date()).unix(),
        is_recommended: temp.is_recommended,
        source_type: temp.source_type,
      }
      updateMedication(_id.toString(), _payload).then((res: any) => {
        if (res) {
          setData(
            data.map((el: any) =>
              el.id === res.data.id
                ? {
                  ...res.data,
                  programs: temp.programs,
                  program: temp.program,
                  mode: 'view',
                }
                : el
            )
          )
          setProgramConfirmCount(
            temp.needs_update,
            res.data.needs_update,
            temp.programs
          )
        }
      })
    } else if (_res === 'start') {
      setData(
        data.map((el: any) =>
          el.id === _id
            ? {
              ...el,
              mode: _mode,
              is_started: true,
              date_recorded: _date,
              status: 'Taking',
            }
            : el
        )
      )
    } else {
      setData(
        data.map((el: any) => (el.id === _id ? { ...el, mode: _mode } : el))
      )
    }
  }

  const onCarePlanRefClick = (prg: any, isOpen: boolean) => {
    setSelectedProgramObj(
      selectedProgramObj.map((e: any) => {
        return { ...e, isRef: (e.program === prg) ? isOpen : e.isRef }
      })
    )
    return
  }

  const onRefClick = (_id: any, _mode: string) => {
    setData(onSetView(_id, _mode, data))
  }

  const onRefSubmit = (_id: any, date: number, is_referred: boolean, referral_id: any) => {
    setData(onDataSubmit(_id, date, is_referred, referral_id, data))
  }

  const onDeleteMedicationClick = (_id: any, _mode: string) => {
    setData(onSetView(_id, _mode, data))
  }

  const onDeleteMedicationSubmit = (recId: any) => {
    setData(data.filter((e: { id: any }) => e.id !== recId))
    updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
  }
  const onCarePlanRefSubmit = (_id: any, date: number, is_referred: boolean, referral_id: any) => {
    setSelectedProgramObj(
      selectedProgramObj.map((e: any) => {
        if (e.care_plan_id === _id) {
          return { ...e, isRef: false, is_referred: is_referred, referral_id: referral_id }
        }
        else {
          return { ...e }
        }
      })
    )
  }

  const handleSave = (
    _values: any,
    _mode: any,
    _typeOut: any,
    medicinedata: any,
    _dosageInfo: any,
    response: any
  ) => {
    if (_typeOut === 'confirm') {
      setCount(
        data.find((f: { id: string }) => f.id === medicinedata.id)
          ?.needs_confirmation,
        false
      )
    } else {
      setCount(
        data.find((f: { id: string }) => f.id === medicinedata.id)
          ?.needs_confirmation,
        true
      )
    }

    const tempObj = data.find((f: { id: string }) => f.id == medicinedata.id)

    if (tempObj.source_type === 'imported') {
      setProgramConfirmCount(tempObj?.needs_confirmation, _typeOut == 'confirm' ? false : true, tempObj?.programs)
    }

    if (_typeOut === 'confirm') {
      confirmMedicine(_typeOut, medicinedata, _mode)

      setData(
        data.map((el: any) =>
          el.id === medicinedata.id
            ? {
              ...response.data,
              mode: _mode,
              programs: el.programs,
              program: el.program,
              needs_confirmation: false,
              recent_confirmation: {
                confirmed_on: response.data.date_recorded,
              },
            }
            : el
        )
      )
    } else {
      setData(
        data.map((el: any) =>
          el.id === medicinedata.id
            ? {
              ...response.data,
              mode: _mode,
              programs: el.programs,
              program: el.program,
              needs_confirmation: response.data.needs_confirmation
                ? response.data.needs_confirmation
                : false,
              confirmed_on: '',
              date_recorded: response.data.date_recorded,
            }
            : el
        )
      )
    }
  }

  const lastEvalDate = (sl: string) => {
    let currPlanObj = data.filter((m: any) => m.program === sl && m?.care_plan)[0]
    const prgEvalDate = (currPlanObj?.care_plan)
      ? currPlanObj?.care_plan?.last_evaluation_date || ''
      : patientDetail?.medications?.care_plans?.find((f: { program: any }) => f.program === sl)?.last_evaluation_date || ''

    return (prgEvalDate)
      ? <List className={classes.criteriaList} style={{ paddingLeft: '0.85rem' }}>
        <ListItem className={classes.puList}>
          <Typography variant='body2' className={classes.evalBoxVal}>
            Last evaluation: {moment.unix(prgEvalDate).format('MMM DD, YYYY hh:mm a')}
          </Typography>
        </ListItem>
      </List>
      : ''
  }

  const pendingMedActions = (item: any) => {
    let currInstObj = [] as any
    if (item.is_communicated === false) {
      currInstObj.push('Patient communication', 'Provider approval')
    } else {
      if (item.approval_status !== 'Approved') {
        currInstObj.push(`Provider approval`)
      }
      if (
        Helper.checkGroup(environment.okta.groups, currentRole) &&
        item.approval_status === 'Approved' &&
        (configItem.stage === 'med_initiation' ||
          ((item?.titration_status).includes('prior dose')
            || (item?.titration_status).includes('lowest dose')
            || (item?.titration_status).includes('downtitrate')
          )
        ) && item.intake_started_on === null
      ) {
        currInstObj.push(`Start date if patient has started taking med.`)
      }
      if (
        Helper.checkGroup(environment.okta.groups, currentRole) &&
        item?.titration_status?.includes('discontinue') &&
        item.intake_stopped_on === null
      ) {
        currInstObj.push(`Stop date if patient stopped taking med`)
      }
    }
    if (item.is_recommended && !item.is_order_submitted) {
      const isAllCaptureBaselineCount = (data?.filter((i: any) => i.is_recommended
        && i.is_communicated
        && i.approval_status !== 'Approved'
        && i.is_ordered === false
        && ['data_intake', 'med_baseline'].includes(getStageString(i.programs[0]))
        && !(i.titration_status?.includes('discontinue') && i.need_update)
      ) || [])?.length || 0

      const approvedCount = (data?.filter((i: any) => i.is_recommended
        && i.is_communicated
        && i.approval_status === 'Approved'
        && i.program === item.program
        && i.is_ordered === false
        && ['data_intake', 'med_baseline'].includes(getStageString(i.programs[0]))
        && !(i.titration_status?.includes('discontinue') && i.need_update)
      ) || [])?.length || 0

      const prgPendingCount = multiProgramConfirm.find((m) => m.program === item.programs[0])?.pending || 0

      if (item.is_baselined === true && item.is_ordered === false) {
        currInstObj.push(`Order Placed`)
      }
      else if (item.is_communicated && item.approval_status === 'Approved'
        && item.is_ordered === false
        && ['data_intake', 'med_baseline'].includes(getStageString(item.programs[0]))
        && ((prgPendingCount === approvedCount && isAllCaptureBaselineCount === 0
        ))
      ) {
        currInstObj.push(`Capture Baseline`)
      }
    }

    return currInstObj?.length > 0 && <>
      <Typography variant="body2" className={classes.evalBoxVal}>
        Pending actions:
      </Typography>
      <List className={classes.confirmCardContent}>
        {currInstObj?.map((d: any) => {
          return <ListItem className={classes.puList} key={`action-${d}`}>
            <Typography variant='body2' component="span" className={classes.evalBoxVal}>
              {d}
            </Typography>
          </ListItem>
        })
        }
      </List>
    </>
  }

  const printInstructions = (sl: string) => {
    let currPlanObj = data.filter((m: any) => m.program === sl && m?.care_plan)[0]
    const currInstObj = (currPlanObj?.care_plan)
      ? currPlanObj?.care_plan?.instructions || []
      : instructions.filter((m: any) => m.program === sl)[0]?.instructions || []

    return Object.keys(currInstObj)?.length > 0 && <List sx={{ padding: '0px 0px 5px 0.85rem' }}>
      {Object.keys(currInstObj)?.map((d: any) => {
        if (currInstObj[d].startsWith('[')) {
          try {
            let currInst = currInstObj[d]
            currInst = eval(currInst);
            let actionList = currInst !== undefined && currInst && Object.keys(currInst)?.map((i: any, index: any) => {
              const { action, color, display_name } = currInst[i]
              return <>
                <Typography component="span" className={classes.instructionVal} sx={{ color: `${color} !important` }}>
                  {display_name && display_name !== 'None' ? display_name : action}
                </Typography>
                <Typography component="span" className={classes.evalBoxVal} sx={{ paddingLeft: '0.01rem' }}>
                  {`${(index + 1) < currInst?.length ? ', ' : ''}`}
                </Typography>
              </>
            })
            return <ListItem className={classes.puList} key={`inst-${d}`}>
              <Typography variant='body2' className={classes.evalBoxVal}>Plan: {actionList}</Typography>
            </ListItem>
          }
          catch {
            return <ListItem className={classes.puList} key={`inst-${d}`}>
              <Typography variant='body2' className={classes.evalBoxVal}>Plan</Typography>
            </ListItem>
          }
        }
        else {
          return <ListItem className={classes.puList} key={`inst-${d}`}>
            <Typography variant='body2' component="span" className={classes.evalBoxVal}>
              {currInstObj[d]}
            </Typography>
          </ListItem>
        }
      })
      }
    </List>
  }

  const handleProviderRecom = () => { }

  const getCardOutline = (sl: string) => {
    const color = program_enrollments?.find((f: { program: string }) => f.program === sl)?.color_code
    if (Object.keys(selectedProgramObj)?.length > 0 &&
      (selectedProgramObj.filter((e: any) => e.program === sl && e.is_referred))?.length > 0) {
      return {
        border: '0.15rem solid #DB2323 !important',
        backgroundColor: '#f8ebeb !important',
        borderRadius: '4px'
      }
    }
    else {
      return { border: `2px solid ${color}` }
    }
  }

  const getStageString = (sl: string) => program_enrollments?.find((f: { program: any }) => f.program === sl)?.current_stage

  const isRecomMed = (prg: any) => data?.some((f: { is_recommended: boolean, program: any, titration_status: any }) => (!['discontinue', 'discontinue, restrict', 'discontinue, and initiate']?.includes(f.titration_status)) && f.is_recommended && f.program === prg) || false

  const getMedMoreDetails = (pId: string, recId: string, medName: string, code: any) => {
    setLoading(true)
    setMedTitle(`${medName} [${code}]`)

    patientService.getMedMoreDetails(pId, recId)
      .then((response: any) => {
        setMedMoreDetails(response.data?.fhir_json);
        setLoading(false)
      }).catch((err: any) => {
        setLoading(false)
        console.error(err);
      });
    setOpenFhirJson(true)
  }

  const handleFhirJsonClose = () => setOpenFhirJson(false)

  const getCarePlanStatus = (sl: any) => {
    let currPlanMedObj = data.filter((m: any) => m.program === sl)[0]
    const carePlanObj = (currPlanMedObj?.care_plan)
      ? currPlanMedObj?.care_plan
      : patientDetail?.medications?.care_plans?.find((f: { program: any }) => f.program === sl) || {}

    const carePlanName = carePlanObj?.plan_display_name || carePlanObj?.plan_name || ''
    let statusLabel = carePlanObj?.plan_status || ''
    if (statusLabel === 'partially_effective') statusLabel = 'intensify'
    else if (statusLabel === 'toxicity_management') statusLabel = 'toxicity monitoring'
    else if (statusLabel === 'ineffective') statusLabel = 'ineffective plan'
    else if (statusLabel === 'effective') statusLabel = 'effective plan'
    return ` (${statusLabel}${carePlanName !== undefined && carePlanName !== '' ? ` - ${carePlanName}` : ''})`
  }

  const getSelectedPrg = () => (patientInfo?.isActionItems && patientInfo?.actionProgram)
    ? [patientInfo?.actionProgram]
    : selectedProgram

  const isMedExists = () => patientInfo?.isActionItems === true && patientInfo?.actionProgram
    ? data?.length > 0
    : true

  const getCarePlanDetials = (sl: any) => {
    const carePlanObj = selectedProgramObj.find((f: { program: any }) => f.program === sl)
    return {
      id: carePlanObj?.care_plan_id,
      is_referred: carePlanObj?.is_referred || false,
      referral_id: carePlanObj?.referral_id
    }
  }

  const getCarePlanTitle = (sl: any) => {
    const prgDisplayName = program_enrollments.find((p: any) => p.program === sl)?.program_display_name || ''
    return `${prgDisplayName}  ${getCarePlanStatus(sl)}`
  }

  return (
    <>
      <Grid container direction="row">
        {isMedExists() && getSelectedPrg()?.map((sl: any, i: any) => (
          <Grid container key={`prg-med-${i}`} direction="column">
            <Box mb={1} p={0} className={classes.customCard}>
              <Card variant="outlined" sx={getCardOutline(sl)}>
                <CardContent sx={{ padding: '0px 6px 0px 5px !important' }}>
                  <Grid container direction="column" justifyContent="flex-start">
                    <Grid item className={classes.fullWidth}>
                      <Grid container direction="row" justifyContent="flex-start">
                        <Grid item style={{ width: '97%' }}>
                          <Typography variant="subtitle2" sx={{ color: '#FF7015', paddingTop: '0.1rem' }}>
                            {getCarePlanTitle(sl)}
                          </Typography>
                        </Grid>
                        <Grid item style={{ width: '3%' }}>
                          {!patientInfo?.isActionItems &&
                            <LongMenu
                              patientId={patientId}
                              title={getCarePlanTitle(sl)}
                              currPrg={program_enrollments.find((p: any) => p.program === sl)?.program}
                              currStage={getStageString(sl)}
                              onRefClick={() => onCarePlanRefClick(sl, true)}
                              isRecomMed={data?.some((f: { is_recommended: boolean, program: any }) => f.is_recommended && f.program === sl) || false}
                              careplanId={patientDetail?.medications?.care_plans?.find((f: { program: any }) => f.program === sl)?.care_plan_id || -1}
                              onProviderSubmit={handleProviderRecom}
                            />
                          }
                        </Grid>
                      </Grid>
                      <Grid item className={classes.fullWidth}>
                        <Typography variant="body2" className={classes.desc}>
                          {lastEvalDate(sl)}
                        </Typography>
                      </Grid>
                      <Grid item className={classes.fullWidth}>
                        <Typography variant="body2" className={classes.desc}>
                          {printInstructions(sl)}
                        </Typography>
                      </Grid>
                    </Grid>
                    {data?.filter((f: { is_recommended: boolean }) =>
                      f.is_recommended === true).map((d: any) => (
                        <React.Fragment key={`prg-med-recom-${d.id} `}>
                          {d.mode === 'view' && d.program === sl && (
                            <>
                              {patientInfo?.isActionItems === true && patientInfo?.actionProgram &&
                                pendingMedActions(d)
                              }
                              <CardLayoutComponent
                                id={d.id}
                                name={d?.name}
                                leftHeader={checkCondition(d.titration_status === 'initiate', Helper.getRXnorm(d.pu_med_name, d.rxnorm), Helper.getRXnorm(d.pu_order?.name, d.taking_med_rxnorm))}
                                subHeader={formatSubHeader(d)}
                                leftHeader1={checkCondition((d.titration_status)?.includes('discontinue') || (d.titration_status)?.includes('initiate_current'), '', checkCondition(d.intake_started_on === null && d.titration_status !== 'initiate' && d.pu_order?.name !== d.pu_med_name, `${checkCondition(d.pu_med_name !== null, Helper.getRXnorm(d.pu_med_name, d.rxnorm), Helper.getRXnorm(d.name, d.rxnorm))} `, ``))}
                                subHeader1={checkCondition((d.titration_status)?.includes('discontinue') || (d.titration_status)?.includes('initiate_current'), '', checkCondition(d.titration_status !== 'initiate' && d.intake_started_on === null, formatSubHeader1(d), ''))}
                                rightHeader={formatRightHeader(d)}
                                bottomText={formatConfirmDate(d)}
                                source={`${d?.source_type} - ${d?.source_system ? d?.source_system : 'ATMAN'}`}
                                needs_confirmation={
                                  d.source_type === 'imported'
                                    ? d.needs_confirmation || undefined
                                    : undefined
                                }
                                needs_update={d.needs_update || undefined}
                                icons={(patientInfo?.isActionItems === true) ? [] : setIcons(d)}
                                is_recommended={d.is_recommended}
                                programs={d.programs}
                                onRefClick={() => onRefClick(d.id, 'ref')}
                                onMedDetailsClick={() => getMedMoreDetails(patientDetail.id, d.id, d.pu_med_name ? d.pu_med_name : d.name, d?.rxnorm)}
                                onDeleteMedicationClick={() => onDeleteMedicationClick(d.id, 'deleteMed')}
                                is_referred={d.is_referred}
                                is_overridden={d.is_overridden || undefined}
                                entityRef="medication"
                                patientId={patientId}
                                is_care_plan_non_recc={
                                  !(d.is_recommended || d.is_referred)
                                }
                                category={'medications'}
                                titration_status={d.titration_status}
                                recommendation_source={d?.recommendation_source}
                              />
                              {selectedNotes.includes(d.id) && (
                                <NotesComponent entityId={d.id} entityRef={'medication'} onCancel={onNotesClick}>
                                  Notes
                                </NotesComponent>
                              )}
                            </>
                          )}
                          {d.mode === 'ref' && d.program === sl && (
                            <ReferralComponent
                              entityRef="medication"
                              input={d}
                              patientId={patientId}
                              leftHeader={Helper.getRXnorm(d.name, d.rxnorm)}
                              rightHeader={formatRightHeader(d)}
                              subHeader={formatSubHeader(d)}
                              programs={d.programs}
                              closeRef={() => onRefClick(d.id, 'view')}
                              onRefSubmit={onRefSubmit}
                            />
                          )}
                          {d.mode === 'deleteMed' && d.program === sl && (
                            <DeleteMedComponent
                              entityRef='medication'
                              input={d}
                              patientId={patientDetail.id}
                              leftHeader={Helper.getRXnorm(d.name, d?.rxnorm)}
                              rightHeader={d.intake_regularity?.charAt(0).toUpperCase() +
                                d.intake_regularity?.slice(1) || ''}
                              subHeader={formatSubHeader(d)}
                              programs={d.programs}
                              closeDeleteMed={() => onDeleteMedicationClick(d.id, 'view')}
                              onDeleteMedicationSubmit={onDeleteMedicationSubmit}
                            />
                          )}
                          {d.mode === 'edit' &&
                            d.is_recommended &&
                            d.program === sl && (
                              <>
                                <ScheduleCarePlanComponent data={d} outer={outerSubmit} />
                              </>
                            )}
                          {d.mode === 'edit' &&
                            !d.is_recommended &&
                            d.program === sl && (
                              <>
                                <ProgramSubmedicationsComponent
                                  input={d}
                                  outer={outerSubmit}
                                  selectedProgram={sl}
                                  uiResource={uiResource}
                                  patientInfo={patientInfo}
                                  handleP={handleClose}
                                  passData={handleSave}
                                  changeOption={onBtnChange}
                                  currentOption={currentOption}
                                />
                              </>
                            )}
                        </React.Fragment>
                      ))}
                    <OtherMedicationsComponent
                      patientInfo={{
                        ...patientInfo,
                        'is_recommended': isRecomMed(sl),
                        'isPrgMed': true,
                        'isActionItems': patientInfo?.isActionItems,
                        'actionProgram': patientInfo?.actionProgram
                      }}
                      currentProgram={patientInfo?.isActionItems ? patientInfo?.actionProgram : sl}
                    />
                    {Object.keys(selectedProgramObj)?.length > 0 &&
                      (selectedProgramObj.filter((e: any) => e.program === sl && e.isRef))?.length > 0 && (
                        <ReferralComponent
                          entityRef="care_plan"
                          isCarePlan={true}
                          input={getCarePlanDetials(sl)}
                          patientId={patientId}
                          leftHeader={getCarePlanTitle(sl)}
                          rightHeader={''}
                          subHeader={''}
                          programs={[sl]}
                          closeRef={() => onCarePlanRefClick(sl, false)}
                          onRefSubmit={onCarePlanRefSubmit}
                        />
                      )}
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        ))}
      </Grid>

      {open && (
        <BaseDialogModalComponent
          open={open}
          currentRole={currentRole}
          item={currentItem}
          configItem={configItem}
          handleDialogClose={handleDialogClose}
          patientId={patientId}
        />
      )}
      {
        openFhirJson && (
          <MedDetailsComponent
            openFhirJson={openFhirJson}
            medTitle={medTitle}
            handleFhirJsonClose={handleFhirJsonClose}
            medMoreDetails={medMoreDetails} />
        )
      }
    </>
  )
}

export default withStyles(stylesheet)(ProgramMedicationsComponent)