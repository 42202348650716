import React, { useEffect, useState } from 'react'
import {
  Box, Button, TextField, ButtonGroup, Card, CardContent, Grid, Typography,
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import { PatientService } from '../../../../services/patient.service'
import 'react-calendar/dist/Calendar.css'
import moment from 'moment'
import mtz from 'moment-timezone';
import { toast } from 'react-toastify'
import Helper from '../../../../utils/helper'
import { useCurrentUser } from '../../../../utils/use-current-user'
import DateTimezoneComponent from '../common/date-timezone.component'
import { RootState } from '../../../../redux/store'
import { useSelector } from 'react-redux'
import DropDownComponent from '../common/drop-down.component'
import { useCheckConfirmCount } from '../../../../utils/use-checkconfirmationcount-hook'
import { useDispatchAPIs } from '../../../../utils/use-dispatchApis-hook'
import { useUpdateDataAction } from '../../../../utils/update-details-hook'

interface IProps {
  input: any
  mode: string
  outer: any
  classes: ClassNameMap
}

const MeasurementCardComponent: React.FC<IProps> = ({ input, mode, outer, classes, }) => {
  const [value, setValue] = useState(input?.value as any)
  const [currentTimeZone, setCurrentTimeZone] = useState('')
  const patientService = new PatientService()
  const [recordedDate, setRecordedDate] = useState(input?.date_recorded !== null
    ? moment.unix(input?.date_recorded).format('YYYY-MM-DDTHH:mm')
    : '') as any
  const [loading, setLoading] = useState(false)
  const [tagData, setTagData] = useState([] as any)
  let [tag, setTag] = useState(null)
  const [valueRange, setValueRange] = useState(input?.value_range || {})
  const [unit, setUnit] = useState(input?.unit || '')
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const uiResource = useSelector((state: RootState) => state.metaData.metadata)
  const [, setCount] = useCheckConfirmCount(patientDetail.id)
  const { setProgramConfirmCount } = useDispatchAPIs(patientDetail.id);
  const { updatePatientData } = useUpdateDataAction(patientDetail.id)

  useEffect(() => {
    setTagData((uiResource?.MEASUREMENT_TAGS)?.[input?.name] || [])
    setTag(input?.tag_detail?.tag_name || '')
  }, [])

  const onBtnSubmit = async (_mode: string, _typeOut: string) => {
    setLoading(true)
    const rDate = mtz.tz(recordedDate, currentTimeZone).unix()
    const tagInfo = tag !== undefined && tag !== 'null' ? tag : null
    patientService.measurementsUpdate(patientDetail.id, {
      program: input?.programs ? input?.programs?.toString() : '',
      date: rDate,
      id: input?.id,
      values: [{
        date_recorded: rDate,
        rec_id: mode === 'edit' ? input?.id : input?.rec_id,
        value: value,
        tag: tagInfo,
      }],
      isPrgChecks: true
    }).then((res: any) => {
      setLoading(false)
      if (res?.data?.measurement) {
        updatePatientData({ isMeasurement: true, isTreatments: true })
        outer(res?.data?.measurement?.id, 'view')
      }
    }).catch((error: any) => {
      setLoading(false)
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    })
  }

  const checkSaveDisabled = () => (recordedDate === '' || value === '')

  const timeZoneChanged = (e: string) => setCurrentTimeZone(e)

  const getRangeUnit = (value_range: any) => {
    if (value_range?.min === null && value_range?.max === null)
      return unit ? unit : ''
    let vrmin = ''
    let vrmax = ''
    if (value_range?.min !== null) vrmin = value_range?.min
    if (value_range?.max !== null) vrmax = value_range?.max
    return <Typography component={'span'} color="textSecondary" variant="subtitle2">
      &nbsp;<small>({vrmin}-{vrmax}{unit ? ` ${unit}` : ''})</small>
    </Typography>
  }

  const getTagLabel = (item: any) => {
    return (item).includes('Glu')
      ? 'timing'
      : (['SBP', 'DBP', 'HR'].includes(item))
        ? 'position'
        : 'tag'
  }

  const handelMeasurementChange = (value = '') => {
    if ((/^\d*\.?\d{0,2}$/).test(value))
      setValue(value)
  }

  const handleTagChange = (val: any, id: any) => {
    if (id) {
      const patientValRange = val !== '' ? tagData?.find((i: any) => i.tag_display_name === val) : {}
      setTag(patientValRange?.tag_name)

      if (patientValRange && Object.keys(patientValRange)?.length > 0) {
        setValueRange(patientValRange?.value_range?.[(patientDetail?.c_sex === 'Female') ? 'female' : 'male'])
        setUnit(patientValRange?.unit)
      } else {
        setValueRange(input?.default_value_range || input?.value_range)
        setUnit(input?.default_unit || input?.unit)
      }
    }
  }

  const renderMeasurement = () => {
    return <Grid container justifyContent={'space-between'} direction="row" className={classes.programTargetsNames}>
      <Grid item xs={tagData?.length > 0 ? 6 : 7} className={classes.breakWord}>
        <Typography variant="subtitle2">{input?.display_name || input?.name}{getRangeUnit(valueRange)}</Typography>
      </Grid>
      <Grid item xs={tagData?.length > 0 ? 2 : 3}>
        <div className={classes.measurementInputOuter}>
          <TextField
            disabled={(input?.res_id)}
            id={input?.res_id}
            variant="standard"
            type="number"
            inputProps={{ sx: { textAlign: 'right' } }}
            onChange={(e) => handelMeasurementChange(e?.target.value)}
            value={value}
          />
        </div>
      </Grid>
      {tagData?.length > 0 &&
        <Grid item justifyContent="flex-end" sx={{ textAlign: "end" }}>
          <DropDownComponent
            id={input.id}
            disabled={false}
            type={"edit-measurement-card"}
            tagLabel={getTagLabel(input?.name)}
            data={tagData}
            value={tag}
            onChange={handleTagChange}
          />
        </Grid>
      }
    </Grid>
  }

  return (
    <Box mb={1} className={[classes.customCard, classes.customCardTarget].join(" ")}>
      <Card variant="outlined" className={classes.questionCardContent}>
        <CardContent className={classes.zeroPadding}>
          <Box className={classes.questionHeaderSection} p={1}>
            <Grid container direction="row" alignItems="center">
              <Typography>
                <strong>{`Edit`}</strong>
              </Typography>
            </Grid>
          </Box>
          <Box p={1} className={classes.programTargetsConatiner}>
            {renderMeasurement()}
            <Box className={classes.targetCardTimeZone}>
              <DateTimezoneComponent
                disabled={false}
                dateValue={recordedDate}
                onChangeEvent={(e: { target: { value: any } }) => {
                  setRecordedDate(e.target.value);
                }}
                onTimeZoneChange={timeZoneChanged}
              />
            </Box>
          </Box>
          <Box>
            <Grid container xs={12} direction="row" justifyContent="space-around" alignItems="center" className={classes.rrdButtons} >
              <ButtonGroup
                className={classes.questionButtonStyle}
                size="small"
                variant="text"
                aria-label="button"
              >
                <Button
                  disabled={checkSaveDisabled() || loading}
                  onClick={Helper.throttle(() => {
                    onBtnSubmit("view", "save");
                  }, 1000)}
                  className={classes.questionButtonWidth1}
                >
                  <strong>Save</strong>
                </Button>
                <Button onClick={() => outer(input?.id, 'view')} className={classes.questionButtonWidth1}>
                  <strong>Cancel</strong>
                </Button>
              </ButtonGroup>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default withStyles(stylesheet)(MeasurementCardComponent)
