import React, { useState, useEffect, memo, } from 'react'
import { Grid } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import CardLayoutComponent from '../common/card-layout.component'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import stylesheet from '../detail-container.stylesheet'

interface IProps {
  input: any
  classes: ClassNameMap
}

const ClinicalScoresComponent: React.FC<IProps> = ({ input, classes }) => {
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const clinical_scores = useSelector((state: RootState) => state.commonData.ClinicalScores)
  const patientId: string = patientDetail?.id
  const [data, setData] = useState([] as any)

  useEffect(() => {
    if (patientDetail?.measurements?.clinical_scores)
      initData(patientDetail?.measurements?.clinical_scores);
  }, [patientDetail?.measurements?.clinical_scores]);

  const initData = (clinicalData: any) => {
    const stage = clinicalData?.map((e: any) => {
      return { ...e, mode: 'view' }
    })
    setData(stage)
  }


  const formatConfirmDate = (save_timestamp: number, is_referred: boolean, referred_on: number) => {
    if (is_referred) {
      return moment.unix(referred_on).format('MM/DD/YY')
    } else if (save_timestamp) {
      return moment.unix(save_timestamp).format('MM/DD/YY')
    } else {
      return ''
    }
  }

  const setIcons = () => {
    return [{ iconType: 'more', callback: outer }]
  }

  const outer = (_id: any, _mode: string) => {
    // setData(onSetView(_id, _mode, data))
  }

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
      {data && Object.keys(data)?.length > 0 && data
        ?.sort((a: any, b: any) => a.display_name > b.display_name ? 1 : -1)
        ?.map((d: any, i: any) => (
          <React.Fragment key={`cli-scores-${i}`}>
            {d.mode === 'view' && input.parent === 'CS' && (
              <>
                <CardLayoutComponent
                  id={d.id}
                  name={d?.name}
                  leftHeader={d.display_name + ": " + d.score + " " + d.unit}
                  rightHeader={''}
                  subHeader={''}
                  bottomText={formatConfirmDate(d?.save_timestamp, d?.is_referred, d?.referred_on)}
                  needs_confirmation={d.needs_confirmation || false}
                  needs_update={d.needs_update || false}
                  programs={d.programs}
                  icons={setIcons()}
                  onRefClick={() => null}
                  is_referred={d.is_referred || false}
                  is_overridden={false}
                  entityRef='clinical_scores'
                  patientId={patientId}
                  category={'measurements'}
                />
              </>
            )}
          </React.Fragment>
        ))}
    </Grid>
  )
}

export default withStyles(stylesheet)(memo(ClinicalScoresComponent))
