import React, { useEffect, useState } from 'react'
import { Box, Input } from '@mui/material'
import { RouteComponentProps } from 'react-router-dom'

interface IProps extends RouteComponentProps {
  uniqueId: String
  question: any
  onTextBoxChange: Function
  defaultValue: any
  isAnswerSelected: Function
  history: any
  location: any
  match: any
}

const InputStage: React.FC<IProps> = ({
  uniqueId,
  question,
  onTextBoxChange,
  defaultValue,
  isAnswerSelected,
}) => {
  let [textValue, setTextValue] = useState('')
  useEffect(() => {
    question.component !== 'ParameterValue' &&
      setTextValue(isAnswerSelected(question.id, '', 'InputText'))
  }, [isAnswerSelected])

  useEffect(() => {
    question.component === 'ParameterValue' && setTextValue(defaultValue)
  }, [defaultValue])

  return (
    <Box sx={{
      paddingLeft: '0.4rem',
      marginBottom: '0.4rem',
      width: '-webkit-fill-available',
    }}>
      <Input
        required
        type={question.component.includes('Number') ? 'number' : 'text'}
        id={'standard-required' + uniqueId}
        value={textValue || ''}
        key={'input-field-' + uniqueId + defaultValue}
        placeholder={question.label || ''}
        onChange={(e: any) => {
          setTextValue(e.target.value)
          onTextBoxChange(e.target.value, question.id)
        }}
        sx={question.component.includes('InputTextQuestion') ? { width: '95%' } : {}}
      />
    </Box>
  )
}

export default InputStage
