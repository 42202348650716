import React from 'react'
import { Select, InputLabel, MenuItem, FormControl, } from '@mui/material'
import stylesheet from '../detail-container.stylesheet'
import { ClassNameMap, makeStyles, withStyles } from '@mui/styles'

const styles = makeStyles(() => ({
  formControl: {
    minWidth: '100px !important',
  },
  icon: {
    fill: '#D87C4E',
  },
  disableIcon: {
    fill: 'gray'
  },
  actionMenu: {
    fontSize: '0.8rem',
    color: '#D87C4E',
    padding: '0.15rem 0.6rem',
  },
}))
interface IProps {
  value: any
  stageActions: any
  handleActionChange: any
  classes: ClassNameMap
}

const ActionDropdownComponent: React.FC<IProps> = ({
  value,
  stageActions,
  handleActionChange,
  classes
}) => {

  const actionClasses = styles()

  return <>
    <FormControl className={actionClasses.formControl} disabled={(Object.keys(stageActions)).length <= 0 ? true : false}>
      <InputLabel htmlFor="actions-id" className={classes.stageLabel}
        sx={{ color: (Object.keys(stageActions)).length <= 0 ? 'gray' : '#D87C4E' }}>
        Actions
      </InputLabel>
      <Select
        label="Grouping"
        variant="standard"
        id="actions-id"
        value={value}
        className={classes.actionSelect}
        disabled={Object.keys(stageActions).length <= 0}
        inputProps={{
          classes: {
            icon: (Object.keys(stageActions)).length <= 0 ? actionClasses.disableIcon : actionClasses.icon,
          },
        }}
        onChange={handleActionChange}
      >
        {Object.keys(stageActions)?.sort()?.map((item: any, i: any) =>
          <MenuItem
            key={`menu-${i}`}
            value={`${stageActions[item]}/${i}`}
            className={classes.actionMenu}
          >
            {stageActions[item]}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  </>
}

export default withStyles(stylesheet)(ActionDropdownComponent)