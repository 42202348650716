import React, { useState, useEffect } from 'react'
import { withStyles, ClassNameMap } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import { Grid, LinearProgress, Typography, Tooltip, Box } from '@mui/material'
import KeyboardVoiceRoundedIcon from '@mui/icons-material/KeyboardVoiceRounded';
import { PatientService } from '../../../../services/patient.service'
import EmptyCardComponent from './empty-card.component'

interface IProps {
	id: any
	category: string
	handleLoading: any
	classes: ClassNameMap
}

const ContextualHelpDataComponent: React.FC<IProps> = ({ id, category, handleLoading, classes }) => {
	const [data, setData] = useState({} as any)
	const [loading, setLoading] = useState(false)
	const patientService = new PatientService()

	useEffect(() => {
		getContextualHelpData()
	}, [])

	const getContextualHelpData = async () => {
		setLoading(true)
		handleLoading(true)

		if (category === '') {
			patientService.getAAFormContextualHelp(id).then((eduResponse: any) => {
				const educationObj = eduResponse.data || {}
				if (Object.keys(educationObj).length > 0) {
					if (!isContextObjEmpty(educationObj))
						setData(educationObj)
				}
				setLoading(false)
				handleLoading(false)
			}).catch(() => {
				setLoading(false)
				handleLoading(false)
			})
		} else {
			patientService.getContextualHelp(category, id).then((eduResponse: any) => {
				const educationObj = eduResponse.data || {}
				if (Object.keys(educationObj).length > 0) {
					if (!isContextObjEmpty(educationObj))
						setData(educationObj)
				}
				setLoading(false)
				handleLoading(false)
			}).catch(() => {
				setLoading(false)
				handleLoading(false)
			})
		}
	}

	const renderKeyVal = (title: any, val: any) => {
		return <>
			<Grid item xs={1.5}>
				<Typography variant="subtitle2"
					sx={['common', 'serious'].includes(title)
						? { paddingRight: '1.5rem', textAlign: 'right' }
						: {}
					}
				>{title}	</Typography></Grid>
			<Grid item xs={10}><Typography variant="body2">{val ? val : '-'}</Typography></Grid>
		</>
	}

	// const getIngredients = () => data?.ingredients?.map((item: any) => item?.name)?.join(', ') || '-'

	const isContextObjEmpty = (contexData: any) => {
		if (contexData?.brand_names?.length && contexData?.drug_class?.length && contexData?.ingredients?.length)
			return true
		else {
			return false
		}
	}

	const renderVal = (key: any, val: any) => {
		if (key === 'side_effects') {
			return val?.map((curr: any, i: any) => {
				return <>
					<Tooltip title={curr.definition} placement="bottom-start">
						<Typography component="span" variant="body2" >
							<span className={classes.pointerCursor} style={{ textDecorationLine: "underline" }}>{curr.name}</span>
						</Typography>
					</Tooltip>
					{i + 1 < val?.length && ', '}
				</>
			})
		} else if (key === 'info_URL') {
			if (typeof val === 'string') val = val?.split(',')
			return (val && val?.length > 0) ?
				val?.map((item: any, index: any) => {
					return <Typography
						display="block"
						key={`info-url-${index}`}
						onClick={() => window.open(item, "_blank")}
						className={classes.pointerCursor}
						component="span"
						color="primary"
						variant="subtitle2">
						<u>{item}</u>
					</Typography>
				})
				: <Typography variant="body2" >-</Typography>
		}
	}

	const msg = new SpeechSynthesisUtterance()

	const speechHandler = (msg: any, text: any) => {
		msg.text = text
		window.speechSynthesis.speak(msg)
	}

	return <>
		{loading && <LinearProgress />}
		<Box className={classes.contextualHelpBox}>
			{Object.keys(data)?.length > 0 ?
				<>
					{(Object.keys(data?.definition || [])?.length > 0 || Object.keys(data?.use || [])?.length > 0)
						? <Grid container spacing={1} className={classes.contextualHelpContainer}>
							{renderKeyVal('Definition', data?.definition)}
							{renderKeyVal('Use', data?.use)}
						</Grid>
						: <>
							{category === "measurements" || category === "conditions" ? (
								<Grid container spacing={1} className={classes.contextualHelpContainer}>
									<Grid item xs={1.5}><Typography variant="subtitle2">Pronunciation</Typography></Grid>
									<Grid item xs={10}>
										<Typography variant="body2">
											{data?.pronounciation ?
												<>
													{data?.pronounciation}
													<KeyboardVoiceRoundedIcon
														onClick={() => speechHandler(msg, data?.pronounciation)}
														className={classes.pronunciationKeyboard}
														titleAccess='pronunciation'
													/>
												</> : '-'}
										</Typography>
									</Grid>
									{renderKeyVal('Synopsis', data?.synopsis)}
									{renderKeyVal('Mechanism', data?.mechanism)}
								</Grid>
							) : (
								<Grid container spacing={1} className={classes.contextualHelpContainer}>
									{renderKeyVal('Brand names', data?.brand_names?.join(', ') || '-')}
									{renderKeyVal('Drug class', data?.drug_class)}
									<Grid item xs={1.5}><Typography variant="subtitle2">Pronunciation</Typography></Grid>
									<Grid item xs={10}>
										<Typography variant="body2">
											{data?.pronounciation ?
												<>
													{data?.pronounciation}
													<KeyboardVoiceRoundedIcon
														onClick={() => speechHandler(msg, data?.pronounciation)}
														className={classes.pronunciationKeyboard}
														titleAccess='pronunciation'
													/>
												</> : '-'}
										</Typography>
									</Grid>
									{renderKeyVal('Synopsis', data?.synopsis)}
									{renderKeyVal('Mechanism', data?.mechanism)}
									<Grid item xs={1.5}><Typography variant="subtitle2">Side effects</Typography></Grid>
									<Grid item xs={12}>
										<Grid container spacing={1}>
											{renderKeyVal('common', renderVal('side_effects', data?.side_effects?.common))}
											{renderKeyVal('serious', renderVal('side_effects', data?.side_effects?.severe))}
										</Grid>
									</Grid>
									{renderKeyVal('Info URL', renderVal('info_URL', data?.info_URL))}
								</Grid>
							)}
						</>
					}
				</>
				: <Box p={2}><EmptyCardComponent /></Box>
			}
		</Box>
	</>
}

export default withStyles(stylesheet)(ContextualHelpDataComponent)