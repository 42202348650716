import React, { useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { RouteComponentProps } from 'react-router-dom'
import { Box, IconButton, Paper, Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import RadioStage from './radio-stage.component'
import ParameterValueComponentStage from './parametervalue-stage.component'
import ScaleStage from './scale-stage.component'
import DropdownStage from './dropdown-stage.component'
import InputStage from './input-stage.component'
import DateStage from './date-stage.component'

const useStyles = makeStyles(() => ({
  subBranch: {
    backgroundColor: '#064c441c',
  },
  details: {
    display: 'flex',
    padding: '0 0 0.5rem 0.625rem',
    flexFlow: 'row wrap',
    alignContent: 'space-between',
    fontSize: '0.8rem',
  },
  pannel: {
    padding: '0.5rem 1rem 0 1rem',
    fontWeight: 500,
    fontSize: '0.8rem'
  },
  infoIcon: {
    color: '#00000096',
    paddingLeft: '0.3rem',
  },
  infoIconFont: {
    fontSize: '16px'
  },
}))

interface IProps extends RouteComponentProps {
  question: any
  onTextChange: Function
  isAnswerSelected: Function
  isViewHistory?: boolean
  answerSet: any
  type: string
  dataReceivedFlag: boolean
  history: any
  location: any
  match: any
}

const GenericQuestionnaireComponent: React.FC<IProps> = ({
  question,
  onTextChange,
  isAnswerSelected,
  isViewHistory,
  answerSet,
  type,
  dataReceivedFlag,
}) => {
  const classes = useStyles()

  const checkStringExp = (arg1: string, arg2: string) => {
    if (typeof arg1 === 'string') {
      const filterChar = arg1.replace(/[^\w\s]/gi, '')
      if (typeof arg1 === 'string' && arg1 && arg1.includes('!')) {
        if (arg2.includes(':')) {
          let currValObj = arg2.split(/[:]/)
          let currVal = currValObj[0]
          if (currVal && filterChar != currVal) return true
        } else if (filterChar != arg2) return true
      } else if (filterChar == arg2) return true
    }
    return false
  }

  const iterateViaBranch = (subBranch: any, inputQuestion: any) =>
    (subBranch.questions || []).map((que: any, index: any) => {
      let checkValue = subBranch.condition
      let displayCondition = answerSet && answerSet?.findIndex((obj: any) => {
        let inputValue = obj[inputQuestion.id] && obj[inputQuestion.id]['value']
        if (
          Object.keys(obj).includes(inputQuestion.id) &&
          checkValue &&
          inputValue
        ) {
          return (
            inputValue === checkValue || checkStringExp(checkValue, inputValue)
          )
        }
      })
      if (displayCondition !== -1) {
        return (<GenericQuestionnaireComponent
          key={que.id}
          question={que}
          onTextChange={onTextChange}
          isAnswerSelected={isAnswerSelected}
          answerSet={answerSet}
          type={'inner'}
          dataReceivedFlag={dataReceivedFlag}
          history={''}
          location={''}
          match={''}
        />
        )
      }
    })

  const nestedQuestions = (inputQuestion: any) =>
    (question.branches || []).map((subBranch: any) => {
      return iterateViaBranch(subBranch, inputQuestion)
    })

  const genericHandler = (answerValue: any, questionId: any, questionMeta: any) => {
    let answer = ''
    if (question.component === 'ScaleQuestion') {
      const currOptions = question?.options
      answer = currOptions[answerValue] !== undefined
        ? currOptions[answerValue]
        : answerValue
    } else
      answer = answerValue

    let answerObj = {
      [questionId]: {
        value: answer,
      },
    }
    if (!isViewHistory)
      return onTextChange(answerObj, questionMeta)
  }

  const parameterValueHandler = (
    answerValue: any,
    answerUnit: any,
    questionId: any,
    questionMeta: any,
  ) => {
    let answerObj = {
      [questionId]: {
        value: answerValue,
        units: answerUnit,
      },
    }
    if (!isViewHistory)
      return onTextChange(answerObj, questionMeta, true)
  }

  let uniqueId: string = ''
  if (question && question.id) {
    uniqueId = question.id
  }

  useEffect(() => {
    renderQuestions()
  }, [answerSet, onTextChange])

  const renderQuestions = () => {
    return <>
      <Paper key={'paper-' + uniqueId} elevation={3}>
        <Box mb={1}>
          <div key={'panel1a-header-' + uniqueId} className={classes.pannel}>
            {question.question_text}
            <Tooltip
              data-testid={'question-helper-text-' + uniqueId}
              title={question?.helper_text || 'Details not available'}
              placement="bottom"
            >
              <IconButton size='small' className={classes.infoIcon}>
                <InfoIcon sx={{ fontSize: '1rem' }} />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.details} key={'panel-details-' + uniqueId}>
            {(question.component === 'RadioQuestion' ||
              question.component === 'YesNoRadioQuestion' ||
              question.component === 'PHQGADRadioQuestion') && (
                <RadioStage
                  question={question}
                  uniqueId={uniqueId}
                  onInChange={genericHandler}
                  isAnswerSelected={isAnswerSelected}
                  history={''}
                  location={''}
                  match={''}
                />
              )}
            {question.component === 'ScaleQuestion' && (
              <ScaleStage
                question={question}
                uniqueId={uniqueId}
                onInChange={genericHandler}
                isAnswerSelected={isAnswerSelected}
                history={''}
                location={''}
                match={''}
              />
            )}
            {question.component === 'ParameterValue' && (
              <ParameterValueComponentStage
                question={question}
                uniqueId={uniqueId}
                onDropTextChange={parameterValueHandler}
                answerArray={answerSet}
                isAnswerSelected={isAnswerSelected}
                dataReceivedFlag={dataReceivedFlag}
                history={''}
                location={''}
                match={''}
              />
            )}
            {question.component === 'DropDownQuestion' && (
              <DropdownStage
                question={question}
                uniqueId={uniqueId}
                onDropDownChange={genericHandler}
                defaultUnits={''}
                history={''}
                location={''}
                match={''}
              />
            )}
            {question.component === 'DateQuestion' && (
              <DateStage
                uniqueId={uniqueId}
                question={question}
                onTextBoxChange={genericHandler}
                isAnswerSelected={isAnswerSelected}
                history={''}
                location={''}
                match={''}
              />
            )}
            {['InputTextQuestion', 'Text', 'NaturalNumber'].includes(question.component) && (
              <InputStage
                uniqueId={uniqueId}
                question={question}
                onTextBoxChange={genericHandler}
                defaultValue={''}
                isAnswerSelected={isAnswerSelected}
                history={''}
                location={''}
                match={''}
              />
            )}
          </div>
        </Box>
      </Paper>
      {nestedQuestions(question)}
    </>
  }

  return (
    <div key={`display-div-${uniqueId}`} style={{ marginLeft: type === 'inner' ? '1rem' : '0px' }}>
      {renderQuestions()}
    </div>
  )
}

export default GenericQuestionnaireComponent
