import React, { useEffect } from 'react';
import axios from 'axios';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppThunkDispatch } from '../redux/store';
import { resetEncryptedToken, setEncryptedToken } from '../redux/common-data-slice';
import { PatientService } from '../services/patient.service';

interface Idata {
    oktaAuth: any,
    history: any,
    location: any,
    match: any,
    children: any,
    requestInterceptorHandler: any,
    requestInterceptorErrorHandler: any,
    responseInterceptorSuccessHandler: any,
    responseInterceptorErrorHandler: any,
}

let requestInterceptor: any = null;
let responseInterceptor: any = null;

const UndecoratedSetupAxios: React.FC<any> = ({ oktaAuth, history, location, match,
    children, requestInterceptorHandler = (config: any) => config,
    requestInterceptorErrorHandler = (error: any) => Promise.reject(error),
    responseInterceptorSuccessHandler = (response: any) => response,
    responseInterceptorErrorHandler = (error: any) => Promise.reject(error) }) => {
    const dispatch = useAppThunkDispatch();
    const encryptedToken = useSelector((state: RootState) => state.commonData.encryptedToken.token)
    const patientService = new PatientService()

    useEffect(() => {
        requestInterceptor = axios.interceptors.request.use(
            requestInterceptorSuccessHandler,
            requestInterceptorErrorHandlerFun
        );
        responseInterceptor = axios.interceptors.response.use(
            responseInterceptorSuccessHandlerFun,
            responseInterceptorErrorHandlerFun
        );
        return () => {
            axios.interceptors.request.eject(requestInterceptor);
            axios.interceptors.response.eject(responseInterceptor);
        }
    }, [encryptedToken])

    const tokenVerificationAPI = async (url: string) => {
        try {
            const res = await patientService.tokenVerification()
            dispatch(setEncryptedToken({ token: res.data.token, status: 'ready' }))
            return res.data.token
        } catch (error) {
            console.error(error)
        }
    }

    const requestInterceptorSuccessHandler = async (config: any) => {
        const token = await oktaAuth?.getAccessToken();
        // If not able to retrieve a token, send the user back to login
        if (typeof token === 'undefined' && oktaAuth) {
            await oktaAuth.revokeAccessToken()
            oktaAuth.signOut()
            dispatch(resetEncryptedToken())
            return config;
        }
        const newConfig = requestInterceptorHandler(config);
        let tokenFilter = newConfig.url.endsWith('/token-verification') ? token : encryptedToken;
        if (tokenFilter === '') {
            tokenFilter = await tokenVerificationAPI(config.url)
        }
        // Return the config with the token appended to the Authorization Header
        let newHeaders = {}
        newConfig.headers.Accept = 'application/json';
        if (newConfig?.method === 'get') {
            newHeaders = {
                ...newConfig,
                headers: {
                    Authorization: `Bearer ${tokenFilter}`, ...(newConfig?.headers || {})
                },
            };
        } else if (newConfig?.method === 'post' && newConfig.url.endsWith('ccd/uploads')) {
            newHeaders = {
                ...newConfig,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${tokenFilter}`, ...(newConfig?.headers || {})
                },
            };
        } else {
            newHeaders = {
                ...newConfig,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${tokenFilter}`, ...(newConfig?.headers || {})
                },
            };
        }
        return newHeaders;
    };

    const requestInterceptorErrorHandlerFun = (error: any) =>
        requestInterceptorErrorHandler(error);

    const responseInterceptorSuccessHandlerFun = (response: any) =>
        responseInterceptorSuccessHandler(response);

    const responseInterceptorErrorHandlerFun = async (error: any) => {
        if (error?.response?.status === 401) {
            await oktaAuth.revokeAccessToken()
            dispatch(resetEncryptedToken())
            oktaAuth.signOut()
        }
        return responseInterceptorErrorHandler(error);
    }
    return children;

}
UndecoratedSetupAxios.displayName = "SetupAxios"
export default withRouter(withOktaAuth(UndecoratedSetupAxios));