import { Box, Typography, } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import moment from "moment";
interface IProps {
  item: any
  classes: ClassNameMap
}

const PatientCurrentStatusComponent: React.FC<IProps> = ({ item, classes }) => {
  return (
    <Box p={2} className={classes.subModalBackground} data-testid='current-status'>
      <Typography variant="subtitle2">
        {`Patient has started taking ${item.name} on ${moment.unix(item.intake_started_on).format("MMM DD YYYY")}.`}
      </Typography>
    </Box>
  )
}

export default withStyles(stylesheet)(PatientCurrentStatusComponent)
