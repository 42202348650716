import React, { useEffect } from "react";
import { FormControl, Select, MenuItem, InputLabel, } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { RouteComponentProps } from 'react-router-dom';


const useStyles = makeStyles(() => ({
  formControl: {
    margin: '5px',
    marginTop: '5px',
    minWidth: 120,

    '&.MuiMenuItem-root': {
      fontSize: '0.8rem !important',
      padding: '2px 5px',
    },
    '&.MuiFormLabel-root.Mui-focused': {
      paddingTop: '10px',
      fontSize: '0.8rem',
    },
    '&.MuiList-padding': {
      padding: '2px'
    },
  },
  valLabel: {
    fontSize: '0.8rem'
  }
}));

interface IProps extends RouteComponentProps {
  question: any,
  uniqueId: string,
  onDropDownChange: Function,
  defaultUnits: any,
  history: any,
  location: any,
  match: any
}

const DropdownStage: React.FC<IProps> = ({ question, uniqueId, onDropDownChange, defaultUnits }) => {
  const classes = useStyles()
  let isUnitsValid = true

  useEffect(() => {
    if (defaultUnits !== '') {
      onDropDownChange(defaultUnits, question.id, question, 'drop')
    }
  }, [defaultUnits])

  if (!Array.isArray(question?.units)) isUnitsValid = false

  return (
    <FormControl
      className={classes.formControl}
      key={'form-control' + uniqueId}
      size='small'
    >
      <InputLabel
        id="demo-simple-select-label"
        key={'input-label-' + uniqueId + defaultUnits}
        className={classes.valLabel}
      >
        Units
      </InputLabel>
      {isUnitsValid && (
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          key={'select-' + uniqueId + defaultUnits}
          className={classes.valLabel}
          defaultValue={defaultUnits}
          onChange={(e: any) => {
            onDropDownChange(e.target.value)
          }}
        >
          {question?.units.map((index: any, i: any) => {
            return (
              <MenuItem key={'menu-item' + uniqueId + index} value={index} className={classes.valLabel}>
                {index}
              </MenuItem>
            )
          })}
        </Select>
      )}
    </FormControl>
  )
}

export default DropdownStage;