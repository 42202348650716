import React, { useState } from 'react'
import { Box, Grid, TextField, Typography } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import Helper from '../../../../utils/helper'
import { checkCondition } from '../common/condition-check'
import stylesheet from '../detail-container.stylesheet'
interface IProps {
  details: any
  isOtherMed: any
  outerSubmit: any
  classes: ClassNameMap
}

const DetailComponent: React.FC<IProps> = ({ details, isOtherMed, outerSubmit, classes }) => {
  const uiResource = useSelector((state: RootState) => state.metaData.metadata)
  const _schedule = details.c_schedule //details.c_schedule
  const _porder = details.pu_order
  const _rec_sch = details?.is_recommended ? details?.pu_rec : details?.c_rec_schedules?.find((d: any) => d.chosen)
  const [daysSupply, setDaysSupply] = useState(details.days_supply || '' as any)

  const handleInputChange = (e: any) => {
    setDaysSupply(e.target.value)
    outerSubmit(e.target.name, e.target.value)
  }

  return (
    <Box p={1}>
      <Box mb={2}>
        <Grid container xs={12} direction="row" alignItems="center">
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              Name
            </Typography>
            <Grid container xs={12} direction="row" alignItems="center">
              <Typography variant="subtitle2">
                <strong>
                  {checkCondition(_schedule?.aa_form, Helper.getRXnorm(_schedule?.aa_form, _schedule?.rxnorm), '-')}
                </strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid container xs={12} direction="row" alignItems="center">
          <Grid item xs={3}>
            <Typography variant="body2" color="textSecondary">
              Schedule name
            </Typography>
            <Grid container xs={12} direction="row" alignItems="center">
              <Typography variant="subtitle2">
                <strong>
                  {checkCondition(_schedule?.schedule_display_name, _schedule?.schedule_display_name, '-')}
                </strong>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="textSecondary">
              Number of units
            </Typography>
            <Grid container xs={12} direction="row" alignItems="center">
              <Typography variant="subtitle2">
                <strong>
                  {checkCondition(_porder?.num_units && (_schedule?.aa_form || _schedule?.schedule_display_name), `${_porder?.num_units}`, '-')}
                </strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid container xs={12} direction="row" alignItems="center">
          <Grid item xs={3}>
            <Typography variant="body2" color="textSecondary">
              Frequency
            </Typography>
            <Grid container xs={12} direction="row" alignItems="center">
              <Typography variant="subtitle2">
                <strong>
                  {checkCondition(uiResource.MEDICATION_DOSAGE_FREQUENCY[_porder?.frequency] && (_schedule?.aa_form || _schedule?.schedule_display_name), uiResource.MEDICATION_DOSAGE_FREQUENCY[_porder?.frequency], '-')}
                </strong>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="textSecondary">
              Route
            </Typography>
            <Grid container xs={12} direction="row" alignItems="center">
              <Typography variant="subtitle2">
                <strong>
                  {checkCondition(_porder?.route && (_schedule?.aa_form || _schedule?.schedule_display_name), _porder?.route, '-')}
                </strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid container xs={12} direction="row" alignItems="center" mb={1}>
        <Grid item xs={3}>
          <Typography variant="body2" color="textSecondary">
            Max Dose
          </Typography>
          <Grid container xs={12} direction="row" alignItems="center">
            <Typography variant="subtitle2">
              <strong>
                {checkCondition(_schedule?.max_dose, `${_schedule?.max_dose} ${_schedule?.unit}`, '-')}
              </strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" color="textSecondary">
            Directions
          </Typography>
          <Grid container xs={12} direction="row" alignItems="center">
            <Typography variant="subtitle2">
              {/* <strong>{checkCondition(_schedule?.directions, _schedule?.directions, '-')}</strong> */}
              <strong>{checkCondition(_rec_sch?.directions?.init, _rec_sch?.directions?.init, '-')}</strong>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={12} direction="row" alignItems="center">
        <Grid item xs={3}>
          <Typography variant="body2" color="textSecondary">No. supply</Typography>
          <Grid container xs={12} direction="row" alignItems="center">
            <TextField
              variant="standard"
              size="small"
              type="number"
              value={daysSupply}
              onChange={handleInputChange}
              name="days_supply"
              className={classes.medicationTextField}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withStyles(stylesheet)(DetailComponent)
