import { lazy, Suspense, useState, useEffect } from 'react'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { SecureRoute, Security, LoginCallback, useOktaAuth } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import NavbarComponent from './components/navbar/navbar.component'
import OktaNavbarComponent from './components/navbar/okta-navbar.component'
import HomePageComponent from './components/homepage/home-page.component'
import CorsErrorModalComponent from './components/okta/cors-error-modal.component'
import { oktaAuthConfig, oktaSignInConfig } from './components/okta/config'
import LoginComponent from './components/okta/login.component'
import SetupAxios from './utils/httpInterceptor'
import { environment } from './environments/environment'
import { ThemeProvider } from '@mui/material/styles'
import { appTheme } from './themes/themes'
import 'react-toastify/dist/ReactToastify.css'
import './App.scss'
import { useAppThunkDispatch } from './redux/store'
import { resetLoggedInUser } from './redux/common-data-slice'
import PlanComponent from './components/patientRole/plan/plan.component'
import ActivityComponent from './components/patientRole/activity/activity.component'
import EducationComponent from './components/patientRole/education/education.component'
import MessageComponent from './components/patientRole/message/message.component'
import ErrorBoundary from './components/homepage/error-boundary.component'
import PageNotFoundComponent from './components/homepage/page-not-found.component'
import FHIRPatientCallbackComponent from './components/fhir/fhir-patient-callback.component'
import LoginCodeComponent from './components/okta/login-code.component'

const DetailContainerComponent = lazy(
  () => import('./components/patients/detail/detail-container.component')
)
const QuestionnaireComponent = lazy(
  () => import('./components/questionnaires/questionnaire.component')
)
const PatientTreatmentPlanComponent = lazy(
  () => import('./components/patients/treatment-plan.component')
)
const QuestionnaireListComponent = lazy(
  () => import('./components/patients/questionnaire-list.component')
)
const authClient = new OktaAuth(oktaAuthConfig) as any

const AppComponent = () => {
  const [corsErrorModalOpen, setCorsErrorModalOpen] = useState(false)
  const history = useHistory()
  const onAuthRequired = () => history.push('/login')
  const dispatch = useAppThunkDispatch()

  authClient.authStateManager.subscribe((authState: any) => {
    // handle emitted latest authState
    if (authState.isAuthenticated) {
      authClient.authStateManager.updateAuthState()
    } else {
      if (params.get('code') === null) {
        authClient.start()
        authClient.signInWithRedirect({ redirectUri: oktaSignInConfig.redirectUri })
      }
    }
  })

  const params = new URLSearchParams(useLocation().search);

  useEffect(() => {
    return () => {
      dispatch(resetLoggedInUser())
    }
  }, [])

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin))
  }

  const getOktaAccessRoute = () => {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <SetupAxios>
          <Switch>
            <SecureRoute exact path="/" component={HomePageComponent} />
            <SecureRoute
              component={QuestionnaireComponent}
              exact
              path="/patients/:patientId/questionnaires/:questionnaireId"
            />
            <SecureRoute
              component={DetailContainerComponent}
              exact
              path="/patients/:patientId/details"
            />
            <SecureRoute
              component={QuestionnaireListComponent}
              exact
              path="/patients/:patientId/list"
            />
            <SecureRoute
              component={PatientTreatmentPlanComponent}
              exact
              path="/patients/:patientId/treatment-plan"
            />
            {/* <SecureRoute exact path="/epic-fhir/sync/launch" component={FHIRLaunchComponent} />
            <SecureRoute exact path="/epic-fhir/sync/launch-callback" component={FHIRCallbackComponent} /> */}
            <SecureRoute exact path="/epic-fhir/sync/:fhirPatientId/launch-callback" component={FHIRPatientCallbackComponent} />
            <SecureRoute component={PlanComponent} exact path="/plan" />
            <SecureRoute component={ActivityComponent} exact path="/activity" />
            <SecureRoute
              component={EducationComponent}
              exact
              path="/education"
            />
            <SecureRoute component={MessageComponent} exact path="/message" />
            <Route
              exact
              path="/login"
              render={() => (
                <LoginComponent
                  history={''}
                  location={window.location}
                  match={''}
                  config={oktaSignInConfig}
                />
              )}
            />
            <Route
              path="/login/callback"
              render={() => (
                <LoginCodeComponent
                  history={''}
                  location={window.location}
                  match={''}
                  config={oktaSignInConfig}
                />
              )}
            //  render={(props) => <LoginCallback {...props} onAuthResume={() => history.push('/login')} />}
            />
            <Route path="*" component={PageNotFoundComponent} />
          </Switch>
        </SetupAxios>
      </Suspense>
    )
  }
  const getAccessRoute = () => {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/" component={HomePageComponent} />
          <Route
            component={QuestionnaireComponent}
            exact
            path="/patients/:patientId/questionnaires/:questionnaireId"
          />
          <Route
            component={DetailContainerComponent}
            exact
            path="/patients/:patientId/details"
          />
          <Route
            component={QuestionnaireListComponent}
            exact
            path="/patients/:patientId/list"
          />
          <Route
            component={PatientTreatmentPlanComponent}
            exact
            path="/patients/:patientId/treatment-plan"
          />
          <Route component={PlanComponent} exact path="/plan" />
          <Route component={ActivityComponent} exact path="/activity" />
          <Route component={EducationComponent} exact path="/education" />
          <Route component={MessageComponent} exact path="/message" />
          {/* <Route exact path="/epic-fhir/sync/launch" component={FHIRLaunchComponent} />
          <Route exact path="/epic-fhir/sync/launch-callback" component={FHIRCallbackComponent} /> */}
          <Route path="*" component={PageNotFoundComponent} />
        </Switch>
      </Suspense>
    )
  }

  return (
    <ErrorBoundary>
      <ThemeProvider theme={appTheme}>
        <section className="disease-management-app">
          {environment.okta.isEnabled ? (
            <Security
              oktaAuth={authClient}
              onAuthRequired={onAuthRequired}
              restoreOriginalUri={restoreOriginalUri}
            >
              <OktaNavbarComponent
                history={''}
                location={window.location}
                match={''}
                {...{ setCorsErrorModalOpen }}
              />
              {corsErrorModalOpen && (
                <CorsErrorModalComponent
                  history={''}
                  location={window.location}
                  match={''}
                />
              )}
              {getOktaAccessRoute()}
            </Security>
          ) : (
            <>
              <NavbarComponent
                history={''}
                location={window.location}
                match={''}
              />
              {getAccessRoute()}
            </>
          )}
          <ToastContainer />
        </section>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export default AppComponent
