import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { PatientService } from '../services/patient.service'
import Helper from '../utils/helper'

export interface PatientDetailState {
  patientDetail: any
  status: any
  errorText: any
}
const patientService = new PatientService()
const initialState: PatientDetailState = {
  patientDetail: {},
  status: null,
  errorText: null,
}

export const getPatientInfo = createAsyncThunk(
  'patient-detail/getPatientInfo',
  async (params: any, { rejectWithValue }) => {
    try {
      const response = patientService.getPatientInfo(
        params.patientId,
        params.selectedProgram
      )
      return await response
    } catch (err: any) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err)
    }
  }
)

export const patientDetailSlice = createSlice({
  name: 'patient-detail',
  initialState,
  reducers: {
    resetPatientDetail: (state) => {
      return initialState
    },
    updatePatientDetail: (state, action: PayloadAction<any>) => {
      state.patientDetail = {
        ...state.patientDetail,
        [action.payload.L1]: {
          ...state.patientDetail[action.payload.L1],
          [action.payload.L2]: action.payload.data,
        },
      }
    },
    updatePDConditions: (state, action: PayloadAction<any>) => {
      state.patientDetail = {
        ...state.patientDetail,
        conditions: action.payload,
      }
    },
    updatePDMeasurements: (state, action: PayloadAction<any>) => {
      state.patientDetail = {
        ...state.patientDetail,
        measurements: action.payload,
      }
    },
    updatePDMedications: (state, action: PayloadAction<any>) => {
      state.patientDetail = {
        ...state.patientDetail,
        medications: action.payload,
      }
    },
    updateEnrollments: (state, action: PayloadAction<any>) => {
      state.patientDetail = {
        ...state.patientDetail,
        program_enrollments: action.payload,
      }
    },
    loadingPatientDetail: (state, action: PayloadAction<any>) => {
      state.status = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPatientInfo.pending, (state) => {
      state.status = 'loading'
      state.errorText = ''
    })
    builder.addCase(getPatientInfo.fulfilled, (state, { payload }) => {
      state.status = 'success'
      state.patientDetail = payload.data.patient
      state.errorText = ''
    })
    builder.addCase(
      getPatientInfo.rejected,
      (state, { payload, error, meta, type }) => {
        state.status = 'failed'
        state.errorText = Helper.getErrorText(payload)
      }
    )
  },
})

// Action creators are generated for each case reducer function
export const {
  resetPatientDetail,
  updatePatientDetail,
  updatePDConditions,
  updatePDMeasurements,
  updatePDMedications,
  updateEnrollments,
  loadingPatientDetail,
} = patientDetailSlice.actions

export default patientDetailSlice.reducer
