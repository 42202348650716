export const environment = {
  production: false,
  webservices: {
    // endpoint: 'http://localhost:5000',
    endpoint: 'https://qa.services.atmanhealth.com',
  },
  okta: {
    isEnabled: true,
    issuer: 'https://atmanhealth.okta.com/oauth2/default',
    clientId: '0oagmmxpjdWvMKkid5d7',
    baseUrl: 'https://atmanhealth.okta.com',
    groups: ['PROVIDER', 'NAVIGATOR'],
    providerGroup: ['PROVIDER'],
    navigatorGroup: ['NAVIGATOR'],
  },
  epic: {
    PROVIDER_CLIENTID: 'f6d9ea0f-9d55-43ed-837f-2a0db18e2393',
  },
  express: {
    isEnabled: true,
  },
}
