import React, { useState, useEffect, memo, useRef } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
import CardLayoutComponent from '../common/card-layout.component'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { ClassNameMap, withStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { RootState, useAppThunkDispatch } from '../../../../redux/store'
import { PatientService } from '../../../../services/patient.service'
import { useCheckConfirmCount } from '../../../../utils/use-checkconfirmationcount-hook'
import DeleteCard from '../common/delete-card-component'
import NotesComponent from '../common/notes.component'
import ProgramMonitorTypeComponent from './program-monitor-type.component'
import { useReferralAction } from '../../../../utils/referral-hook'
import { setMonitors } from '../../../../redux/common-data-slice'
import stylesheet from '../detail-container.stylesheet'
import MonitorDialogComponent from '../common/monitor-dialog.component'
import Helper from '../../../../utils/helper'
import EmptyCardComponent from '../common/empty-card.component'


interface IProps {
  input: any
  classes: ClassNameMap
}
const ProgramMonitorsComponent: React.FC<IProps> = ({ input, classes }) => {
  const [data, setData] = useState([] as any)
  const patientDetail = useSelector(
    (state: RootState) => state.patientDetail.patientDetail
  )
  const inputElTaking = useRef(null) as any
  const inputElNotTaking = useRef(null) as any

  useEffect(() => {
    let stage = patientDetail?.medications?.monitors?.map((e: any) => {
      return { ...e, mode: 'view' }
    })
    if (input?.isActionItems === true)
      stage = stage?.filter((i: { needs_update: any, programs: any }) => i.needs_update === true && i.programs?.some((s: string) => s === input?.actionProgram))
    setData(stage)
  }, [patientDetail])

  const getMonitorListByType = (data: any, type: any) => {
    data = input?.isActionItems === true
      ? data?.filter((i: { needs_update: any, is_overridden: any, programs: any }) => i.is_overridden !== true && i.needs_update === true && i.programs?.some((s: string) => s === input?.actionProgram)) || []
      : data || []

    return <Accordion square defaultExpanded className={classes.patientAccrodionBg}>
      <AccordionSummary
        ref={type === 'active' ? inputElNotTaking : inputElTaking}
        expandIcon={<ExpandMoreIcon className={classes.patientAccrodionExpandIcon} />}
      >
        <Typography className={classes.subTitle}>{Helper.stageName(type)}</Typography>
      </AccordionSummary>
      <AccordionDetails >
        <Grid container className={classes.zeroTopPadding}>
          <Grid item className={classes.fullWidth}>
            <ProgramMonitorTypeComponent
              input={{
                data: data,
                type: type
              }}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  }

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
      {Object.keys(patientDetail?.medications?.monitors)?.length > 0 &&
        <Grid item className={classes.fullWidth}>
          {getMonitorListByType(patientDetail?.medications?.monitors, 'active')}
        </Grid>
      }
      {patientDetail?.medications?.stopped_monitors &&
        Object.keys(patientDetail?.medications?.stopped_monitors)?.length > 0 && !input?.actionProgram &&
        <Grid item className={classes.fullWidth}>
          {getMonitorListByType(patientDetail?.medications?.stopped_monitors, 'completed')}
        </Grid>
      }
      {!input?.actionProgram &&
        (patientDetail?.medications?.monitors && Object.keys(patientDetail?.medications?.monitors)?.length < 1 &&
          patientDetail?.medications?.stopped_monitors && Object.keys(patientDetail?.medications?.stopped_monitors)?.length < 1
        ) && <EmptyCardComponent />
      }
    </Grid>
  )
}

export default withStyles(stylesheet)(memo(ProgramMonitorsComponent))