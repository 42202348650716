import React, { useEffect, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import OktaSignIn from '@okta/okta-signin-widget'
import { setAccessToken } from '../../redux/auth-slice';
import { useSelector } from 'react-redux';
import { RootState, useAppThunkDispatch } from '../../redux/store';
import '../../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { Box, LinearProgress } from '@mui/material';
import OktaAuth from '@okta/okta-auth-js';
import { oktaAuthConfig } from './config';

interface IProps extends RouteComponentProps {
  history: any;
  location: any;
  match: any;
  config: any;
}

const LoginCodeComponent: React.FC<IProps> = ({
  history,
  location,
  match,
  config
}) => {
  const dispatch = useAppThunkDispatch();
  const authClient = new OktaAuth(oktaAuthConfig)
  const params = new URLSearchParams(useLocation().search);

  const getAuthCode = async () => {
    const authCode = params.get('code');
    if (authCode !== null) {
      const { tokens } = await authClient.token.parseFromUrl();
      authClient.tokenManager.setTokens(tokens);
      dispatch(setAccessToken(tokens));
      localStorage.setItem("LOGIN_REDIRECT", 'true')
      const TARGET_EPIC_CALLBACK = localStorage.getItem('TARGET_EPIC_CALLBACK')
      localStorage.removeItem('TARGET_EPIC_CALLBACK')
      window.location.href = (TARGET_EPIC_CALLBACK) ? TARGET_EPIC_CALLBACK : '/'
    }
  }

  useEffect(() => {
    getAuthCode()
  }, []);

  return (
    <Box><LinearProgress sx={{ width: '100%' }} /></Box>
  );
};

export default LoginCodeComponent;
