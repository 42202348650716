import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import IpatientsTable from '../model/patientTable'

export interface commonDataState {
  selectedStage: string
  selectedProgram: string[]
  patientsTable: IpatientsTable
  loggedInUser: any
  encryptedToken: { token: string; status: string }
  carePlanRejected: boolean
  selectedPU: { name: ''; does: ''; unit: ''; fq: ''; rxcui: '' }
  selectedSchedule: any
  selectedCarePlan: any
  otherConditions: any
  ICD10Codes: any
  ClinicalScores: any
  monitors: any
  lifestyles: any
  programFilter: any
  programChips: any
}

const initialState: commonDataState = {
  selectedStage: '',
  selectedProgram: [],
  patientsTable: {
    limit: 25,
    page: 1,
    sort: '',
    search: '',
    programs: '',
    hospitals: localStorage.getItem('atman-hospitals') || '',
    status: '',
    gender: '',
    stage: '',
    state: '',
    priority: '',
  },
  loggedInUser: { roles: [], email: '' },
  encryptedToken: { token: '', status: '' },
  carePlanRejected: false,
  selectedPU: { name: '', does: '', unit: '', fq: '', rxcui: '' },
  selectedSchedule: {},
  selectedCarePlan: {},
  otherConditions: [],
  ICD10Codes: [],
  ClinicalScores: [],
  monitors: [],
  lifestyles: [],
  programFilter: [],
  programChips: [],
}

export const commonDataSlice = createSlice({
  name: 'common_data',
  initialState,
  reducers: {
    setSelectedStage: (state, action: PayloadAction<any>) => {
      state.selectedStage = action.payload
    },
    setSelectedProgram: (state, action: PayloadAction<any>) => {
      state.selectedProgram = action.payload
    },
    setPatientsTable: (state, action: PayloadAction<any>) => {
      state.patientsTable = action.payload
    },
    setLoggedInUser: (state, action: PayloadAction<any>) => {
      state.loggedInUser = action.payload
    },
    setEncryptedToken: (state, action: PayloadAction<any>) => {
      state.encryptedToken = action.payload
    },
    setCarePlanRejected: (state, action: PayloadAction<any>) => {
      state.carePlanRejected = action.payload
    },
    setSelectedPU: (state, action: PayloadAction<any>) => {
      state.selectedPU = action.payload
    },
    setSelectedSchedule: (state, action: PayloadAction<any>) => {
      state.selectedSchedule = action.payload
    },
    setSelectedCarePlan: (state, action: PayloadAction<any>) => {
      state.selectedCarePlan = action.payload
    },
    setMonitors: (state, action: PayloadAction<any>) => {
      state.monitors = action.payload
    },
    setLifestyles: (state, action: PayloadAction<any>) => {
      state.lifestyles = action.payload
    },
    setOtherConditions: (state, action: PayloadAction<any>) => {
      state.otherConditions = action.payload
    },
    setICD10Codes: (state, action: PayloadAction<any>) => {
      state.ICD10Codes = action.payload
    },
    setClinicalScores: (state, action: PayloadAction<any>) => {
      state.ClinicalScores = action.payload
    },
    setProgramFilter: (state, action: PayloadAction<any>) => {
      state.programFilter = action.payload
    },
    setProgramChips: (state, action: PayloadAction<any>) => {
      state.programChips = action.payload
    },
    resetSelectedProgram: (state) => {
      state.selectedProgram = initialState.selectedProgram
    },
    resetSelectedStage: (state) => {
      state.selectedStage = initialState.selectedStage
    },
    resetPatientsTable: (state) => {
      state.patientsTable = initialState.patientsTable
    },
    resetLoggedInUser: (state) => {
      state.loggedInUser = initialState.loggedInUser
    },
    resetEncryptedToken: (state) => {
      state.encryptedToken = initialState.encryptedToken
    },
    resetCarePlanRejected: (state) => {
      state.carePlanRejected = initialState.carePlanRejected
    },
    resetSelectedPU: (state) => {
      state.selectedPU = initialState.selectedPU
    },
  },
})

export const {
  setSelectedStage,
  setSelectedProgram,
  resetSelectedProgram,
  setPatientsTable,
  resetSelectedStage,
  resetPatientsTable,
  setLoggedInUser,
  resetLoggedInUser,
  setEncryptedToken,
  resetEncryptedToken,
  setCarePlanRejected,
  resetCarePlanRejected,
  setSelectedPU,
  resetSelectedPU,
  setSelectedSchedule,
  setSelectedCarePlan,
  setOtherConditions,
  setICD10Codes,
  setClinicalScores,
  setMonitors,
  setLifestyles,
  setProgramFilter,
  setProgramChips,
} = commonDataSlice.actions
export default commonDataSlice.reducer
