import React, { useState, useRef, useEffect } from 'react'
import {
  Grid, LinearProgress, MenuItem, DialogTitle,
  DialogContent, DialogActions, Button, Dialog, FormControl, InputLabel, Select,
} from '@mui/material'
import stylesheet from '../detail-container.stylesheet'
import { ClassNameMap, withStyles } from '@mui/styles'
import Draggable from "react-draggable"
import DialogTitleHeaderComponent from '../common/dialog-title-header.component'

interface IProps {
  open: boolean,
  item: any,
  handleDialogClose: any,
  handleSubmit: any,
  classes: ClassNameMap
}

const AddLifestyleDialogComponent: React.FC<IProps> = ({ open, item, handleDialogClose, handleSubmit, classes }) => {
  const monitorRef = useRef(null) as any
  const [loadingMonitor, setIsLoadingMonitor] = useState(true)
  const [currLifestyle, setCurrLifestyle] = useState('' as any)
  const [isMonitorSubmit, setIsMonitorSubmit] = useState(false)

  useEffect(() => {
    setTimeout(function () {
      setIsLoadingMonitor(false)
    }, 550);
  })

  const setSubmitData = () => {
    setIsMonitorSubmit(true)
    handleSubmit(currLifestyle)
  }

  return (
    <Draggable ref={monitorRef} defaultPosition={{ x: 0, y: 0 }} handle='.add-lifestyle-dialog'>
      <Dialog
        id={'add-lifestyle'}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="Add lifestyle plan"
        hideBackdrop
        disableScrollLock
        onKeyUp={(e) => {
          if (e.key === 'Enter' && !(currLifestyle === '' || isMonitorSubmit))
            setSubmitData()
        }}
        sx={{
          position: "absolute",
          left: '15%',
          top: '20%'
        }}
      >
        <DialogTitle className={classes.addPatientQuestionHeaderSection} classes={{ root: 'add-lifestyle-dialog' }}>
          <DialogTitleHeaderComponent title={item?.title} handleDialogClose={handleDialogClose} />
        </DialogTitle>
        <Grid item> {loadingMonitor && <LinearProgress />} </Grid>
        <DialogContent className={classes.addPatientDialogContent}>
          <Grid container spacing={2} className={classes.containerPadding}>
            <Grid item md={12} xs={12}>
              <FormControl className={classes.formControlStage}>
                <InputLabel id="add-monitor-label" className={classes.stageLabel}>{item.label || 'Select'}</InputLabel>
                <Select
                  id="lifestyle"
                  labelId="add-monitor-label"
                  onChange={(e) => setCurrLifestyle(e?.target?.value)}
                  label={item.label || 'Select'}
                  className={classes.programSelect}
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                >
                  {Object.keys(item?.lifestyleData)
                    ?.sort((a: any, b: any) => (a).toLowerCase() > (b).toLowerCase() ? 1 : -1)
                    ?.map((i: any) =>
                      <MenuItem key={i} value={i} className={classes.programMenu}>{item?.lifestyleData[i]}</MenuItem>
                    )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.containerPadding} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                disabled={currLifestyle === '' || isMonitorSubmit}
                onClick={setSubmitData}
                component="label"
              >
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                onClick={handleDialogClose}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Draggable>
  );
};

export default withStyles(stylesheet)(AddLifestyleDialogComponent)
