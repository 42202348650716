import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  Grid,
  ButtonGroup,
  CircularProgress,
  Divider,
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import React, { useState, ReactElement, useEffect } from 'react'
import stylesheet from '../detail-container.stylesheet'
import ColorBubbleComponent from './color-bubble.component'
import { PatientService } from '../../../../services/patient.service'
import { toast } from 'react-toastify'
import Helper from '../../../../utils/helper'
import moment from 'moment';
import { checkCondition } from './condition-check'
import { useCurrentUser } from '../../../../utils/use-current-user'
import { environment } from '../../../../environments/environment'


interface IRefState {
  created_on: number;
  note: string
  by: string
}
interface IProps {
  entityRef: string
  patientId: string
  input: any
  leftHeader: string
  rightHeader: string | ReactElement
  programs?: string[]
  subHeader?: string | ReactElement
  is_recommended?: boolean
  isCarePlan?: boolean
  closeRef: any
  onRefSubmit: any
  classes: ClassNameMap
}

const ReferralComponent: React.FC<IProps> = ({
  entityRef,
  patientId,
  input,
  leftHeader,
  rightHeader,
  programs = [],
  subHeader,
  is_recommended,
  closeRef,
  onRefSubmit,
  classes,
}) => {
  const [refer, setRefer] = useState(false)
  const [note, setNote] = useState('')
  const [loading, setLoading] = useState(false)
  const [resolveRef, setResolveRef] = useState(false)
  const [refData, setRefData] = useState<IRefState[]>([])
  const [current_referral_id, setCurrent_referral_id] = useState(input.is_referred ? input.referral_id : null)
  const patientService = new PatientService()
  const { currentRole } = useCurrentUser()

  useEffect(() => {
    if (current_referral_id) {
      gerReferralData()
    }
  }, [current_referral_id])

  const gerReferralData = () => {
    setLoading(true);
    patientService.getReferral(patientId, entityRef, current_referral_id).then((res: any) => {
      setLoading(false)
      const dt = res.data.referral.map((m: any) => { return { created_on: m.timestamp, note: m.text, by: m.name } })
      setRefData([...dt])
    }).catch((error: any) => {
      setLoading(false)
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    })
  }

  const onConfirm = (resolved: boolean = false) => {
    if (refer || resolved) {
      setLoading(true)
      let payload = {}
      if (current_referral_id) {
        payload = {
          id: current_referral_id,
          ref_id: input.id,
          ref_table: entityRef,
        }
      } else {
        payload = {
          ref_id: input.id,
          ref_table: entityRef,
        }
      }

      payload = resolved
        ? { ...payload, data: note, status: 'resolved' }
        : { ...payload, data: note }

      patientService.postReferral(patientId, payload).then((res: any) => {
        setRefer(true)
        setCurrent_referral_id(res.data.id)
        if (current_referral_id) {
          gerReferralData()
        }
        (resolved)
          ? onRefSubmit(input.id, res.data.created_on, false, null)
          : onRefSubmit(input.id, res.data.created_on, true, res.data.id)

        setLoading(false)
      }).catch((error: any) => {
        setLoading(false)
        toast.error(Helper.getErrorText(error), Helper.bottom_center())
      })
    } else {
      setRefer(true)
      setResolveRef(false)
    }
  }

  const onCloseRef = () => {
    setRefer(false)
    setResolveRef(false)
    closeRef(input.id)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setNote(event.target.value)

  return (
    <Grid container direction="column">
      <Grid item xs={12} sm={12} lg={12} md={12} zeroMinWidth>
        <Box mb={1} className={classes.customCard}>
          <Card variant="outlined" className={classes.fullWidth}>
            <CardContent>
              <Grid
                container
                xs={12}
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
                className={classes.initialFlexwrap}
              >
                <Grid item>
                  <Typography variant="subtitle2">
                    {leftHeader}
                    <ColorBubbleComponent selectedProgramList={programs} />
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={
                      is_recommended
                        ? { lineHeight: '1', color: '#FFFFFF' }
                        : { lineHeight: '1' }
                    }
                    align="right"
                  >
                    {rightHeader}{' '}
                  </Typography>
                </Grid>
              </Grid>
              {subHeader && (<>
                <Grid
                  container
                  xs={12}
                  direction="row"
                  alignItems="baseline"
                  className={classes.initialFlexwrap}
                >
                  <Grid item className={classes.subHeaderTitle}>
                    <Typography color="textSecondary" variant="subtitle2">
                      {subHeader}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </>
              )}
              {loading && (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box p={1}>
                    <CircularProgress size="2rem" />
                  </Box>
                </Grid>
              )}
              {current_referral_id &&
                Helper.checkGroup(environment.okta.providerGroup, currentRole) &&
                <div className={classes.textAlignEnd}>
                  <Button variant='text' size='small' className={classes.twoButtonWidth}
                    onClick={() => setResolveRef(true)}>Resolve Referral</Button>
                </div>
              }
              {(refer || resolveRef) && !loading && (
                <Box mt={1}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="add note here"
                    size="small"
                    className={classes.fullWidth}
                    multiline={true}
                    rows="3"
                    value={note}
                    onChange={handleChange}
                  />
                </Box>
              )}
              <Grid xs={12} className={classes.tooltipScroll}>
                {refData?.map((r: any) => <>
                  <Box p={1} >
                    <Typography variant="body2" align='left'>{r?.note}</Typography>
                    <Box pt={1}>
                      <Typography color="textSecondary" variant="body2" className={classes.textAlignStart}>
                        {checkCondition(r?.created_on !== undefined,
                          <small>{`by ${r.by} on ${moment.unix(r?.created_on).format('MMM DD, YYYY hh:mm a')}`}</small>,
                          <>-</>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </>
                )}
              </Grid>
              {!loading && !resolveRef && (
                <Box pb={2} pt={2}>
                  <Typography variant="subtitle2" className={classes.desc}>
                    {!current_referral_id ? 'Do you want to send a referral ?' : 'Do you want to add referral note ?'}
                  </Typography>
                </Box>
              )}
            </CardContent>
            <Grid
              xs={12}
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <ButtonGroup
                className={classes.questionButtonStyle}
                size="small"
                variant="text"
                aria-label="small outlined button group"
              >
                <Button
                  disabled={((refer || resolveRef) && note === '')}
                  onClick={() => onConfirm(resolveRef)}
                  className={classes.twoButtonWidth}
                >
                  <strong>{
                    resolveRef
                      ? 'Resolve referral'
                      : checkCondition(refer, checkCondition(current_referral_id, 'Add referral note', 'Send referral'), 'Yes')}</strong>
                </Button>
                <Button onClick={onCloseRef} className={classes.twoButtonWidth}>
                  <strong> {(resolveRef || refer) ? 'Cancel' : 'No'}</strong>
                </Button>
              </ButtonGroup>
            </Grid>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}

export default withStyles(stylesheet)(ReferralComponent)
