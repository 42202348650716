import React from 'react'
import stylesheet from '../detail-container.stylesheet'
import { Autocomplete, MenuItem, Select, TextField, } from '@mui/material'
import { ClassNameMap, withStyles, makeStyles } from '@mui/styles'

interface IProps {
  id: any
  disabled: any
  type: any
  tagLabel?: any
  onChange: any
  data: any
  value: any
  classes: ClassNameMap
}

const DropDownComponent: React.FC<IProps> = ({ id, disabled, type, tagLabel, onChange, data, value, classes }) => {

  const getTagsList = () => {
    if (type === 'target-card') {
      return data?.map((item: any) => Object.keys(item)?.map((key: any) => {
        <MenuItem key={`${type}-${key}`} className={classes.desc} value={key}>
          {item[key]}
        </MenuItem>
      }))
    } else {
      return data?.map((item: any, key: any) => (
        <MenuItem key={`${type}-${key}`} className={classes.desc} value={item?.tag_name}>{item?.tag_display_name}</MenuItem>
      ))
    }
  }

  return <>
    <Autocomplete
      id="measurement-tag"
      options={data as any}
      getOptionLabel={(option: any) => option?.tag_display_name}
      value={data?.findIndex((i: any) => i.tag_name === value) !== undefined
        ? data[data?.findIndex((i: any) => i.tag_name === value)]
        : ''}
      clearIcon={false}
      clearText='true'
      onChange={() => null}
      onInputChange={(e, newInputValue) => onChange(newInputValue, id)}
      sx={{
        width: '6rem !important',
        '& .MuiAutocomplete-inputRoot': {
          paddingRight: '0.6rem !important'
        }
      }}
      renderInput={(params: any) => <TextField
        {...params}
        variant="standard"
        placeholder={tagLabel}
        sx={{ fontSize: '0.75rem' }}
        InputProps={{
          ...params.InputProps
        }}
      />
      }
    />
  </>
}

export default withStyles(stylesheet)(DropDownComponent)
