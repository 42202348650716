import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useParams, useHistory, useLocation } from 'react-router-dom'
import FHIR from 'fhirclient'
import { PatientService } from '../../services/patient.service'

import { CircularProgress } from '@mui/material'

interface IProps extends RouteComponentProps {
  // history: any;
  // location: any;
  // match: any;
}

const FHIRPatientCallbackComponent: React.FC<IProps> = () => {
  const [loading, setLoading] = useState(true)
  const patientService = new PatientService()
  const history = useHistory()
  const urlParams: any = useParams()
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search)
  const fhirPatientId: string = urlParams.fhirPatientId;

  useEffect(() => {
    patientService.triggerFHIRPatientSync({
      client_id: queryParams.get('clientId'),
      endpoint: queryParams.get('endpoint'),
      patient_id_list: [fhirPatientId]
    })
      .then((response) => {
        setLoading(false)
        const refId = response.data.patients[0].ref_id;
        history.push(`/patients/${refId}/details`);
      })
      .then(console.log)
      .catch(console.error);
  }, []);

  return (
    <div style={{
      display: 'flex',
      marginTop: '30%',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      {loading ? <><CircularProgress />
        FHIR authorisation successful. Syncing patient data...
      </>
        : <div>
          Synced patient data successfully.
        </div>
      }
    </div>
  );
};

export default FHIRPatientCallbackComponent;
