import React, { useEffect, useRef, useState } from 'react'
import {
  Box, Card, CardContent, Grid, Typography, Accordion, AccordionDetails, AccordionSummary, Tooltip, IconButton,
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MedicationsComponent from '../common/medications.component'
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ManualOverrideComponent from "../common/manual-override.component"
import stylesheet from '../detail-container.stylesheet'
import { PatientService } from '../../../../services/patient.service'
import CardLayoutComponent from '../common/card-layout.component'
import NotesComponent from '../common/notes.component'
import DeleteCard from '../common/delete-card-component'
import { RootState, useAppThunkDispatch } from '../../../../redux/store'
import { setAddValue } from '../../../../redux/add-control-slice'
import { useDispatchAPIs } from '../../../../utils/use-dispatchApis-hook'
import ReferralComponent from '../common/referral-card.component'
import { useReferralAction } from '../../../../utils/referral-hook'
import Helper from '../../../../utils/helper'
import EmptyCardComponent from '../common/empty-card.component'
import { useCurrentUser } from '../../../../utils/use-current-user'
import { checkCondition } from '../common/condition-check'
import IngredientTooltip from '../common/ingredient-tooltip'
import BaseDialogModalOtherComponent from '../common/base-dialog-modal-other.component'
import { compsConfig } from '../../../../utils/dynamic-comps'
import MedDetailsComponent from '../common/med-details.component'
import { useOverrideAction } from '../../../../utils/override-hook'
import { useCheckConfirmCount } from '../../../../utils/use-checkconfirmationcount-hook'
import { useUpdateDataAction } from '../../../../utils/update-details-hook'

interface IProps {
  patientInfo: any
  currentProgram: string
  classes: ClassNameMap
}

const OtherMedicationsComponent: React.FC<IProps> = ({
  patientInfo,
  currentProgram,
  classes,
}) => {
  const patientDetail = useSelector(
    (state: RootState) => state.patientDetail.patientDetail
  )
  const uiResource = useSelector((state: RootState) => state.metaData.metadata)
  const [, setOpen] = useState(false)
  const [data, setData] = useState([] as any)
  const patientService = new PatientService()
  const [selectedNotes, setSelectedNotes] = useState([] as number[])
  const addButtons = useSelector((state: RootState) => state.addButtons)
  const dispatch = useAppThunkDispatch()
  const { setProgramConfirmCount } = useDispatchAPIs(patientDetail.id);
  const { onSetView, onDataSubmit } = useReferralAction()
  const { currentRole } = useCurrentUser()
  const { onSetOverView, onDataOverSubmit } = useOverrideAction()
  const [, setCount] = useCheckConfirmCount(patientDetail.id)
  const { multipleProgramCounts } = useDispatchAPIs(patientDetail.id)
  const [loading, setLoading] = useState(false)
  const [openFhirJson, setOpenFhirJson] = useState(false)
  const [openPrgMed, setOpenPrgMed] = useState(false)
  const [medMoreDetails, setMedMoreDetails] = useState({} as any)
  const [medTitle, setMedTitle] = useState('')
  const [configItem, setConfigItem] = useState({} as any)
  const [currentItem, setCurrentItem] = useState({} as any)
  const inputElTaking = useRef(null) as any
  const inputElNotTaking = useRef(null) as any
  const inputElDiscontinued = useRef(null) as any
  const { updatePatientData } = useUpdateDataAction(patientDetail.id)

  const handleClose = () => {
    handleAddHideShow()
    setOpen(false)
  }

  useEffect(() => {
    initialiseData()
  }, [patientDetail])

  const getStageString = (sl: any) => {
    if (typeof sl === 'object') sl = sl[0]
    return patientDetail?.program_enrollments.find((f: { program: any }) => f.program === sl)?.current_stage
  }

  const initialiseData = () => {
    let stage = []
    if (currentProgram) {
      const temp = patientDetail?.medications?.care_plans?.filter((f: { program: string }) => f.program === currentProgram)
      if (temp.length) {
        let _temp = temp[0].medications.filter((f: { is_recommended: boolean }) => f.is_recommended === false)
        stage = _temp?.map(
          (e: any) => {
            return { ...e, mode: 'view' }
          }
        )

        if (patientInfo?.isActionItems === true)
          stage = stage?.filter((i: { needs_update: boolean, needs_confirmation: boolean, is_overridden: boolean, programs: any, overridden_programs: any }) => (
            (i.needs_update || i.needs_confirmation) &&
            i.programs?.some((s: string) => s === patientInfo?.actionProgram) &&
            !i.overridden_programs?.includes(patientInfo?.actionProgram)
          ))
      }
    } else {
      stage = patientDetail?.medications?.other_medications?.map(
        (e: any) => {
          return { ...e, mode: 'view' }
        }
      )
    }
    setData(stage)
  }

  const formatConfirmDate = (
    confirm_timestamp: any,
    save_timestamp: any,
    needs_confirmation: boolean,
    imported: boolean,
    is_referred: boolean,
    referred_on: number
  ) => {
    if (is_referred) {
      return moment.unix(referred_on).format('MM/DD/YY')
    } else if (needs_confirmation) {
      if (save_timestamp) {
        return moment.unix(save_timestamp).format('MM/DD/YY')
      } else {
        return ''
      }
    } else {
      if (confirm_timestamp) {
        return moment.unix(confirm_timestamp).format('MM/DD/YY')
      } else {
        return ''
      }
    }
  }

  const outer = (_id: string, _mode: string) => {
    setData(
      data.map((el: any) => (el.id === _id ? { ...el, mode: _mode } : el))
    )
  }

  const getIntakeReg = (reg: string) => {
    return Object.keys(uiResource.INTAKE_REGULARITIES).find((key: any) => uiResource.INTAKE_REGULARITIES[key] === reg)
  }

  const postConfirmations = (
    values: any,
    _mode: any,
    _typeOut: any,
    medicinedata: any,
    dosageInfo: any,
    intake: any
  ) => {

    try {
      let payload: any
      if (intake === 'not taking') {
        payload = {
          med_id: medicinedata.med_id,
          med_type: medicinedata.med_type,
          intake_regularity: getIntakeReg(intake),
          user_role: currentRole,
          notes: values.note,
          prescription_taken: values.prescription_taken
        }
      } else {
        payload = {
          med_id: medicinedata.med_id,
          med_type: medicinedata.med_type,
          num_units: values.num_units,
          delivery_method: values.delivery_method,
          intake_quantity: values.intake_quantity,
          intake_regularity: getIntakeReg(intake),
          frequency: values.frequency,
          dosage_info: dosageInfo,
          source_type: medicinedata.source_type,
          notes: values.note,
          user_role: currentRole,
          prescription_taken: values.prescription_taken
        }
        if (medicinedata?.care_plan_id)
          payload = { ...payload, schedule_name: values?.schedule_name, }
      }

      patientService
        .putOtherMedication(patientInfo.id, medicinedata.id, payload)
        .then((response: any) => {
          setData(
            data.map((el: any) =>
              el.id === medicinedata.id
                ? {
                  ...response.data,
                  mode: _mode,
                  programs: el.programs,
                  date_recorded: response.data.date_recorded,
                }
                : el
            )
          )
          if (_typeOut === 'confirm') {
            confirmMedicine(_typeOut, response.data, _mode)
          } else if (_typeOut === 'save') {
            const tempObj = data.find((f: { id: string }) => f.id === response.data.id)
            if (tempObj?.programs?.length) {
              setProgramConfirmCount(tempObj?.needs_confirmation,
                response.data.needs_confirmation, tempObj?.programs)
            }
            // toast.success(`Saved successfully.`, Helper.bottom_center())
          }
        })
        .catch((error: any) => {
          toast.error(Helper.getErrorText(error), Helper.bottom_center())
        })
    } catch (error: any) {
      console.error(error)
    }
  }

  const confirmMedicine = (
    _typeOut: any,
    medicinedata: any,
    _mode: any,
    action?: string
  ) => {
    if (_typeOut === 'confirm') {
      const payload = {
        entity_ref: 'medication',
        entity_id: medicinedata.id,
        confirmed_by: patientInfo.id,
      }
      patientService
        .PostConfirmations(payload)
        .then((_response: any) => {
          updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: false })
        })
        .catch((err: any) => {
          const tempObj = data.find((f: { id: string }) => f.id == medicinedata.id)
          if (tempObj?.programs?.length) {
            setProgramConfirmCount(tempObj?.needs_confirmation,
              true, tempObj?.programs)
          }
          toast.error(Helper.getErrorText(err), Helper.bottom_center())
        })

    }
  }

  const setIconsWithDelete = () => {
    if (patientInfo?.isPrgMed === true) {
      return [
        { iconType: 'edit', callback: onSetDetail },
        { iconType: 'delete', callback: onDeleteClick },
        { iconType: 'more', callback: {} },
      ]
    } else
      return [
        { iconType: 'edit', callback: onSetDetail },
        { iconType: 'description', callback: onNotesClick },
        { iconType: 'delete', callback: onDeleteClick },
        { iconType: 'more', callback: {} },
      ]
  }

  const formatSubHeader = (d: any) => {
    if (Object.keys(d)?.length > 0) {
      const num_units = d?.pu_order?.num_units ? d.pu_order?.num_units : ''
      const formulation = d?.pu_order?.formulation_display_name ? `${d.pu_order?.formulation_display_name}` : ''
      const route = d?.pu_order?.route_display_name ? `${d.pu_order?.route_display_name}` : ''
      const frequency = uiResource.MEDICATION_DOSAGE_FREQUENCY[d.pu_order?.frequency] ?
        uiResource.MEDICATION_DOSAGE_FREQUENCY[d.pu_order?.frequency] : ''
      const dosageInfo = (d?.dosage_info) && d?.dosage_info?.[0]?.medicationTimings
        ? uiResource.MEDICATION_TIMINGS[d?.dosage_info[0]['medicationTimings']]
        : ''
      const text = `${num_units}${formulation}${route}${frequency}`
        ? `${num_units} ${formulation} ${route} ${frequency} ${dosageInfo}`
        : 'Details not available'

      const isSchedule = (d?.care_plan_id !== null && (d?.aa_rec) && d.needs_update)
        ? <Tooltip data-testid='schedule-need' title={'Schedule is not chosen'} placement="bottom">
          <IconButton size='small' className={classes.infoIcon}>
            <NoteAddIcon className={classes.infoIconFont} />
          </IconButton>
        </Tooltip>
        : ''

      return <>{text}{' '}<IngredientTooltip ingredients={d?.calc_dose} />{isSchedule}</>
    }
    else return ''
  }

  const getInnerProps = (t: any, _item: any) => {
    if (t.comp === 'ScheduleCarePlanComponent_Key') {
      return {
        ...t, props: {
          data: _item,
          // outer: outerSubmit
        }
      }
    } else if (t.comp === 'ReferralComponent_Key') {
      return {
        ...t
      }
    } else if (t.comp === 'NotesComponent_Key') {
      return {
        ...t,
        props: {
          entityId: _item.id,
          entityRef: 'medication',
          onCancel: onNotesClick,
        },
      }
    } else {
      return { ...t, props: {} }
    }
  }

  const handleDialogClose = () => {
    setCurrentItem({})
    setOpenPrgMed(false)
  }

  const mainOuterSubmit = (dt: any) => {
    setData(
      data.map((el: any) =>
        el.id === dt.id
          ? {
            ...dt,
            mode: 'view',
            programs: el.programs,
            date_recorded: dt.date_recorded,
          }
          : el
      )
    )
    handleDialogClose()
  }

  const onSetDetail = async (_id: number) => {
    const currItem = data.map((el: any) => el.id === _id ? { ...el, mode: 'edit' } : el)
    setCurrentItem({})
    if (patientInfo?.isPrgMed === true) {
      const item = await currItem.find((f: any) => f.id === _id)
      const _tempCompConfig = compsConfig.find((f: { id: string }) => f.id === 'P_O_MB')
      const tempCompConfig = { ..._tempCompConfig, stage: currentProgram ? getStageString(currentProgram) : '', }

      const compsSet = _tempCompConfig?.tabs.map((t) => getInnerProps(t, item))

      const _actionsSet = _tempCompConfig?.actions.map((a: any) => {
        if (a.title === 'Cancel') {
          return { ...a, returnFun: handleDialogClose }
        } else if (a.title.includes('Save')) {
          return { ...a, returnFun: mainOuterSubmit }
        } else {
          return { ...a }
        }
      })

      setConfigItem({
        ...tempCompConfig,
        source_type: item.source_type,
        actions: _actionsSet,
        tabs: compsSet,
      })
      setCurrentItem(item)
      setOpenPrgMed(true)
    } else {
      setData(currItem)
    }
  }

  const onDeleteClick = (_id: number) => {
    setData(
      data.map((el: any) => (el.id === _id ? { ...el, mode: 'delete' } : el))
    )
  }

  const onOverrideClick = (_id: any, _mode: string) => setData(onSetOverView(_id, _mode, data))

  const onOverrideSubmit = (_id: any, date: number, is_overridden: boolean, overridenPrograms: any[]) => {
    const updatedData = onDataOverSubmit(_id, date, is_overridden, data, overridenPrograms)
    setData(updatedData)
    if (is_overridden) {
      setCount(true, false)
      multipleProgramCounts(
        [{
          "previous": true,
          "current": false,
          "programs": overridenPrograms
        }]
      )
    } else {
      setCount(false, true)
      multipleProgramCounts(
        [{
          "previous": false,
          "current": true,
          "programs": overridenPrograms
        }])
    }
  }
  const outerDelete = (_id: any) => {
    patientService.deleteMedication(patientInfo.id, _id)
      .then((_response: any) => {
        updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
      })
      .catch((err: any) => {
        toast.error(Helper.getErrorText(err), Helper.bottom_center())
      })
  }

  const onDeleteCancel = (_id: number) => {
    setData(data.map((el: any) => (el.id === _id ? { ...el, mode: 'view' } : el)))
  }

  const onNotesClick = (_id: any) => {
    if (selectedNotes.includes(+_id)) {
      setSelectedNotes([...selectedNotes.filter((f) => f !== +_id)])
    } else {
      setSelectedNotes([...selectedNotes, +_id])
    }
  }

  const handleSubmit = (values: any, medicine: any, dosageInfo: any, intake: string, _type: string, _mode: string) => {
    let req = {
      med_id: medicine.id,
      med_type: medicine.med_type,
      name: medicine.name,
      num_units: values.num_units,
      delivery_method: values.delivery_method,
      intake_quantity: values.intake_quantity,
      frequency: values.frequency,
      dosage_info: dosageInfo,
      notes: values.note,
      user_role: currentRole,
      prescription_taken: values.prescription_taken,
      intake_regularity: getIntakeReg(intake)
    }
    patientService.postOtherMedication(patientInfo.id, req).then((response: any) => {
      (_type === 'confirm')
        ? confirmMedicine(_type, response.data, 'view', 'add')
        : response.data && setData([...data, { ...response.data, mode: _mode }])
    }).catch((error: any) => toast.error(Helper.getErrorText(error), Helper.bottom_center()))
  }

  const handleAddHideShow = () => {
    const temp = [
      ...addButtons?.addControl.map((e: any) =>
        e.key === 'MD-IMMD' ? { ...e, value: false } : e
      ),
    ]
    dispatch(setAddValue(temp))
  }

  const onRefClick = (_id: any, _mode: string) => {
    setData(onSetView(_id, _mode, data))
  }

  const onRefSubmit = (_id: any, date: number, is_referred: boolean, referral_id: any) => {
    setData(onDataSubmit(_id, date, is_referred, referral_id, data))
  }

  const getMedMoreDetails = (pId: string, recId: string, medName: string, code: any) => {
    setLoading(true)
    setMedTitle(`${medName} [${code}]`)

    patientService.getMedMoreDetails(pId, recId)
      .then((response: any) => {
        setMedMoreDetails(response.data?.fhir_json);
        setLoading(false)
      }).catch((err: any) => {
        setLoading(false)
        console.error(err);
      });
    setOpenFhirJson(true)
  }

  const handleFhirJsonClose = () => setOpenFhirJson(false)
  const handleMedDialogClose = () => setOpenPrgMed(false)

  const isImportSynced = () => {
    if (patientDetail?.source_details?.length > 0) {
      const srcDetails = patientDetail?.source_details[0] || []
      if (srcDetails?.length > 0) {
        if (patientDetail?.created_on === patientDetail?.last_sync_date && ['EPIC', 'ELATION']?.includes(srcDetails[0]))
          return true;
      }
    }
    return false
  }

  const getOtherMedList = (data: any, type: any) => {
    return <Accordion
      square
      defaultExpanded={(type === 'discontinued' && isImportSynced()) ? false : true}
      className={classes.patientAccrodionBg}
    >
      <AccordionSummary
        ref={type === 'discontinued' ? inputElDiscontinued : 'not taking' ? inputElNotTaking : inputElTaking}
        expandIcon={<ExpandMoreIcon className={classes.patientAccrodionExpandIcon} />}
      >
        <Typography className={classes.subTitle}>{Helper.stageName(type)}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item className={classes.fullWidth}>
            {getOtherMedCard(data, type)}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion >
  }

  const getOtherMedCard = (data: any, type: any) => {
    if (type === 'not taking')
      data = data.filter((i: any) => i.intake_regularity === type && !i.is_discontinued)
    else if (type === 'taking')
      data = data.filter((i: any) => i.intake_regularity !== 'not taking' && !i.is_discontinued)
    else if (type === 'discontinued')
      data = data.filter((i: any) => i.is_discontinued)

    return data?.map((d: any) => {
      return <React.Fragment key={`other-med-${d.name}`}>
        {d.mode === 'view' && (
          <>
            <CardLayoutComponent
              id={d.id}
              name={d?.name}
              leftHeader={Helper.getRXnorm(d.pu_med_name ? d.pu_med_name : d.name, d?.rxnorm)}
              rightHeader={d.intake_regularity?.charAt(0).toUpperCase() + d.intake_regularity?.slice(1) || ''}
              subHeader={formatSubHeader(d)}
              bottomText={formatConfirmDate(
                d.recent_confirmation?.confirmed_on,
                d.date_recorded,
                d.needs_confirmation,
                d.source_type === 'imported' ? true : false,
                d.is_referred,
                d.referred_on
              )}
              source={`${d?.source_type} - ${d?.source_system ? d?.source_system : 'ATMAN'}`}
              needs_confirmation={d.needs_confirmation || undefined}
              needs_update={d.needs_update || undefined}
              icons={patientInfo?.isActionItems === true
                ? []
                : setIconsWithDelete()
              }
              programs={d.programs || undefined}
              onRefClick={() => onRefClick(d.id, 'ref')}
              is_referred={d.is_referred}
              is_overridden={patientInfo?.isPrgMed ? d.is_overridden : undefined}
              overridenPrograms={d?.overridden_programs}
              onOverrideClick={() => onOverrideClick(d.id, 'override')}
              onMedDetailsClick={() => getMedMoreDetails(patientDetail.id, d.id, d.pu_med_name ? d.pu_med_name : d.name, d?.rxnorm)}
              entityRef={currentProgram ? 'care_plan' : type === 'discontinued' ? 'medications' : 'other_medication'}
              patientId={patientDetail.id}
              category={'medications'}
            />
            {selectedNotes.includes(d.id) && (
              <NotesComponent
                entityId={d.id}
                entityRef={'medication'}
                cancelBtn={true}
                onCancel={onNotesClick}
              >
                Notes
              </NotesComponent>
            )}
          </>
        )}
        {d.mode === 'delete' && (
          <>
            <DeleteCard
              id={d.id}
              header={d.name}
              closeDelete={onDeleteCancel}
              outerDelete={outerDelete}
            />
          </>
        )}
        {d.mode === 'override' && (
          <ManualOverrideComponent
            entityRef='medication'
            input={d}
            leftHeader={Helper.getRXnorm(d.pu_med_name ? d.pu_med_name : d.name, d?.rxnorm)}
            rightHeader={<></>}
            programs={d.programs}
            closeOverride={() => onOverrideClick(d.id, 'view')}
            onOverrideSubmit={onOverrideSubmit}
          />
        )}
        {d.mode === 'ref' && (
          <ReferralComponent
            entityRef='medication'
            input={d}
            patientId={patientDetail.id}
            leftHeader={Helper.getRXnorm(d.name, d?.rxnorm)}
            rightHeader={d.intake_regularity?.charAt(0).toUpperCase() +
              d.intake_regularity?.slice(1) || ''}
            subHeader={formatSubHeader(d)}
            programs={d.programs}
            closeRef={() => onRefClick(d.id, 'view')}
            onRefSubmit={onRefSubmit}
          />
        )}
        {(d.mode === 'edit' ||
          d.mode === 'TAKING' ||
          d.mode === 'NOT_TAKING' ||
          d.mode === 'TAKING_DIFFERENTLY') && (
            <Box mb={1} className={classes.fullWidth}>
              <Card variant='outlined'
                className={
                  data.needs_confirmation
                    ? [
                      classes.confirmCardBackground,
                      classes.questionCardContent,
                      classes.fullWidth
                    ].join(' ')
                    : classes.questionCardContent
                }
              >
                <CardContent className={classes.zeroPadding}>
                  <Box p={2} pb={0}>
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        className={classes.nameLineHeight}
                      >
                        {d.pu_med_name ? d.pu_med_name : d.name}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        {formatSubHeader(d)}
                      </Typography>
                    </Grid>
                  </Box>
                  <MedicationsComponent
                    action='edit'
                    medicineInfo={{ ...d, is_recommended: patientInfo?.is_recommended || false }}
                    writeMode={checkCondition(d.intake_regularity === 'not taking', false, true)}
                    noDropDown="none"
                    heading=""
                    handleClose={handleClose}
                    patientId={patientDetail.id}
                    currentStage={currentProgram ? getStageString(currentProgram) : ''}
                    differently={true}
                    classes={classes}
                    input={d}
                    outer={outer}
                    outerSubmit={postConfirmations}
                    uiResource={uiResource}
                  />
                </CardContent>
              </Card>
            </Box>
          )}
      </React.Fragment>
    })
  }

  return (
    <>
      <Grid container direction="row"
        className={currentProgram !== '' ? classes.zeroPadding : classes.medIntoleranceContainer}
      >
        {currentProgram === '' && <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item className={classes.fullWidth}>
            {addButtons?.addControl.find(
              (e: { key: string }) => e.key === 'MD-IMMD'
            ).value ? (
              <Card variant='outlined' className={[classes.questionCardContent, classes.medIntoleranceCard2].join(' ')}>
                <CardContent className={classes.zeroPadding}>
                  <MedicationsComponent
                    classes={classes}
                    writeMode={true}
                    noDropDown=""
                    heading="Add new medication"
                    differently={false}
                    input=""
                    outer=""
                    outerSubmit={handleSubmit}
                    patientId={patientDetail.id}
                    currentStage={currentProgram ? getStageString(currentProgram) : ''}
                    medicineInfo={null}
                    uiResource={uiResource}
                    handleClose={handleClose}
                  />
                </CardContent>
              </Card>
            ) : null}
          </Grid>
        </Grid>
        }

        {patientInfo?.isPrgMed === true
          ? <Grid item className={classes.fullWidth}>{getOtherMedCard(data, '')}</Grid>
          : <Grid container className={classes.fullWidth}>
            <Grid item className={classes.fullWidth}>{getOtherMedList(data, 'taking')}</Grid>
            <Grid item className={classes.fullWidth}>{getOtherMedList(data, 'not taking')}</Grid>
            <Grid item className={classes.fullWidth}>{getOtherMedList(data, 'discontinued')}</Grid>
          </Grid>
        }
        {(data?.length == 0 && !addButtons?.addControl.find(
          (e: { key: string }) => e.key === 'MD-IMMD'
        ).value) && currentProgram === '' &&
          <EmptyCardComponent />
        }
      </Grid>
      {openPrgMed && Object.keys(currentItem)?.length > 0 && (
        <BaseDialogModalOtherComponent
          open={openPrgMed}
          currentRole={currentRole}
          item={{
            ...currentItem,
            is_recommended: patientInfo?.is_recommended || false,
            isPrgMed: patientInfo?.isPrgMed || false
          }}
          configItem={configItem}
          handleDialogClose={handleMedDialogClose}
          patientId={patientDetail.id}
        />
      )}
      {openFhirJson &&
        <MedDetailsComponent
          openFhirJson={openFhirJson}
          medTitle={medTitle}
          handleFhirJsonClose={handleFhirJsonClose}
          medMoreDetails={medMoreDetails} />
      }

    </>
  )
}

export default withStyles(stylesheet)(OtherMedicationsComponent)
