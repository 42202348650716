import React, { useState, useEffect, memo } from 'react'
import { Grid } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import stylesheet from '../detail-container.stylesheet'
import CardLayoutComponent from '../common/card-layout.component'
import { RootState, useAppThunkDispatch } from '../../../../redux/store'
import { PatientService } from '../../../../services/patient.service'
import { useCheckConfirmCount } from '../../../../utils/use-checkconfirmationcount-hook'
import DeleteCard from '../common/delete-card-component'
import NotesComponent from '../common/notes.component'
import ReferralComponent from '../common/referral-card.component'
import { useReferralAction } from '../../../../utils/referral-hook'
import Helper from '../../../../utils/helper'
import EditProblemComponent from '../info-header/edit-problem.component'
import EmptyCardComponent from '../common/empty-card.component'
import { useUpdateDataAction } from '../../../../utils/update-details-hook'

interface IProps {
  input: any
  classes: ClassNameMap
}
const ProgramProblemsComponent: React.FC<IProps> = ({ input, classes }) => {
  const [data, setData] = useState([] as any)
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const urlParams: any = useParams()
  const patientId: string = urlParams.patientId
  const patientService = new PatientService()
  const [selectedNotes, setSelectedNotes] = useState([] as number[])
  const { onSetView, onDataSubmit } = useReferralAction()
  const [isEditProblem, setIsEditProblem] = useState(false)
  const [currPrb, setCurrPrb] = useState({} as any)
  const programsSet = useSelector((state: RootState) => state.metaData.metadata?.PROGRAMS)
  const selectedProgram = useSelector((state: RootState) => state.commonData.selectedProgram)
  const { updatePatientData } = useUpdateDataAction(patientDetail.id)

  useEffect(() => {
    const stage = patientDetail?.conditions?.problems?.map((e: any) => {
      return { ...e, mode: 'view' }
    })
    setData(stage)
  }, [patientDetail])

  const setOtherIcons = () => {
    return [
      { iconType: 'edit', callback: onSetDetail },
      { iconType: 'delete', callback: onDelIconClick },
      { iconType: 'description', callback: onNotesClick },
      { iconType: 'more', callback: outer },
    ]
  }

  const onNotesClick = (_id: any) => {
    if (selectedNotes.includes(+_id)) {
      setSelectedNotes([...selectedNotes.filter((f) => f !== +_id)])
    } else {
      setSelectedNotes([...selectedNotes, +_id])
    }
  }

  const onDelIconClick = (_id: any) => {
    setData(
      data.map((el: any) => (el.id === _id ? { ...el, mode: 'delete' } : el))
    )
  }

  const outer = (_id: any, _mode: string) => {
    setData(onSetView(_id, _mode, data))
  }

  const confirmDelete = (_id: any) => {
    patientService
      .deleteProblemDetails(patientId, _id)
      .then((_response: any) => {
        if (_response?.data) {
          setData(data.filter((e: { id: any }) => e.id !== _id))
        }
      }).catch((err: any) => {
        toast.error(Helper.getErrorText(err), Helper.bottom_center())
      })
  }

  const onSetDetail = (_id: number) => {
    setData(
      data.map((el: any) => (el.id === _id ? { ...el, mode: 'view' } : el))
    )
    setCurrPrb(data.filter((el: any) => (el.id === _id))[0] || {})
    setIsEditProblem(true)
  }

  const closeDelete = (_id: number) => {
    setData(
      data.map((el: any) => (el.id === _id ? { ...el, mode: 'view' } : el))
    )
  }
  const handlePrbDialogClose = () => {
    setCurrPrb({})
    setIsEditProblem(false)
  }

  const handlePrbDialogSubmit = (data: any, isAdd = false) => {
    handlePrbDialogClose()
    if (isAdd) {
      data && patientService.updateProblem(patientDetail.id, currPrb?.id, { 'program_name': data })
        .then((_res: any) => {
          updatePatientData({ isConditions: true })
        }).catch((error) => {
          setIsEditProblem(false)
          toast.error(Helper.getErrorText(error), Helper.bottom_center())
        })
    } else {
      data && patientService.removeEnrollment(patientDetail.id, data)
        .then((_res: any) => {
          updatePatientData({ isConditions: true })
        }).catch((error) => {
          setIsEditProblem(false)
          toast.error(Helper.getErrorText(error), Helper.bottom_center())
        })
    }
  }

  const onRefSubmit = (_id: any, date: number, is_referred: boolean, referral_id: any) => {
    setData(onDataSubmit(_id, date, is_referred, referral_id, data))
  }

  const formatConfirmDate = (save_timestamp: number, is_referred: boolean, referred_on: number) => {
    if (is_referred) {
      return moment.unix(referred_on).format('MM/DD/YY')
    } else if (save_timestamp) {
      return moment.unix(save_timestamp).format('MM/DD/YY')
    } else {
      return ''
    }
  }

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center"  >
      {data && data
        ?.sort((a: any, b: any) => a.display_name > b.display_name ? 1 : -1)
        ?.map((d: any, i: any) => (
          <React.Fragment key={`prg-problems-${i}`}>
            {d.mode === 'view' && input.parent === 'PRB' && (
              <>
                <CardLayoutComponent
                  id={d.id}
                  leftHeader={d.display_name || d?.name}
                  rightHeader={''}
                  subHeader={''}
                  bottomText={formatConfirmDate(d?.save_timestamp, d?.is_referred, d?.referred_on)}
                  needs_confirmation={d.needs_confirmation || false}
                  needs_update={d.needs_update || false}
                  programs={d.programs || undefined}
                  icons={setOtherIcons()}
                  onRefClick={() => outer(d.id, 'ref')}
                  is_referred={d.is_referred}
                  is_overridden={false}
                  entityRef='problem'
                  patientId={patientId}
                  category={'conditions'}
                />
                {selectedNotes.includes(d.id) && (
                  <NotesComponent
                    entityId={d.id}
                    entityRef={'problem'}
                    cancelBtn={true}
                    onCancel={onNotesClick}
                  >
                    Notes
                  </NotesComponent>
                )}
              </>
            )}
            {d.mode === 'delete' && (
              <DeleteCard
                id={d.id}
                header={d.display_name}
                closeDelete={() => closeDelete(d.id)}
                outerDelete={() => confirmDelete(d.id)}
              />
            )}
            {d.mode === 'ref' && (
              <ReferralComponent
                entityRef='problem'
                input={d}
                patientId={patientId}
                leftHeader={d.display_name}
                rightHeader=''
                programs={d.programs}
                closeRef={() => outer(d.id, 'view')}
                onRefSubmit={onRefSubmit}
              />
            )}
          </React.Fragment >
        ))}
      {(Object.keys(data)?.length < 1) && <EmptyCardComponent />}

      {isEditProblem &&
        <EditProblemComponent
          open={isEditProblem}
          item={{
            title: currPrb?.display_name || currPrb?.name || 'Problem',
            programs: currPrb?.programs,
            diagnosisPrg: currPrb?.diagnosis_program_name
          }}
          handleDialogClose={handlePrbDialogClose}
          handleSubmit={handlePrbDialogSubmit}
        />
      }
    </Grid>
  )
}

export default withStyles(stylesheet)(memo(ProgramProblemsComponent))