import React, { useRef, useEffect, useState } from 'react'
import {
  Grid, LinearProgress, DialogTitle, DialogContent, Dialog, Box, Typography, Card, CardContent, FormControlLabel,
  Checkbox, Accordion, AccordionSummary, AccordionDetails, Tooltip, TextField, ButtonGroup, Button,
} from '@mui/material'
import stylesheet from '../detail-container.stylesheet'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ClassNameMap, withStyles } from '@mui/styles'
import Draggable from "react-draggable"
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { PatientService } from '../../../../services/patient.service'
import ColorBubbleComponent from '../common/color-bubble.component'
import DialogTitleHeaderComponent from '../common/dialog-title-header.component'
import { toast } from 'react-toastify'
import Helper from '../../../../utils/helper'
import { useCurrentUser } from '../../../../utils/use-current-user'
import EmptyCardComponent from '../common/empty-card.component'
import { useUpdateDataAction } from '../../../../utils/update-details-hook'

interface IProps {
  open: boolean,
  item: any,
  handleDialogClose: any,
  classes: ClassNameMap
}

const OverrideItemsDialogComponent: React.FC<IProps> = ({ open, item, handleDialogClose, classes }) => {
  const actionPrgRef = useRef(null) as any
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const [patientDetails, setPatientDetails] = useState({} as any)
  const patientService = new PatientService();
  const [loadingPrg, setLoadingPrg] = useState(true)
  const [isAllChecked, setIsAllChecked] = useState(false)
  const [isOverridden, setIsOverridden] = useState(false);
  const [isNoteFocused, setIsNoteFocused] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [overrideObj, setOverrideObj] = useState([] as any)
  const [note, setNote] = useState('')
  const { currentRole } = useCurrentUser()
  const { updatePatientData } = useUpdateDataAction(patientDetail.id)

  useEffect(() => {
    setLoadingPrg(true)
    patientService.getPatientInfo(patientDetail?.id)
      .then((response: any) => {
        setPatientDetails({ ...response.data.patient })
        setLoadingPrg(false)
      }).catch(() => setLoadingPrg(false))
  }, [])

  const getTitle = (d: any) => {
    const tagName = (d?.tag_detail)
      ? ` (${d?.tag_detail?.tag_display_name || d?.tag_detail?.tag_name})`
      : ''
    return `${d.display_name}${tagName}`
  }

  const subHeaderCal = (d: any) => {
    if (!d?.value_range)
      return ''
    if (d?.value_range?.min === null && d?.value_range?.max === null)
      return d?.unit ? d.unit : ''

    let vrmin = ''
    let vrmax = ''
    if (d?.value_range?.min !== null) vrmin = d?.value_range?.min
    if (d?.value_range?.max !== null) vrmax = d?.value_range?.max
    let unit = d?.unit ? ` ${d?.unit}` : ''
    return <>({vrmin}{vrmin && vrmax ? '-' : ''}{vrmax}{unit})</>
  }

  const isMeasurementExists = () => {
    const program_checks = (patientDetails?.measurements?.program_checks)?.filter(
      (e: any) => e?.programs.includes(item.program) && e.needs_update && !(e.overridden_programs || []).includes(item.program)) || []

    const program_targets = (patientDetails?.measurements?.program_targets)?.filter(
      (e: any) => e?.program.includes(item.program) && e.needs_update && !(e.overridden_programs || []).includes(item.program)) || []

    const calculators = (patientDetails?.measurements?.calculators)?.filter(
      (e: any) => e.category !== "diagnosis" && e?.programs.includes(item.program) && e.needs_confirmation && !(e.overridden_programs || []).includes(item.program)
    ) || []
    return Object.keys([...program_checks, ...program_targets, ...calculators]).length > 0 ? false : true
  }

  const getAllMeasurements = () => {
    const allMeasurements = [
      ...(patientDetails?.measurements?.program_checks)?.map((e: any) => {
        if (e?.programs.includes(item.program) && e.needs_update && !(e.overridden_programs || []).includes(item.program))
          return e?.id
      }),
      ...(patientDetails?.measurements?.program_targets)?.map((e: any) => {
        if (e?.program.includes(item.program) && e.needs_update && !(e.overridden_programs || []).includes(item.program))
          return e?.id
      }),
      ...(patientDetails?.measurements?.calculators)?.map((e: any) => {
        if (e.category !== "diagnosis" && e?.programs.includes(item.program) && e.needs_confirmation && !(e.overridden_programs || []).includes(item.program))
          return e?.id
      }),
    ] || []
    setOverrideObj(allMeasurements?.filter((i: any) => i !== undefined))
  }

  const getMeasurementName = (id: any) => {
    const program_checks = (patientDetails?.measurements?.program_checks)?.find((i: any) => i.id === id)
    if (program_checks) return program_checks?.display_name || program_checks?.name

    const program_targets = (patientDetails?.measurements?.program_targets)?.find((i: any) => i.id === id)
    if (program_targets) return program_targets?.display_name || program_targets?.name

    const calculators = (patientDetails?.measurements?.calculators)?.find((i: any) => i.id === id)
    if (calculators) return calculators?.display_name || calculators?.name
    return ''
  }

  const handleCancel = () => {
    setNote('')
    setConfirm(false)
  }

  const handleConfirm = () => {
    setLoadingPrg(true)
    let payload = {
      note: note,
      programs: [item?.program],
      author_role: currentRole,
    }
    let isSaveErr: any[] = []
    let isSaveList: any[] = []

    Promise.all(
      overrideObj?.map((currItem: any) => {
        return new Promise((resolve: any) => {
          patientService.postOverrideValue({
            ...payload,
            entity_id: currItem,
            entity_ref: getEntityName(currItem)
          }).then((res: any) => {
            if (res?.data) {
              isSaveList.push(getMeasurementName(currItem))
              resolve()
            } else {
              isSaveErr.push(getMeasurementName(currItem))
              resolve()
            }
          }).catch((res: any) => {
            isSaveErr.push(getMeasurementName(currItem))
            resolve()
          })
        })
      })
    ).then(() => {
      setLoadingPrg(false)
      setIsOverridden(true)
      // if (isSaveList.length > 0)
      //   toast.success(`Data overridden successfully for ${isSaveList.join(', ')}.`, Helper.bottom_center())

      if (isSaveErr.length > 0)
        toast.error(`Failed to override ${isSaveErr.join(', ')}.`, Helper.bottom_center())
      handleDialogClose()
      updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
    })
  }

  const getEntityName = (id: any) => (patientDetails?.measurements?.calculators)?.find((e: any) => e?.id === id) ? 'calculator' : 'measurement'

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = parseInt(event.target.name);
    (event.target.checked)
      ? setOverrideObj([...overrideObj, id])
      : setOverrideObj(overrideObj.filter((i: any) => i !== id))
  }

  const isMeasurementChecked = (id: any) => ((overrideObj.includes(id))) ? true : false

  const renderMeasurementCard = (data = [] as any) => {
    return <React.Fragment>
      {data?.map((d: any, i: number) => (
        <Grid container spacing={1} className={classes.compactCardContent}>
          <Grid item sx={{ width: '5%' }}>
            <Checkbox
              name={d.id}
              disabled={confirm}
              checked={isMeasurementChecked(d.id)}
              sx={{ padding: '0px 0px 5px 0px !important' }}
              size="small"
              onChange={handleCheckboxChange}
            />
          </Grid>
          <Grid item sx={{ width: '90%' }}>
            <Grid container spacing={1} direction="row">
              <Grid item>
                <Typography variant="body2" className={classes.desc}>
                  {getTitle(d)}
                  <ColorBubbleComponent selectedProgramList={d.programs} overridenProgramList={d.overridden_programs} />
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.subHeaderTitle} variant="subtitle2" color="textSecondary">
                  {subHeaderCal(d)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </React.Fragment>
  }

  const renderList = (headerName: any, key: any) => {
    let data = [] as any
    if (key === 'program_checks')
      data = (patientDetails?.measurements?.program_checks)?.filter(
        (e: any) => e?.programs.includes(item.program) && e.needs_update &&
          !(e.overridden_programs || []).includes(item.program)
      )
    else if (key === 'program_targets')
      data = (patientDetails?.measurements?.program_targets)?.filter(
        (e: any) => e?.program.includes(item.program) && e.needs_update &&
          !(e.overridden_programs || []).includes(item.program)
      )
    else if (key === 'calculators')
      data = (patientDetails?.measurements?.calculators)?.filter(
        (e: any) => e.category !== "diagnosis" && e?.programs.includes(item.program) && e.needs_confirmation
          && !(e.overridden_programs || []).includes(item.program)
      )

    return <Accordion square defaultExpanded={true} className={classes.patientAccrodionBg}>
      <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.patientAccrodionExpandIcon} />}>
        <Typography className={classes.subHeadername} variant="h6">{`${headerName}`}</Typography>
      </AccordionSummary>
      <AccordionDetails>{renderMeasurementCard(data)}</AccordionDetails>
    </Accordion>
  }

  const handleClose = () => isOverridden
    ? updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
    : handleDialogClose()

  return (
    <Draggable ref={actionPrgRef} handle='.override-items-dialog' defaultPosition={{ x: 0, y: 0 }}>
      <Dialog
        id={'action-program'}
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="Actio items"
        hideBackdrop
        disableScrollLock
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            if (confirm && note !== '' && !isNoteFocused)
              handleConfirm()
            else if (overrideObj?.length > 1 && !loadingPrg && !confirm)
              setConfirm(true)
          }
        }}
        sx={{
          position: "absolute",
          left: '15%',
          top: '5%'
        }}
      >
        <DialogTitle className={classes.addPatientQuestionHeaderSection} classes={{ root: 'override-items-dialog' }}>
          <DialogTitleHeaderComponent
            title={<>
              <Typography color="textSecondary">
                <small>{`Override Measurements`}</small>
              </Typography>
              <Typography id="modal-modal-title" variant="h6">
                <strong>{item?.title}</strong> <ColorBubbleComponent selectedProgramList={[item?.program]} />
              </Typography>
            </>
            }
            handleDialogClose={() => handleClose()}
          />
        </DialogTitle>
        {loadingPrg && <Box><LinearProgress /></Box>}
        <DialogContent sx={{ padding: '0px 0px 5px 5px !important', minHeight: '5rem', backgroundColor: '#F1F1F6' }}>
          {!loadingPrg &&
            <>
              {isMeasurementExists()
                ? <Box sx={{ padding: '1rem 1rem' }}><EmptyCardComponent /></Box>
                : <Box className={classes.dialogHeaderBox} sx={{ height: '35rem !important' }}>
                  <Box sx={{ padding: '1rem 1rem 0rem 1.5rem' }}>
                    <Grid spacing={1} container justifyContent={'space-between'}>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isAllChecked}
                              disabled={confirm}
                              onChange={() => {
                                if (isAllChecked) {
                                  setIsAllChecked(false)
                                  setOverrideObj([])
                                } else {
                                  setIsAllChecked(true)
                                  getAllMeasurements()
                                }
                              }}
                              size="small"
                              name='all'
                              className={classes.checkBoxStyle}
                            />
                          }
                          label={<Typography variant="subtitle2">Override All</Typography>}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={(overrideObj?.length < 1 || loadingPrg || confirm)}
                          onClick={() => setConfirm(true)}
                          variant="outlined"
                          size="small"
                        >
                          Confirm
                        </Button>
                      </Grid>
                    </Grid>
                    {confirm &&
                      <Box mt={1}>
                        <Box mb={1} sx={{ width: '60%' }}>
                          <Card variant='outlined' className={classes.questionCardContent}>
                            <CardContent>
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                label="Add note for overriding"
                                size="small"
                                className={classes.fullWidth}
                                multiline={true}
                                minRows="3"
                                value={note}
                                onBlur={() => setIsNoteFocused(false)}
                                onFocus={() => setIsNoteFocused(true)}
                                onChange={(event: any) => setNote(event.target.value)}
                              />
                            </CardContent>
                            <Grid xs={12} direction="row" justifyContent="space-around" alignItems="center">
                              <ButtonGroup className={classes.questionButtonStyle} size="small" variant="text" aria-label="override-btn">
                                <Button
                                  disabled={note === ''}
                                  onClick={() => handleConfirm()}
                                  className={classes.twoButtonWidth}
                                >
                                  <strong>{'Submit'}</strong>
                                </Button>
                                <Button className={classes.twoButtonWidth}
                                  onClick={handleCancel}
                                >
                                  <strong>{'Cancel'}</strong>
                                </Button>
                              </ButtonGroup>
                            </Grid>
                          </Card>
                        </Box>
                      </Box>
                    }
                  </Box>
                  <Box sx={{ paddingLeft: '1rem' }}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="baseline">
                      <Grid item xs={4}>{renderList('Program Targets', 'program_targets')}</Grid>
                      <Grid item xs={4}>{renderList('Program Checks', 'program_checks')}</Grid>
                      <Grid item xs={4}>{renderList('Calculators', 'calculators')}</Grid>
                    </Grid>
                  </Box>
                </Box>
              }
            </>
          }
        </DialogContent>
      </Dialog>
    </Draggable>
  );
};

export default withStyles(stylesheet)(OverrideItemsDialogComponent)