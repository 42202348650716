import React from 'react';
import { Box, Button, ButtonGroup, Card, CardContent, Grid, makeStyles, Typography } from '@mui/material'
import stylesheet from '../detail-container.stylesheet'
import { ClassNameMap, withStyles } from '@mui/styles'

interface IProps {
    id: number,
    header: string,
    outerDelete: any,
    closeDelete: any
    classes: ClassNameMap;
}

const DeleteCard: React.FC<IProps> = ({ id, header, closeDelete, outerDelete, classes }) => {
    const handelCloseDelete = () => {
        closeDelete(id);
    }
    const handleOuterDelete = () => {
        outerDelete(id);
    }

    return (
        <>
            <Grid container xs={12} direction="column" data-testid='delete-card'>
                <Box mb={1} className={classes.customDeleteCard}>
                    <Card variant="outlined" className={classes.deleteCardContainer}>
                        <CardContent className={classes.deleteCardContent}>
                            <Grid container xs={12} direction="row" justifyContent="space-between" alignItems="baseline" className={classes.initialFlexwrap}  >
                                <Grid item >
                                    <Typography variant="subtitle2"
                                        className={classes.headertext}
                                        data-testid='card-header'
                                    >{header}</Typography>
                                    <Typography variant="subtitle2"
                                        className={classes.confirmDelete}
                                    >Are you sure you want to delete this?</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Grid xs={12} direction="row" justifyContent="space-around" alignItems="center">
                            <ButtonGroup
                                className={classes.deleteButtonStyle} size="small" variant="text" aria-label="small outlined button group"
                            >
                                <Button
                                    onClick={handleOuterDelete}
                                    className={classes.deleteButtonWidth}
                                    data-testid='delete-button'
                                >
                                    <strong>Delete</strong></Button>
                                <Button
                                    onClick={handelCloseDelete}
                                    className={classes.deleteButtonWidth}
                                    data-testid='cancel-button'
                                ><strong>Cancel</strong></Button>
                            </ButtonGroup>
                        </Grid>
                    </Card>
                </Box>
            </Grid>
        </>
    )
}


export default withStyles(stylesheet)(DeleteCard)