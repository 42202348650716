import React, { useState, useEffect, ReactElement, memo, useRef } from 'react'
import {
  Accordion, AccordionDetails, AccordionSummary, Grid, Typography
} from '@mui/material'
import { withStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import QuestionOCComponent from './question-oc-component'
import CardLayoutComponent from '../common/card-layout.component'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { RootState, useAppThunkDispatch } from '../../../../redux/store'
import { useParams } from 'react-router-dom'
import { PatientService } from '../../../../services/patient.service'
import { useCheckConfirmCount } from '../../../../utils/use-checkconfirmationcount-hook'
import DeleteCard from '../common/delete-card-component'
import NotesComponent from '../common/notes.component'
import { setAddValue } from '../../../../redux/add-control-slice'
import ReferralComponent from '../common/referral-card.component'
import { useReferralAction } from '../../../../utils/referral-hook'
import EmptyCardComponent from "../common/empty-card.component"
import { useCurrentUser } from '../../../../utils/use-current-user'
import { setOtherConditions } from '../../../../redux/common-data-slice'
import stylesheet from '../detail-container.stylesheet'
import { ClassNameMap, styled } from '@mui/styles'
import Helper from '../../../../utils/helper'

interface IProps {
  input: any
  classes: ClassNameMap
}
const OtherConditionsComponent: React.FC<IProps> = ({ input, classes }) => {
  const [data, setData] = useState([] as any)
  const patientDetail = useSelector(
    (state: RootState) => state.patientDetail.patientDetail
  )
  const urlParams: any = useParams()
  const patientId: string = urlParams.patientId
  const dispatch = useAppThunkDispatch()
  const [, setCount] = useCheckConfirmCount(patientId)
  const patientService = new PatientService()
  const [selectedNotes, setSelectedNotes] = useState([] as number[])
  const [errorMessage, setErrorMessage] = useState('')
  const { currentRole } = useCurrentUser()
  const inputElPatientReported = useRef(null) as any
  const inputElImported = useRef(null) as any
  const addButtons = useSelector((state: RootState) => state.addButtons)
  const [isExpandReported, setIsExpandReported] = useState(false)
  const [isExpandImported, setIsExpandImported] = useState(false)
  const { onSetView, onDataSubmit } = useReferralAction()

  useEffect(() => {
    if (Object.keys(patientDetail?.conditions?.other_conditions)?.length > 0) {
      const stage = patientDetail?.conditions?.other_conditions?.map((e: any) => {
        return { ...e, mode: 'view' }
      })
      setData(stage)
    }
  }, [patientDetail])


  const formatConfirmDate = (save_timestamp: number, is_referred: boolean, referred_on: number) => {
    if (is_referred) {
      return moment.unix(referred_on).format('MM/DD/YY')
    } else if (save_timestamp) {
      return moment.unix(save_timestamp).format('MM/DD/YY')
    } else {
      return ''
    }
  }

  const formatRightHeader = (score: string, response_value: string): string | ReactElement => {
    return (
      <>
        {score ? (
          <>
            {' '}
            <Typography variant="body1">
              {' '}
              <strong>{score}</strong>
              <Typography variant="caption" color="textSecondary">
                {` (${response_value})`}
              </Typography>
            </Typography>{' '}
          </>
        ) : (
          <>{''}</>
        )}
      </>
    )
  }
  const setOtherIcons = (type = '') => {
    let actionObj = [
      { iconType: 'edit', callback: onSetQuestion },
      { iconType: 'description', callback: onNotesClick },
      { iconType: 'more', callback: outer },
    ]
    if (type !== 'imported')
      actionObj.push({ iconType: 'delete', callback: onDelIconClick })
    return actionObj
  }

  const onNotesClick = (_id: any) => {
    if (selectedNotes.includes(+_id)) {
      setSelectedNotes([...selectedNotes.filter((f) => f !== +_id)])
    } else {
      setSelectedNotes([...selectedNotes, +_id])
    }
  }

  const onDelIconClick = (_id: any) => {
    setData(
      data.map((el: any) => (el.id === _id ? { ...el, mode: 'delete' } : el))
    )
  }

  const outer = (_id: any, _mode: string) => {
    setData(onSetView(_id, _mode, data))
  }
  const onRefSubmit = (_id: any, date: number, is_referred: boolean, referral_id: any) => {
    setData(onDataSubmit(_id, date, is_referred, referral_id, data))
  }

  const confirmDelete = (_id: any) => {
    patientService
      .deleteOtherConditions(patientId, _id)
      .then((_response: any) => {
        setData(data.filter((e: { id: any }) => e.id !== _id))
      })
      .catch((err: any) => {
        console.error(err)
      })
  }

  const onSetQuestion = (_id: number) => {
    setData(
      data.map((el: any) => (el.id === _id ? { ...el, mode: 'question' } : el))
    )
  }

  const closeDelete = (_id: number) => {
    setData(
      data.map((el: any) => (el.id === _id ? { ...el, mode: 'view' } : el))
    )
  }

  const handleClose = () => {
    handleAddHideShow()
    setErrorMessage('')
  }

  const pushData = (dt: any) => {
    setData([...data, { ...dt, mode: 'view' }])
  }

  const editCondition = (recordedDate: any, _id: any, note: string) => {
    const payload = {
      date_recorded: moment(recordedDate).unix(),
      notes: note,
      user_role: currentRole,
    }
    patientService
      .editOtherConditions(payload, patientId, _id)
      .then((response: any) => {
        setData(
          data.map((el: any) =>
            el.id === response.data.id
              ? {
                ...el,
                mode: 'view',
                date_recorded: moment(recordedDate).unix(),
              }
              : el
          )
        )
      })
  }

  const outerSubmit = (_mode: string, condition: any, recordedDate: any, note: string) => {
    const payload = {
      name: condition.name,
      date_recorded: moment(recordedDate).unix(),
      notes: note,
      user_role: currentRole,
    }
    setErrorMessage('')
    patientService
      .postOtherConditions(payload, patientId)
      .then((response: any) => {
        const dt = response.data
        setErrorMessage('')
        setData(
          data.map((el: any) =>
            el.id === dt.id
              ? {
                ...el,
                mode: _mode,
                needs_confirmation: false,
                date_recorded: dt.date_recorded,
                response_value: dt.response_value,
                score: dt.score,
              }
              : el
          )
        )
        pushData({ ...dt, needs_confirmation: false, saved_on: new Date() })
        setCount(
          data.find((f: { id: string }) => f.id === dt.id)?.needs_confirmation,
          dt.needs_confirmation
        )
        handleClose()
      })
      .catch((err: any) => {
        console.error(err)
        if (
          err.response.data.error.message ===
          'Record already exists for the patient'
        ) {
          setErrorMessage('Record already exists for the patient')
        }
      })
  }

  const handleAddHideShow = () => {
    const temp = [
      ...addButtons?.addControl.map((e: any) =>
        e.key === 'CO-OC' ? { ...e, value: !e.value } : e
      ),
    ]
    dispatch(setAddValue(temp))
  }

  const getOtherConditionCard = (data: any, cat: any) => {
    if (cat) data = data.filter((i: any) => i.type === cat)
    data = data?.sort((a: any, b: any) => (a?.display_name).toLowerCase() > (b?.display_name?.toLowerCase()) ? 1 : -1)

    return data?.map((d: any, i: any) => (
      <React.Fragment key={`oc-${cat}-${i}`}>
        {d.mode === 'view' && input.parent === 'OC' && (
          <>
            <CardLayoutComponent
              id={d.id}
              name={d?.name}
              leftHeader={d.display_name}
              rightHeader={formatRightHeader(d.score, d.response_value)}
              subHeader={''}
              bottomText={formatConfirmDate(d.date_recorded, d.is_referred, d.referred_on)}
              needs_confirmation={d.needs_confirmation || undefined}
              needs_update={d.needs_update || undefined}
              icons={setOtherIcons(cat)}
              onRefClick={() => outer(d.id, 'ref')}
              is_referred={d.is_referred}
              is_overridden={d.is_overridden || undefined}
              entityRef='condition'
              patientId={patientId}
              category={'conditions'}
            ></CardLayoutComponent>
            {selectedNotes.includes(d.id) && (
              <NotesComponent
                entityId={d.id}
                entityRef='condition'
                cancelBtn={true}
                onCancel={onNotesClick}
              >
                Notes
              </NotesComponent>
            )}
          </>
        )}
        {d.mode === 'question' && (
          <QuestionOCComponent
            input={d}
            noDropDown=""
            name={d}
            outer={outer}
            outerSubmit={editCondition}
            handleClose={() => outer(d.id, 'view')}
            edit={true}
            errorMessage=""
          />
        )}

        {d.mode === 'delete' && (
          <DeleteCard
            id={d.id}
            header={d.display_name}
            closeDelete={() => closeDelete(d.id)}
            outerDelete={() => confirmDelete(d.id)}
          />
        )}
        {d.mode === 'ref' && (
          <ReferralComponent
            entityRef='condition'
            input={d}
            patientId={patientId}
            leftHeader={d.display_name}
            rightHeader=''
            programs={d.programs}
            closeRef={() => outer(d.id, 'view')}
            onRefSubmit={onRefSubmit}
          />
        )}
      </React.Fragment>
    ))
  }

  const updateExpand = (type: any) => type === 'patient_reported'
    ? setIsExpandReported(!isExpandReported)
    : setIsExpandImported(!isExpandImported)


  const getOCList = (type: any) => {
    return <Accordion square
      defaultExpanded={type === 'patient_reported' ? isExpandReported : isExpandImported}
      className={classes.patientAccrodionBg}
    >
      <AccordionSummary
        ref={type === 'imported' ? inputElImported : inputElPatientReported}
        expandIcon={<ExpandMoreIcon className={classes.patientAccrodionExpandIcon} />}
        onClick={() => updateExpand(type)}
      >
        <Typography className={classes.subTitle}>{Helper.stageName(type)}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item className={classes.fullWidth}>
            {getOtherConditionCard(data, type)}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion >
  }
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item className={classes.fullWidth}>
        {addButtons?.addControl.find(
          (e: { key: string }) => e.key === 'CO-OC'
        ).value && (
            <QuestionOCComponent
              noDropDown=""
              input=""
              name=""
              outer={outer}
              outerSubmit={outerSubmit}
              handleClose={handleClose}
              edit={false}
              errorMessage={errorMessage}
            />
          )}
      </Grid>
      {data?.length > 0 &&
        <Grid container direction="row" sx={{ marginTop: '-5px', paddingLeft: '0.2rem' }}>
          <Grid item className={classes.fullWidth}>
            {getOCList('patient_reported')}
          </Grid>
          <Grid item className={classes.fullWidth}>
            {getOCList('imported')}
          </Grid>
        </Grid>
      }
      {data?.length == 0 &&
        !addButtons?.addControl?.find((e: { key: string }) => e.key === 'CO-OC').value &&
        <EmptyCardComponent />
      }
    </Grid >
  )
}

export default withStyles(stylesheet)(memo(OtherConditionsComponent))
