import { useEffect, useState } from 'react'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import moment from 'moment'

interface IProps {
  item: any
  classes: ClassNameMap
}

const MedReferralComponent: React.FC<IProps> = ({ item, classes }) => {
  const data = item?.referral_data || {} as any
  const getVal = (d: any, key: any) => d.hasOwnProperty(key) ? d[key] : '-'

  return (
    <Grid>
      <TableContainer component={Paper}>
        <Table size="small" className={classes.table} aria-label="referral table">
          <TableHead>
            <TableRow className={classes.medHistoryTitles}>
              <TableCell width={'25%'}>{`Date & Time`}</TableCell>
              <TableCell width={'25%'}>User</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Status</TableCell>
              <TableCell width={'20%'}>Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(data).length > 0
              ? <>
                {Object.keys(data)?.map((row: any, i: number) => (
                  <TableRow key={i}>
                    <TableCell >
                      {moment.unix(data[row].timestamp).format('DD MMM YYYY hh:mm a')}
                    </TableCell>
                    <TableCell>{getVal(data[row], 'name')}</TableCell>
                    <TableCell>{getVal(data[row], 'user_role')}</TableCell>
                    <TableCell>{item?.referral_status || '-'}</TableCell>
                    <TableCell>{getVal(data[row], 'text')}</TableCell>
                  </TableRow>
                ))}
              </>
              : <TableRow><TableCell>Referral data not available...</TableCell></TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

export default withStyles(stylesheet)(MedReferralComponent)
