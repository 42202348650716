import React, { useEffect, useState } from 'react'
import {
  Card, Box, LinearProgress, Grid,
} from '@mui/material'
import { RouteComponentProps } from 'react-router-dom'
import { ClassNameMap, makeStyles } from '@mui/styles'
import { RootState, useAppThunkDispatch } from '../../redux/store'
import { useSelector } from 'react-redux'
import FiltersComponent from './landing/filters.component'
import { getMetaData } from '../../redux/meta-data-slice'
import PatientDataTableComponent from './landing/patientDataTable-demo.component'

const useStyles = makeStyles(() => ({
  inputField: {
    top: '24px',
  },
  root: {
    backgroundColor: '#f8f8f8',
    borderRadius: '0px',
  },
  pannel: {
    height: '3.2rem',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    marginBottom: '1rem',
    cursor: 'pointer',
    strokeWidth: 1,
    borderColor: 'white',
    borderWidth: '0 0 0 4px',
    boxShadow: '0.9px -0.3px 10px 3px rgb(0 0 0 / 8%)',
    '&:hover': {
      borderColor: '#f5842e',
      borderWidth: '0 0 0 4px',
    },
  },
  mainDiv: {
    padding: '50px 50px',
  },
  patientName: {
    height: '19px',
    fontSize: '16px',
    color: '#000000',
    fontWeight: 600,
    fontFamily: 'Helvetica',
    textDecoration: 'none solid rgb(0, 0, 0)',
    lineHeight: '24px',
    display: 'inline-block',
  },
  patientDetails: {
    height: '20px',
    fontSize: '14px',
    color: '#787878',
    textDecoration: 'none solid rgb(120, 120, 120)',
    lineHeight: '20px',
    textAlign: 'right',
    display: 'inline-block',
  },
  patientDetailsSpan: {
    float: 'right',
    display: 'inline-block',
  },
  patientStatus: {
    height: '15px',
    fontSize: '13px',
    color: '#404040',
    textDecoration: 'none solid rgb(64, 64, 64)',
    textAlign: 'right',
    display: 'inline-block',
    marginRight: '9px',
  },
  statusFactor: {
    height: '14px',
    fontSize: '12px',
    color: '#404040',
    textDecoration: 'none solid rgb(64, 64, 64)',
    textAlign: 'right',
    display: 'inline-block',
    marginRight: '8px',
  },
  unitValue: {
    height: '19px',
    fontSize: '16px',
    color: '#404040',
    textDecoration: 'none solid rgb(64, 64, 64)',
    textAlign: 'right',
    display: 'inline-block',
    marginRight: '3px',
  },
  unit: {
    height: '14px',
    fontSize: '12px',
    color: '#828282',
    textDecoration: 'none solid rgb(64, 64, 64)',
    textAlign: 'right',
    display: 'inline-block',
    marginRight: '8px',
  },
  separator: {
    width: '3px',
    height: '14px',
    fontSize: '12px',
    color: '#828282',
    textDecoration: 'none solid rgb(130, 130, 130)',
    textAlign: 'right',
    display: 'inline-block',
    marginRight: '10px',
  },
  ageGenderMrn: {
    height: '14px',
    opacity: '0.5',
    fontSize: '12px',
    color: '#000000',
    textDecoration: 'none solid rgb(0, 0, 0)',
    display: 'inline-block',
    marginRight: '10px',
  },
  dueStatus: {
    height: '16px',
    fontSize: '13px',
    color: '#808080',
    textDecoration: 'none solid rgb(64, 64, 64)',
    textAlign: 'right',
    display: 'inline-block',
    marginRight: '9px',
    float: 'right',
  },
  iconsStyle: {
    paddingTop: '0px',
    border: 0,
    float: 'left',
    marginRight: '15px',
    fill: '#6d6e71',
  },
  patientCountLabel: {
    width: '142px',
    height: '17px',
    fontSize: '16px',
    color: '#757575',
    textDecoration: 'none solid rgb(117, 117, 117)',
  },
  pageHeader: {
    padding: '20px 40px 16px',
  },
  lastUpdateText: {
    height: '14px',
    fontFamily: 'Helvetica Neue',
    fontSize: '14px',
    color: '#939393',
    textDecoration: 'none solid rgb(147, 147, 147)',
    textAlign: 'right',
    float: 'right',
    marginTop: '5px',
  },
  cardContainer: {
    padding: '0px 10px',
  },
  panelContent: {
    padding: '12px 0px 0px 10px',
  },
  contentRowOne: {
    marginBottom: '2px',
  },
}))
interface IProps extends RouteComponentProps { }

const PatientListComponent: React.FC<IProps> = () => {
  const classes: ClassNameMap = useStyles()
  const [, setFilteredType] = useState('')
  const dispatch = useAppThunkDispatch()
  const selectedStage = useSelector(
    (state: RootState) => state.commonData.selectedStage
  )

  const patientsTable = useSelector(
    (state: RootState) => state.commonData.patientsTable
  )

  useEffect(() => {
    if (selectedStage) {
      setFilteredType(selectedStage)
    }

    dispatch(getMetaData()).then((response: any) => {
      getFilterList(response?.payload?.data?.PATIENT_STAGE || [])
    })

  }, [])
  const [filterList, setFiletrList] = useState([] as any)

  const getFilterList = (list: any) => {
    let filterItem = {} as any
    filterItem['ENROLLMENT'] = list['ENROLLMENT']
    filterItem['DATA_INTAKE'] = list['DATA_INTAKE']
    filterItem['MED_BASELINE'] = list['MED_BASELINE']
    filterItem['MED_INITIATION'] = list['MED_INITIATION']
    filterItem['EARLY_TOX_CHECK'] = list['EARLY_TOX_CHECK']
    filterItem['MED_EVALUATION'] = list['MED_EVALUATION']
    filterItem['PROGRAM_EVALUATION'] = list['PROGRAM_EVALUATION']
    filterItem['TOXICITY_MONITORING'] = list['TOXICITY_MONITORING']
    filterItem['MAINTENANCE_SURVEILLANCE'] = list['MAINTENANCE_SURVEILLANCE']

    setFiletrList(filterItem)
  }

  return (
    <section className="questionnaire-wrapper">
      <Card variant="outlined" className={classes.root}>
        <Grid container spacing={0} direction="row">
          <Grid item xs={12} sm={3} lg={2}>
            <Box mt={1}>
              <FiltersComponent filterList={filterList} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} lg={10}>
            <PatientDataTableComponent patientsTable={patientsTable} />
          </Grid>
        </Grid>
      </Card>
    </section>
  )
}

export default PatientListComponent
