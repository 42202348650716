import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { RouteComponentProps } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

const styles = makeStyles((theme: any) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  linkText: {
    textDecoration: 'none',
    color: 'black',
  },
}));

interface IProps extends RouteComponentProps {
  left: any;
  toggleDrawerHandler: Function;
  history: any;
  location: any;
  match: any;
}

const DrawerComponent: React.FC<IProps> = ({ left, toggleDrawerHandler }) => {
  const [leftHere, setLeft] = useState(false);

  useEffect(() => {
    setLeft(left);
  }, [left]);

  const classes = styles();

  const sideList = (side: any) => (
    <div
      className={classes.list}
      role='presentation'
      onClick={(event: any) => {
        toggleDrawerHandler(event);
      }}
      onKeyDown={(event: any) => {
        toggleDrawerHandler(event);
      }}
      data-testid='drawer-testId'
    >
      <List>
        {[
          { value: 'Home', link: '/' },
          { value: 'Journey', link: '/details' },
        ].map((text, index) => (
          <a key={index} href={text && text.link} className={classes.linkText}>
            <ListItem button key={text && text.value}>
              <ListItemIcon>
                {index % 2 === 0 ? <HomeOutlinedIcon /> : <EqualizerIcon />}
              </ListItemIcon>
              <ListItemText primary={text && text.value} />
            </ListItem>
          </a>
        ))}
      </List>
    </div>
  );

  return (
    <Drawer
      open={leftHere}
      onClose={(event: any) => {
        toggleDrawerHandler(event);
      }}
    >
      {sideList('left')}
    </Drawer>
  );
};

export default DrawerComponent;
