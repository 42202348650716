import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import React from 'react';
import stylesheet from "../detail-container.stylesheet";
interface IProps {
  classes: ClassNameMap,

}
const EmptyCardComponent: React.FC<IProps> = ({ classes }) => {
  return (<>
    <Grid container direction="column" data-testid='empty-card'>
      <Box mb={1} className={classes.customCard}>
        <Card variant="outlined" className={classes.fullWidth}>
          <CardContent className={classes.emptyCardContent}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Typography color="textSecondary" className={classes.customCard1}>Data not available</Typography>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Grid>
  </>)
}


export default withStyles(stylesheet)(EmptyCardComponent);