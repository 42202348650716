import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
	title: any
	handleDialogClose: any
}

const DialogTitleHeaderComponent: React.FC<IProps> = ({ title, handleDialogClose }) => {
	return (
		<Grid container justifyContent="space-between">
			<Grid item>
				<Typography id="modal-modal-title" variant="h6">
					<strong>{title}</strong>
				</Typography>
			</Grid>
			<Grid item>
				<IconButton size="small" onClick={handleDialogClose}>
					<CloseIcon />
				</IconButton>
			</Grid>
		</Grid>
	)
}

export default DialogTitleHeaderComponent;