import React, { useEffect, useState } from 'react'
import {
	CircularProgress, Grid, TextField, Typography, Chip, Accordion, AccordionSummary, AccordionDetails, Box,
} from '@mui/material'
import { ClassNameMap } from '@mui/styles'
import Autocomplete from '@mui/material/Autocomplete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import 'react-calendar/dist/Calendar.css'
import { checkCondition } from '../common/condition-check'
import Helper from '../../../../utils/helper'
import { PatientService } from '../../../../services/patient.service'
import EmptyCardComponent from '../common/empty-card.component';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

interface IProps {
	outer: any
	propagation: any
	existingMed: any
	classes: ClassNameMap
}

const AutoCompleteMedicine: React.FC<IProps> = ({ classes, existingMed, propagation, outer }) => {
	const [oneMedicine, setOneMedicine] = useState(existingMed)
	const patientService = new PatientService()
	const [medicineOpen, setMedicineOpen] = useState(false)
	const [medicine, setMedicine] = useState([] as any)
	const [ingredient, setIngredient] = useState(true)
	const [aa_formulation, setAaFormulation] = useState(true)
	const [medication_class, setmedicationClass] = useState(true)
	const [query, setQuery] = useState("");
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		let active = true
		if (active && query.length >= 2) {
			setLoading(true)
			patientService.getRxNorms(query, propagation, aa_formulation, medication_class, ingredient).then((response: any) => {
				setMedicine(response.data)
				setLoading(false)
			}).catch(() => setLoading(false))
		}
		return () => {
			active = false
		}
	}, [query])

	const debounce = (func: any, delay: any) => {
		let debounceTimer: any
		return function () {
			const context = func
			const args = arguments
			clearTimeout(debounceTimer)
			debounceTimer = setTimeout(() => func.apply(context, args), delay)
		}
	}

	const handleChipClick = (e: any) => {
		if (e.target.innerText == 'ingredient') setIngredient(!ingredient)
		if (e.target.innerText == 'medication') setAaFormulation(!aa_formulation)
		if (e.target.innerText == 'class') setmedicationClass(!medication_class)
	}

	const renderChip = (class_name: any, label: string) => <Chip className={class_name} label={label} onClick={handleChipClick} />

	return <>
		<Grid container className={classes.medicationsBoxDisplay}>
			<Grid item className={classes.fullWidth}>
				{renderChip(aa_formulation ? classes.chipSelect : classes.chipDefault, "medication")}
				{renderChip(medication_class ? classes.chipSelect : classes.chipDefault, "class")}
				{renderChip(ingredient ? classes.chipSelect : classes.chipDefault, "ingredient")}
			</Grid>
			<Grid item>
				<Autocomplete
					id="combo-medicine"
					open={medicineOpen}
					onOpen={() => {
						setMedicineOpen(true)
						setMedicine([])
						setQuery('')
					}}
					onClose={() => {
						setMedicineOpen(false)
						setMedicine([])
						setQuery('')
					}}
					options={medicine as any}
					getOptionLabel={(option: any) => option?.type !== '' ? Helper.getRXnorm(option?.name, option?.type) : option.name}
					className={classes.autoCompleteDropDowns}
					loading={loading}
					// value={oneMedicine}
					onChange={(_event, newValue) => {
						setOneMedicine(newValue);
						outer(newValue)
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							onChange={debounce((e: any) => {
								setQuery(e.target.value)
								if (e.target.value === '') {
									setMedicine([])
								}
							}, 1000)}
							variant="standard"
							label="Medicine Selection"
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<React.Fragment>
										{checkCondition(
											loading,
											<CircularProgress color="inherit" size={20} />, null
										)}
										{params.InputProps.endAdornment}
									</React.Fragment>
								),
							}}
						/>
					)}
				/>
			</Grid>
		</Grid>
	</>
}

export default AutoCompleteMedicine
