import { Grid, Typography, Tooltip, IconButton, Stack } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import InfoIcon from '@mui/icons-material/Info'
import stylesheet from '../detail-container.stylesheet'
interface IProps {
  item: any
  compKey: string
  classes: ClassNameMap
}

const RationaleComponent: React.FC<IProps> = ({ item, compKey, classes }) => {
  const program_conditions = useSelector(
    (state: RootState) => state.patientDetail.patientDetail.conditions.program_conditions
  )
  const getSourceAttr = (currItem: any) => {
    const srcVal = (currItem && item?.c_rationale?.source_attributes[currItem]) || ''
    return srcVal?.name ? <Tooltip
      title={<Typography onClick={() => window.open(`${srcVal?.url}`, "_blank")}
        component="span"
        className={[classes.evalBoxVal, classes.srcAttributes].join(' ')}
        variant="subtitle2">
        {srcVal?.name}
      </Typography>}
      placement="bottom-start"
      disableFocusListener
    >
      <IconButton size='small' className={classes.infoIcon}>
        <InfoIcon className={classes.infoIconFont} /> </IconButton>
    </Tooltip>
      : ''
  }

  const getInclusiveCriteria = (criteriaObj: any) => {
    return criteriaObj && Object.keys(criteriaObj)?.map((m: any, key: any) => {
      const { value, dependents } = criteriaObj[m]
      const depenList = dependents && Object.keys(dependents)?.map((key: any) => `${key}: ${dependents[key]}`).join(', ');
      return <Grid item key={`${key}-${m}}`}>
        <Stack direction={'row'}>
          <Typography className={classes.evalBoxVal} variant='subtitle2'>
            {m}: &nbsp;
          </Typography>
          <Typography className={classes.evalBoxVal}
            variant='subtitle2'
            color={(value && value !== '') ? 'red !important' : 'inherit'}
          >
            {JSON.stringify(value)}
          </Typography>
          <Typography className={classes.evalBoxVal} variant='subtitle2'>
            &nbsp;{depenList && `[${depenList}]`}
          </Typography>
        </Stack>
      </Grid >
    })
  }

  const getLabel = (val: any) => <Typography className={classes.evalBoxLabel} variant="body2" color="textSecondary">{val}</Typography>

  return (
    <Grid className={item.isProviderRecomm ? classes.evalProviderContent : classes.evalContent} container spacing={1}>
      {!item?.isProviderRecomm &&
        <Grid item className={classes.fullWidth}>
          <Grid container direction="column">
            <Grid item>{getLabel('Relevant conditions')}</Grid>
            <Grid item>
              <Grid container direction="column" className={classes.evalSubBox}>
                {item.c_rationale?.conditions && item.c_rationale?.conditions?.map((m: any) =>
                  <Grid item key={`relCon-${m}`}>
                    <Typography key={m} className={classes.evalBoxVal}>
                      {`${program_conditions?.find((f: any) => f.id === m)?.display_name || ''}`}
                      {getSourceAttr(m)}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      {!item?.isProviderRecomm &&
        <Grid item className={classes.fullWidth}>
          <Grid container direction="column">
            <Grid item>{getLabel('Inclusion criteria')}</Grid>
            <Grid item>
              <Grid container direction="column" className={classes.evalSubBox}>
                {getInclusiveCriteria(item?.c_rationale?.incl_criteria)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      <Grid item className={classes.fullWidth}>
        <Grid container direction="column">
          <Grid item>{getLabel('Exclusion criteria')}</Grid>
          <Grid item>
            <Grid container direction="column" className={classes.evalSubBox}>
              {getInclusiveCriteria(item?.c_rationale?.excl_criteria)}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.fullWidth}>
        <Typography className={classes.evalBoxLabel} variant="body2" color="textSecondary">
          {`Exclusion reason `}
          <Typography component={'span'} className={classes.evalBoxVal} variant='subtitle2'
            color={item?.c_rationale?.exclusion_reason && item?.c_rationale?.exclusion_reason !== '' ? 'red !important' : ''}
          >
            {item?.c_rationale?.exclusion_reason || ''}
          </Typography>
        </Typography>
      </Grid>
      <Grid item className={classes.fullWidth}>
        <Grid container direction="column">
          <Grid item>{getLabel('Taking med exclusion criteria')}</Grid>
          <Grid item>
            <Grid container direction="column" className={classes.evalSubBox}>
              {getInclusiveCriteria(item?.c_rationale?.taking_med_excl_criteria)}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(stylesheet)(RationaleComponent)
