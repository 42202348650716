import React, { useEffect, useState } from "react";
import { RouteComponentProps } from 'react-router-dom';
import InputStage from "./input-stage.component";
import DropdownStage from "./dropdown-stage.component";

interface IProps extends RouteComponentProps {
  uniqueId: string,
  question: any,
  onDropTextChange: Function,
  answerArray: any,
  isAnswerSelected: Function,
  dataReceivedFlag: boolean,
  history: any,
  location: any,
  match: any
}

const ParameterValueComponentStage: React.FC<IProps> = ({ uniqueId, question, onDropTextChange, answerArray, isAnswerSelected, dataReceivedFlag }) => {

  let [inputValue, setInputValue] = useState('')
  let [measurementUnit, setMeasurementUnit] = useState('')

  useEffect(() => {
    formObject()
  }, [inputValue, measurementUnit, isAnswerSelected])

  const onTextChange = (value: any) => {
    setInputValue(value)
  }

  const onDropChange = (value: any) => {
    setMeasurementUnit(value)
  }

  const formObject = () => {
    if (inputValue !== '' && measurementUnit !== '') {
      return onDropTextChange(inputValue, measurementUnit, question.id, question, 'input')
    }
  }

  const [defaultValue, setDefaultValue] = useState([] as any)
  useEffect(() => {
    if (answerArray && dataReceivedFlag) {
      let callDefaultValue = isAnswerSelected(question.id, '', 'ParameterValue')
      setDefaultValue(callDefaultValue)
    }
  }, [dataReceivedFlag])

  return (
    <div key={'input-section' + uniqueId}>
      <InputStage
        uniqueId={uniqueId}
        question={question}
        onTextBoxChange={onTextChange}
        defaultValue={defaultValue && defaultValue[0] && defaultValue[0]['val']}
        isAnswerSelected={isAnswerSelected}
        history={''}
        location={''}
        match={''}
      />
      <DropdownStage
        question={question}
        uniqueId={uniqueId}
        onDropDownChange={onDropChange}
        defaultUnits={defaultValue && defaultValue[0] && defaultValue[0]['uni']}
        history={''}
        location={''}
        match={''}
      />
    </div>
  )
}

export default ParameterValueComponentStage;