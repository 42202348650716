import { useEffect, useState } from 'react'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import { PatientService } from '../../../../services/patient.service'
import { toast } from 'react-toastify'
import Helper from '../../../../utils/helper'
import moment from 'moment'
import { checkCondition } from '../common/condition-check'
interface IProps {
  item: any
  classes: ClassNameMap
}

const MedHistoryComponent: React.FC<IProps> = ({ item, classes }) => {
  const patientService = new PatientService()
  const [historyLoading, setHistoryLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    patientService
      .getMedHistory(item.id)
      .then((res: any) => {
        setHistoryLoading(false)
        if (res && res.data) setData(res.data)
      })
      .catch((error) => {
        setHistoryLoading(false)
        toast.error(Helper.getErrorText(error), Helper.bottom_center())
      })
  }, [])

  const getVal = (d: any, key: any) => {
    return d.hasOwnProperty(key) ? d[key] : '-'
  }

  return (
    <Grid>
      {historyLoading ? (
        <Grid className={classes.historyLoading}>
          <CircularProgress />
        </Grid>
      ) : (
        <TableContainer component={Paper}>
          <Table
            size="small"
            className={classes.table}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow className={classes.medHistoryTitles}>
                <TableCell width={'25%'}>{`Date & Time`}</TableCell>
                <TableCell width={'25%'}>User Name</TableCell>
                <TableCell width={'22%'}>User Role</TableCell>
                <TableCell width={'28%'}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {checkCondition(data.length > 0,
                <>
                  {data.map((row: any, i: number) => (
                    <TableRow key={i}>
                      <TableCell>
                        {moment.unix(row.timestamp).format('DD MMM YYYY hh:mm a')}
                      </TableCell>
                      <TableCell>{getVal(row, 'user_name')}</TableCell>
                      <TableCell>{getVal(row, 'user_role')}</TableCell>
                      <TableCell>{getVal(row, 'action')}</TableCell>
                    </TableRow>
                  ))}
                </>,
                <TableRow>
                  <TableCell>Histroy not available...</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  )
}

export default withStyles(stylesheet)(MedHistoryComponent)
