import { Box, Grid, Typography, FormControl, MenuItem, Select, InputLabel } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import React from 'react'
import stylesheet from '../detail-container.stylesheet'
import LooksTwoRoundedIcon from '@mui/icons-material/LooksTwoRounded';

interface IProps {
  classes: ClassNameMap
}

const GetSubstituteComponent: React.FC<IProps> = ({ classes }) => {
  return (
    <>
      <Box pb={1} mb={1} className={classes.questionHeaderSection} data-testid='get-substitute-testId'>
        <Box pb={1} mb={1} mt={2} className={classes.questionHeaderSection}>
          <Typography variant='body1' color='textSecondary'><small className={classes.questionHeaderSection1}><LooksTwoRoundedIcon></LooksTwoRoundedIcon></small><strong>Get substitute</strong>  </Typography>
        </Box>
        <Grid container xs={12} direction="row" justifyContent='flex-start'>
          <Grid item xs={3}>
            <Typography variant='body2' color='textSecondary'><small> Medication </small> </Typography>
            <Typography variant="subtitle2"> {'Lisinopril 5mg tablet, once daily'}</Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControl
              className={classes.formControlWidth150}
            >
              <InputLabel id="demo-simple-select-standard-label">Substitute</InputLabel>
              <Select
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ 'aria-label': 'With label' }}
                value={'substitute'}
                name="Substitute"
              >

                <MenuItem value='substitute'>
                  Substitute
                </MenuItem>

              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box mt={1} >
          <Grid container xs={12} direction="row" justifyContent='flex-start'>
            <Grid item xs={3} className={classes.substituteBg}>
              <Typography variant='body2' color='textSecondary'><small> Recommandation </small> </Typography>
              <Typography variant="subtitle2"> {'Valsartan 160mg tablet, once daily'}</Typography>
            </Grid>
            <Grid item xs={1} className={classes.substituteBg}></Grid>
            <Grid item xs={1} className={classes.substituteRestrictBg}>
              <Typography variant='body1' className={classes.substituteRestrictText}><small> Accept </small> </Typography>
            </Grid>
            <Grid item xs={1} className={classes.substituteRestrictBg}>
              <Typography variant='body1' className={classes.substituteRestrictText}><small> Restrict </small> </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default withStyles(stylesheet)(GetSubstituteComponent)