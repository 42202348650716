import React from 'react'
import stylesheet from '../detail-container.stylesheet'
import { Tooltip, IconButton } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import InfoIcon from '@mui/icons-material/Info'

interface IProps {
  ingredients: any
  classes: ClassNameMap
}

const IngredientTooltip: React.FC<IProps> = ({
  ingredients,
  classes,
}) => {

  const title = ingredients?.map((e: any) => {
    return `${e.ingredient} (Daily Dose: ${e.dose} ${e.unit})`
  })
  return <Tooltip
    data-testid='ingredient-testId'
    title={ingredients && ingredients?.length > 0 ? title : 'Details not available'}
    placement="bottom">
    <IconButton size='small' className={classes.infoIcon}>
      <InfoIcon className={classes.infoIconFont} />
    </IconButton>
  </Tooltip>
}

export default withStyles(stylesheet)(IngredientTooltip)
