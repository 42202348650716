import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ProgramTargetsState {
  program_targets: any[]
}

const initialState: ProgramTargetsState = {
  program_targets: [],
}

export const programTargetsSlice = createSlice({
  name: 'program_targets',
  initialState,
  reducers: {
    setProgramTargetsStore: (state, action: PayloadAction<any>) => {
      state.program_targets = action.payload
    },
  },
})

export const { setProgramTargetsStore } = programTargetsSlice.actions

export default programTargetsSlice.reducer
