import React, { useEffect, useRef, useState } from 'react'
import {
  AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Tab, Tabs,
} from '@mui/material'
import stylesheet from '../detail-container.stylesheet'
import { ClassNameMap, withStyles } from '@mui/styles'
import PatientProfileDetailsComponent from './patient-profile-details.component'
import { PatientService } from '../../../../services/patient.service'
import { toast } from 'react-toastify'
import Helper from '../../../../utils/helper'
import { sanitizedData } from '../../../../utils/sanitize-html'
import { useCurrentUser } from '../../../../utils/use-current-user'
import { useDispatchAPIs } from '../../../../utils/use-dispatchApis-hook'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import NotesComponent from '../common/notes.component'
import DialogTitleHeaderComponent from '../common/dialog-title-header.component'
import Draggable from 'react-draggable'
import { useUpdateDataAction } from '../../../../utils/update-details-hook'

interface IProps {
  patientId: string
  open: boolean
  handleDialogClose: any
  classes: ClassNameMap
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const EditProfileModal: React.FC<IProps> = ({
  patientId,
  open,
  handleDialogClose,
  classes,
}) => {
  const [value, setValue] = useState(0)
  const [patientData, setPatientData] = useState([] as any)
  const [profileData, setProfileData] = useState([] as any)
  const [loading, setLoading] = useState(true)
  const [validateInputErr, setValidateInputErr] = useState([] as any)
  const [notes, setNotes] = useState('')
  const [isProfileChange, setIsProfileChange] = useState(false)
  const [macId, setMacId] = useState('')
  const [emptyName, setEmptyName] = useState(false)
  const patientService = new PatientService()
  const { currentRole } = useCurrentUser()
  const profileRef = useRef(null) as any
  const { updatePatientData } = useUpdateDataAction(patientId)

  const getPatientData = (pData = [] as any) => {
    let currPatientObj = pData
    if (currPatientObj?.length > 0) {
      let index = currPatientObj.indexOf('assigned_users')
      if (index !== -1)
        currPatientObj.splice(index, 1);
    }
    return currPatientObj
  }

  const saveDisabled = () => {
    let currPatientData = getPatientData(patientData || [])
    let currProfileData = getPatientData(profileData || [])

    return loading ||
      (validateInputErr !== undefined && validateInputErr.length > 0) ||
      (!isProfileChange && notes === '') || (!emptyName && patientData.given_name === '')
      || JSON.stringify(currPatientData) === JSON.stringify(currProfileData)
  }

  const createNote = () => {
    try {
      const entityRef = 'patient'
      const payload = {
        entity_ref: entityRef,
        entity_id: patientId,
        note: notes,
        author_role: currentRole,
      }
      setNotes('')
      setLoading(true)
      patientService
        .postNotes(payload)
        .then((response: any) => {
          setLoading(false)
          // toast.success('Note added successfully!', Helper.bottom_center())
        })
        .catch((error: any) => {
          setLoading(false)
          toast.error(Helper.getErrorText(error), Helper.bottom_center())
        })
    } catch (error: any) {
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    }
  }

  const handleInputChange = (e: any, updatedVal = []) => {
    let name = ''
    let data: string | never[]
    if (e === 'update_assigned_users') {
      setIsProfileChange(true)
      return
    } else
      if (['assigned_users'].includes(e)) {
        name = e
        data = updatedVal
        if (!updatedVal.every((currItem: any) => (/.+@.+\.[A-Za-z]+$/i).test(currItem))) {
          !validateInputErr.includes('assigned_users') &&
            setValidateInputErr([...validateInputErr, 'assigned_users'])
        } else
          setValidateInputErr(validateInputErr.filter((item: any) => item !== 'assigned_users'))
      }
      else {
        name = e.target.name
        data = e.target.value

        const validateVal = (regStr: any, val: any) => {
          if (!regStr.test(data) && data !== '') {
            !validateInputErr.includes(val) &&
              setValidateInputErr([...validateInputErr, val])
          } else updateValidationObj(val)
        }

        const updateValidationObj = (val: any) => {
          setValidateInputErr(validateInputErr.filter((item: any) => item !== val))
        }

        if (name === 'email') validateVal(/.+@.+\.[A-Za-z]+$/i, 'email')
        else if (name === 'phone')
          validateVal(
            /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
            'phone'
          )
        if (['birthdate', 'c_sex'].includes(name)) {
          if (data === '')
            setValidateInputErr([...validateInputErr, name])
          updateValidationObj(name)
        }
      }
    setPatientData({ ...patientData, [name]: data })
    setIsProfileChange(true)
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) =>
    setValue(newValue)

  const replaceNull = (obj: any) => {
    const replacer = (key: any, val: any) =>
      String(val) === 'null' || String(val) === 'undefined' ? '' : val
    return JSON.parse(JSON.stringify(obj, replacer))
  }

  const handleSubmit = (patientDetails: any) => {
    const { username, mrns, ...payload } = patientDetails
    setLoading(true)

    if (JSON.stringify(patientData) === JSON.stringify(profileData)) {
      setLoading(false)
      return
    }
    if (isProfileChange) {
      patientService.putPatientProfileDetails(patientId, { ...payload, }).then((res: any) => {
        setLoading(false)
        handleDialogClose();
        updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
      }).catch((error) => {
        setLoading(false)
        toast.error(Helper.getErrorText(error), Helper.bottom_center())
      })
    }
    if (notes !== '') createNote()
  }

  useEffect(() => {
    setLoading(true)
    patientService
      .getPatientProfileDetails(patientId)
      .then((res: any) => {
        const temp = replaceNull(res.data)
        setEmptyName(temp.given_name === '')
        setPatientData(temp)
        setProfileData(temp)
        setMacId(temp.bpm_mac_address)
        setLoading(false)
      })
      .catch((error) => {
        toast.error(Helper.getErrorText(error), Helper.bottom_center())
        handleDialogClose()
      })
  }, [])

  const deviceActivate = async () => {
    try {
      await patientService.deviceActivation(patientId)
      toast.success('Device Activated', Helper.bottom_center())
      handleDialogClose()
    } catch (error) {
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    }
  }

  return (
    <Draggable ref={profileRef} handle='.edit-profile-dialog'>
      <Dialog
        id={'edit-profile'}
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="Edit Profile"
        hideBackdrop
        disableScrollLock
        disableEscapeKeyDown
        onKeyUp={(e) => {
          if (e.key === 'Enter' && !saveDisabled())
            handleSubmit(patientData)
        }}
        sx={{
          position: "absolute",
          left: '10%',
          top: '14%'
        }}
      >
        <DialogTitle sx={{ padding: '10px 10px 0px 20px' }} classes={{ root: 'edit-profile-dialog' }}>
          <DialogTitleHeaderComponent
            title={'Edit Patient Profile'}
            handleDialogClose={handleDialogClose}
          />
        </DialogTitle>
        <DialogContent className={classes.zeroPadding}>
          <AppBar position="sticky" color="inherit" classes={{ root: 'feedback-title' }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Profile" {...a11yProps(0)} />
              <Tab label="Patient Notes" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <Box sx={{ height: "31rem", overflowY: "scroll", }}>
            {loading && <LinearProgress />}
            <div className={classes.dialogHeader} />
            <TabPanel value={value} index={0}>
              {!loading && (
                <PatientProfileDetailsComponent
                  nameError={!emptyName && patientData.given_name === ''}
                  values={patientData}
                  handleInputChange={handleInputChange}
                  validateInput={validateInputErr}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <NotesComponent entityId={patientId} entityRef="patient" />
            </TabPanel>
          </Box>
        </DialogContent>
        <DialogActions className={classes.cardLabInfo}>
          <Button
            disabled={saveDisabled()}
            onClick={() => handleSubmit(patientData)}
            variant="outlined"
            size="medium"
          >
            <strong>Save</strong>
          </Button>
          <Button
            onClick={() => deviceActivate()}
            disabled={macId ? false : true}
            variant="outlined"
            size="medium"
          >
            <strong>Activate Device</strong>
          </Button>

          <Button
            onClick={handleDialogClose}

            variant="outlined"
            size="medium"
          >
            <strong>Cancel </strong>
          </Button>
        </DialogActions>
      </Dialog>
    </Draggable>
  )
}

export default withStyles(stylesheet)(EditProfileModal)

