import React, { memo, ReactElement, useEffect, useState, useRef } from 'react'
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import moment from 'moment'
import stylesheet from '../detail-container.stylesheet'
import QuestionPSCComponent from './edit-psc.component'
import CardLayoutComponent from '../common/card-layout.component'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import NotesComponent from '../common/notes.component'
import ReferralComponent from '../common/referral-card.component'
import { useReferralAction } from '../../../../utils/referral-hook'
import EmptyCardComponent from '../common/empty-card.component'
import { checkCondition } from '../common/condition-check'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useUpdateDataAction } from '../../../../utils/update-details-hook'
interface IProps {
  input: any
  classes: ClassNameMap
}

const ProgramSpecificConditionsComponent: React.FC<IProps> = ({
  input,
  classes,
}) => {
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const selectedProgram = useSelector((state: RootState) => state.commonData.selectedProgram)
  const [data, setData] = useState([] as any)
  const [selectedNotes, setSelectedNotes] = useState([] as number[])
  const { onSetView, onDataSubmit } = useReferralAction()
  const [expand, setExpand] = useState(input?.isActionItems ? true : false);
  const toggleUnlikelyAcordion = () => setExpand((prev) => !prev);
  const inputEl = useRef(null) as any
  const inputLike = useRef(null) as any
  const { updatePatientData } = useUpdateDataAction(patientDetail.id)

  useEffect(() => {
    let pscData = input?.isActionItems === true
      ? patientDetail?.conditions?.program_conditions?.filter((i: { needs_confirmation: any, is_overridden: any, programs: any }) => i.is_overridden !== true && i.needs_confirmation === true && i.programs?.some((s: string) => s === input?.actionProgram))
      : patientDetail?.conditions?.program_conditions

    const stage = pscData?.map((e: any) => {
      return { ...e, mode: 'view' }
    })
    setData(stage)
  }, [patientDetail])

  const formatConfirmDate = (
    score: string,
    confirm_timestamp: number,
    save_timestamp: number,
    needs_confirmation: boolean,
    is_referred: boolean,
    referred_on: number
  ) => {
    if (is_referred) {
      return moment.unix(referred_on).format('MM/DD/YY')
    } else if (confirm_timestamp) {
      return moment.unix(confirm_timestamp).format('MM/DD/YY')
    } else if (score != null && save_timestamp) {
      return moment.unix(save_timestamp).format('MM/DD/YY')
    } else if (needs_confirmation) {
      return ''
    } else {
      return ''
    }
  }

  const formatRightHeader = (
    score: string,
    is_score_different: boolean = false
  ): string | ReactElement => {
    return (
      <>
        {score ? (
          <>
            {' '}
            <Typography variant="body1">
              {' '}
              <strong>
                {score.charAt(0).toUpperCase() + score.slice(1)}
                {checkCondition(is_score_different, ' *', '')}
              </strong>
            </Typography>{' '}
          </>
        ) : (
          <>{''}</>
        )}
      </>
    )
  }

  const setIcons = () => {
    return input?.isActionItems === true
      ? []
      : [
        { iconType: 'edit', callback: onSetQuestion },
        { iconType: 'description', callback: onNotesClick },
        { iconType: 'more', callback: outer },
      ]
  }

  const onSetQuestion = (_id: number) => {
    setData(
      data.map((el: any) => (el.id === _id ? { ...el, mode: 'question' } : el))
    )
  }

  const onNotesClick = (_id: any) => {
    if (selectedNotes.includes(+_id)) {
      setSelectedNotes([...selectedNotes.filter((f) => f !== +_id)])
    } else {
      setSelectedNotes([...selectedNotes, +_id])
    }
  }

  const outerSubmit = (
    _id: string,
    _mode: string,
    _typeOut: string,
    _score: string,
    _needs_confirmation: boolean,
    _date: any,
    _is_score_different: boolean = false
  ) => {
    updatePatientData({ isMeasurement: true, isConditions: true })
  }

  const outer = (_id: any, _mode: string) => setData(onSetView(_id, _mode, data))

  const onRefSubmit = (_id: any, date: number, is_referred: boolean, referral_id: any) => {
    setData(onDataSubmit(_id, date, is_referred, referral_id, data))
  }

  const getListByScore = (d: any) => {
    return <>
      {d.mode === 'view' &&
        d?.programs?.filter((f: string) => selectedProgram.includes(f)).length > 0 && (
          <>
            <CardLayoutComponent
              id={d.id}
              name={d?.indication}
              leftHeader={d.display_name}
              rightHeader={formatRightHeader(
                d.score,
                d.is_score_different
              )}
              subHeader={''}
              bottomText={formatConfirmDate(
                d.score,
                d.recent_confirmation?.confirmed_on,
                d.date_recorded,
                d.needs_confirmation,
                d.is_referred,
                d.referred_on
              )}
              needs_confirmation={d.needs_confirmation || undefined}
              needs_update={d.needs_update || undefined}
              icons={setIcons()}
              programs={d.programs}
              onRefClick={() => outer(d.id, 'ref')}
              is_referred={d.is_referred}
              is_overridden={d.is_overridden || undefined}
              entityRef="condition_evaluation"
              patientId={patientDetail.id}
              category={'conditions'}
            />
            {selectedNotes.includes(d.id) && (
              <NotesComponent
                entityId={d.id}
                entityRef={'condition_evaluation'}
                cancelBtn={true}
                onCancel={onNotesClick}
              >
                Notes
              </NotesComponent>
            )}
          </>
        )}
      {d.mode === 'question' && input.parent === 'PSC' && (
        <QuestionPSCComponent
          patientId={patientDetail.id}
          input={d}
          name={d.display_name}
          outer={outer}
          outerSubmit={outerSubmit}
        />
      )}
      {d.mode === 'ref' && (
        <ReferralComponent
          entityRef="condition_evaluation"
          input={d}
          patientId={patientDetail.id}
          leftHeader={d.display_name}
          rightHeader={formatRightHeader(d.score, d.is_score_different)}
          programs={d.programs}
          closeRef={() => outer(d.id, 'view')}
          onRefSubmit={onRefSubmit}
        />
      )}
    </>
  }

  const getPSCListByScore = (type: any) => {
    const item = data?.filter((i: any) => type === 'Yes' ? i.score === 'yes' : i.score !== 'yes')
      ?.sort((a: any, b: any) => (a?.display_name).toLowerCase() > (b?.display_name?.toLowerCase()) ? 1 : -1)

    return <Accordion
      square
      defaultExpanded={type === 'Yes' ? true : expand}
      className={classes.patientAccrodionBg}
    >
      <AccordionSummary
        ref={type === 'Yes' ? inputLike : inputEl}
        expandIcon={<ExpandMoreIcon className={classes.patientAccrodionExpandIcon} />}
        onClick={() => type !== 'Yes' ? toggleUnlikelyAcordion() : null}
      >
        <Typography component={'span'} className={classes.subTitle}>{type}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justifyContent={"flex-start"}>
          <Grid item className={classes.fullWidth}>
            {item && item?.map((d: any, key: any) => {
              return <React.Fragment key={`${type}-${key}`}>
                {d !== undefined && getListByScore(d)}
              </React.Fragment>
            })}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion >
  }

  return (
    <Grid container direction="row" sx={{ marginTop: '-5px', paddingLeft: '0.2rem' }}>
      <Grid item className={classes.fullWidth}>
        {data !== undefined && getPSCListByScore('Yes')}
      </Grid>
      <Grid item className={classes.fullWidth}>
        {data !== undefined && getPSCListByScore('No')}
      </Grid>
      {data?.length == 0 && <EmptyCardComponent />}
    </Grid>
  )
}

export default memo(withStyles(stylesheet)(ProgramSpecificConditionsComponent))
