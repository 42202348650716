import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PatientService } from '../services/patient.service';


export interface MetaDataState {
    metadata: any,
    status: any
}

const patientService = new PatientService()

const initialState: MetaDataState = {
    metadata: { selectedStage: '' },
    status: null
}

export const getMetaData = createAsyncThunk(
    'metadata/getMetaData',
    async () => {
        const response = patientService.getMetaData()
        return (await response)
    }
)

export const patientDetailSlice = createSlice({
    name: 'metadata',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMetaData.pending, (state) => {
            state.status = 'loading'
        });
        builder.addCase(getMetaData.fulfilled, (state, { payload }) => {
            state.status = 'success';
            state.metadata = payload.data
        });
        builder.addCase(getMetaData.rejected, (state) => {
            state.status = 'failed'
        });
    }

})

export default patientDetailSlice.reducer