import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PatientService } from '../services/patient.service'

export interface StageChangeState {
  updated_stage: any
  loading: boolean
  success: boolean
}
const patientService = new PatientService()
const initialState: StageChangeState = {
  updated_stage: '',
  loading: false,
  success: false,
}

export const getUpdatedStage = createAsyncThunk(
  'stage-change/getUpdatedStage',
  async (args: any, { rejectWithValue }) => {
    try {
      const response = patientService.getUpdatedStage(args.patientId, args.enrollments)
      return await response
    } catch (err: any) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  }
)
export const stageChangeSlice = createSlice({
  name: 'stage-change',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUpdatedStage.pending, (state) => {
      state.loading = true
      state.success = false
    })
    builder.addCase(getUpdatedStage.fulfilled, (state, { payload }) => {
      state.updated_stage = payload.data.updated_stage
      state.loading = false
      state.success = true
    })
    builder.addCase(getUpdatedStage.rejected, (state, action) => {
      state.loading = false
      state.success = false
    })
  },
})

export default stageChangeSlice.reducer
