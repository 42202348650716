import {
  TextField, Box, Grid, IconButton, Typography, Checkbox, FormControlLabel, FormControl,
  MenuItem, Select, Switch, Theme, InputLabel
} from '@mui/material'
import { ClassNameMap, createStyles, withStyles } from '@mui/styles'
import { useState } from 'react'
import stylesheet from '../detail-container.stylesheet'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { checkCondition } from '../common/condition-check'
import { grey } from "@mui/material/colors";
import moment from "moment";
import Helper from '../../../../utils/helper'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import LooksOneRoundedIcon from '@mui/icons-material/LooksOneRounded';



const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: '#3E3D3B',
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.common.white,
          border: `1px solid ${theme.palette.grey[500]}`,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch)

const CustomSwitch1 = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
    },
    switchBase: {

      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: '#3E3D3B',
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.common.white,
          border: `1px solid ${theme.palette.grey[500]}`,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch)

const CustomSwitch = withStyles({

  switchBase: {
    color: grey[500],
    "&$checked": {

      color: '#3E3D3B'
    },
    "&$checked + $track": {
      backgroundColor: grey[200],
      border: `1px solid ${grey[500]}`,

    }
  },
  checked: {},
  track: {
    backgroundColor: grey[200],
    border: `1px solid ${grey[500]}`,

  }
})(Switch);

interface IProps {
  item: any
  outerSubmit: any
  outer: any
  compKey: string
  classes: ClassNameMap
  reaction: any
  navigateBack: any
  heading: any
}

const ToxicitySubComponent: React.FC<IProps> = ({
  item,
  outerSubmit,
  outer,
  compKey,
  classes,
  navigateBack,
  heading,
}) => {
  const [recordedDate, setRecordedDate] = useState('')
  const [checked, setChecked] = useState(false)
  const [programChecked, setProgramChecked] = useState(false)
  const [dosageChecked, setDosageChecked] = useState(false)
  const [frequencyChecked, setFrequencyChecked] = useState(false)
  const initialValues = {
    med_type: item.med_type,
    med_id: item.med_id,
    // med_reaction_id: "",
    // dose: "",
    // unit: "",
    // frequency: "",
    // date_recorded: "",
    // qualified_restriction: false,
    // program_restriction: false,
  }
  const [values, setValues] = useState(initialValues as any);
  const checkSubmit = checkCondition(values.med_reaction_id !== '' && values.date_recorded !== '', true, false)

  const handleInputChange = (e: any) => {
    let value
    let name = e.target.name;
    value = e.target.value;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const uiResource = useSelector((state: RootState) => state.metaData.metadata)

  const medicationReaction = useSelector((state: RootState) => state.medicationReaction.medicationReaction)

  const onCheck = () => {
    setChecked(!checked)
  }
  return (
    <>
      <Box pb={1} mb={1} className={classes.questionHeaderSection}>
        <Grid container direction="row">
          <Grid item>
            <IconButton
              size='small'
              className={classes.backIcon}
              onClick={() => navigateBack()}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="h6">
              <b>{heading ? 'Stop, monitor and restart lower dose' : 'Substitute and monitor'}</b>
            </Typography>
          </Grid>
        </Grid>
        <Box pb={1} mb={1} mt={1} className={classes.questionHeaderSection}>
          <Typography variant='body1' color='textSecondary'> <small className={classes.questionHeaderSection1}><LooksOneRoundedIcon></LooksOneRoundedIcon></small><strong ><small >Set restriction - Lisinopril 5mg tablet</small></strong>  </Typography>
        </Box>

        <Box pl={1} pt={1}>

          <Grid xs={6}>
            <FormControl
              className={classes.fullWidth}
            >
              <Select
                value={values.med_reaction_id}
                name="med_reaction_id"
                onChange={handleInputChange}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {medicationReaction.map((_key: any) => (
                  <MenuItem className={classes.formControlItemsPadding} value={_key.id}>
                    {_key.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={6}>

            <TextField
              id="date"
              name='date_recorded'
              type="datetime-local"
              onChange={(e: any) => {
                setRecordedDate(e.target.value)
                setValues({
                  ...values,
                  date_recorded: moment(e.target.value).unix(),
                })

              }}
              defaultValue={recordedDate}
              className={classes.datePicker}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyPress={(e) => { e.preventDefault(); return false }}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="standard" className={classes.formControlWidth250}>
              <InputLabel id="demo-simple-select-standard-label">Propagation</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={'full'}
                label="Propagation"
              >
                <MenuItem value={'full'}>Full</MenuItem>

              </Select>
            </FormControl>
          </Grid>

          <Box mb={1} mt={2}>
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  checked={checked}
                  onChange={onCheck}
                  color="default"
                  size="small"
                  name='qualified_restriction'
                  className={classes.checkBoxStyle}
                />
              }
              label={
                <Typography variant="subtitle2">Qualified restriction</Typography>
              }
            />
          </Box>
          <Grid item xs={6} id="left-container">
            <InputLabel id="demo-simple-select-standard-label" shrink={true}> Total daily dosage</InputLabel>

            <Grid container xs={12} direction="row" alignItems="center">
              <Grid item xs={3}>
                <TextField
                  disabled={!dosageChecked}
                  id="standard-basic"
                  size="small"
                  value={values.dose}
                  onChange={handleInputChange}
                  name="dose"
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <FormControl className={classes.formControl}>
                  <Select
                    disabled={!dosageChecked}

                    displayEmpty
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'Without label' }}
                    value={values.unit}
                    name="unit"
                    onChange={handleInputChange}
                  >
                    {Object.keys(uiResource?.MEDICATION_UNITS).map(
                      (_key, index) => (
                        <MenuItem
                          className={classes.medicationUnits}
                          value={_key}
                        >
                          {uiResource?.MEDICATION_UNITS[_key]}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid>
                <CustomSwitch
                  checked={dosageChecked}
                  onChange={() => setDosageChecked(!dosageChecked)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Typography
            color="textSecondary"
            className={classes.tooltip1}
            variant="subtitle2"
          >
            {' '}
          </Typography>
          <InputLabel id="demo-simple-select-standard-label" shrink={true}>Frequency of dosage</InputLabel>
          <Grid container xs={12} direction="row" alignItems="center">
            <Grid item xs={3}>
              <FormControl
                className={classes.formControlWidth165}
              >
                <Select
                  disabled={!frequencyChecked}
                  displayEmpty
                  className={classes.selectEmpty}
                  inputProps={{ 'aria-label': 'Without label' }}
                  label='Frequency of dosage'
                  value={values.frequency}
                  name="frequency"
                  onChange={handleInputChange}
                >
                  {Object.keys(uiResource.MEDICATION_DOSAGE_FREQUENCY).map(
                    (_key, index) => (
                      <MenuItem className={classes.formControlItemsPadding} value={_key}>
                        {uiResource.MEDICATION_DOSAGE_FREQUENCY[_key]}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <CustomSwitch
                checked={frequencyChecked}
                onChange={() => setFrequencyChecked(!frequencyChecked)}
              />
            </Grid>
          </Grid>

          <Grid></Grid>
          <Box pt={2} pb={2}>
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  checked={programChecked}
                  onChange={() => setProgramChecked(!programChecked)}
                  color="default"
                  size="small"
                  className={classes.checkBoxStyle}
                />
              }
              label={
                <Typography variant="subtitle2">
                  {Helper.toCamelCase(item.program)} program specific restriction
                </Typography>
              }
            />
          </Box>
          <Grid xs={6}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="add note here"
              size="small"
              className={classes.fullWidth}
              multiline={true}
              rows="2"
            />
          </Grid>
        </Box>
      </Box>


    </>
  )
}
export default withStyles(stylesheet)(ToxicitySubComponent)
