import {
  Box, Card, CardContent, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableRow,
  Typography, MenuItem, Popover, Tooltip
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import React, { useEffect, useState } from "react";
import stylesheet from "../detail-container.stylesheet";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded'
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined'
import HistoryPTComponent from "./history-pt.component";
import { useSelector } from "react-redux";
import { useDispatchAPIs } from "../../../../utils/use-dispatchApis-hook";
import moment from "moment";
import { RootState } from "../../../../redux/store";
import { checkCondition } from '../common/condition-check'
import NotesComponent from '../common/notes.component'
import DeleteCard from "../common/delete-card-component";
import { PatientService } from "../../../../services/patient.service";
import { toast } from "react-toastify";
import Helper from "../../../../utils/helper";
import { useOverrideAction } from "../../../../utils/override-hook";
import ManualOverrideComponent from "../common/manual-override.component"
import { useCheckConfirmCount } from "../../../../utils/use-checkconfirmationcount-hook";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ContextualHelpComponent from "../common/contextual-help.component";
import RationaleDialogComponent from '../common/rationale-dialog.component';

interface IProps {
  input: any;
  classes: ClassNameMap;
}

const ProgramTargetsComponent: React.FC<IProps> = ({ input, classes }) => {
  const [data, setData] = useState([] as any);
  const [currItem, setCurrItem] = useState({} as any);
  const [currHelpItem, setCurrHelpItem] = useState({} as any);
  const [notesOpen, setNotesOpen] = useState(false)
  const [isAction, setIsAction] = useState('')
  const [syncStatus, setSyncStatus] = useState('')
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail);
  const [, setCount] = useCheckConfirmCount(input.patientId)
  const { multipleProgramCounts } = useDispatchAPIs(patientDetail.id)
  // const _programTargets = useSelector((state: RootState) => state.programTargets.program_targets || [] as any)
  const { onSetOverView, onDataOverSubmit } = useOverrideAction()
  const [cardAnchorEl, setCardAnchorEl] = useState<null | HTMLElement>(null);
  const [targetAnchorEl, setTargetAnchorEl] = useState<null | HTMLElement>(null);
  const [openHelpDialog, setOpenHelpDialog] = useState(false)
  const [openRationale, setOpenRationale] = useState(false)
  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => setCardAnchorEl(event.currentTarget);
  const handleCardClose = () => setCardAnchorEl(null);
  const handleTargetCardClick = (event: React.MouseEvent<HTMLElement>) => setTargetAnchorEl(event.currentTarget);
  const handleTargetCardClose = () => setTargetAnchorEl(null);

  useEffect(() => {
    targetAnchorEl !== null && handleCardClose()
  }, [targetAnchorEl])

  useEffect(() => {
    handleCardClose()
  }, [isAction]);

  useEffect(() => {
    initialise(patientDetail?.measurements?.program_targets);
  }, [patientDetail]);


  const checkSyncStatus = (data: any) => {
    const _temp = data?.filter((f: { program: any; }) => f.program === input.cProgram) || []
    if (_temp?.length && _temp[0].last_sync_date) {
      setSyncStatus(`Sync ${_temp[0].sync_type} on ${moment.unix(_temp[0].last_sync_date).format("MMM DD, YYYY h:mm a")}`)
    }
  }

  const initialise = (data: any) => {
    const targetData = (input?.isActionItems === true)
      ? data?.filter((f: { program: any, needs_update: boolean }) => f.needs_update && f.program === input.actionProgram) || []
      : data?.filter((f: { program: any; }) => f.program === input.cProgram) || []

    setData({
      patientId: patientDetail?.id,
      program_targets: targetData,
      program_targets_mode: "view",
    });
    checkSyncStatus(data)
  };

  const enroll_id = patientDetail?.program_enrollments?.find((f: { program: any; }) => f.program === input.cProgram).enrollment_id

  const handleRationale = () => {
    handleTargetCardClose()
    setOpenRationale(true)
  }

  const handleRationaleClose = () => setOpenRationale(false)

  const onNotesClick = () => {
    setNotesOpen(!notesOpen)
    handleCardClose()
  }

  const onSetMode = (_mode: string, outputData: any[] = [], _syncStatus = '') => {
    setIsAction(_mode)
    if (_mode === 'history' && _syncStatus) {
      setSyncStatus(_syncStatus)
    } else if (_mode === 'override') {
      handleTargetCardClose()
      onSetOverView(currItem?.id, _mode, data)
    }
    setData({ ...data, program_targets_mode: _mode });
    setNotesOpen(false)
  };

  const onOverrideSubmit = (_id: any, date: number, is_overridden: boolean, overridenPrograms: any[]) => {
    handleCardClose()
    const updatedData = data?.program_targets?.map((el: any) => el.id === _id
      ? { ...el, is_overridden: is_overridden, overridden_on: date, overridden_programs: overridenPrograms }
      : el
    )
    setData({ ...data, program_targets: updatedData, program_targets_mode: 'view' });
    setIsAction('view')
    if (is_overridden) {
      setCount(true, false)
      multipleProgramCounts(
        [{
          "previous": true,
          "current": false,
          "programs": overridenPrograms
        }]
      )
    } else {
      setCount(false, true)
      multipleProgramCounts(
        [{
          "previous": false,
          "current": true,
          "programs": overridenPrograms
        }])
    }
  }

  const formatConfirmDate = (confirmed_on: number) => {
    return confirmed_on ? moment.unix(confirmed_on).format("MM/DD/YY") : ''
  };

  const getRowClass = (i: any) => (i + 1) === data?.program_targets.length ? classes.programTargetLastTableCell : classes.programTargetTableCell

  const rightHeaderCal = (d: any) => {
    return (
      <Typography className={classes.descValue} color={checkCondition((d.value === null || d.matches_target), 'inherit', 'red')}>
        {checkCondition(d.value !== null, <strong>{d.value}{" "}</strong>, <> NA {" "}</>)}
      </Typography>
    );
  };

  const subHeaderCal = (d: any) => {
    if (d?.value_range?.min === null && d?.value_range?.max === null)
      return d?.unit ? d.unit : ''
    let vrmin = ''
    let vrmax = ''
    if (d?.value_range?.min !== null) vrmin = d?.value_range?.min
    if (d?.value_range?.max !== null) vrmax = d?.value_range?.max
    return <Typography className={d?.is_overridden ? classes.overrideTitle : ''} color="textSecondary" variant="subtitle2">
      &nbsp;({vrmin}-{vrmax} {d?.unit ? d.unit : ''})</Typography>
  }

  const onHelpClick = () => {
    if (targetAnchorEl !== null) {
      setCurrHelpItem({
        id: currItem?.id || currItem?.name,
        title: currItem?.display_name || currItem?.name
      })
      handleTargetCardClose()
    }
    else
      setCurrHelpItem({ id: `target/${input?.cProgram}`, title: input?.title })

    handleCardClose()
    setOpenHelpDialog(true)
  }

  const closeHelpDialog = () => setOpenHelpDialog(false)

  const renderTarget = () => {
    let targetData = input?.isActionItems === true
      ? data?.program_targets?.filter((i: { needs_update: any, is_overridden: any }) => i.is_overridden !== true && i.needs_update === true)
      : data?.program_targets

    return targetData?.map((d: any, i: number) => (
      <TableRow key={`target-${d?.display_name}-${i}`}
        className={d.is_overridden && d.needs_update
          ? classes.overrideTargetRow
          : d.needs_update
            ? classes.confirmCardBackground
            : classes.updatedTargetRow}
        onClick={(e) => {
          setCurrItem(d);
          handleTargetCardClick(e)
        }}>
        <TableCell className={getRowClass(i)} scope="column">
          <Grid container spacing={0} className={classes.programTargetName}>
            <Grid item>
              {d.is_overridden ? <Tooltip title={`${d?.display_name} overridden`}>
                <Typography variant="body2" className={[classes.desc, classes.overrideTitle].join(' ')}>
                  {d?.display_name}
                </Typography>
              </Tooltip>
                : <Typography variant="body2" className={classes.desc} color={`${d?.is_overridden ? 'red' : 'inherit'}`}>
                  {d?.display_name}
                </Typography>
              }
            </Grid>
            <Grid item className={classes.subHeaderTitle}>
              {subHeaderCal(d)}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={getRowClass(i)} align="right" width="10%">
          <Grid item>
            <Typography align="right" variant="subtitle2" color="textSecondary"
              className={[classes.subHeaderTitle, d?.is_overridden && classes.overrideTitle].join(' ')}
            >
              <small>{formatConfirmDate(d.date_recorded)}</small>
            </Typography>
          </Grid>
        </TableCell>
        <TableCell className={getRowClass(i)} align="right">
          <Grid item>
            <Typography
              className={[classes.descValue, d?.is_overridden && classes.overrideTitle].join(' ')}
              color={(d.value === null || d.matches_target) ? 'inherit' : 'red'}>
              {d.value !== null ? <strong>{d.value}</strong> : <> NA {" "}</>}
            </Typography>
          </Grid>
        </TableCell>
        <TableCell className={getRowClass(i)} align="right">
          <Grid item>
            <Typography
              className={[classes.descValue, d?.is_overridden && classes.overrideTitle].join(' ')}
            >
              <strong>{d.target_value}</strong>
            </Typography>
          </Grid>
        </TableCell>
      </TableRow >
    ))
  }

  return (
    <>
      {checkCondition(data?.program_targets?.length > 0,
        <Grid container spacing={0} direction="column" className={classes.customCard}>
          {((input?.isActionItems && data?.program_targets?.some((e: any) => e.needs_update && e.is_overridden !== true))
            || !input?.isActionItems) &&
            <Grid item className={classes.fullWidth}>
              <Card variant="outlined"
                className={checkCondition(data?.program_targets?.some((e: any) => (e.needs_update && e.is_overridden !== true) && e?.program === input?.cProgram),
                  classes.confirmCardBackground,
                  classes.compactTargetCard)}>
                <CardContent className={classes.programTargetContainer}>
                  <TableContainer onClick={handleCardClick}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={2} className={[classes.programTargetFirstTableCell, classes.programTargetFirstTableInputTitle].join(' ')} scope="row" >
                            <Typography variant="subtitle2" className={classes.programTargetFirstTableInputTitleColor} >
                              {input?.title}
                            </Typography>
                          </TableCell>
                          <TableCell className={[classes.programTargetFirstTableCell, classes.programTargetFirstTableWidth].join(' ')} align="right">
                            <Grid item>
                              <Typography color="textSecondary" variant="subtitle2">
                                <small>&nbsp;Current</small>
                              </Typography>
                            </Grid>
                          </TableCell>
                          <TableCell className={[classes.programTargetFirstTableCell, classes.programTargetTargetHeader].join(' ')} align="right">
                            <Grid item>
                              <Typography color="textSecondary" variant="subtitle2">
                                <small>Target</small>
                              </Typography>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        {renderTarget()}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {input?.isActionItems !== true && <>
                    <Popover
                      id={`target-item-${input?.cProgram}`}
                      open={Boolean(targetAnchorEl)}
                      anchorEl={targetAnchorEl}
                      onClose={handleTargetCardClose}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      transformOrigin={{ vertical: 4, horizontal: 20 }}
                    >
                      <Box className={classes.compactMenuCard}>
                        {(currItem.needs_update || currItem.is_overridden) &&
                          <Tooltip title="Override">
                            <MenuItem className={classes.compactTargetMenuItem} onClick={() => onSetMode('override')}>
                              <DoubleArrowRoundedIcon className={classes.overideIcon} />
                            </MenuItem>
                          </Tooltip>
                        }
                        <Tooltip title="Rationale">
                          <MenuItem className={classes.compactMenuItem} onClick={() => handleRationale()}>
                            <PrivacyTipOutlinedIcon className={classes.overideIcon} />
                          </MenuItem>
                        </Tooltip>
                        {/* <Tooltip title="Help">
                          <MenuItem className={classes.compactMenuItem} onClick={() => onHelpClick()}>
                            <HelpOutlineOutlinedIcon color="disabled" className={classes.compactMenuIcon} />
                          </MenuItem>
                        </Tooltip> */}
                      </Box>
                    </Popover>
                    <Popover
                      id={`target-menu-${input?.cProgram}`}
                      open={Boolean(cardAnchorEl)}
                      anchorEl={cardAnchorEl}
                      onClose={handleCardClose}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      transformOrigin={{ vertical: 4, horizontal: 84 }}
                    >
                      <Box className={classes.compactMenuCard}>
                        {syncStatus &&
                          <MenuItem sx={{ padding: '0.1rem 0.25rem 0.1rem 0.25rem' }}>
                            <Typography color="textSecondary" variant="subtitle2">
                              <small>{syncStatus}</small>
                            </Typography>
                          </MenuItem>
                        }
                        <MenuItem className={classes.compactTargetMenuItem}>
                          <IconButton
                            onClick={() => onSetMode("history")}
                            size='small'
                            className={classes.iconBaseBorder} >
                            <VisibilityOutlinedIcon className={classes.programTargetVisibilityIcon} />
                          </IconButton>
                        </MenuItem>
                        <MenuItem className={classes.compactTargetMenuItem}>
                          <IconButton size='small' className={classes.iconBaseBorder}>
                            <InsertDriveFileOutlinedIcon className={classes.editIcon} onClick={onNotesClick} />
                          </IconButton>
                        </MenuItem>
                        <Tooltip title="Help">
                          <MenuItem className={classes.compactMenuItem} onClick={() => onHelpClick()}>
                            <HelpOutlineOutlinedIcon color="disabled" className={classes.compactMenuIcon} />
                          </MenuItem>
                        </Tooltip>
                      </Box>
                    </Popover>
                  </>
                  }
                </CardContent>
              </Card>
              {checkCondition(notesOpen,
                <Box mt={1}>
                  <NotesComponent
                    entityId={enroll_id}
                    entityRef={'enrollment'}
                    cancelBtn={true}
                    onCancel={onNotesClick}
                  />
                </Box>
              )}
              {currItem && data.program_targets_mode === 'override' && (
                <ManualOverrideComponent
                  entityRef='measurement'
                  input={currItem}
                  leftHeader={currItem?.name}
                  rightHeader={rightHeaderCal(currItem)}
                  subHeader={subHeaderCal(currItem)}
                  programs={[currItem?.program]}
                  closeOverride={() => {
                    handleCardClose()
                    onSetMode('view')
                  }}
                  onOverrideSubmit={onOverrideSubmit}
                />
              )}
            </Grid>
          }
        </Grid>
      )}
      {checkCondition(data.program_targets_mode === "history",
        <HistoryPTComponent input={input} name={"History"} outer={onSetMode} />
      )}
      {openHelpDialog &&
        <ContextualHelpComponent
          open={openHelpDialog}
          handleClose={closeHelpDialog}
          id={currHelpItem?.id}
          title={currHelpItem?.title}
          programs={currItem?.program}
          category={'measurements'}
        />
      }
      {openRationale && (
        <RationaleDialogComponent
          open={openRationale}
          title={currItem?.display_name || currItem?.name}
          category={'measurement'}
          input={{
            program: [currItem?.program] || [],
            name: currItem?.name
          }}
          handleClose={handleRationaleClose}
        />
      )}
    </>
  )
};

export default withStyles(stylesheet)(ProgramTargetsComponent);
