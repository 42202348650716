import React, { useState, useEffect } from 'react'
import {
  Box, Button, ButtonGroup, Card, CircularProgress, Grid, IconButton, MenuItem,
  Paper, Select, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Typography
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ChartComponent from '../common/chart-component'
import MenuIcon from '@mui/icons-material/Menu'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import moment from 'moment'
import { PatientService } from '../../../../services/patient.service'
import { checkCondition } from '../common/condition-check'
import { toast } from 'react-toastify'
import Helper from '../../../../utils/helper'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SyncIcon from '@mui/icons-material/Sync';

interface IProps {
  input: any
  name: string
  outer: any
  classes: ClassNameMap
}

const HistoryPTComponent: React.FC<IProps> = ({
  input,
  name,
  outer,
  classes,
}) => {
  const [historyData, setHistoryData] = useState([] as any)
  const [chart, setChart] = useState(false)
  const [chartList, setChartList] = useState({} as any)
  const [currChart, setCurrChart] = useState(null)
  const [currChartData, setCurrChartData] = useState([] as any)
  const [loading, setLoading] = useState(false)
  const [sortList, setSortList] = useState({ sortBy: 'none', date: 'none', name: 'none' } as any)
  const patientService = new PatientService()

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    let currChartObj = historyData?.map((row: any) => {
      if ([row?.target[0]?.name, row?.target[0]?.tag].includes(currChart)) {
        return {
          date: row?.date,
          display_name: row?.target[0].display_name,
          name: row?.target[0].name,
          tag: row?.target[0]?.tag,
          value: row?.target[0]?.value,
          unit: row?.target[0]?.unit
        }
      }
    }).filter((r: any) => r !== undefined);
    setCurrChartData(sortData(currChartObj))
  }, [currChart])

  const getData = () => {
    setLoading(true)
    patientService
      .getTargetHistory(input.patientId, input.cProgram)
      .then((res: any) => {
        setHistoryData(res.data)
        if (res.data.length !== 0) {
          let historyItem = {}
          res?.data?.map((item: any) => {
            item?.target?.map((t: any) => {
              if (!Object.values(historyItem).includes(t.display_name)) {
                if (Object.keys(historyItem)?.length === 0)
                  setCurrChart(t?.tag ? t?.tag : t?.name)

                historyItem = (t?.tag)
                  ? { ...historyItem, [t.tag]: t.tag_display_name }
                  : { ...historyItem, [t.name]: t.display_name }
              }
            })
          })
          setChartList(historyItem)

        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        toast.error(Helper.getErrorText(error), Helper.bottom_center())
      })
  }

  const sortData = (data: any) => [...data]?.sort((a: any, b: any) => a.date - b.date)

  useEffect(() => {
    if (sortList?.sortBy === 'name') {
      setHistoryData(sortList?.name === 'desc'
        ? [...historyData]?.sort((a: any, b: any) => a?.target[0]?.name < b?.target[0]?.name ? 1 : -1)
        : [...historyData]?.sort((a: any, b: any) => a?.target[0]?.name > b?.target[0]?.name ? 1 : -1)
      )
    } else if (sortList?.sortBy === 'date') {
      setHistoryData(sortList?.date === 'desc'
        ? [...historyData]?.sort((a: any, b: any) => a.date < b.date ? 1 : -1)
        : [...historyData]?.sort((a: any, b: any) => a.date > b.date ? 1 : -1)
      )
    }
  }, [sortList])

  const getSortIcon = (d: any) => {
    if (d === 'desc') {
      return <ArrowDropDownIcon className={classes.programTargetEditIcon} />
    } else if (d === 'asc') {
      return <ArrowDropUpIcon className={classes.programTargetEditIcon} />
    } else {
      return <UnfoldMoreIcon className={classes.programTargetEditIcon} />
    }
  }

  const sortHistroyByType = (type: string) => {
    sortList[type] === 'asc'
      ? setSortList({ ...sortList, sortBy: type, [type]: 'desc' })
      : setSortList({ ...sortList, sortBy: type, [type]: 'asc' })
  }

  const getChartlabels = () => currChartData?.map((row: any) => moment.unix(row.date).format('DD MMM YY')
  ).filter((r: any) => r !== undefined);

  const getChartValues = () => currChartData.map((f: any) => parseInt(f.value))

  const onCloseClick = (mode: string) => outer(mode)

  const onChartClick = () => setChart(!chart)

  const handleChartChange = (e: any) => setCurrChart(e?.target?.value)

  const syncDeviceData = async () => {
    try {
      setLoading(true)
      const tempData = await patientService.syncDeviceData(input.patientId)
      getData()
      setLoading(false)
      if (tempData?.data?.last_sync_date) {
        outer('history', [], `Sync ${tempData?.data?.sync_type} on ${moment.unix(tempData?.data?.last_sync_date).format("MMM DD, YYYY h:mm a")}`)
      }
    } catch (error) {
      setLoading(false)
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    }
  }

  const renderChartHistory = () => {
    return <Select
      id={'chart-history'}
      value={currChart}
      variant='standard'
      onChange={(e) => handleChartChange(e)}
      className={classes.historySelect}
    >
      {Object.keys(chartList)?.map((key: any) => (
        <MenuItem key={`chart-history-${key}`} className={classes.historyMenu} value={key}>{chartList[key]}</MenuItem>
      ))}
    </Select>
  }

  const checkIconsDisabled = (row: any) => row.target.every((t: { input_method: string }) => t.input_method === 'MANUAL')

  let cellWidthObj = { headerDate: '', rowDate: '', headerName: '', headerTag: '', headerValue: '', rowName: '', rowTag: '', rowValue: '', rowIcon: '' }

  if (input.cProgram === 'type_2_diabetes_mellitus') {
    cellWidthObj.headerDate = '28%'
    cellWidthObj.headerName = '22%'
    cellWidthObj.headerTag = '24%'
    cellWidthObj.headerValue = '15%'
    cellWidthObj.rowDate = '25%'
    cellWidthObj.rowName = '20%'
    cellWidthObj.rowTag = '20%'
    cellWidthObj.rowValue = '10%'
    cellWidthObj.rowIcon = '15%'
  } else {
    cellWidthObj.headerDate = '35%'
    cellWidthObj.headerName = '40%'
    cellWidthObj.headerValue = '15%'
    cellWidthObj.rowDate = '35%'
    cellWidthObj.rowName = '30%'
    cellWidthObj.rowValue = '20%'
    cellWidthObj.rowIcon = '15%'
  }

  return (
    <Grid className={classes.customCard}>
      <Card variant="outlined" id="card" >
        <Box mb={0} className={classes.ptHistoryBox}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="history table">
              <TableHead >
                <TableRow>
                  <TableCell style={{ width: cellWidthObj.headerDate }} className={classes.tablePtHistory} scope="row">
                    {chart &&
                      <Typography className={classes.historyDate}>
                        <strong>{`History`}</strong>
                      </Typography>
                    }
                    {!chart && !loading &&
                      <Typography className={classes.historyDate}>
                        {'Date'}
                        <IconButton size="small" onClick={() => sortHistroyByType('date')}>
                          {getSortIcon(sortList.date)}
                        </IconButton>
                        {checkCondition(
                          input.cProgram === 'hypertension',
                          <Button
                            onClick={() => syncDeviceData()}
                            variant="text"
                            size="small"
                            className={classes.expressModeBtn}
                            style={{ padding: '0px', margin: '0px' }}
                          >
                            <SyncIcon className={classes.infoIconFont} /><strong>Sync Data</strong>
                          </Button>
                        )}
                      </Typography>
                    }
                  </TableCell>
                  {checkCondition(
                    !chart,
                    checkCondition(
                      !loading,
                      <React.Fragment key={`target-device-header`}>
                        <TableCell className={classes.tableHeader} align="left" style={{ width: cellWidthObj.headerName, cursor: 'pointer' }}>
                          Name
                          <IconButton size="small" onClick={() => sortHistroyByType('name')}>
                            {getSortIcon(sortList.name)}
                          </IconButton>
                        </TableCell>
                        {(input.cProgram === 'type_2_diabetes_mellitus') &&
                          <TableCell className={classes.tableHeader} align="left" style={{ width: cellWidthObj.headerTag }}>
                            Tag
                          </TableCell>
                        }
                        <TableCell className={classes.tableHeader} align="left" style={{ width: cellWidthObj.headerValue }}>
                          Value
                        </TableCell>
                      </React.Fragment>
                    )
                  )}
                  {chart && historyData.length > 0 &&
                    <TableCell className={classes.tableCellDDHistory} align="right">
                      {renderChartHistory()}
                    </TableCell>
                  }
                  <TableCell className={chart ? classes.tableCellChHistory : classes.tableCellPtHistory} align="right">
                    <IconButton disabled={historyData?.length < 1} size="small" onClick={() => onChartClick()}>
                      {chart ? (<>
                        <MenuIcon />
                      </>
                      ) : (
                        <EqualizerIcon fontSize="small"></EqualizerIcon>
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            {checkCondition(loading,
              <Grid container justifyContent='center'>
                <Box p={1}>
                  <CircularProgress size="2rem" />
                </Box>
              </Grid>,
              checkCondition(historyData.length == 0,
                <Table>
                  <TableCell className={classes.chartBorder} scope="row">
                    <Typography color="textSecondary">
                      Data not available
                    </Typography>
                  </TableCell>
                </Table>,
                checkCondition(chart,
                  <Table>
                    <TableRow className={classes.chartBorder}>
                      {currChartData?.length > 0 &&
                        <ChartComponent
                          type='line'
                          labels={getChartlabels()}
                          xLabels={true}
                          color="#800080"
                          value={input?.program_targets?.find(
                            (v: any) => v?.dependant_kbm?.name === currChart
                          )?.target_value}
                          yValues={getChartValues()}
                          chartLabel={''}
                          height={190}
                        />
                      }
                    </TableRow>
                  </Table>,
                  <Box className={classes.historyTableScroll}>
                    <Table aria-label="simple table">
                      <TableBody>
                        {historyData?.map((row: any, i: any) => (
                          <TableRow key={i} >
                            <TableCell style={{ width: cellWidthObj.rowDate }} className={classes.tablePtHistory} scope="row">
                              <Typography color={'textSecondary'} className={classes.desc}>
                                <small>{moment.unix(row.date).format('MMM DD YYYY hh:mm a')}</small>
                              </Typography>
                            </TableCell>
                            {row?.target.map((t: any, index: any) => (<React.Fragment key={`target-device-${index}`}>
                              <TableCell style={{ width: cellWidthObj.rowName }} align="left"
                                className={[classes.tablePaddingPT, classes.desc].join(" ")}>
                                <small>{t?.display_name || '-'}</small>
                              </TableCell>
                              {(input.cProgram === 'type_2_diabetes_mellitus') &&
                                <TableCell style={{ width: cellWidthObj.rowTag }} align="left"
                                  className={[classes.tablePaddingPT, classes.desc].join(" ")}>
                                  <small>{row.target.find((f: { name: any }) => f.name === t.name)?.tag_display_name || '-'}</small>
                                </TableCell>
                              }
                              <TableCell style={{ width: cellWidthObj.rowValue }} align="right"
                                className={[classes.tablePaddingPT, classes.subTitle].join(" ")}>
                                <strong>{t?.value || '-'}</strong>
                              </TableCell>
                            </React.Fragment>
                            ))}
                            <TableCell style={{ width: cellWidthObj.rowIcon }} className={classes.tableCellIconPtHistory} align="right">
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                )
              )
            )}
          </TableContainer>
          <ButtonGroup
            className={classes.programTargetFooterStyle}
            size="small"
            variant="text"
            aria-label="small outlined button group"
          >
            <Button onClick={() => onCloseClick('view')} className={classes.questionButtonWidth1}>
              <strong>Close</strong>
            </Button>
          </ButtonGroup>
        </Box>
      </Card>
    </Grid>
  )
}

export default withStyles(stylesheet)(HistoryPTComponent)
