import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  ButtonGroup,
  CircularProgress,
  Divider,
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import React, { useState, ReactElement, useEffect } from 'react'
import stylesheet from '../detail-container.stylesheet'
import ColorBubbleComponent from './color-bubble.component'
import { PatientService } from '../../../../services/patient.service'
import { toast } from 'react-toastify'
import Helper from '../../../../utils/helper'


interface IProps {
  entityRef: string
  patientId: string
  input: any
  leftHeader: string
  rightHeader: string | ReactElement
  programs?: string[]
  subHeader?: string | ReactElement
  is_recommended?: boolean
  closeDeleteMed: any
  onDeleteMedicationSubmit: any
  classes: ClassNameMap
}

const DeleteMedComponent: React.FC<IProps> = ({
  patientId,
  input,
  leftHeader,
  rightHeader,
  programs = [],
  subHeader,
  is_recommended,
  closeDeleteMed,
  onDeleteMedicationSubmit,
  classes,
}) => {
  const [loading, setLoading] = useState(false)
  const patientService = new PatientService()

  const onConfirm = (pId: any, recId: any) => {
    setLoading(true)
    patientService.deleteMedication(pId, recId)
      .then((_response: any) => {
        onDeleteMedicationSubmit(recId)
        setLoading(false)
      })
      .catch((err: any) => {
        toast.error(Helper.getErrorText(err), Helper.bottom_center())
      })

  }

  const oncloseDeleteMed = () => {
    closeDeleteMed(input.id)
  }

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        lg={12}
        md={12}
        direction="column"
        zeroMinWidth
      >
        <Box mb={1} className={classes.customCard}>
          <Card variant="outlined" className={classes.fullWidth}>
            <CardContent>
              <Grid
                container
                xs={12}
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
                className={classes.initialFlexwrap}
              >
                <Grid item>
                  <Typography variant="subtitle2">
                    {leftHeader}
                    <ColorBubbleComponent selectedProgramList={programs} />
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={
                      is_recommended
                        ? { lineHeight: '1', color: '#FFFFFF' }
                        : { lineHeight: '1' }
                    }
                    align="right"
                    className={classes.desc}
                  >
                    {rightHeader}
                  </Typography>
                </Grid>
              </Grid>
              {subHeader && (<>
                <Grid
                  container
                  xs={12}
                  direction="row"
                  alignItems="baseline"
                  className={classes.initialFlexwrap}
                >
                  <Grid item className={classes.subHeaderTitle}>
                    <Typography color="textSecondary" variant="subtitle2">
                      {subHeader}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider></Divider>
              </>
              )}
              {loading && (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box p={1}>
                    <CircularProgress size="2rem" />
                  </Box>
                </Grid>
              )}
              {!loading && (
                <Box pb={2} pt={2}>
                  <Typography variant="subtitle2">
                    {'Are you sure you want to delete this medication?'}
                  </Typography>
                </Box>
              )}
            </CardContent>
            <Grid
              xs={12}
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <ButtonGroup
                className={classes.questionButtonStyle}
                size="small"
                variant="text"
                aria-label="small outlined button group"
              >
                <Button onClick={() => onConfirm(patientId, input.id)} className={classes.twoButtonWidth}>
                  <strong>Yes</strong>
                </Button>
                <Button onClick={oncloseDeleteMed} className={classes.twoButtonWidth}>
                  <strong>No</strong>
                </Button>
              </ButtonGroup>
            </Grid>
          </Card>
        </Box>
      </Grid>
    </>
  )
}

export default withStyles(stylesheet)(DeleteMedComponent)
