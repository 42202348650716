import React from 'react';
import { Box } from '@mui/material'
import {
	Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend,
} from 'chart.js';
import { Line } from "react-chartjs-2";
import stylesheet from "../detail-container.stylesheet";
import { withStyles } from '@mui/styles'
import { checkCondition } from './condition-check';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
interface IProps {
	labels: any,
	xLabels: boolean,
	color: string,
	value?: string,
	yValues: any,
	chartLabel: any,
	height: any,
	type: string,
	yMin?: string,
	yMax?: string
}

const ChartComponent: React.FC<IProps> = ({ labels, xLabels, color, value, yValues, chartLabel, height, type, yMin, yMax }) => {

	const annotationType = checkCondition(type === 'line',
		{
			type: 'line',
			mode: 'horizontal',
			scaleID: 'y-axis-0',
			value: value,
			borderColor: '#0b9c31',
		}, {
		type: 'box',
		display: true,
		xScaleID: "x-axis-0",
		yScaleID: "y-axis-0",
		yMin: yMin,
		yMax: yMax,
		backgroundColor: 'rgba(11, 156, 49, 0.20)',
		borderColor: 'rgba(11, 156, 49, 0.20)',
	})

	const data = {
		labels: labels,
		datasets: [
			{
				label: chartLabel,
				borderColor: color,
				borderWidth: 1.5,
				backgroundColor: '#F0F1F2',
				pointBackgroundColor: color,
				data: yValues,
				pointRadius: 2,
				pointHoverRadius: 2,
				fill: true
			},
		]
	}

	const options = {
		responsive: true,
		tooltips: {
			displayColors: false,
		},
		layout: {
			padding: 5
		},
		plugins: {
			legend: {
				display: false
			}
		},
		elements: {
			line: {
				tension: 0.35,
			},
		},
		scales: {
			y: {
				display: true,
				beginAtZero: false,
			},
			x: {
				barPercentage: 0.4,
				display: xLabels,
				fontSize: 9,
				autoSkip: false,
				maxRotation: 90,
				minRotation: 90,
			}
		},
		annotation: {
			annotations: [annotationType],
			drawTime: "afterDatasetsDraw"
		}
	};

	return <Box width='100%' padding='0.5rem' sx={{ overflowX: 'auto' }}>
		<Line data={data} options={options} height={height} />
	</Box>
}

export default withStyles(stylesheet)(ChartComponent);


