import React, { useEffect, useState } from "react";
import { Box, Radio } from '@mui/material'
import { RouteComponentProps } from 'react-router-dom';

interface IProps extends RouteComponentProps {
  question: any,
  uniqueId: string,
  onInChange: Function,
  isAnswerSelected: Function,
  history: any,
  location: any,
  match: any
}

const RadioStage: React.FC<IProps> = ({ question, uniqueId, onInChange, isAnswerSelected }) => {
  const [optionsArray, setOptionsArray] = useState([] as any)

  useEffect(() => {
    if (question.component === 'YesNoRadioQuestion') {
      setOptionsArray(['Yes', 'No'])
    } else if (question.component === 'PHQGADRadioQuestion') {
      setOptionsArray(["0: not at all", "1: several days", "2: more than half the days", "3: nearly every day"])
    } else {
      if (question && question.options) {
        setOptionsArray(question.options)
      }
    }
  }, [])

  return (
    optionsArray && optionsArray.map((option: any) => {
      return (
        <Box
          key={'radio-section' + question.question_text + option}
          onClick={(e: any) => { onInChange(option, question.id, question) }}
        >
          <Radio
            id={'radio-' + uniqueId}
            key={"radio-option-" + uniqueId}
            // color={"primary"}
            checked={isAnswerSelected(question.id, option, question.component)}
          />
          <span>
            {option}
          </span>
        </Box>
      )
    })
  );
}

export default RadioStage;