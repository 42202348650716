import React, { memo } from 'react'
import {
  Typography, Box, FormControlLabel, Checkbox, Grid,
} from '@mui/material'
import stylesheet from '../detail/detail-container.stylesheet'
import { checkCondition } from '../detail/common/condition-check'
import { ClassNameMap, withStyles } from '@mui/styles'

interface IProps {
  input: any
  filterCount: any
  optionChanged: any
  classes: ClassNameMap
}

const FilterProgramChunkComponent: React.FC<IProps> = ({
  input,
  filterCount,
  optionChanged,
  classes
}) => {
  const renderFilterList = () => {
    return input?.map((e: any, i: number) => {
      return <Grid
        key={i}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
        className={classes.filterChunkItems}
      >
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                onChange={() => optionChanged(e.name, e.display_name, e.index)}
                style={{
                  color: e.color || 'default',
                }}
                className={classes.checkBoxStyle}
                checked={e.checked}
                name="checkedA"
                size="small"
              />
            }
            label={
              <Typography component={'span'} variant="body2">
                {checkCondition(e.checked === true, (<strong>{e.display_name}</strong>), e.display_name)}
              </Typography>
            }
          />

        </Grid>
        <Grid item className={classes.whiteSpaceNowrap}>
          <Typography color="textSecondary">
            <small> {(e?.count || filterCount?.[e.value] || filterCount?.[e.name]) ?? '0'}</small>
          </Typography>
        </Grid>
      </Grid>
    })
  }

  return <Box pb={1}>
    {renderFilterList()}
  </Box>
}

export default memo(withStyles(stylesheet)(FilterProgramChunkComponent))
