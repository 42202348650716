import React, { useEffect, useState } from 'react'
import { Box, Input } from '@mui/material'
import { RouteComponentProps } from 'react-router-dom'
import DatePickerComponent from '../../../utils/datepicker.component'

interface IProps extends RouteComponentProps {
  uniqueId: String
  question: any
  onTextBoxChange: Function
  isAnswerSelected: Function
  history: any
  location: any
  match: any
}

const DateStage: React.FC<IProps> = ({
  uniqueId,
  question,
  onTextBoxChange,
  isAnswerSelected,
}) => {
  let [textValue, setTextValue] = useState('')

  useEffect(() => {
    setTextValue(isAnswerSelected(question.id, '', 'InputText'))
  }, [isAnswerSelected])

  return (
    <Box sx={{
      paddingLeft: '0.4rem',
      marginBottom: '0.4rem',
      width: '-webkit-fill-available',
    }}>
      <DatePickerComponent
        label=""
        type="datetime-local"
        variant={'standard'}
        cssClass={''}
        defaultValue={textValue}
        value={textValue}
        onCalendarChange={(e: any) => {
          setTextValue(e.target.value)
          onTextBoxChange(e.target.value, question.id)
        }}
      />
    </Box>
  )
}

export default DateStage
