import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ProgramConditionState {
    program_conditions: any[]
}

const initialState: ProgramConditionState = {
    program_conditions: [],
}

export const programConditionSlice = createSlice({
    name: 'program_conditions',
    initialState,
    reducers: {
        setProgramCondition: (state, action: PayloadAction<any>) => {
            state.program_conditions = action.payload
        }
    }
})

export const { setProgramCondition } = programConditionSlice.actions

export default programConditionSlice.reducer