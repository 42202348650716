import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  Grid,
  CircularProgress,
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import stylesheet from '../detail-container.stylesheet'
import { PatientService } from '../../../../services/patient.service'
import moment from 'moment'
import { useCurrentUser } from '../../../../utils/use-current-user'
import { checkCondition } from './condition-check'
import { toast } from 'react-toastify'
import Helper from '../../../../utils/helper'
import { sanitizedData } from '../../../../utils/sanitize-html'
import NotesList from './notes.list'

interface IProps {
  entityId: any
  entityRef: string
  cancelBtn?: boolean
  onCancel?: any
  classes: ClassNameMap
}

const NotesComponent: React.FC<IProps> = ({
  entityId,
  entityRef,
  cancelBtn,
  onCancel,
  classes,
}) => {
  const patientService = new PatientService()
  const [notes, setNotes] = useState('')
  const [noteErr, setNoteErr] = useState({ error: false, msg: '' })
  const [notesList, setNotesList] = useState([])
  const [loading, setLoading] = useState(false)
  const { validateSanitizeInput } = sanitizedData()
  const { currentRole } = useCurrentUser()

  useEffect(() => {
    getNotesList()
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value)
    setNoteErr({ error: false, msg: '' })
  }

  const sanitize = () => {
    const sanitizeData = validateSanitizeInput(notes)
    setNoteErr({ error: sanitizeData.error, msg: sanitizeData.msg })
  }

  const createNote = () => {
    try {
      const payload = {
        entity_ref: entityRef,
        entity_id: entityId,
        note: notes,
        author_role: currentRole,
      }
      setNotes('')
      setLoading(true)
      patientService
        .postNotes(payload)
        .then((_response: any) => {
          getNotesList()
          setLoading(false)
        })
        .catch((err: any) => {
          setLoading(false)
          console.error(err)
          toast.error(Helper.getErrorText(err), {
            position: 'top-right',
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          })
        })
    } catch (error: any) {
      setLoading(false)
      console.error(error)
    }
  }

  const getNotesList = () => {
    // Need to change once UserMgmt Added
    setLoading(true)
    patientService
      .getNotes('1', '25', entityRef, entityId + '')
      .then((response: any) => {
        setNotesList(response.data.notes)
        setLoading(false)
      })
      .catch((err: any) => {
        console.error(err)
        setLoading(false)
      })
  }

  const noteContent = () => {
    return <>
      <CardContent>
        <TextField
          id="notes-basic"
          label="Start new notes here..."
          value={notes}
          variant="outlined"
          size="small"
          InputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          onChange={handleChange}
          onBlur={sanitize}
          error={noteErr.error}
          helperText={noteErr.msg}
          multiline={true}
          rows="3"
          className={classes.fullWidth}
        />
        <Box display="flex" justifyContent="flex-end" mt={1}>
          {checkCondition(cancelBtn,
            <Button
              size="small"
              onClick={() => onCancel(entityId)}
              className={classes.corfirmCancelButtonStyle}
              data-testid='cancel-button'
            >
              Cancel
            </Button>
          )}
          <Button
            onClick={createNote}
            disabled={notes.length < 1 || noteErr.error}
            size="small"
            className={classes.corfirmButtonStyle}
            data-testid='add-button'
          >
            Add
          </Button>
        </Box>
      </CardContent>
      {checkCondition(
        loading,
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box p={1}>
            <CircularProgress size="2rem" />
          </Box>
        </Grid>
      )}
      <Box style={{ maxHeight: '200px', overflow: 'auto' }}>
        <Box pl={2} pr={2} pb={1}>
          <NotesList notes={notesList} />
        </Box>
      </Box>
    </>
  }

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        lg={12}
        md={12}
        direction="column"
        zeroMinWidth
        data-testid='notes-testId'
      >
        <Box mb={1} className={classes.customCard}>
          {checkCondition(cancelBtn,
            <Card
              variant="outlined"
              className={classes.fullWidth}
            >
              {noteContent()}
            </Card>,
            noteContent()
          )}
        </Box>
      </Grid>
    </>
  )
}

export default withStyles(stylesheet)(NotesComponent)
