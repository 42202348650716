import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ProgramChecksState {
  icd10_codes: any[]
}

const initialState: ProgramChecksState = {
  icd10_codes: [],
}

export const calculatedICDSlice = createSlice({
  name: 'icd10_codes',
  initialState,
  reducers: {
    setCalculatedICDSlice: (state, action: PayloadAction<any>) => {
      state.icd10_codes = action.payload
    },
  },
})

export const { setCalculatedICDSlice } = calculatedICDSlice.actions

export default calculatedICDSlice.reducer
