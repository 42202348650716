import {
    Grid, Button, Typography, Box, TextField, FormControl, Select, MenuItem
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import stylesheet from '../../detail/detail-container.stylesheet'

interface IProps {
    item: any
    compKey: string
    outerSubmit: any
    classes: ClassNameMap
}

const TakingStatusComponent: React.FC<IProps> = ({ item, compKey, outerSubmit, classes }) => {
    const metaData = useSelector(
        (state: RootState) => state.metaData.metadata
    )
    const [status, setStatus] = useState(item.intake_regularity);
    const [route, setRoute] = useState(item.c_schedule.route);
    const [dose, setDose] = useState(item.c_schedule.dose);
    const [unit, setUnit] = useState(item.c_schedule.unit);
    const [frequency, setFrequency] = useState(item.c_schedule.frequency);
    const onBtnClick = (_param: string) => {
        setStatus(metaData.INTAKE_REGULARITIES[_param])
        outerSubmit(compKey, _param)
    }

    const handleInputChange = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        outerSubmit(name, value);
        if (name === 'route') {
            setRoute(value)
        }
        else if (name === 'dose') {
            setDose(value)
        }
        else if (name === 'unit') {
            setUnit(value)
        }
        else if (name === 'frequency') {
            setFrequency(value)
        }
    };

    return (
        <Box className={classes.subModalBackground} p={1}>
            <Grid spacing={1} container>
                <Grid item sm={12}>
                    <Typography variant='body2'><strong>
                        {`Let us know if you are taking it, not taking it or taking it differently.`}</strong></Typography>
                </Grid>
                <Grid item >
                    <Button
                        variant="outlined"
                        className={status === 'taking' ? classes.dialogBtnActionSelected : classes.dialogBtnAction}
                        onClick={() => onBtnClick('TAKING')}
                        size="small">
                        {`Taking`}
                    </Button>
                </Grid>
                <Grid item >
                    <Button
                        variant="outlined"
                        className={status === 'not taking' ? classes.dialogBtnActionSelected : classes.dialogBtnAction}
                        onClick={() => onBtnClick('NOT_TAKING')}
                        size="small">
                        {`Not Taking`}
                    </Button>
                </Grid>
                <Grid item >
                    <Button
                        variant="outlined" disabled={item.source_type === 'reported' ? true : false}
                        className={status === 'taking differently' ? classes.dialogBtnActionSelected : classes.dialogBtnAction}
                        onClick={() => onBtnClick('TAKING_DIFFERENTLY')}
                        size="small">
                        {`Taking Differently`}
                    </Button>
                </Grid>

            </Grid >
            <Grid item className={classes.totalDailyDose}>
                {(status === 'taking differently') &&
                    <>
                        <Grid container xs={12} direction="row" alignItems="center">
                            <Grid item sm={4}>
                                <Typography variant="body2" color="textSecondary">
                                    {`Strength`}</Typography>
                                <TextField className={classes.doseTextFieldPadding}
                                    id="standard-basic"
                                    size="small"
                                    value={dose}
                                    onChange={handleInputChange}
                                    name="dose" />
                                <FormControl className={classes.formControl}>
                                    <Select
                                        displayEmpty
                                        className={classes.selectEmpty}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        value={unit}
                                        name="unit"
                                        onChange={handleInputChange}>
                                        {Object.keys(metaData.MEDICATION_UNITS).map(
                                            (_key, index) => (
                                                <MenuItem className={classes.medicationUnits} value={_key}>
                                                    {metaData.MEDICATION_UNITS[_key]}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={3}>
                                <Typography variant="body2" color="textSecondary">
                                    {`Route`}</Typography>
                                <FormControl
                                    className={classes.formControlWidth150}>
                                    <Select
                                        value={route}
                                        name="route"
                                        onChange={handleInputChange}
                                        displayEmpty
                                        className={classes.selectEmpty}
                                        inputProps={{ 'aria-label': 'Without label' }}>
                                        {Object.keys(metaData.MEDICATION_INTAKE_ROUTES).map(
                                            (_key, index) => (
                                                <MenuItem className={classes.formControlItemsPadding} value={_key}>
                                                    {metaData.MEDICATION_INTAKE_ROUTES[_key]}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container xs={12} direction="row" alignItems="center">
                            <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary">
                                    Frequency of dosage
                                </Typography>
                                <FormControl
                                    className={classes.formControlWidth150}>
                                    <Select
                                        displayEmpty
                                        className={classes.selectEmpty}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        value={frequency}
                                        name="frequency"
                                        onChange={handleInputChange}>
                                        {Object.keys(metaData.MEDICATION_DOSAGE_FREQUENCY).map(
                                            (_key, index) => (
                                                <MenuItem className={classes.formControlItemsPadding} value={_key}>
                                                    {metaData.MEDICATION_DOSAGE_FREQUENCY[_key]}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </>}
            </Grid>
        </Box>)
}

export default withStyles(stylesheet)(TakingStatusComponent)