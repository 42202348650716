import React, { useState, useRef, useEffect } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useSelector } from 'react-redux'
import { ClassNameMap, withStyles } from '@mui/styles'
import Draggable from "react-draggable"
import {
  Accordion, AccordionDetails, AccordionSummary, AppBar, Box, Dialog, DialogContent, DialogTitle,
  Grid, LinearProgress, List, ListItem, Tab, Tabs, Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify'
import stylesheet from '../detail-container.stylesheet'
import { PatientService } from '../../../../services/patient.service'
import Helper from '../../../../utils/helper'
import { RootState } from '../../../../redux/store'
import EmptyCardComponent from './empty-card.component'
import ColorBubbleComponent from './color-bubble.component'

interface IProps {
  open: boolean,
  title: string,
  input: any,
  category: string,
  handleClose: any,
  classes: ClassNameMap
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (<div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <>{children}</>}
  </div>
  )
}

const RationaleDialogComponent: React.FC<IProps> = ({ open, title, input, category, handleClose, classes }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({} as any)
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const patientService = new PatientService()
  const rationaleRef = useRef(null) as any
  const [configItem] = useState({
    focusTabIndex: 0,
    tabs: [
      { title: 'Medications', index: 0, comp: '', props: {} },
      { title: 'Measurements', index: 1, comp: '', props: {} },
      { title: 'Conditions', index: 2, comp: '', props: {} },
    ]
  })
  const [value, setValue] = useState(configItem?.focusTabIndex || 0 as number)
  const [currPrg, setCurrPrg] = useState(0 as number)

  useEffect(() => {
    setIsLoading(true)
    if (input?.program[currPrg] !== '' && input?.program[currPrg] !== undefined) {
      if (category === 'condition evaluation') {
        patientService.getPSCRationale(input?.program[currPrg], input.name).then((res: any) => {
          setIsLoading(false)
          setData(res?.data)
        }).catch((error: any) => {
          setIsLoading(false)
          toast.error(Helper.getErrorText(error), Helper.bottom_center())
        })
      } else {
        patientService.getMeasurementRationale(input?.program[currPrg], input.name).then((res: any) => {
          setIsLoading(false)
          setData(res?.data)
        }).catch((error: any) => {
          setIsLoading(false)
          toast.error(Helper.getErrorText(error), Helper.bottom_center())
        })
      }
    }
  }, [currPrg])

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => setValue(newValue)

  const handlePrgChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(0)
    setCurrPrg(newValue)
  }

  const a11yProps = (index: number) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  const getListItem = (recObj: any) => {
    return <List className={classes.zeroPadding}>
      {recObj
        ?.sort((a: any, b: any) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)
        ?.map((key: any) => (
          <React.Fragment key={`list-${key}`}>
            <Typography variant="body2" className={classes.desc}>{key}</Typography>
          </React.Fragment>
        ))}
    </List>
  }

  const getList = (title: any, criObj: any) => {
    return <Grid container spacing={0.1} direction="column">
      <Grid item className={classes.fullWidth}>
        <Typography className={classes.subHeaderTitle} variant="body2">{title}</Typography>
      </Grid>
      <Grid item className={classes.evalSubBox}>{getListItem(criObj)}</Grid>
    </Grid>
  }

  const getCriteria = (title: any, criObj: any) => {
    return <Grid container spacing={0.2} direction="row">
      <Grid item>
        <Typography variant="body2" pr={0.5} className={classes.evalBoxLabel} color="textSecondary">{title}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" className={classes.evalBoxLabel}>{criObj?.join(', ')}</Typography>
      </Grid>
    </Grid>
  }
  const renderMedGroup = (key: any, index: any, item: any) => {
    return <React.Fragment key={`medication-${key}`}>
      <Accordion square className={classes.zeroTopPadding} sx={{ backgroundColor: 'transparent !important' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.zeroPadding} id={`medication-${key}`}>
          <Typography variant="subtitle2" className={classes.subTitle}>{key}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.zeroTopPadding}>
          <Grid container spacing={0.5} sx={{ padding: '0rem 1rem 0.2rem 0.8rem !important' }}>
            {Object.keys(item)
              ?.sort((a: any, b: any) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)
              ?.map((i: any, index: any) => (
                <Grid item key={`prg-${index}`} className={classes.calculatorCard}>
                  {(item[i]['inclusion criteria']?.length > 0 ||
                    item[i]['exclusion criteria']?.length > 0 ||
                    item[i]['schedule conditions']?.length > 0
                  ) &&
                    <Grid container spacing={0.2} direction="row" sx={{ width: 'max-content !important', overflow: 'auto' }}>
                      <Grid item>
                        <Typography variant="subtitle2" pr={0.5} className={classes.subHeaderTitle}>{i}</Typography>
                      </Grid>
                      {item[i]['exclusion criteria']?.length > 0 &&
                        <Grid item>{getCriteria('exclusion criteria', item[i]['exclusion criteria'])} </Grid>
                      }
                      {item[i]['inclusion criteria']?.length > 0 &&
                        <Grid item>{getCriteria('inclusion criteria', item[i]['inclusion criteria'])} </Grid>
                      }
                      {item[i]['schedule conditions']?.length > 0 &&
                        <Grid item>{getCriteria('schedule conditions', item[i]['schedule conditions'])} </Grid>
                      }
                    </Grid>
                  }
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  }

  const renderMeasurements = (key: any, item: any) => {
    return <React.Fragment key={`measurements-${key}`}>
      <Accordion square className={classes.zeroTopPadding} sx={{ backgroundColor: 'transparent !important' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.zeroPadding} id={`measurements-${key}`}>
          <Typography variant="subtitle2" className={classes.subTitle}>{key}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box pl={0.6}> {getList('', item)}</Box>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  }

  const renderConditions = (key: any, item: any) => {
    return <React.Fragment key={`condition-${key}`}>
      <Accordion square className={classes.zeroTopPadding} sx={{ backgroundColor: 'transparent !important' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.zeroPadding} id={`condition-${key}`}>
          <Typography variant="subtitle2" className={classes.subTitle}>{key}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box pl={0.6}> {getList('', item)}</Box>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  }

  const renderData = (title: string) => {
    if (isLoading || Object.keys(data)?.length < 1)
      return <Box pr={'1.5rem'}><EmptyCardComponent /></Box>

    if (title === 'Medications') {
      const medData = data['medications'] || {}
      return <Box className={classes.zeroTopPadding}>
        {medData && Object.keys(medData)?.length > 0
          ? <>
            {Object.keys(medData)
              ?.sort((a: any, b: any) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)
              ?.map((currItem: any, indx: any) => (
                renderMedGroup(currItem, indx, medData[currItem])
              ))}
          </>
          : <Box pr={'1.5rem'}><EmptyCardComponent /></Box>
        }
      </Box>
    }
    else if (title === 'Measurements') {
      const meaData = data['measurements'] || {}
      return <Box className={classes.zeroTopPadding}>
        {meaData && Object.keys(meaData)?.length > 0
          ? <>
            {Object.keys(meaData)
              ?.sort((a: any, b: any) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)
              ?.map((currItem: any, indx: any) => (
                renderMeasurements(currItem, meaData[currItem])
              ))}
          </>
          : <Box pr={'1.5rem'}><EmptyCardComponent /></Box>
        }
      </Box>
    }
    else if (title === 'Conditions') {
      const conData = data['conditions'] || {}
      return <Box className={classes.zeroTopPadding}>
        {conData
          ? <>
            {Object.keys(conData)
              ?.sort((a: any, b: any) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)
              ?.map((currItem: any, i: any) => (
                renderConditions(currItem, conData[currItem])
              ))}
          </>
          : <Box pr={'1.5rem'}><EmptyCardComponent /></Box>
        }
      </Box>
    }
  }

  const getProgramName = (prg: string) => patientDetail?.program_enrollments
    ?.filter((m: { program: any }) => m.program === prg)?.[0]?.program_display_name || prg

  return <>
    {open &&
      <Draggable ref={rationaleRef} defaultPosition={{ x: 80, y: 0 }} handle='.rationale-dialog'>
        <Dialog
          id={`rationale-program-${currPrg}`}
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={handleClose}
          hideBackdrop
          disableScrollLock
          sx={{
            position: "absolute",
            left: '15rem',
            top: '18%'
          }}
        >
          <DialogTitle className={classes.dialogTitlePadding} classes={{ root: 'rationale-dialog' }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="baseline" className={classes.initialFlexwrap} >
              <Grid item xs={8} style={{ paddingLeft: '0.5rem' }}>
                <Typography color="textSecondary"><small>{`Rationale / ${category}`}</small></Typography>
                <Typography variant="h6">
                  <strong>{title}</strong><ColorBubbleComponent selectedProgramList={[input?.program[currPrg]]} />
                </Typography>
              </Grid>
              <Grid item><CloseIcon className={classes.closeIcon} onClick={handleClose} /></Grid>
            </Grid>
          </DialogTitle>
          <AppBar position="static" color="transparent" sx={{ boxShadow: 'none' }}>
            <Tabs value={currPrg} onChange={handlePrgChange} variant="scrollable">
              {input?.program?.map((c: any, index: any) => (
                <Tab key={`prg-${title}`} label={getProgramName(c)}  {...a11yProps(index)} />
              ))}
            </Tabs>
          </AppBar>
          <Box className={classes.rationaleDialogHeader} />
          {isLoading && <LinearProgress />}
          <DialogContent className={classes.dialogContentStyle}>
            {!isLoading &&
              <Box sx={{ margin: '0rem 0.4rem' }}>
                <Box className={classes.rationaleDialogHeader} />
                <AppBar position="static" sx={{ backgroundColor: '#f8f8f8' }}>
                  <Tabs value={value} onChange={handleChange} variant="standard">
                    {configItem?.tabs?.map((c: any) => (
                      <Tab
                        key={`config-${c.title}`}
                        label={c.title}
                        {...a11yProps(c.index)}
                        disabled={false}
                      />
                    ))}
                  </Tabs>
                </AppBar>
              </Box>
            }
            <Box className={classes.root}>
              <Grid className={classes.linearProgressContainer} sx={{ marginBottom: '1rem' }}>
                <Box className={classes.rationaleDialogHeaderBox}>
                  <Box m={'1rem 0.4rem'}>
                    {!isLoading && configItem?.tabs?.map((c: any, i: any) => (
                      <Box pl={2} pr={2} key={`compset-${i}`}>
                        <TabPanel value={value} index={c.index}>
                          {renderData(c.title)}
                        </TabPanel>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
      </Draggable>
    }
  </>
}

export default withStyles(stylesheet)(RationaleDialogComponent)