import React, { memo, ReactElement, useEffect, useRef, useState } from 'react'
import { Box, Button, Grid, Tooltip, Typography, Accordion, AccordionDetails, AccordionSummary, Stack } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import stylesheet from './detail-container.stylesheet'
import ProgramTargetsComponent from './measurments/program-targets.component'
import OtherMedicationsComponent from './medications/other-medications.component'
import ProgramDataIntakeComponent from './measurments/program-data-intake.component'
import ProgramSpecificConditionsComponent from './conditions/program-specific-conditions.component'
import ProgramMedicationsComponent from './medications/program-medications.component'
import ProgramMonitorsComponent from './medications/program-monitors.component'
import ProgramLifestylesComponent from './medications/program-lifestyles.component'
import OtherMeasurmentComponent from './measurments/other-measurment.component'
import CalculatorsComponent from './measurments/calculators.component'
import PatientMedIntolrences from './medications/med-intolerance.component'
import OtherConditionsComponent from './conditions/other-conditions.component'
import ICD10CodesComponent from './conditions/ICD10-codes.component'
import ClinicalScoresComponent from './measurments/calculated-values.component'
import ProgramProblemsComponent from './medications/program-problems.component'
import { currentStatus } from '../../../redux/add-control-slice'
import { RootState, useAppThunkDispatch } from '../../../redux/store'
import ColorBubbleComponent from './common/color-bubble.component'
import { checkCondition } from '../detail/common/condition-check'
import ErrorBoundary from '../../homepage/error-boundary.component'
import { PatientService } from '../../../services/patient.service'
import Helper from '../../../utils/helper'
import AddProgramDialogComponent from './info-header/add-program.component'
import MedReconcilationComponent from './medications/imported-med.component'
import moment from 'moment'
import MedReconciliationDialogComponent from './info-header/med-reconciliation-dialog.component'
import CategoryQuesionnariesComponent from '../../questionnaires/category-quesionnaries.component'
import ProgramToxicitiesComponent from './medications/program-toxicities.component';
import { useUpdateDataAction } from '../../../utils/update-details-hook';

interface IProps {
  patientInfo: any
  parent: string
  headerName: string | ReactElement
  cProgram?: string
  classes: ClassNameMap
}

const DetailCardComponent: React.FC<IProps> = ({
  patientInfo,
  parent,
  headerName,
  cProgram,
  classes,
}) => {
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const selectedProgram = useSelector((state: RootState) => state.commonData.selectedProgram)
  const program_enrollments = useSelector((state: RootState) => state.patientDetail.patientDetail?.program_enrollments || [])
  const _calculators = useSelector((state: RootState) => state.programCalculators.program_calculators);
  const [diagnosisData, setDiagnosisData] = useState([] as any)
  const [isAddProblem, setIsAddProblem] = useState(false)
  const [problemList, setProblemList] = useState([] as any)
  const [openPrgMed, setOpenPrgMed] = useState(false)
  const [openQuestionnarie, setOpenQuestionnarie] = useState(false)
  const dispatch = useAppThunkDispatch()
  const patientService = new PatientService()
  const inputEl = useRef(null) as any;
  const { updatePatientData } = useUpdateDataAction(patientDetail.id)

  useEffect(() => {
    setDiagnosisData(_calculators?.filter((i: any) => i.category === 'diagnosis') || [])
  }, [_calculators])

  const renderSwitch = (param: string) => {
    let component
    switch (param) {
      case 'ME-PDI':
        component = (
          <ProgramDataIntakeComponent
            input={{
              patientId: patientInfo?.id,
              program_checks: patientInfo?.measurements?.program_checks,
              isActionItems: patientInfo?.isActionItems,
              isOverrideItems: patientInfo?.isOverrideItems,
              actionProgram: patientInfo?.actionProgram
            }}
          />
        )
        break
      case 'ME-CS':
        component = <ClinicalScoresComponent input={{ parent: 'CS' }} />
        break
      case 'ME-CAL':
        component = (
          <CalculatorsComponent
            input={{
              patientId: patientInfo?.id,
              calculators: patientInfo?.measurements?.calculators,
              isActionItems: patientInfo?.isActionItems,
              isOverrideItems: patientInfo?.isOverrideItems,
              actionProgram: patientInfo?.actionProgram
            }}
          />
        )
        break
      case 'ME-OTHR':
        component = (
          <OtherMeasurmentComponent
            input={{
              patientId: patientInfo?.id,
              others: patientInfo?.measurements?.others,
              isActionItems: patientInfo?.isActionItems,
              isOverrideItems: patientInfo?.isOverrideItems,
            }}
          />
        )
        break
      case 'MD-PRMD':
        component = <ProgramMedicationsComponent
          patientInfo={{
            ...patientInfo,
            isActionItems: patientInfo?.isActionItems,
            isOverrideItems: patientInfo?.isOverrideItems,
            actionProgram: patientInfo?.actionProgram
          }} />
        break
      case 'MD-TOXEVAL':
        component = <ProgramToxicitiesComponent
          input={{
            isActionItems: patientInfo?.isActionItems,
            isOverrideItems: patientInfo?.isOverrideItems,
            actionProgram: patientInfo?.actionProgram
          }} />
        break
      case 'MD-LS':
        component = <ProgramLifestylesComponent input={{
          parent: 'MD',
          isActionItems: patientInfo?.isActionItems,
          isOverrideItems: patientInfo?.isOverrideItems,
          actionProgram: patientInfo?.actionProgram
        }} />
        break
      case 'MD-PRMT':
        component = <ProgramMonitorsComponent input={{
          parent: 'MD',
          isActionItems: patientInfo?.isActionItems,
          isOverrideItems: patientInfo?.isOverrideItems,
          actionProgram: patientInfo?.actionProgram
        }} />
        break
      case 'MD-IMMD':
        component = <OtherMedicationsComponent patientInfo={patientInfo} currentProgram='' />
        break;
      case 'MD-RECON':
        component = <MedReconcilationComponent
          patientInfo={{
            ...patientInfo,
            isActionItems: patientInfo?.isActionItems,
            isOverrideItems: patientInfo?.isOverrideItems,
            actionProgram: patientInfo?.actionProgram
          }}
        />
        break
      case 'CO-PRB':
        component = <ProgramProblemsComponent input={{ parent: 'PRB', patientInfo: { patientInfo } }} />
        break
      case 'CO-DIA':
        component = (
          <CalculatorsComponent
            input={{
              patientId: patientInfo?.id,
              calculators: patientInfo?.measurements?.calculators,
              type: 'diagnosis',
              isActionItems: patientInfo?.isActionItems,
              isOverrideItems: patientInfo?.isOverrideItems,
              actionProgram: patientInfo?.actionProgram
            }}
          />
        )
        break
      case 'CO-PSC':
        component = <ProgramSpecificConditionsComponent
          input={{
            parent: 'PSC',
            isActionItems: patientInfo?.isActionItems,
            isOverrideItems: patientInfo?.isOverrideItems,
            actionProgram: patientInfo?.actionProgram
          }} />
        break
      // case 'CO-IMC':
      //     return <ProgramSpecificConditionsComponent input={{ patientId: patientInfo?.id, parent: 'IC', program_conditions: patientInfo?.conditions?.patient_imported_conditions }}></ProgramSpecificConditionsComponent>;
      case 'MD-PRM':
        component = <PatientMedIntolrences patientInfo={patientInfo} classes={classes} />
        break
      // case 'MD-PRM':
      //     return <PatientMedIntolrences patientInfo={patientInfo} classes={classes} ></PatientMedIntolrences>;
      case 'CO-OC':
        component = <OtherConditionsComponent input={{ parent: 'OC' }} />
        break
      case 'CO-ICD10':
        component = <ICD10CodesComponent input={{ parent: 'ICD10' }} />
        break

      default:
        component = <Typography component={'span'} pl={2} className={classes.desc}>Not Available</Typography>
        break
    }
    return component
  }

  const handleQuestionnarielose = () => setOpenQuestionnarie(false)

  const createHeader = (_headerName: any) => {
    let output
    switch (_headerName) {
      case 'Other Conditions':
        output = subHeaderFormat(_headerName, 'Add New')
        break
      case 'Other Medications':
        output = subHeaderFormat(_headerName, 'Add New')
        break
      case 'Medication Restrictions':
        output = subHeaderFormat(_headerName, 'Add New')
        break
      case 'Relevant Comorbidities':
        output = subHeaderFormat(_headerName, 'Recalculate')
        break
      case 'ICD10 Codes':
        output = subHeaderFormat(_headerName, 'Recalculate')
        break
      case 'Problems for evaluation':
        output = subHeaderFormat(_headerName, 'Add New')
        break
      case 'Imported':
        output = importedMedHeader(_headerName, '')
        break
      case 'Diagnosis and Coding Assessments':
        output = subHeaderFormat(_headerName, '')
        break
      default:
        output = _headerName
        break
    }
    return output
  }

  const onAddOrManageClick = (event: any) => {
    if (inputEl.current && inputEl.current.getAttribute('aria-expanded') == 'true') {
      event.stopPropagation()
      event.preventDefault()
    }
    let temp = {}
    if (parent === 'CO-OC' || parent === 'MD-IMMD' || parent === 'MD-PRM' || parent == 'MD-PRMT' || parent == 'CO-ICD10' || parent == 'MD-LS') {
      temp = { key: parent, value: true }
      dispatch(currentStatus(temp))
    }
  }

  const handleAddProblem = (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    patientService.getProblemsList().then((response: any) => {
      let prbData = Object.keys(response?.data)?.map((i: any) => {
        const isPrbExists = patientDetail?.conditions?.problems?.filter((a: any) => a.name === i)
        return { name: i, display_name: response?.data[i], checked: isPrbExists?.length > 0 ? true : false }
      }) || []
      setProblemList([...prbData]?.sort((a: any, b: any) => a.display_name > b.display_name ? 1 : -1))
    }).catch((error) => toast.error(Helper.getErrorText(error), Helper.bottom_center())
    )
    setIsAddProblem(true)
  }

  const handlePrgDialogClose = () => setIsAddProblem(false)

  const handleMedDialogClose = (data: any) => {
    setOpenPrgMed(false)
    if (data?.isReconcile)
      updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
  }

  const handlePrgDialogSubmit = (data: any) => {
    handlePrgDialogClose()
    data && patientService.createProblem(patientDetail.id, { 'problem_name': data })
      .then((_res: any) => {
        updatePatientData({ isConditions: true })
      }).catch((error: any) => toast.error(Helper.getErrorText(error), Helper.bottom_center()))
  }

  const onRefreshPSCScore = (event: any, isICD = false) => {
    getCalculatedPSC(isICD)
    event.stopPropagation()
    event.preventDefault()
  }

  const getCalculatedPSC = async (isICD: boolean) => {
    try {
      if (isICD) {
        const icdData = await patientService.recalculateICD10(patientDetail.id)
        updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
      } else {
        const pscData = await patientService.getCalculatedPSC(patientDetail.id)
        updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
      }
    } catch (error) {
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    }
  }

  const subHeaderFormat = (_headerName: string, _rightLabel: string) => {
    return (
      <Grid container
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingRight: ['Other Medications', 'Medication Restrictions'].includes(_headerName) ? '7px' : 'inherit'
        }}>
        <Typography className={classes.subHeadername} variant="h6">
          {`${_headerName}`}
        </Typography>
        {!patientInfo?.actionProgram &&
          <Button
            size='small'
            className={(isAddProblem && _headerName === 'Problems for evaluation')
              ? classes.activeBtn
              : classes.expressModeBtn}
            sx={{
              paddingRight:
                _headerName === 'Diagnosis and Coding Assessments'
                  ? '0rem'
                  : _headerName === 'Relevant Comorbidities'
                    ? '0.7rem'
                    : '0.5rem'
            }}
            onClick={(e) => (_headerName === 'Relevant Comorbidities' || _headerName === 'ICD10 Codes')
              ? onRefreshPSCScore(e, _headerName === 'ICD10 Codes' ? true : false)
              : _headerName === 'Problems for evaluation'
                ? handleAddProblem(e)
                : onAddOrManageClick(e)}
          >
            {!['Diagnosis and Coding Assessments', 'Relevant Comorbidities', 'ICD10 Codes'].includes(_headerName) &&
              <AddCircleOutlinedIcon className={classes.addCircleButton} />
            }
            {_rightLabel}
          </Button>
        }
      </Grid>
    )
  }

  const handleReconcile = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenPrgMed(true)
  }

  const importedMedHeader = (_headerName: string, _rightLabel: string) => {
    return (
      <Grid container justifyContent="flex-start" direction='row' alignItems="center">
        <Grid item style={{ width: '60%' }}>
          <Typography className={classes.subHeadername} component='span' variant="h6">
            {`${_headerName}`}
          </Typography>
          <Typography component='span' color="textSecondary" className={classes.historyDate} style={{ paddingLeft: '0.3rem' }}>
            {patientDetail?.medications?.last_sync_timestamp &&
              <>Synced on {moment.unix(patientDetail?.medications?.last_sync_timestamp)?.format("MMM DD, YYYY h:mm a")}
              </>
            }
          </Typography>
        </Grid>
        <Grid item style={{ width: Object.keys(patientDetail?.medications?.non_synced_medications)?.length > 0 ? '40%' : '37%' }}>
          {Object.keys(patientDetail?.medications?.non_synced_medications)?.length > 0 &&
            <Stack direction={'row'} justifyContent={'flex-end'}>
              <Typography component='span' className={classes.reconcilePendingAction}>
                {patientDetail?.medications?.is_med_reconciled ? `Reconciled` : `Reconcile pending`}
              </Typography>
              <Tooltip title={`Med reconcile list`}>
                <AddCircleOutlinedIcon onClick={(e) => handleReconcile(e)}
                  className={classes.reconcileListIcon} />
              </Tooltip>
            </Stack>
          }
        </Grid>
      </Grid>
    )
  }

  const renderProgTargets = () => {
    const targetObjList = (patientInfo?.isActionItems === true && patientInfo?.actionProgram)
      ? [patientInfo?.actionProgram]
      : Array.from(
        new Set(
          patientDetail?.measurements?.program_targets?.map(
            (e: { program: string }) => e.program
          )
        )
      )
    return targetObjList?.map((p: any, i: number) => (
      <React.Fragment key={`props-${i}`}>
        {selectedProgram.includes(p) && (
          <Box className={classes.programTargetBox}>
            <ProgramTargetsComponent
              input={{
                patientId: patientInfo?.id,
                title: <>
                  {`${program_enrollments.find((e: any) => e.program === p)?.program_display_name}`}{' '}
                  <ColorBubbleComponent selectedProgramList={[p]} />
                </>,
                program_targets: patientInfo?.measurements?.program_targets,
                cProgram: p,
                isActionItems: patientInfo?.isActionItems,
                isOverrideItems: patientInfo?.isOverrideItems,
                actionProgram: patientInfo?.actionProgram
              }}
            />
          </Box>
        )}
      </React.Fragment>
    ))
  }

  const MEPTRSetup = () => {
    return <Accordion square defaultExpanded className={classes.patientAccrodionBg}>
      <AccordionSummary
        ref={inputEl}
        expandIcon={<ExpandMoreIcon className={classes.patientAccrodionExpandIcon} />}
      >
        <Typography className={classes.subHeader} component={'span'} variant="h6">
          {createHeader('Program Targets')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {renderProgTargets()}
      </AccordionDetails>
    </Accordion >
  }

  const isImportSynced = () => {
    if (patientDetail?.source_details?.length > 0) {
      const srcDetails = patientDetail?.source_details[0] || []
      if (srcDetails?.length > 0) {
        if (patientDetail?.created_on === patientDetail?.last_sync_date && ['EPIC', 'ELATION']?.includes(srcDetails[0]))
          return true
      }
    }
    return false
  }

  return (
    <>
      <ErrorBoundary>
        <Box>
          {checkCondition(parent === 'ME-PTR', MEPTRSetup())}
          {parent !== 'ME-PTR' && (
            <Accordion
              square
              defaultExpanded={(parent === 'ME-OTHR' || (parent === 'MD-RECON' && isImportSynced())) ? false : true}
              className={classes.patientAccrodionBg}
            >
              <AccordionSummary
                ref={inputEl}
                expandIcon={<ExpandMoreIcon className={classes.patientAccrodionExpandIcon} />}
              >
                <Typography className={classes.subHeader} variant="h6">
                  {createHeader(headerName)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderSwitch(parent)}
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
        {isAddProblem &&
          <AddProgramDialogComponent
            open={isAddProblem}
            item={{
              title: 'Add Problem',
              label: 'Select Problem',
              prgData: problemList
            }}
            handleDialogClose={handlePrgDialogClose}
            handleSubmit={handlePrgDialogSubmit}
          />
        }
        {openPrgMed && (
          <MedReconciliationDialogComponent
            open={openPrgMed}
            item={{
              title: 'Med reconcile list',
              isAllList: true
            }}
            handleDialogClose={handleMedDialogClose}
          />
        )}

        {openQuestionnarie && (
          <CategoryQuesionnariesComponent
            open={openQuestionnarie}
            title={'Diagnosis and Coding Assessments'}
            item={diagnosisData}
            handleClose={handleQuestionnarielose}
          />
        )}
      </ErrorBoundary>
    </>
  )
}

export default memo(withStyles(stylesheet)(DetailCardComponent))
