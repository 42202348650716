import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface multiProgramConfirmationState {
  program: string
  enrollment_id: number
  pending: number
  // overridenCount: number
}

const initialState: multiProgramConfirmationState[] = []

export const multiProgramConfirmSlice = createSlice({
  name: 'multiProgramConfirm',
  initialState,
  reducers: {
    resetMultiProgramConfirm: (state) => {
      return initialState
    },
    setMultiProgramConfirm: (
      state,
      action: PayloadAction<multiProgramConfirmationState[]>
    ) => {
      return action.payload
    },
  },
})

export const { resetMultiProgramConfirm, setMultiProgramConfirm } =
  multiProgramConfirmSlice.actions
export default multiProgramConfirmSlice.reducer
