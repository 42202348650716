import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'
import stylesheet from './questionnaire.stylesheet';
import { PatientService } from '../../services/patient.service';
import GenericQuestionnaireComponent from './widgets/generic-questionnaire.component';
import {
  CardContent, Button, CircularProgress, DialogActions, DialogContentText, DialogContent, Box, Grid, AppBar, Tabs, Tab, Tooltip, IconButton, DialogTitle, Typography
} from '@mui/material';
import { ClassNameMap, withStyles } from '@mui/styles';
import { toast } from 'react-toastify';
import Helper from '../../utils/helper';
import DialogTitleHeaderComponent from '../patients/detail/common/dialog-title-header.component';

interface IProps {
  data?: any,
  qu: any,
  pId: string,
  qId: string,
  isAllQuestionnarie?: boolean,
  handleChange?: any,
  handleIsSubmit: any,
  classes: ClassNameMap,
  handleClose: any,
  handleSave: any,
  isSubmit: any,
  isConfirmSubmit?: boolean
  needsConfirmation: any
}

const QuestionnaireComponent: React.FC<IProps> = ({ data, qu = '', pId = '', qId = '', isAllQuestionnarie = false, handleChange, handleIsSubmit, classes, handleClose, handleSave, isSubmit, isConfirmSubmit = false, needsConfirmation }) => {
  const urlParams: any = useParams();
  const patientId: string = urlParams.patientId ?? pId;
  const questionnaireId: string = urlParams.questionnaireId ?? qId;
  const patientService = new PatientService();
  const [answerArray, setAnswerArray] = useState([] as any);
  const [hisAnswerArray, setHisAnswerArray] = useState([] as any);
  const [question, setQuestionnaire] = useState([] as any);
  const [showCircularProgress, setCircularProgress] = useState(true)
  const [dataReceivedFlag, setDataReceivedFlag] = useState(false)
  let [answer, setAnswer] = useState({} as any)
  const [currTabIndex, setCurrTabIndex] = useState(0 as number)
  const [configItem, setConfigItem] = useState({
    focusTabIndex: 0,
    tabs: [{ title: 'Current', index: 0, comp: '', props: {} }]
  })

  useEffect(() => {
    fetchQuestionnaireDataset();
  }, [])

  useEffect(() => {
    if (isConfirmSubmit && !(answerArray.length === 0 || showCircularProgress))
      handleSubmit("confirm")
  }, [isConfirmSubmit])

  const setInitialAnswers = (inputAnswerSet: any) => {
    for (let i = 0; i < Object.keys(inputAnswerSet).length; i++) {
      let key = Object.keys(inputAnswerSet)[i]
      let formObj = {
        [key]: Object.values(inputAnswerSet)[i]
      }
      if (answerArray?.findIndex((el: any) => el[key]) === -1)
        answerArray.push(formObj)
    }
    setAnswerArray([...answerArray])

    if (isAllQuestionnarie) {
      const currAnsObj = [...answerArray]
      let currPayload: any = {};
      currAnsObj?.map((obj: any) => {
        let key: any = Object.keys(obj)[0]
        currPayload[key] = Object.values(obj)[0]
      })
      handleChange(qu, currPayload, true)
    }
  }

  const handleTabChange = (event: any, newValue: React.SetStateAction<number>) => setCurrTabIndex(newValue)

  const a11yProps = (index: number) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  const fetchQuestionnaireDataset = () => {
    patientService
      .getQuestionnaireDataset(questionnaireId, patientId)
      .then((response: any) => {
        if (response !== undefined && Object.keys(response?.question_set?.questions)?.length > 0) {
          setQuestionnaire(response?.question_set?.questions || []);
          setInitialAnswers((response && response?.answer_set) || []);
          setHisAnswerArray((response && response?.historical_answer_set) || {})
          let tabs = configItem?.tabs
          Object.keys(response?.historical_answer_set)
            ?.sort((a: any, b: any) => a.title < b.title ? 1 : -1)
            ?.map((i: any, index: any) => {
              tabs = [...tabs, { title: i, index: (index + 1), comp: '', props: {} }]
            })
          setConfigItem({ ...configItem, tabs: tabs })
        } else {
          setQuestionnaire([]);
          setInitialAnswers([]);
        }
        setDataReceivedFlag(true);
        setCircularProgress(false)
      })
      .catch((err: any) => {
        setCircularProgress(false)
        setQuestionnaire([]);
        setInitialAnswers([]);
        toast.error(Helper.getErrorText(err), Helper.bottom_center())
      });
  }

  const getAnswers = () => {
    if (currTabIndex <= 0)
      return answerArray
    else {
      const key = configItem.tabs?.find((i: any) => i.index === currTabIndex)?.title || ''
      let hisAnsObj = [] as any
      if (key) {
        Object.keys(hisAnswerArray[key])?.map((i: any) => {
          const keyObj = { [i]: hisAnswerArray[key][i] }
          hisAnsObj.push(keyObj)
        })
        return hisAnsObj
      }
    }
  }
  const onTextChange = (obj: any, index: any, isParamText = false) => {
    if (currTabIndex !== 0)
      return
    if (answerArray.findIndex((ele: any) => Object.keys(ele)[0] === Object.keys(obj)[0]) !== -1) {
      let replaceIndex = answerArray.findIndex((ele: any) => Object.keys(ele)[0] === Object.keys(obj)[0])
      answerArray.splice(replaceIndex, 1, obj);
      setAnswerArray([...answerArray])
      if (index?.branches)
        deleteBranches(obj, index, '')

      if (isParamText)
        setAnswerArray([...answerArray, obj])
    } else {
      setAnswerArray([...answerArray, obj])
    }

    if (isAllQuestionnarie) {
      const currAnsObj = [...answerArray, obj]
      let currPayload: any = {};
      currAnsObj?.map((obj: any) => {
        let key: any = Object.keys(obj)[0]
        currPayload[key] = Object.values(obj)[0]
      })
      handleChange(qu, currPayload, false)
    }
  }

  const findKey = (array: any, key: any) => array.findIndex((ele: any) => ele === key);

  const isAnswerSelected = (
    answerKey: any,
    ans: any,
    component: any,
    scaleArray: any
  ) => {
    const answerArray = getAnswers()
    if (component === 'ScaleQuestion') {
      let currentIndex = 0
      if (
        answerArray &&
        answerArray.findIndex((ele: any) => findKey(Object.keys(ele), answerKey) !== -1) !== -1
      ) {
        let d =
          answerArray &&
          answerArray.map((ele: any, index: any) => {
            if (Object.keys(ele)[0] === answerKey) {
              currentIndex = index
              return ele[answerKey]['value']
            }
          })
        return {
          value: d[currentIndex],
          answered: true,
        }
      } else {
        let obj = {
          value: scaleArray[0]['value'],
          answered: false,
        }
        return obj
      }
    } else if (component === 'ParameterValue') {
      setDataReceivedFlag(false)
      var defaultValue: string = ''
      var defaultUnit: string = ''
      var defaultValueArray: any = []
      if (
        answerArray &&
        answerArray.findIndex(
          (ele: any) => findKey(Object.keys(ele), answerKey) !== -1
        ) !== -1
      ) {
        let d =
          answerArray &&
          answerArray.map((ele: any) => {
            if (Object.keys(ele)[0] === answerKey) {
              if (
                ele[answerKey]['value'] !== '' &&
                ele[answerKey]['units'] !== ''
              ) {
                defaultValue = ele[answerKey]['value']
                defaultUnit = ele[answerKey]['units']
                let obj = {
                  val: defaultValue,
                  uni: defaultUnit,
                }
                defaultValueArray.push(obj)
              }
            }
          })
        return defaultValueArray
      }
    } else if (component === 'InputText') {
      let currentIndex = 0
      if (
        answerArray &&
        answerArray.findIndex(
          (ele: any) => findKey(Object.keys(ele), answerKey) !== -1
        ) !== -1
      ) {
        let d =
          answerArray &&
          answerArray.map((ele: any, index: any) => {
            if (Object.keys(ele)[0] === answerKey) {
              currentIndex = index
              return ele[answerKey]['value']
            }
          })
        return d[currentIndex] || ''
      }
      return ''
    } else {
      return (
        answerKey && answerArray.findIndex(
          (ele: any) =>
            findKey(Object.keys(ele), answerKey) !== -1 &&
            ele[answerKey] &&
            ele[answerKey]['value'] === ans
        ) !== -1
      )
        ? true
        : false
    }
  }

  const deleteInnerBranch = (subBranch: any, type: string) => {
    return (subBranch.questions || []).map((obj: any) => {
      const findBranchIndex = answerArray.findIndex((obj1: any) => Object.keys(obj1).includes(obj.id))
      if (findBranchIndex !== -1) {
        answerArray.splice(findBranchIndex, 1)
        setAnswerArray([...answerArray])
        return deleteBranches('', obj, type)
      }
    })
  }

  const deleteBranches = (selectedAnswer: any, index: any, type: string) => {
    if (index && !index.branches) {
      let findIndex = answerArray.findIndex((obj1: any) => Object.keys(obj1).includes(index.id))
      if (findIndex !== -1) {
        answerArray.splice(findIndex, 1)
        setAnswerArray([...answerArray])
      }
    } else {
      if (type !== 'inner') {
        let changedAnswer: any = Object.values(selectedAnswer)[0]
        let checkValue = index && index.branches && index.branches[0] && index.branches[0].condition
        if (changedAnswer !== checkValue) {
          let questionIdArray = index && index.branches && index.branches.map((subBranch: any) => {
            return deleteInnerBranch(subBranch, 'inner')
          })

          if (questionIdArray) {
            return questionIdArray.map((innerArray: any) => {
              for (let i = 0; i < innerArray.length; i++) {
                let findIndex = answerArray.findIndex((obj1: any) => Object.keys(obj1).includes(innerArray[i]))
                if (findIndex !== -1) {
                  answerArray.splice(findIndex, 1);
                  setAnswerArray([...answerArray])
                }
              }
            })
          }
        }
      } else {
        let questionIdArray = index && index.branches && index.branches.map((subBranch: any) => deleteInnerBranch(subBranch, ''))

        if (questionIdArray) {
          return questionIdArray.map((innerArray: any) => {
            for (let i = 0; i < innerArray.length; i++) {
              let findIndex = answerArray.findIndex((obj1: any) => Object.keys(obj1).includes(innerArray[i]))
              if (findIndex !== -1) {
                answerArray.splice(findIndex, 1);
                setAnswerArray([...answerArray])
              }
            }
          })
        }
      }
    }
  }

  const handleSubmit = (_type: string) => {
    setCircularProgress(true)
    let payload: any = {};
    answerArray?.map((obj: any) => {
      let key: any = Object.keys(obj)[0]
      payload[key] = Object.values(obj)[0]
    })
    setAnswer(answer)

    patientService.updateQuestionnaires(patientId, {
      "data": [{
        "id": qu,
        "answer": payload,
        "confirmed": _type === 'confirm' ? true : false
      }]
    })
      .then((response: any) => {
        setCircularProgress(false)
        if (response?.data?.message === 'Success') {
          if (typeof handleIsSubmit === "function")
            handleIsSubmit(true)
          handleSave(_type, response?.data?.result[0] || {})
        } else {
          handleSave(_type, response?.data?.result[0] || {})
        }
      })
      .catch((res: any) => {
        handleIsSubmit(true)
        if (res?.response?.data?.error?.message)
          toast.error(Helper.getErrorText(res), Helper.bottom_center())
        else if (res?.response?.data?.result)
          handleSave('confirm_err', res?.response?.data?.result[0] || {})
        setCircularProgress(false)
      })
  }

  const getQuestionnarieSeverity = () => {
    let val = ''
    if (data.category === "diagnosis") {
      const firstQueId = question[0]?.id || ''
      if (firstQueId) {
        const currAns = currTabIndex === 0 ? answerArray : getAnswers();
        const firstQueRes = (currAns)?.find((i: any) => (Object.keys(i).includes(firstQueId)) && i)
        if ((firstQueRes && firstQueRes[firstQueId]?.value)) {
          val = firstQueRes[firstQueId]?.value || ''
          // val = (val === 'Yes') ? val : ''
        }
      }
      return val && val !== ''
        ? <Typography sx={{ fontSize: '0.8rem', fontWeight: '500', color: 'rgba(0, 0, 0, 0.87)' }}>
          {data.name}:&nbsp;{val}
        </Typography>
        : ''
    }
    else {
      if (currTabIndex === 0 && data?.severity?.value) {
        val = `${data?.severity?.value}${data?.severity?.max_value ? `/${data?.severity?.max_value} ` : ''}`
      }
      else {
        const currAns = getAnswers();
        const currSeverity = (currAns)?.find((i: any) => (Object.keys(i).includes('severity')) && i)
        if ((currSeverity && currSeverity['severity']?.value)) {
          val = currSeverity['severity']?.value
          if (currSeverity['severity']?.max_value)
            val += `/${currSeverity['severity']?.max_value}`
        }
      }
      return <Typography sx={{ fontSize: '0.8rem', fontWeight: '500', color: 'rgba(0, 0, 0, 0.87)' }}>
        Severity:&nbsp;{val || 'None'}
      </Typography>

    }
  }

  return (
    <Box className={classes.fullWidth}>
      {!data?.isBulkEdit &&
        <DialogTitle id={`q - ${qId} `} classes={{ root: 'questionnarie-dialog' }} sx={{ paddingBottom: '0px' }}>
          <DialogTitleHeaderComponent title={data?.name} handleDialogClose={handleClose} />
        </DialogTitle>
      }
      {!data?.isBulkEdit &&
        <AppBar position="static" color="transparent" sx={{ boxShadow: 'none' }}>
          <Tabs value={currTabIndex} onChange={handleTabChange} variant="scrollable">
            {(configItem?.tabs)?.map((c: any, index: any) => (
              <Tooltip title={c.title}>
                <Tab key={c.title} label={(c.title).substring(0, 10)} {...a11yProps(index)} />
              </Tooltip>
            ))}
          </Tabs>
        </AppBar>
      }
      <DialogContent className={classes.questionnaireContainer}>
        <Box className={classes.rationaleDialogHeader} mb={1} />
        <DialogContentText className={classes.zeroBottomMargin}>
          <CardContent key={'questionnaireCard'} className={classes.questionnaireCardContainer}>
            {showCircularProgress &&
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item><CircularProgress size="2rem" /></Grid>
              </Grid>
            }
            {!showCircularProgress && !data?.isBulkEdit &&
              <Box pb={1} pl={0.2}>
                {getQuestionnarieSeverity()}
              </Box>
            }
            {!showCircularProgress && question?.map((ques: any) => {
              return (
                <GenericQuestionnaireComponent
                  key={ques.id}
                  question={ques}
                  onTextChange={onTextChange}
                  isViewHistory={currTabIndex !== 0}
                  isAnswerSelected={isAnswerSelected}
                  answerSet={getAnswers()}
                  dataReceivedFlag={dataReceivedFlag}
                  type={''}
                  history={''}
                  location={''}
                  match={''}
                />
              )
            })
            }
          </CardContent>
        </DialogContentText>
      </DialogContent>
      {!isAllQuestionnarie &&
        <DialogActions className={classes.btnContainer}>
          <Button
            disabled={answerArray.length === 0 || showCircularProgress || currTabIndex !== 0}
            onClick={() => handleSubmit("confirm")}
            variant="outlined"
            size='small'
          >
            <strong>{`Save & Confirm`}</strong>
          </Button>
          <Button
            disabled={answerArray.length === 0 || showCircularProgress || currTabIndex !== 0}
            onClick={() => handleSubmit("save")}
            variant="outlined"
            size='small'
          >
            <strong>Save</strong>
          </Button>
          <Button disabled={showCircularProgress} onClick={handleClose} variant="outlined" size='small'>
            <strong>Cancel</strong>
          </Button>
        </DialogActions>
      }
    </Box>
  );
}

export default withStyles(stylesheet)(QuestionnaireComponent);