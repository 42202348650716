import { StyleRules } from '@mui/styles'

const stylesheet: StyleRules = {
  topBar: {
    height: '60px !important',
    padding: '1rem 1rem !important',
    background: '#ffff !important',
    borderLeft: '0.5px solid #8c8c8c !important',
  },
  fontSizeSmall: {
    fontSize: '1.25rem !important',
  },
  email: {
    padding: '0.625rem !important',
  },
  itemTwo: {
    marginRight: '1rem !important',
    padding: '0.625rem !important',
  },
  itemTwo1: {
    marginRight: '1rem !important',
    background: '#FFECDD !important',
    padding: '0.625rem !important',
  },
  itemThree: {
    padding: '0.625rem !important',
  },
  ageGenderMrn: {
    height: '1.125rem !important',
    fontSize: '0.8125rem !important',
    textDecoration: 'none solid rgb(120, 120, 120) !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
    width: '15% !important',
    position: 'absolute',
  },
  separator: {
    width: '0.188rem !important',
    fontSize: '0.75rem !important',
    color: '#828282 !important',
    textDecoration: 'none solid rgb(130, 130, 130) !important',
    textAlign: 'right',
    display: 'inline-block !important',
    marginRight: '10px !important',
  },
  topBarDetails: {
    display: 'flex !important',
    fontSize: '0.875rem !important',
    color: '#333333 !important',
    fontWeight: 'bold !important',
    textDecoration: 'none solid rgb(120, 120, 120) !important',
    whiteSpace: 'break-spaces',
  },

  avatarPic: {
    width: '2.5rem !important',
    height: '2.5rem !important',
    borderRadius: '10px !important',
    border: '0.18rem solid #CBC7C780'
  },
  topBar1: {
    height: '6rem !important',
    paddingLeft: '0.938rem !important',
  },
  patientName: {
    height: '1.625rem !important',
    display: 'inline !important',
    fontSize: '1.25rem !important',
    fontWeight: 'bold !important',
  },
  topBarHeads: {
    fontSize: '0.8125rem !important',
    display: 'inline-block !important',
    height: '18px !important',
  },
  topBarDetails1: {
    fontSize: '0.875rem !important',
    height: '19px !important',
    textDecoration: 'none solid rgb(120, 120, 120) !important',
    margin: '0 0.5rem 0 0 !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
  },
  patientNameTop: {
    height: '1.4rem !important',
    fontSize: '1rem !important',
    fontWeight: 'bold !important',
    letterSpacing: '0rem !important',
  },
  topBarNotif: {
    display: 'flex !important',
    height: '3.125rem !important',
    backgroundColor: '#F0F1F2 !important',
    borderRadius: '0.25rem !important',
    opacity: '1 !important',
    float: 'right',
  },
  topBarNot: {
    padding: '10px 0 10px 0 !important',
    marginRight: '1rem !important',
    float: 'right',
  },
  topBarIcon: {
    color: '#dd9614 !important',
  },
  topBarIconOut: {
    borderRadius: '50% !important',
    border: '3px solid #dd9614 !important',
    width: '1.438rem !important',
    height: '1.438rem !important',
    margin: '0.75rem 0 0 1.25rem !important',
  },
  topBarNotifVal: {
    margin: '0.75rem 0.5rem 0.75rem 0.5rem !important',
    fontSize: '0.75rem !important',
    paddingTop: '0.5rem !important',
    fontWeight: 'normal !important',
    lineHeight: '1rem !important',
    color: '#666666 !important',
  },
  topBarNotifSat: {
    fontSize: '0.75rem !important',
    fontWeight: 'normal !important',
    lineHeight: '0.75rem !important',
    color: '#666666 !important',
  },
  notVal: {
    color: '#FF7015 !important',
  },
  notVal1: {
    color: '#666666 !important',
    fontSize: '1rem !important',
    fontWeight: 'bold !important',
  },
  notVal2: {
    color: 'red !important',
  },
  topTHeader: {
    fontSize: '1rem !important',
  },
  subHeader: {
    color: '#080808 !important',
    fontSize: '0.9rem !important',
    fontWeight: 500,
    width: '100% !important',
    letterSpacing: '0.001rem !important',
  },
  topHead: {
    display: 'flex !important',
    padding: '0.75rem 0 0.375rem 0 !important',
  },
  topHead1: {
    background: '#ffff !important',
  },
  journeyHeader: {
    padding: '1rem 2rem !important',
    background: '#FFFFFF !important',
    boxShadow: '0px 0px 6px #00000029 !important',
    opacity: 1,
    display: 'flex !important',
    justifyContent: 'flex-start !important',
    alignItems: 'flex-end !important',
    width: 'inherit !important',
    maxHeight: '25rem',
    overflow: 'auto !important',
  },
  timelineBox: {
    padding: '1rem',
    // background:
    //   'linear-gradient(#FFFFFF 25%,#F2F2F2 26%, #F2F2F2 48%, #FFFFFF 40%) !important',
    width: 'inherit !important',
    height: '9.5rem !important',
    minWidth: 'fit-content !important',
  },
  journeyMedList: {
    width: '100% !important',
    padding: '0rem 0.2rem 0rem 0.2rem !important'
  },
  journeyMedStatus: {
    height: '1rem !important',
    fontSize: '0.5rem !important',
    lineHeight: '0.7rem !important',
    paddingRight: '0.15rem',
    overflow: 'hidden'
  },
  dateInfo: {
    display: 'flex !important',
    justifyContent: 'flex-end !important',
    alignItems: 'flex-start !important',
    flexDirection: 'column',
    width: '10rem !important',
  },
  dateBox: {
    color: '#707070 !important',
    backgroundColor: '#FFF',
    fontSize: '0.5rem !important',
    minWidth: '0.1rem !important',
    marginTop: '0.3rem !important',
    minHeight: '0.7rem !important',
    height: '10% !important',
  },
  activeDateline: {
    borderLeft: '0.1rem dotted #f5842e !important',
    paddingRight: '0.1rem'
  },
  activeDate: {
    color: '#fff !important',
    fontSize: '8px !important',
    background: '#f5842e !important',
    height: '1rem !important',
    left: '-6% !important',
    position: 'relative',
    '& span': {
      padding: '0rem 0.2rem !important',
    },
  },
  testBox: {
    height: '35% !important',
    width: 'inherit !important',
  },
  stageBorder: {
    borderLeft: '0.1rem solid #CBCBCB !important',
  },
  testInfoBox: {
    left: '-40% !important',
    top: '0 !important',
    width: '8.5rem !important',
    background: '#fff !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end !important',
    alignItems: 'center !important',
    position: 'relative',
  },
  medicineBox: {
    height: '15% !important',
    width: 'inherit !important',
    diplay: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'flex-start !important',
    padding: '0.2rem 0rem !important'
  },
  medicineInfo: {
    height: '15% !important',
    color: '#707070 !important',
    fontSize: '9px !important',
  },
  mediChange: {
    width: '0.59rem !important',
    fill: '#958C8C !important',
  },
  medicineInfoBox: {
    left: '-40% !important',
    top: '44% !important',
    width: '8.5rem !important',
    position: 'relative',
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column'
  },
  stageTimelineBox: {
    height: '18% !important',
  },
  stageCircle: {
    width: '0.6rem !important',
    color: 'green',
    left: '-10%',
    top: '-50%',
    position: 'relative'
  },
  stageDate: {
    top: '-75%',
    position: 'relative',
    left: '-51% !important'
  },
  actionBox: {
    height: '20% !important',
    width: 'inherit !important',
  },
  actionInfoBox: {
    left: '-40% !important',
    top: '-1rem !important',
    textAlign: 'center',
    width: '8.5rem !important',
    position: 'relative',
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column'
  },
  medTooltip: {
    backgroundColor: "#FFF !important",
    width: 'fit-content !important',
    maxWidth: '70% !important',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '1',
    '&:hover': {
      fontWeight: '600',
      backgroundColor: 'honeydew !important'
    }
  },
  actionLabel: {
    fontSize: '0.55rem !important',
    lineHeight: '1.1 !important',
    verticalAlign: 'text-top !important',
    wordBreak: 'break-word',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
  },
  actionIcon: {
    fill: '#3FB500 !important',
    width: '0.85rem !important',
    '&.high': {
      fill: 'blue !important',
    },
    '&.low': {
      fill: 'red !important',
    },
    '&.medium': {
      fill: 'orange !important',
    },
  },
  testIcon: {
    fill: '#958C8C !important',
    width: '0.85rem !important',
  },
  medicineIcon: {
    fill: '#958C8C',
    width: '0.6rem !important',
    height: '0.65rem !important',
    transform: 'rotate(140deg) !important',
    marginBottom: '-0.1rem !important',
    paddingRight: '0.28rem'
  },
  medEvalIcon: {
    fill: '#958C8C',
    width: '0.55rem !important',
    height: '0.55rem !important',
    paddingRight: '0.08rem'
  },
  downArrowIcon: {
    top: '75% !important',
    left: '-69% !important',
    width: 'auto !important',
    position: 'relative',
    zIndex: 1,
    opacity: 1,
    '&:hover': {
      fill: '#52504F !important',
      cursor: 'pointer !important',
    },
  },
  timeline: {
    height: '0.3rem !important',
    borderLeft: '0.1rem solid #B2AFAF !important',
  },
  timelineBg: {
    width: 'inherit !important',
    borderBottom: '0.1rem dotted #CBCBCB !important',
  },
  pastTimelineBg: {
    width: 'inherit !important',
    borderBottom: '2px solid #3FB500 !important',
  },
  topNameDet: {
    padding: '0 0 0 0.625rem !important',
    width: '100% !important',
    overflow: 'hidden !important',
    display: 'inline !important',
    '&:hover': {
      cursor: 'default !important',
    },
  },
  iconBase: {
    width: '1em !important',
    height: '1em !important',
    padding: '0 !important',
  },
  iconBaseBorder: {
    padding: '0.1rem !important',
  },
  emptyImg: {
    width: '3rem !important',
    padding: '0rem 1rem !important',
  },
  tablePadding: {
    padding: '0.3rem !important',
  },
  tablePaddingPList: {
    padding: '0.4rem !important',
    border: 'none !important',
  },
  tablePaddingPListHeader: {
    padding: '0.4rem !important',
    border: 'none !important',
    backgroundColor: '#fff !important',
  },
  tablePaddingPListSelected: {
    padding: '0.4rem !important',
    border: 'none !important',
    backgroundColor: '#f8f4ea !important',
  },
  tablePaddingPT: {
    padding: '0.3rem 0.5rem 0.3rem 0.5rem !important',
  },
  tableIconPaddingPT: {
    padding: '0.5rem 0.25rem 0.3rem 0.15rem !important',
  },
  targetIcons: {
    fontSize: '14px !important',
    padding: '0.15rem !important',
  },
  tableHeader: {
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    padding: '0.3rem 0.5rem 0.3rem 0.5rem !important',
    fontSize: '0.75rem !important'
  },
  closeBtn: {
    color: '#FF7015 !important',
  },
  tableReconcileHeader: {
    verticalAlign: 'top !important',
    borderRight: '1px solid lightgray !important'
  },
  confirmCardBackground: {
    backgroundColor: '#fefaed !important',
    '&:hover': {
      cursor: 'pointer !important'
    }
  },
  recommendedBackground: {
    backgroundColor: '#52514F !important',
  },
  recommendedRefDisconBackground: {
    backgroundColor: '#373534a8 !important',
    border: '0.1rem solid #DB2323 !important',
  },
  recommendedDisconBackground: {
    backgroundColor: '#373534a8 !important',
  },
  corfirmButtonStyle: {
    color: '#FF7015 !important',
    padding: '0.1rem !important',
    textTransform: 'none',
  },
  corfirmCancelButtonStyle: {
    padding: '0.2rem !important',
    textTransform: 'none',
    color: '#FF7015 !important',
  },
  boxBorder: {
    background:
      'transparent linear-gradient(180deg, #E1E1E1B3 0%, #F2F2F2 34%, #FFFFFF 78%, #FFFFFF 100%) 0% 0% no-repeat padding-box !important',
    backgroundSize: '100% 5px !important',
    minHeight: '0.10rem'
  },
  dialogBtnStyle: {
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FF7015 !important',
      color: 'white !important',
      border: '#FF7015 solid .1rem !important',
    },
    color: 'black !important',
    border: 'lightgray solid .1rem !important',
    padding: '0.2rem !important',
  },
  questionButtonStyle: {
    justifyContent: 'space-around !important',
    display: 'flex !important',
    flexWrap: 'wrap',
    width: '100% !important',
    borderTop: 'lightgray solid 1px !important',
  },
  questionFullButtonStyle: {
    justifyContent: 'space-around !important',
    display: 'flex !important',
    flexWrap: 'wrap',
    width: '100% !important',
    border: 'lightgray solid 1px !important',
  },
  questionButtonWidth: {
    textTransform: 'none',
    width: '33.3% !important',
    color: '#FF7015 !important',
  },
  questionButtonWidth1: {
    textTransform: 'none',
    width: '50% !important',
    color: '#FF7015 !important',
  },

  twoButtonWidth: {
    textTransform: 'none',
    width: '50% !important',
    color: '#FF7015 !important',
  },
  OneButtonWidth: {
    textTransform: 'none',
    width: '100% !important',
    color: '#FF7015 !important',
  },
  questionCardContent: {
    padding: '0px !important',
    border: '#FF7015 solid 0.1rem !important',
  },
  questionCardSubContent: {
    padding: '0px !important'
  },
  questionSubmitAnsButton: {
    padding: '2px 5px !important',
    textTransform: 'none',
    '&:active': {
      backgroundColor: '#000000 !important',
      color: 'white !important',
    },
  },
  chipDefault: {
    margin: '0.3rem !important',
    textTransform: 'none',
    height: '25px !important',
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.5) !important',
  },

  questionSubmitAnsButtonActive: {
    padding: '2px 5px !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#958C8C !important',
      color: 'white !important',
    },
    backgroundColor: '#958C8C !important',
    color: 'white !important',
  },
  chipSelect: {
    backgroundColor: '#3E3D3B !important',
    height: '25px !important',
    color: 'white !important',
    fontWeight: 500,
    margin: '0.3rem !important',
    '&:focus': {
      backgroundColor: '#3E3D3B !important',
    },
    '&:hover': {
      backgroundColor: '#3E3D3B !important',
    },
  },
  questionHeaderSection: {
    borderBottom: 'lightgray solid 1px !important',
    padding: '0.5rem 1rem !important',
  },
  questionHeaderOtherMedSection: {
    padding: '0.5rem 1rem !important',
  },
  questionHeaderSection1: {
    display: 'inline-block !important',
    verticalAlign: 'middle !important',
  },
  questionHeaderSection2: {
    display: 'inline-block !important',
    width: 'calc(100% - 50px) !important',
    verticalAlign: 'middle !important',
  },
  backIcon: {
    color: '#D87C4E !important',
    display: 'inline-block !important',
    margin: '5px 5px 0 0 !important',
    border: '2px solid #D87C4E !important',
    borderRadius: '24px !important',
    height: '24px !important',
    width: '24px !important',
    verticalAlign: 'middle !important',
    padding: 0,

    '& span': {
      width: '20px !important',
      height: '20px !important',
    },
  },
  medicineSubmitAnsButton: {
    '&:active': {
      backgroundColor: '#151cffa6 !important',
      color: 'white !important',
    },
    background: 'white !important',
    marginRight: '1rem !important',
    height: '1.5rem !important',
    textTransform: 'none',
    border: '0.1rem solid rgb(0 0 255 / 25%) !important',
  },
  medicineSubmitAnsButtonActive: {
    '&:hover': {
      backgroundColor: '#151cffa6 !important',
      color: 'white !important',
    },
    backgroundColor: '#151cffa6 !important',
    color: 'white !important',
    textTransform: 'none',
    border: '0.1rem solid rgb(0 0 255 / 25%) !important',
  },
  decisionView: {
    padding: '1rem !important',
  },
  fullWidth: {
    width: '100% !important',
  },
  halfWidth: {
    width: '50% !important'
  },
  notesCancelBtn: {
    color: 'grey !important',
    fontWeight: 'bold !important',
    textTransform: 'none',
  },
  notesSaveBtn: {
    color: '#FF7015 !important',
    fontWeight: 'bold !important',
    textTransform: 'none',
  },
  bottomBtn: {
    display: 'flex !important',
    padding: '0rem !important',
  },
  decisionBtn: {
    fontSize: '0.6rem !important',
    fontWeight: 'bold !important',
    color: '#FF7015 !important',
    width: '100% !important',
    borderRight: '1px solid lightgray !important',
    borderTop: '1px solid lightgray !important',
  },
  takingBorder: {
    padding: '1rem !important',
  },
  bootmBtn: {
    width: '33.3% !important',
    color: '#FF7015 !important',
    fontSize: '0.7rem !important',
    fontWeight: 'bold !important',
    borderRight: '1px solid lightgray !important',
    borderTop: '1px solid lightgray !important',
  },
  diffBorder: {
    paddingLeft: '1rem !important',
    paddingRight: '1rem !important',
    paddingBottom: '1rem !important',
  },

  programTargetFirstTableCell: {
    borderBottom: 'none !important',
    padding: '0rem !important',
  },
  tableCellEdit: {
    borderBottom: 'none !important',
    padding: '0 0 0.5rem 0 !important',
  },
  tableCellEditTop: {
    borderBottom: 'none !important',
    padding: '0 0.2rem 0.5rem 0.2rem !important',
    verticalAlign: 'top !important',
  },
  tableCellEditTopRB: {
    borderBottom: 'none !important',
    padding: '0 0.2rem 0.5rem 0.2rem !important',
    verticalAlign: 'top !important',
    borderRight: '1px solid lightgray !important',
  },
  grayScheduleCardLayout: {
    cursor: 'pointer !important',
    border: '1px solid lightgray !important',
  },
  selectedScheduleCardLayout: {
    cursor: 'pointer !important',
    backgroundColor: 'white !important',
    border: '#32a332 solid 0.1rem !important',
  },
  scheduleCardLayout: {
    cursor: 'pointer !important',
    backgroundColor: 'white !important',
    border: '#ea9864 solid 0.1rem !important',
  },
  nonSelectedScheduleCardLayout: {
    backgroundColor: '#aca8a852 !important',
    border: '#aca8a852 solid 0.1rem !important',
  },
  selectedScheduleCardMargin: {
    marginBottom: '1rem'
  },
  programTargetTableCell: {
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
    padding: '0.1rem 0.2rem 0.2rem 0rem !important',
  },
  programTargetLastTableCell: {
    borderBottom: 'none !important',
    padding: '0.1rem 0.2rem 0.2rem 0rem !important',
  },
  programTargetFooterStyle: {
    justifyContent: 'space-around !important',
    display: 'flex !important',
    flexWrap: 'wrap',
    width: '100% !important',
    borderRight: 'none !important',
    backgroundColor: 'white !important',
    borderTop: '1px solid rgba(224, 224, 224, 1) !important',
    '&.MuiButtonGroup-root': {
      borderRadius: '0 0 0.25rem 0.25rem !important',
    },
  },
  ProgramTargetHistoryBy: {
    color: '#FF7015 !important',
  },
  borderLayout: {
    border: '0.1rem solid #FF7015 !important',
  },

  toggleButtonItem: {
    '& >button': {
      '& >span': {
        lineHeight: '1.2 !important',
      },
      height: '28px !important',
      borderRadius: '4px 4px 4px 4px !important',
      borderLeft: '1px solid !important',
      borderRight: '1px solid !important',
      borderColor: '#77AEDD !important',
      textTransform: 'none',
      width: 'fitContent !important',
      margin: '0 5px 0 0 !important',

      '&.Mui-selected': {
        backgroundColor: '#77AEDD !important',
        color: 'white !important',
      },
      '&:hover': {
        backgroundColor: '#77AEDD !important',
        color: 'white !important',
        borderColor: '#77AEDD !important',
      },
      '&:focus': {
        backgroundColor: '#77AEDD !important',
        color: 'white !important',
        borderColor: '#77AEDD !important',
      },
    },
  },
  textLabel: {
    padding: '0.75rem 0 0 0.625rem !important',
    color: 'black !important',
    boxSizing: 'border-box',
  },

  medicationTextAre: {
    display: 'block !important',
    margin: '19px 0 26px !important',
    padding: '4px 6px !important',
    height: '80px !important',
    width: '100% !important',
    fontSize: '0.8125rem !important',
    boxSizing: 'border-box',
    color: '#999999 !important',
    borderColor: '#77AEDD !important',
    '&:hover': {
      borderColor: '#77AEDD !important',
    },
    '&:focus': {
      borderColor: '#77AEDD !important',
    },
  },
  buttonLabel: {
    width: '100% !important',
    boxSizing: 'border-box',
    maxWidth: '100% !important',
    borderBottomRightRadius: '12px !important',
    borderBottomLeftightRadius: '12px !important',
    borderTopRightRadius: '0 !important',
    borderTopLeftRadius: '0 !important',

    '& >button': {
      border: '#CBCBCB 1px solid !important',
      borderBottomRightRadius: '12px !important',
      borderBottomLeftightRadius: '12px !important',
      borderTopRightRadius: '0 !important',
      borderTopLeftRadius: '0 !important',
      marginBottom: '-1px !important',
      fontSize: '1rem !important',
      color: '#FF7015 !important',
      fontWeight: '700 !important',
      width: '100% !important',
      maxWidth: '100% !important',
    },
  },
  medicineBorder: {
    border: '#FF7015 0.1rem solid !important',
    borderRadius: '0.75rem !important',
  },
  bottomBorder: {
    paddingBottom: '0.5rem !important',
    borderBottom: '0.1rem solid #8080801f !important',
    display: 'block !important',
  },
  tooltip: {
    height: '10px !important',
    padding: '0px !important',
    fontSize: '1.25rem !important',
    color: '#ffffff !important',
  },
  measurementsText: {
    textAlign: 'left',
    letterSpacing: '0px !important',
    color: '#080808 !important',
    opacity: 1,
  },
  measurementsSubText: {
    fontSize: '0.75rem !important',
    color: '#999999 !important',
    float: 'right',
  },
  measurementTextBox: {
    paddingTop: '10px !important',
    '& div': {
      width: '100% !important',
    },
  },
  measurementInput: {
    width: '40% !important',
    paddingLeft: '5px !important',
  },
  measurementInputOuter: {
    border: '1px solid #D3D3D3 !important',
    borderRadius: '3px !important',
    opacity: 1,
    '& ::before': {
      content: 'none !important',
    },
  },
  measurementDateBlock: {
    background: '#FFFFFF 0% 0% no-repeat padding-box !important',
    border: '1px solid #CCCCCC !important',
    borderRadius: '4px !important',
    opacity: '1 !important',
    marginTop: '12px !important',
    padding: '10px !important',
    '& span': {
      color: '#000000 !important',
    },
  },
  modalAtCenter: {
    top: '33% !important',
    left: '33% !important',
    position: 'absolute',
  },
  cardLeftFooter: {
    '&>*:not(:last-child)': {
      marginRight: '0.2rem !important',
    },
  },
  tooltip1: {
    textAlign: 'left',
    letterSspacing: '0rem !important',
    opacity: '1 !important',
    paddingTop: '0.5rem !important',
  },
  tooltip2: {
    textAlign: 'left',
    letterSspacing: '0rem !important',
    color: '#000000 !important',
    opacity: '1 !important',
  },
  checkBoxShadow: {
    background: 'rgba(228, 230, 232, 0.4) !important',
  },
  header2: {
    textAlign: 'left',
    letterSspacing: '0rem !important',
    color: '#000000 !important',
    opacity: '1 !important',
    paddingBottom: '0.625rem !important',
  },
  addnew: {
    '&:hover': {
      opacity: 1,
      color: '#FF7015 !important',
    },
    textAlign: 'left',
    letterSpacing: '0rem !important',
    color: '#080808 !important',
    opacity: '0.7 !important',
  },
  sfBtn: {
    backgroundColor: '#F08230 !important',
    fontSize: '11px !important',
    color: 'white !important',
    '&:hover': {
      background: '#F7AC40 !important',
    },
  },
  sfBtnContainer: {
    margin: '15px 0 10px 0 !important',
  },
  headerTitle: {
    fontSize: '1.1rem !important',
    letterSpacing: '0.001rem !important',
  },
  expressModeBtn: {
    color: '#757575 !important',
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    marginLeft: '0.1rem',
    fontSize: '0.75rem !important',
    letterSpacing: 0,
    '&:hover': {
      color: '#757575 !important',
      backgroundColor: 'rgba(0, 0, 0, 0.0) !important',
    },
  },
  activeBtn: {
    textTransform: 'none',
    color: '#FF7015 !important',
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: '0.1rem !important',
    fontSize: '0.75rem !important',
    letterSpacing: 0,
    // cursor: 'pointer',
    '&:hover': {
      color: '#FF7015 !important',
      backgroundColor: 'rgba(0, 0, 0, 0.0) !important',
    },
  },
  manageReportBtn: {
    color: '#080808 !important',
    fontWeight: 420,
    paddingLeft: '3rem !important',
    display: 'inline-block !important',
    '&:hover': {
      color: '#FF7015 !important',
      cursor: 'pointer !important',
    },
  },
  addIcon: {
    display: 'inline !important',
    paddingLeft: '0.5rem !important',
  },
  cardBox: {
    width: '100% !important',
    background: '#FFFFFF 0% 0% no-repeat padding-box !important',
    border: '0.1rem solid #FF7015 !important',
    borderRadius: '6px !important',
    opacity: 1,
    marginBottom: '0.5rem !important',
    fontSize: '0.8rem !important',
  },
  cardLabInfo: {
    borderTop: '1px solid lightgray !important',
    padding: '0.6rem !important',
  },
  cardLabList: {
    paddingLeft: '0.5rem !important',
  },
  cardLabRange: {
    verticalAlign: 'middle !important',
    fontSize: '0.8rem !important',
  },
  cardLabIcon: {
    fill: '#999999 !important',
    width: '14px !important',
    verticalAlign: 'middle !important',
  },
  cardLabvalue: {
    height: '1.8rem !important',
    fontSize: '0.8rem !important',
  },
  cardLabButton: {
    width: '40% !important',
    color: '#FF7015 !important',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '0.85rem !important',
  },
  cardLabNotes: {
    width: '56% !important',
    fontSize: '0.8rem !important',
    backgroundColor: '#ffffff !important',
  },
  cardLabDate: {
    height: '2rem !important',
    fontSize: '0.75rem !important',
  },
  datePicker: {
    width: '100% !important',
    margin: '10px 0 20px 0 !important',
  },
  overflowTextTwoLine: {
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
    display: '-webkit-box !important',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    fontSize: '0.875rem !important',
    color: '#333333 !important',
    fontWeight: 'bold !important',
    textDecoration: 'none solid rgb(120, 120, 120) !important',
  },
  customCard: {
    width: '100%',
    marginBottom: '5px',
  },
  customCard1: {
    lineHeight: '1 !important',
    padding: '0px !important',
    textAlign: 'center',
    width: '100% !important',
    fontSize: '0.8rem !important',
  },
  chartBorder: {
    borderBottom: '0.5px solid #CCCCCC !important',
  },
  descValue: {
    fontSize: '0.8rem !important',
  },
  subTitle: {
    fontSize: '0.8rem !important',
    fontWeight: '500 !important',
    letterSpacing: '0.00714em !important',
    color: 'rgba(0,0,0,0.87) !important',
  },

  subHeaderTitle: {
    fontSize: '0.75rem !important',
    // color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: 400,
    '& h6': {
      fontSize: '0.75rem !important',
      // color: 'rgba(0, 0, 0, 0.5)',
      fontWeight: 400,
    },
  },
  cardDate: {
    fontSize: '0.75rem !important',
    color: 'rgba(0, 0, 0, 0.5) !important',
    fontWeight: 400,
    paddingRight: '0.5rem !important'
  },
  addCircleButton: {
    marginRight: '0.3125rem !important',
    fontSize: '0.9rem !important',
    letterSpacing: '0px',
    cursor: 'pointer',
  },
  closeIcon: {
    height: '1.25rem !important',
    width: '1.25rem !important',
    color: '#757575 !important',
    float: 'right',
    paddingTop: '0.5rem !important',
    paddingRight: '0.5rem !important',
    '&:hover': {
      fill: '#52504F !important',
      cursor: 'pointer !important',
    },
  },
  monitorIcon: {
    width: '20px !important',
    height: '20px !important',
    fill: '#FF7015 !important',
    paddingRight: '0.3rem'
  },
  extendMonitorErr: {
    paddingLeft: '16.5% !important',
    paddingTop: '0.5rem'
  },
  topTextOC: {
    color: '#999999 !important',
  },
  programTargetBox: {
    width: '100% !important',
    marginBottom: '5px'
  },
  errorText: {
    color: 'red !important',
  },
  PSCbuttons: {
    '&.MuiButton-root': {
      padding: '4px 30px !important',
    },
  },
  questionPSCButton: {
    padding: '0.25rem !important',
    textTransform: 'none',
    '&:active': {
      backgroundColor: '#000000 !important',
      color: 'white !important',
    },
  },
  questionPSCButtonActive: {
    padding: '0.25rem !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#958C8C !important',
      color: 'white !important',
    },
    backgroundColor: '#958C8C !important',
    color: 'white !important',
  },
  questionPSCNoButton: {
    margin: '0px 0rem  0rem 1.5rem !important'
  },
  clearBtn: {
    color: '#000 !important',
    cursor: 'pointer !important',
  },
  referralBackground: {
    border: '0.1rem solid #DB2323 !important',
    backgroundColor: '#f8ebeb !important',
    borderRadius: '4px'
  },
  reconcileMedListBox: {
    maxHeight: "62vh",
  },
  reconcilHeaderBox: {
    height: "400px",
    overflow: "scroll",
    marginTop: "4px",
  },
  addMeasurementBox: {
    paddingTop: '1rem',
    maxHeight: '30rem !important',
    overflowY: 'auto'
  },
  restrictMedHeaderBox: {
    minHeight: '5rem',
    maxHeight: '20rem !important',
    overflowY: 'auto'
  },
  aaFormContainer: {
    padding: '0rem 0rem 0.5rem 0.5rem !important',
    minHeight: '3.5rem'
  },
  addStartIcon: {
    fontSize: '0.75rem !important',
    marginRight: '-5px !important'
  },
  addMeasurementButton: {
    fontSize: '0.75rem',
    letterSpacing: 0,
    paddingTop: '20px !important',
    marginRight: '0px !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  reconcileHeader: {
    backgroundColor: '#f1f1f1 !important',
    borderBottom: 'none'
  },
  reconcilePendingIcon: {
    fontSize: '1.12rem !important',
    verticalAlign: 'middle !important',
    fill: '#FF7015 !important',
    paddingRight: '5px !important',
  },
  reconcilePendingAction: {
    display: 'flex !important',
    justifyContent: 'right !important',
    paddingRight: '0.2rem !important',
    fontSize: '0.75rem !important',
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.5) !important',
  },
  reconcileIcon: {
    fontSize: '1rem !important',
    verticalAlign: 'middle !important',
    fill: '#08ad08 !important',
    paddingRight: '5px !important',
  },
  reconcileListIcon: {
    fontSize: '1rem !important',
    verticalAlign: 'bottom !important',
    paddingRight: '0rem !important',
  },
  reconcileBg: {
    border: '0.1rem solid #00800078 !important',
    backgroundColor: '#00800052 !important',
    borderRadius: '4px'
  },
  reconcileQuarantineBg: {
    border: '0.1rem solid #f65b5bbf !important',
    backgroundColor: '#fba7a7d1 !important',
    borderRadius: '4px'
  },
  reconcileBgNewMed: {
    border: '0.1rem solid #99ddff !important',
    backgroundColor: '#e6f7ff !important',
    borderRadius: '4px'
  },
  overrideItemCard: {
    border: '0rem solid #F1F1F6 !important',
    backgroundColor: '#F1F1F6 !important',
    borderRadius: '0px'
  },
  overrideBackground: {
    border: '0.1rem solid #FFDAB9 !important',
    backgroundColor: '#FBF6F6 !important',
    borderRadius: '4px'
  },
  overrideTitle: {
    color: '#ffb573 !important',
  },
  overrideTargetRow: {
    backgroundColor: '#FBF6F6 !important',
  },
  updatedTargetRow: {
    backgroundColor: '#FFF !important',
  },
  carePlanBorder: {
    border: '0.1rem solid gray !important',
  },
  tooltipScroll: {
    maxHeight: '10rem !important',
    overflow: 'auto !important',
  },
  refRecomendBackground: {
    backgroundColor: '#52514F !important',
    border: '0.1rem solid #DB2323 !important',
  },
  icdBorder: {
    '&:not(:last-child)': {
      borderBottom: 'lightgray solid 1px !important',
    },
  },
  icdActive: {
    backgroundColor: '#E1E1E1 !important',
  },
  icdDesc: {
    '&:hover': {
      cursor: 'pointer !important',
    },
  },
  icdButtons: {
    marginRight: '1rem !important',
    padding: '1rem, 0.25rem !important',
    textTransform: 'none',
  },
  dialogHeader: {
    background:
      'linear-gradient(180deg, #E1E1E1B3 0%, #F2F2F2 34%, #FFFFFF 78%, #FFFFFF 100%) 0% 0% no-repeat padding-box !important',
    height: '20px !important',
  },
  dialogBtnActionSelected: {
    textTransform: 'none',
    backgroundColor: '#3E3D3B !important',
    '&:hover': {
      color: '#FFFFFF !important',
      backgroundColor: '#3E3D3B !important',
    },
    color: '#FFFFFF !important',
    border: '#3E3D3B solid .1rem !important',
    padding: '0.3125rem 1.625rem !important',
  },
  subModalBackground: {
    backgroundColor: '#F7AC4033 !important',
    padding: '10px 20px'
  },
  addmedicationmodal: {
    position: 'absolute',
    top: '50% !important',
    left: '50% !important',
    transform: 'translate(-50%, -50%) !important',
    width: 400,
    bgcolor: 'background.paper !important',
    border: '2px solid #000 !important',
    boxShadow: '24 !important',
    p: 4,
  },

  displayNone: {
    display: 'none !important',
  },
  backIconRestrict: {
    border: '2px solid #D87C4E !important',
    borderRadius: '50% !important',
    color: '#D87C4E !important',
    padding: '0 !important',
    margin: '0.2rem 0.5rem 0 0.5rem !important',
  },
  visible: {
    visibility: 'hidden',
  },
  toxicityTableSection: {
    border: 'lightgray solid 2px !important',
    padding: '0.5rem !important',
    '&:not(:last-child)': {
      borderBottom: 'none !important',
    },
  },
  bagdeIcon: {
    color: '#00A793 !important',
  },
  editProfileIcon: {
    marginRight: '0.5rem !important',
    '&:hover': {
      cursor: 'pointer !important',
    },
  },
  historyData: {
    borderBottom: '0.5px solid #CCCCCC !important',
    borderTop: '0.5px solid #CCCCCC !important',
  },
  historyTableScroll: {
    maxHeight: '20rem !important',
    overflow: 'auto !important',
  },
  measurementButton: {
    borderTop: '0.5px solid #CCCCCC !important',
    backgroundColor: 'white !important',
  },
  customBadge: {
    backgroundColor: '#958c8c !important',
    color: 'white !important',
  },
  wrapNote: {
    wordWrap: 'break-word',
  },
  infoIcon: {
    color: '#b0abab !important',
    padding: '0rem 0rem 0rem 0.1rem !important',
  },
  desc: {
    fontSize: '0.8rem !important',
    wordBreak: 'break-word',
    lineHeight: '1rem !important',
  },
  compactCard: {
    border: 'none !important',
    alignItems: 'center !important',
    padding: '0px !important',
    marginBottom: '5px !important',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  compactOverrideCard: {
    border: 'none !important',
    alignItems: 'center !important',
    padding: '0px !important',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  compactTargetCard: {
    width: '100% !important',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  medChoicesBox: {
    padding: '0.5rem 1rem 0.5rem 1rem !important',
    maxHeight: '30rem !important',
    overflowY: 'auto'
  },
  medChoicesRecomBox: {
    padding: '0rem 1rem 0.5rem 1rem !important',
    // maxHeight: '30rem !important',
    // overflowY: 'auto'
  },
  medChoiceActiveBtn: {
    // color: '#FF7015 !important',
    fontWeight: '500 !important',
    cursor: 'pointer',
    // '&:hover': {
    //   color: '#FF7015 !important',
    //   backgroundColor: 'rgba(0, 0, 0, 0.0) !important',
    // },
  },
  medChoiceBtn: {
    fontWeight: '400 !important',
    cursor: 'pointer',
  },
  medChoiceCardContent: {
    margin: '0.3rem 0.8rem 0.5rem 0rem !important',
    border: 'rgba(0, 0, 0, 0.12) solid 0.1rem !important',
    boxShadow: '0px 0px 6px #00000029 !important',
  },
  compactMedCard: {
    //  alignItems: 'center',
    padding: '0px',
    marginBottom: '5px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  compactCardContent: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flexDirection: 'row',
    minHeight: '1.16rem !important',
    padding: '2px 2px 2px 5px',
    '& p': {
      fontSize: '0.8rem',
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  compactMenuCard: {
    display: 'flex !important',
    flexDirection: 'row',
    height: '1.8rem !important',
    padding: '0px 4px !important'
  },
  compactTargetMenuItem: {
    justifyContent: 'center !important',
    padding: '0px 0.05rem !important',
  },
  compactMenuItem: {
    justifyContent: 'center !important',
    padding: '0px 0.15rem !important',
  },
  corfirmCompactButtonStyle: {
    color: '#FF7015 !important',
    padding: '0px !important',
    textTransform: 'none',
  },
  compactMenuIcon: {
    fontSize: '18px !important',
  },
  puList: {
    display: 'list-item !important',
    listStyle: 'disc !important',
    padding: '0px !important',
    fontSize: '0.7rem'
  },
  evalContent: {
    display: 'flex !important',
    flexDirection: 'column',
    padding: '0.2rem 1rem 1rem 1rem'
  },
  evalProviderContent: {
    display: 'flex !important',
    flexDirection: 'column',
    padding: '0.2rem 1rem 0.2rem 0rem'
  },
  evalBox: {
    padding: '0.4rem 0rem 0.4rem 0.5rem'
  },
  evalSubBox: {
    paddingLeft: '0.5rem !important'
  },
  evalToxCriteria: {
    padding: '0.3rem 0 0.2rem 1rem'
  },
  evalBoxLabel: {
    fontSize: '0.7rem !important',
    fontWeight: 400,
    letterSpacing: '0.01071em'
  },
  evalBoxVal: {
    color: '#080808 !important',
    fontSize: '0.7rem !important',
    fontWeight: '410 !important',
    letterSpacing: '0.01071em'
  },
  instructionVal: {
    fontSize: '0.7rem !important',
    fontWeight: '410 !important',
    letterSpacing: '0.01071em'
  },
  alignTextCenter: {
    textAlign: 'center'
  },
  circularLoading: {
    height: '90vh !important',
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
  },
  addMeasurementBtn: {
    textAlign: 'right',
    padding: '12px 5px 0px 10px !important'
  },
  subHeadername: {
    fontSize: '0.9rem !important',
    paddingTop: '0rem !important',
    letterSpacing: '0px'
  },
  formControlWidth150: {
    width: '120px'
  },
  substituteBg: {
    backgroundColor: '#FAF8DF'
  },
  substituteRestrictBg: {
    paddingTop: '20px !important',
    backgroundColor: '#FAF8DF'
  },
  substituteRestrictText: {
    color: '#FF7015'
  },

  historyLoading: {
    paddingLeft: '45% !important',
    paddingTop: '10%'
  },

  medHistoryTitles: {
    backgroundColor: 'whitesmoke !important'
  },

  formControlStage: {
    marginTop: '-5px'
  },

  providerRecommendationTable: {
    paddingTop: '0.4rem !important'
  },

  renderMedDropDownWidth: {
    width: '350px'
  },
  dialogTitlePadding: {
    padding: '2px 5px 2px 15px !important'
  },
  closeBtnPadding: {
    padding: '25px 0px 0px 30px'
  },
  schedulesContainer: {
    flexWrap: 'nowrap',
    width: 'max-content !important',
    backgroundColor: '#EDEFF2'
  },
  scheduleTitle: {
    lineHeight: '1 !important',
    wordBreak: "break-word",
    fontSize: '0.75rem !important'
  },
  scheduleDose: {
    cursor: 'pointer !important',

    lineHeight: '1'
  },
  scheduleItemWidth: {
    minWidth: '200px'
  },
  containerPadding: {
    padding: '10px'
  },
  planLoading: {
    position: 'absolute',
    width: '20px !important',
    height: '20px !important',
    margin: '2px'
  },
  MoreVertIconFontSize: {
    fontSize: "19px !important"
  },
  medicationUnits: {
    padding: '0px 0 7px 4px'
  },
  nameLineHeight: {
    // lineHeight: '1 !important'
  },
  autoCompleteDropDowns: {
    width: '100% !important',
    height: 'auto !important',
    borderColor: '#CBCBCB !important',
  },
  medBox: {
    padding: '5px 10px 10px 30px !important',
  },
  rrdFormControl: {
    width: '210px'
  },
  formControlItemsPadding: {
    padding: '0.1rem 0.5rem !important',
    fontSize: '0.8rem !important'
  },
  checkBoxStyle: {
    marginLeft: '0.3rem !important',
    padding: '0.2rem !important',
  },
  totalDailyDose: {
    paddingTop: '1rem'
  },
  rrdButtons: {
    flexWrap: 'nowrap'
  },
  recommendedCheckCircle: {
    verticalAlign: 'text-bottom !important',
    height: '1rem !important',
    fill: 'green !important'
  },
  conditionsWordBreak: {
    wordBreak: 'break-all'
  },
  formulationDisplayName: {
    paddingTop: '0.1rem'
  },
  puSchedulePadding: {
    paddingBottom: '0.5rem'
  },

  doseTextFieldPadding: {
    padding: '3px 0 6px'
  },
  formControlWidth250: {
    width: '250px'
  },
  formControlWidth165: {
    width: '165px'
  },

  toxicityEvalBoxVal: {
    color: '#080808 !important',
    fontSize: '0.7rem !important',
    fontWeight: 410,
    letterSpacing: '0.01071em !important',
    paddingLeft: '5px'
  },
  criteriaList: {
    padding: '0rem 0rem 0rem 0.1rem !important'
  },
  loadingEval: {
    padding: '0.3rem !important',
    width: '25px !important',
    height: '25px !important'
  },
  dialogContentStyle: {
    padding: "0 !important",
    // height: "23.6rem",
    overflow: "hidden",
    Widgets: '100%'
  },
  prbdialogContentStyle: {
    padding: "0",
    minHeight: "4rem",
    overflow: "hidden"
  },
  linearProgressContainer: {
    //  height: "450px",
    overflow: "hidden"
  },
  circularProgressBar: {
    padding: '1rem',
    width: '20px !important',
    height: '20px !important',
    color: '#FF7015 !important'
  },
  dialogHeaderBox: {
    height: "24rem",
    overflowY: "scroll",
    marginTop: "4px",
  },
  rationaleDialogHeaderBox: {
    height: "24rem",
    overflowY: "auto",
  },
  errorDataDialogHeaderBox: {
    maxHeight: "24rem",
    overflowY: "auto",
    padding: '0.3rem 1rem 0rem 0.8rem',
    marginBottom: '0.5rem'
  },
  rationaleDialogHeader: {
    background:
      'linear-gradient(180deg, #E1E1E1B3 0%, #F2F2F2 34%, #FFFFFF 78%, #FFFFFF 100%) 0% 0% no-repeat padding-box !important',
    height: '0.1rem !important',
  },
  editIcon: {
    fontSize: '16px !important'
  },
  iconFontSize: {
    fontSize: "small"
  },
  visibleIconSize: {
    fontSize: '17px !important'
  },
  notValCompactMenuIcon: {
    fontSize: '18px !important',
    color: '#FF7015'
  },
  overideIcon: {
    fontSize: '18px !important',
    color: '#757575'
  },
  customMiniCard: {
    width: '100% !important',
    marginBottom: '0px !important'
  },
  cardsContentPadding: {
    padding: '4px 5px 4px 4px !important'
  },
  medCardsContentPadding: {
    padding: '0px 4px 0px 0px !important'
  },
  conditionsContainer: {
    display: 'flex !important',
    justifyContent: 'flex-end'
  },
  conditionsItem: {
    padding: '7px 4px 0px 0px !important',
    width: '40%'
  },
  evalTitleHeader: {
    maxWidth: '78%'
  },
  programCardContent: {
    padding: '8px 8px 0px 8px !important'
  },

  initialFlexwrap: {
    flexWrap: 'initial'
  },
  textAlignEnd: {
    textAlign: 'end'
  },
  textAlignStart: {
    textAlign: 'start'
  },
  notActiveIcon: {
    color: '#FF7015 !important',
    paddingRight: '0.5rem'
  },
  helpOutlinePadding: {
    paddingRight: '0.5rem'
  },
  descleftHeader: {
    fontSize: '0.8rem !important',
    lineHeight: '1 !important',
    color: '#F7AC40'
  },

  pronunciationKeyboard: {
    verticalAlign: 'middle !important',
    height: '20px !important',
    fill: '#FF7015 !important',
    cursor: 'pointer'
  },
  pointerCursor: {
    cursor: 'pointer'
  },
  contextualHelpContext: {
    minHeight: '3.5rem'
  },
  contextualHelpBox: {
    maxHeight: '25rem',
    marginBottom: '0.8rem !important',
    overflowY: 'auto'
  },
  contextualHelpContainer: {
    padding: '0.8rem 1rem 0.5rem 1.5rem !important',
    minHeight: '4rem !important',
  },
  contextualHelpHeader: {
    borderBottom: 'lightgray solid 1px !important',
    padding: '0.5rem 0.5rem 0.5rem 1.5rem !important',
  },
  formControlDateTime: {
    width: '100% !important',
    marginTop: '1rem'
  },

  // Delete Card Component
  deleteCardContainer: {
    border: '#FF7015 solid 0.1rem !important',
    width: '100%'
  },
  deleteButtonStyle: {
    justifyContent: 'space-around !important',
    display: 'flex !important',
    flexWrap: 'wrap',
    width: '100% !important',
    borderTop: 'lightgray solid 1px'
  },
  deleteButtonWidth: {
    width: '50% !important',
    color: '#FF7015 !important',
    textTransform: 'none',
  },
  headertext: {
    lineHeight: '20px !important',
    fontSize: '15px'
  },
  timestamp: {
    fontSize: '13px !important',
    color: '#999999'

  },
  confirmDelete: {
    paddingTop: '20px !important',
    lineHeight: '20px !important',
    fontSize: '15px'
  },
  customDeleteCard: {
    '& >div': {
      boxShadow: '0px 4px 8px 0px rgb(0 0 0 / 7%), 0px 6px 20px 0px rgb(0 0 0 / 7%) !important',
    }
  },
  deleteCardContent: {
    paddingBottom: '1rem'
  },
  emptyCardContent: {
    padding: '0.5rem !important',
    background: '#f8f8f8'
  },
  confirmCardContent: {
    padding: '0rem 0rem 0.2rem 0.85rem !important',
  },
  emptyRecommendedCard: {
    width: '100% !important',
    border: '1px solid gray'
  },
  emptyRecommendedCardContent: {
    paddingBottom: '1rem !important',
    background: '#f8f8f8'
  },
  infoIconFont: {
    fontSize: '15px !important'
  },
  medicationsBox: {
    width: '100% !important',
    display: 'block !important',
    marginBottom: '0rem'
  },
  medicationTextField: {
    width: '25%'
  },
  medicationsBoxDisplay: {
    display: 'block !important',
  },

  notesListContainer: {
    maxHeight: '200px !important',
    overflow: 'auto'
  },
  notesCarePlanText: {
    overflowWrap: 'break-word'
  },

  badgeContainer: {
    marginRight: '0.5rem !important'
  },
  badgeButton: {
    margin: '0px 0.5rem !important'
  },
  badgeButtonText: {
    textTransform: 'capitalize'
  },
  zeroPadding: {
    padding: '0px !important'
  },
  patientAccrodionBg: {
    backgroundColor: '#f1f1f1 !important'
  },
  patientAccrodionExpandIcon: {
    fontSize: '1.5rem !important'
  },
  zeroTopPadding: {
    paddingTop: '0px !important'
  },
  addPatientQuestionHeaderSection: {
    borderBottom: 'lightgray solid 1px !important',
    padding: '10px 20px !important',
  },
  addPatientDialogContent: {
    borderBottom: 'lightgray solid 1px',
    minHeight: '5rem'
  },
  addPatientInput: {
    display: 'none'
  },

  selectedFile: {
    padding: '12px'
  },
  addPatientTextField: {
    width: '320px'
  },
  JourneyComponentContainer: {
    borderBottom: '1px solid lightgray'
  },
  journeyStageBox: {
    height: '0.85rem !important',
    width: '100%',
    fontSize: '0.6rem !important',
    margin: '0.3rem 0.8rem 0.3rem 0rem !important',
  },
  journeyMedDescription: {
    fontSize: '0.55rem!important',
    padding: '0.1rem 0.25rem 0.25rem 0rem',
    letterSpacing: '0.01071em',
    lineHeight: '1 !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
  },
  timeLine: {
    width: 'inherit !important',
  },

  multiProgramInfoContainer: {
    flexWrap: 'inherit',
    alignItems: 'center !important',
    width: '100%',
    paddingTop: '0.28rem'
  },
  multiProgramInfoHeader: {
    display: 'flex !important',
    width: '100%'
  },
  patientProfileContainer: {
    paddingTop: '1rem'
  },

  subHeaderProgramName: {
    marginTop: '0.06rem'
  },
  subHeaderStageBox: {
    paddingLeft: '20px'
  },
  programIcons: {
    marginRight: '0.5rem !important',
  },
  subHeaderStatusIcon: {
    padding: '2px 0px 5px 5px !important',
  },
  warningIcon: {
    fill: 'orange !important',
    fontSize: '21px !important',
    padding: '4px 5px 5px 4px !important',
  },
  subHeaderContainerBg: {
    padding: '16px 24px 8px',
    backgroundColor: '#f0f1f2'
  },
  subHeaderData: {
    display: 'inline-flex'
  },
  actionSelect: {
    maxWidth: '9rem !important',
    width: '100% !important',
    paddingTop: '0px',
    fontSize: '0.8rem !important',
    '&:before': {
      border: 'none !important',
    },
    '&:after': {
      border: 'none !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
  stageLabel: {
    fontSize: '0.8rem !important',
    paddingTop: '0px',
    fontWeight: 500,
  },
  calculatorCard: {
    width: '100% !important',
    paddingLeft: '6px'
  },
  calAccordionSummary: {
    margin: '-4px 0px 0px 0px !important',
    paddingRight: '0px !important'
  },
  calculatorQuestionnarieTitleFont: {
    fontSize: '1rem'
  },
  nextActionSelect: {
    width: '6rem',
    paddingTop: '0px',
    fontSize: '0.8rem',
    '&:before, &:after, & .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
  actionMenu: {
    fontSize: '0.8rem !important',
    color: '#D87C4E !important',
    padding: '0.15rem 0.6rem !important',
  },
  ptHistoryBox: {
    width: '100% !important',
    display: 'block'
  },
  tableHistoryHeader: {
    padding: '0.3rem 0.2rem 0rem 0.5rem !important',
    width: '100%'
  },
  tablePtHistory: {
    padding: '0.3rem 0.2rem 0.3rem 0.5rem !important',
    width: '35%'
  },
  tableCellPtHistory: {
    padding: '0.3rem 0.2rem 0rem 0rem !important',
    width: '15%'
  },
  historyDate: {
    fontSize: '0.75rem !important',
    fontWeight: 500
  },
  tableCellDDHistory: {
    padding: '0.3rem 0rem 0.3rem 0rem !important',
    width: '15%'
  },
  tableCellChHistory: {
    padding: '0.3rem 0.2rem 0.3rem 0rem !important',
    width: '0%'
  },
  tableCellIconPtHistory: {
    padding: '0.3rem 0.25rem 0.3rem 0rem !important',
    width: '15%'
  },
  ptHistoryIconSize: {
    fontSize: '15px !important'
  },

  autoWidth: {
    width: 'auto'
  },
  formControlManageLab: {
    width: '240px'
  },
  selectEmptyFontSIze: {
    fontSize: '0.75rem'
  },
  manageLabButtonsContainer: {
    marginLeft: '25px'
  },
  manageLabAddButton: {
    padding: '0px !important',
    margin: '0px !important',
    fontSize: '13px !important',
    minWidth: 'auto !important',
    marginRight: '5px !important',
  },
  manageLabCancelButton: {
    paddingLeft: '5px !important',
    margin: '0px !important',
    fontSize: '13px !important',
    minWidth: 'auto !important',
  },
  programTargetFirstTableInputTitle: {
    width: '78%'
  },
  programTargetFirstTableInputTitleColor: {
    color: '#FF7015'
  },
  programTargetFirstTableWidth: {
    width: '11%'
  },
  programTargetTargetHeader: {
    width: '11% !important',
    paddingLeft: '5px'
  },
  programTargetName: {
    display: 'flex !important',
    flexDirection: "row"
  },
  programTargetEditIcon: {
    fontSize: '15px !important',
  },
  programTargetVisibilityIcon: {
    fontSize: '17px !important'
  },
  programTargetContainer: {
    padding: '0.4rem 0.6rem 0rem 0.6rem !important'
  },

  customCardTarget: {
    width: '100% !important',
    display: 'block !important',
    marginBottom: '0rem'
  },
  programTargetsConatiner: {
    padding: '16px'
  },
  programTargetsNames: {
    marginBottom: '0.8rem !important',
    flexWrap: 'nowrap'
  },
  targetDropDownPadding: {
    height: '1.8rem !important',
    fontSize: '0.75rem !important',
    '& .MuiOutlinedInput-input': {
      paddingRight: '1.8rem !important',
    }
  },
  breakWord: {
    wordBreak: 'break-word'
  },

  targetCardTimeZone: {
    padding: '0.7rem 0.05rem 0.5rem 0.05rem !important'
  },

  autoCompleteMedicineContainer: {
    lineHeight: '1 !important',
    paddingBottom: '0.25rem'
  },
  autoCompleteMedicineChips: {
    paddingTop: '0.3rem'
  },

  medIntoleranceContainer: {
    padding: '0px 7px 0px 8px'
  },
  medIntoleranceCard1: {
    width: '100% !important',
    marginBottom: '1rem'
  },
  medIntoleranceCard2: {
    width: '100% !important',
    marginBottom: "0.5rem"
  },

  programMedicationsButton: {
    backgroundColor: 'white !important',
    color: '#FF7015'
  },

  programMedicationsButtonText: {
    whiteSpace: 'nowrap',
    textTransform: 'none'
  },
  whiteSpaceNowrap: {
    whiteSpace: 'nowrap',
  },
  programMedicationsCardContent: {
    padding: '0px 6px 0px 5px !important'
  },
  ProgramMedicationsNameContainer: {
    width: '97%'
  },
  ProgramMedicationsName: {
    color: '#FF7015 !important',
    paddingTop: '0.1rem'
  },
  carePlanId: {
    width: '3%'
  },
  printInstructionsContainer: {
    marginBottom: '5px'
  },
  subMedicationImportedSource: {
    paddingTop: '10px'
  },
  srcAttributes: {
    cursor: 'pointer !important',
    fontSize: '0.75rem !important',
    paddingTop: '0px'
  },

  scheduleCarePlanTable: {
    padding: '0.5rem'
  },
  scheduleCarePlanContainer: {
    width: '100% !important',
    backgroundColor: '#edeff2 !important',
    border: '0.1rem solid lightgray'
  },
  scheduleIcon: {
    fontSize: '0.75rem !important',
    lineHeight: '0.8rem !important',
    paddingRight: '0.1rem'
  },
  scheduleDoseInfo: {
    fontSize: '0.8rem !important',
    wordBreak: 'break-word',
    lineHeight: '0.8rem !important',
  },
  preferredScheduleCarePlan: {
    paddingTop: '0.6rem !important'
  },
  alternateSchedules: {
    cursor: 'pointer !important',
    lineHeight: '1'
  },
  questionHeaderSectionBg: {
    backgroundColor: '#fffcf5'
  },

  filterChunkContainer: {
    borderTop: '1px solid lightgray !important'
  },
  filterBorderBottom: {
    borderBottom: '1px solid lightgray !important'
  },
  filterChunkItems: {
    overflowWrap: 'anywhere',
    flexWrap: 'initial'
  },
  filtersAccordionDetails: {
    padding: '0px 5px 0px 0px !important',
    display: 'block'
  },
  programCategory: {
    paddingLeft: '8px'
  },
  searchFieldBg: {
    backgroundColor: '#fff !important'
  },
  addPatientIconBtn: {
    margin: '0px 5px 0px 15px !important'
  },
  syncBtn: {
    margin: '0px 5px 0px 5px'
  },
  patientDataTableContainer: {
    maxHeight: '80vh'
  },
  patientListHeadersBorder: {
    borderRight: 'solid 0.01rem lightgray !important',
    borderBottom: 'solid 0.01rem lightgray !important',
  },
  patientsName: {
    color: '#883A00 !important',
    textDecoration: 'none'
  },
  overflowWrapAnywhere: {
    overflowWrap: 'anywhere'
  },
  overflowWrapBreakWord: {
    overflowWrap: 'break-word'
  },
  patientTextColor: {
    color: '#883A00'
  },
  adornedEnd: {
    paddingRight: "2px !important"
  },
  navbar: {
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    display: 'flow-root'
  },
  pnfContainer: {
    paddingTop: '10% !important',
  },
  subHeaderPadding: {
    padding: "4px 0 4px 4px !important"
  },
  historySelect: {
    width: '8rem !important',
    paddingTop: '0px !important',
    fontSize: '0.8rem !important',
    '&:before, &:after, & .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
    '& .MuiSelect-select:focus': {
      background: 'none'
    }
  },
  historyMenu: {
    fontSize: '0.8rem !important',
    padding: '0.15rem 0.6rem !important',
  },
  programSelect: {
    minWidth: '10rem !important',
    width: 'auto !important',
    paddingTop: '0px !important',
    fontSize: '0.85rem !important',
    '&:before, &:after, & .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
    '& .MuiSelect-select:focus': {
      background: 'none'
    }
  },
  programMenu: {
    fontSize: '0.85rem !important',
    padding: '0.15rem 0.7rem !important',
  },
  programIcon: {
    fill: '#0cae0c !important',
    width: '15px !important',
    cursor: 'none !important',
    paddingLeft: '0.1rem !important',
    verticalAlign: 'middle'
  },
  resetPatientIcon: {
    opacity: '80% !important',
    color: '#FF7015 !important',
    fontSize: '15px !important',
    padding: '0.1px !important',
  },
  medReconcileIcon: {
    opacity: '80% !important',
    fontSize: '18px !important',
    padding: '0.1px'
  },
  medReconcileDisabledIcon: {
    opacity: '80% !important',
    fontSize: '18px !important',
    padding: '0.1px !important',
    cursor: 'default !important',
    fill: '#80808091'
  },
  addLabReport: {
    width: '80% !important',
    fontSize: '0.8rem !important',
    backgroundColor: '#ffffff !important',
  }
}

export default stylesheet
