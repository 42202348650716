import React, { useEffect, useState, useRef } from 'react'
import {
  AppBar, Button, DialogActions, DialogContent, DialogTitle, Tab, Tabs, Box, Grid,
  LinearProgress, Dialog, Typography
} from '@mui/material'
import stylesheet from '../detail-container.stylesheet'
import { ClassNameMap, withStyles } from '@mui/styles'
import DialogModalHeaderComponent from './dialog-modal-header.component'
import ScheduleCarePlanDialogComponent from '../care-plan/schedule-care-plan-dialog.component'
import DetailPrgMedComponent from '../medications/detail-prg-med-dialog.component'
import { PatientService } from '../../../../services/patient.service'
import { toast } from 'react-toastify'
import Helper from '../../../../utils/helper'
import NotesComponent from './notes.component'
import { resetSelectedPU } from '../../../../redux/common-data-slice'
import { RootState, useAppThunkDispatch } from '../../../../redux/store'
import ConfirmationDialogComponent from './confirmation-dialog.component'
import { useDispatchAPIs } from '../../../../utils/use-dispatchApis-hook'
import { useSelector } from 'react-redux'
import MedHistoryComponent from '../care-plan/med-history.component'
import MedReferralComponent from '../care-plan/med-referral.component'
import Draggable from "react-draggable"
import ActionDropdownComponent from './action-dropdown.component'
import MedChoicesComponent from './med-choices.component'
import { useUpdateDataAction } from '../../../../utils/update-details-hook'

type compSet = { title: string; index: number; comp: string; props: any }
type actionSet = { title: string; returnFun: any }
type configSetType = {
  id: string
  role: string
  stage: string
  source_type: string
  actions: actionSet[]
  focusTabIndex: number
  tabs: compSet[]
}

interface IProps {
  open: boolean
  currentRole: string
  item: any
  configItem: configSetType
  handleDialogClose: any
  patientId: any
  classes: ClassNameMap
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

const BaseDialogModalOtherComponent: React.FC<IProps> = ({
  open,
  currentRole,
  item,
  configItem,
  handleDialogClose,
  patientId,
  classes,
}) => {
  const [value, setValue] = useState(configItem?.focusTabIndex || 0)
  const [mapState, setMapState] = useState(new Map())
  const [loading, setLoading] = useState(false)
  const [ddlValue, setDdlValue] = useState(null as any);
  const [confirmContent, setConfirmContent] = useState('');
  const [stageActions, setStageActions] = useState([])
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [isMedChoices, setIsMedChoices] = useState(false)
  const patientService = new PatientService()
  const uiResource = useSelector((state: RootState) => state.metaData.metadata)
  const { setProgramConfirmCount } = useDispatchAPIs(patientId);
  const dispatch = useAppThunkDispatch()
  const updateMap = (_key: string, _value: any) => setMapState((map) => new Map(map.set(_key, _value)))
  const metaData = useSelector((state: RootState) => state.metaData.metadata)
  const baseMedRef = useRef(null) as any
  const { updatePatientData } = useUpdateDataAction(patientId)

  useEffect(() => {
    getProgramActions()
  }, [])

  const getProgramActions = () => {
    const currStageActions = Object.keys(metaData?.STAGE_PROG_MED_ACTION_RESTRICTION)
      ?.filter((key: any) => (key === configItem?.stage))
      ?.reduce((obj: any, key: any) => metaData?.STAGE_PROG_MED_ACTION_RESTRICTION[key], []);
    setStageActions(currStageActions)
  }

  const getActionList = (currActionList: any, is_rec: any) => {
    let currActionObj = { ...currActionList }
    // if (Object.keys(currActionList)?.length > 0 && is_rec) {
    //   delete currActionObj['titrate_cur']
    //   delete currActionObj['up']
    // }
    return currActionObj
  }

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    if (configItem?.tabs) {
      let activeTab = configItem?.tabs && configItem.tabs.find((i: any, index: any) => index === newValue)
      if (activeTab && activeTab?.comp !== 'ScheduleCarePlanComponent_Key') {
        dispatch(resetSelectedPU())
      }
    }
    setValue(newValue)
  }

  const handleConfirmClose = () => {
    setConfirmOpen(false)
    setConfirmContent('')
  }

  const handleActionErr = (msg: any) => {
    setLoading(false)
    setDdlValue(null)
    toast.error(msg ? msg : `Failed to process action`, Helper.bottom_center())
    handleDialogClose()
  }

  const confirmStatus = (param: boolean) => {
    if (param) {
      setConfirmOpen(false)
      setDdlValue(confirmContent)
      let actions = confirmContent.split('/')
      let action = actions[0]
      let sub_action = actions[1]
      if (action === sub_action) sub_action = ''
      setLoading(true)
      patientService.putPatientActions(patientId, item.id, item?.programs[0], action, sub_action, item.care_plan_id)
        .then((_response: any) => {
          setLoading(false)
          setDdlValue(null)
          const { success } = _response?.data
          if (success) {
            //toast.success('Action processed successfully.', Helper.bottom_center());
            handleDialogClose()
            updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
          } else
            handleActionErr(success?.error || `Failed to process action`)
        }).catch((error) => {
          let msg = ''
          if (error?.response?.data?.error) {
            msg = (error?.response?.data?.error).toString()
            msg = (error?.response?.data?.exclusion_reason)
              ? `${msg}. Exclusion reason ${(error?.response?.data?.exclusion_reason).toString()}`
              : msg
          } else {
            msg = error?.message || `Failed to process action`
          }
          handleActionErr((error?.response?.status === 500) ? `Failed to process action` : msg)
        })
    }
  }

  const outerSubmit = (key: string, dt: any) => updateMap(key, dt)

  const returnComp = (_component: compSet) => {
    if (_component.comp === 'ScheduleCarePlanComponent_Key') {
      return React.createElement(
        ScheduleCarePlanDialogComponent,
        { ..._component.props, outerSubmit, compKey: 'c_rec_schedules', mapState },
        null
      )
    }
    if (_component.comp === 'NotesComponent_Key') {
      return React.createElement(NotesComponent, { ..._component.props, compKey: 'NotesComponent_Key' }, null)
    }
    if (_component.comp === 'ReferralComponent_Key') {
      return React.createElement(MedReferralComponent, { ..._component.props, item: item })
    }
    if (_component.comp === 'DetailsComponent_Key') {
      return React.createElement(
        DetailPrgMedComponent,
        {
          ..._component.props, medicineInfo: { ...item, stage: configItem?.stage }, outerSubmit, compKey: 'DetailsComponent_Key'
        },
        null
      )
    }
    if (_component.comp === 'MedHistoryComponent_Key') {
      return React.createElement(
        MedHistoryComponent,
        { ..._component.props, item: item },
        null
      )
    }
  }

  const a11yProps = (index: number) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  const confirmMedicine = (medicinedata: any) => {
    const payload = { entity_ref: 'medication', entity_id: medicinedata.id, confirmed_by: patientId }

    patientService
      .PostConfirmations(payload)
      .then((_response: any) => {
        handleDialogClose()
        updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
      })
      .catch((err: any) => {
        if (item?.programs?.length) {
          setProgramConfirmCount(item?.needs_confirmation, true, item?.programs)
        }
        toast.error(Helper.getErrorText(err), Helper.bottom_center())
      })
  }

  const getIntakeReg = (reg: string) => Object.keys(uiResource.INTAKE_REGULARITIES)?.find((key: any) => uiResource.INTAKE_REGULARITIES[key] === reg) || ''

  const actionsButtonCall = async (c: any) => {
    if (c.title === 'Cancel') {
      c.returnFun()
    } else if (c.title.includes('Save')) {
      setLoading(true)

      const values = mapState.get('values')
      setLoading(false)

      let payload: any
      if (values?.intake_regularity === 'not taking') {
        payload = {
          med_id: item.med_id,
          med_type: item.med_type,
          intake_regularity: getIntakeReg(values?.intake_regularity),
          user_role: currentRole,
          notes: values.note,
          prescription_taken: values.prescription_taken
        }
      } else {
        payload = {
          med_id: item.med_id,
          med_type: item.med_type,
          num_units: values.num_units,
          delivery_method: values?.delivery_method,
          intake_quantity: values?.intake_quantity,
          intake_regularity: getIntakeReg(values?.intake_regularity),
          frequency: values?.frequency,
          dosage_info: values?.dosage_info,
          source_type: item.source_type,
          notes: values.note,
          user_role: currentRole,
          prescription_taken: values.prescription_taken
        }
      }

      if (mapState.has('c_rec_schedules') && mapState.get('c_rec_schedules'))
        payload = { ...payload, 'c_rec_schedules': mapState.get('c_rec_schedules') }

      patientService
        .putOtherMedication(patientId, item.id, payload)
        .then((response: any) => {
          if (c.title === 'Save & Confirm') {
            confirmMedicine(response.data)
          } else if (c.title === 'Save') {
            if (item?.programs?.length && item?.needs_confirmation !== response.data.needs_confirmation)
              setProgramConfirmCount(item?.needs_confirmation, response.data.needs_confirmation, item?.programs)
            //toast.success(`Saved successfully.`, Helper.bottom_center())
          }
        })
        .catch((error: any) => {
          toast.error(Helper.getErrorText(error), Helper.bottom_center())
        })
    }
  }

  const handleActionChange = async (e: any) => {
    setConfirmContent(e.value)
    setConfirmOpen(true)
  }

  const isSchedule = () => (item?.c_rec_schedules?.length > 0 && item?.c_schedule?.name) ? true : false

  return <>
    {open && (
      <Draggable ref={baseMedRef} defaultPosition={{ x: 280, y: 30 }} handle='.prg-med-dialog'>
        <Dialog
          id={'other-med-id'}
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={handleDialogClose}
          hideBackdrop
          disableScrollLock
          onKeyUp={(e) => {
            if (e.key === 'Enter' && !loading)
              actionsButtonCall(configItem?.actions?.find((i: any) => i.title === "Save & Confirm"))
          }}
          aria-labelledby="other-med-dialog"
        >
          <DialogTitle className={classes.dialogTitlePadding} classes={{ root: 'prg-med-dialog' }}>
            {React.createElement(
              DialogModalHeaderComponent,
              { item: { ...item, isOtherMed: true }, handleDialogClose: handleDialogClose },
              null
            )}
          </DialogTitle>
          <DialogContent className={classes.dialogContentStyle}>
            <div className={classes.root}>
              <AppBar position="static" color="transparent">
                <Tabs value={value} onChange={handleChange} variant="standard">
                  {configItem?.tabs?.map((c: compSet) => (
                    <Tab
                      key={`config-${c.title}`}
                      label={c.title}
                      {...a11yProps(c.index)}
                      disabled={false}
                    />
                  ))}
                </Tabs>
              </AppBar>
              <Grid className={classes.linearProgressContainer}>
                {loading === true && <LinearProgress />}
                <Box className={classes.dialogHeaderBox}>
                  <div className={classes.dialogHeader} />
                  {configItem?.tabs?.map((c: compSet, i: any) => (
                    <Box pl={2} pr={2} key={`compset-${i}`}>
                      <TabPanel value={value} index={c.index}>
                        {returnComp(c)}
                      </TabPanel>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </div>
          </DialogContent>
          <DialogActions className={classes.zeroPadding}>
            <Grid container justifyContent={'space-between'} flexDirection={'row'} className={`${classes.boxBorder} ${classes.containerPadding}`}>
              <Grid item>
                <Button sx={{ padding: '1rem 1rem 0rem 1rem' }}>
                  <Typography
                    className={classes.activeBtn}
                    variant="subtitle2"
                    onClick={() => setIsMedChoices(!isMedChoices)}
                  >Alternative recommendations
                  </Typography>
                </Button>
                {item?.intake_regularity === 'taking' &&
                  item?.care_plan_id &&
                  Object.keys(stageActions)?.length > 0 &&
                  <ActionDropdownComponent
                    stageActions={(!isSchedule() || item?.needs_update || item.needs_confirmation)
                      ? {}
                      : getActionList(stageActions, item?.is_recommended || false)
                    }
                    value={confirmContent}
                    handleActionChange={(e: any) => handleActionChange(e.target)}
                  />
                }
              </Grid>
              <Grid item>
                <Grid container spacing={2} justifyContent="center">
                  {configItem?.actions?.map((c: actionSet, index: any) => (
                    <Grid key={`action-${index}`} item>
                      <Button
                        variant="outlined"
                        disabled={loading}
                        size="small"
                        onClick={() => actionsButtonCall(c)}
                      >
                        {c.title}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
          <DialogActions>
            {isMedChoices &&
              <Box sx={{ padding: '0rem 0rem 1rem 0rem', minHeight: '2rem', maxHeight: '15rem', overflowY: 'auto' }}>
                <MedChoicesComponent
                  open={isMedChoices}
                  handleClose={() => null}
                  id={item.care_plan_id}
                  title={''}
                  category={'Treatments'}
                />
              </Box>
            }
          </DialogActions>
        </Dialog>
      </Draggable>
    )}
    {confirmOpen && (
      <ConfirmationDialogComponent
        open={confirmOpen}
        content={`Are you sure for action : ${confirmContent}`}
        handleConfirmClose={handleConfirmClose}
        confirmStatus={confirmStatus}
      />
    )}
  </>
}
export default withStyles(stylesheet)(BaseDialogModalOtherComponent)

