import React, { useState, ReactElement, useRef, useEffect } from 'react'
import { withStyles, ClassNameMap } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import {
	Box,
	Dialog, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, Link, Typography,
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Draggable from "react-draggable"
import EmptyCardComponent from './empty-card.component';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { PatientService } from '../../../../services/patient.service';

interface IProps {
	open: boolean
	handleClose: any
	input: any
	classes: ClassNameMap
}

const RestrictedMedDialogComponent: React.FC<IProps> = ({ open, handleClose, input, classes }) => {
	const conextRef = useRef(null) as any
	const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
	const patientService = new PatientService()
	const [loading, setLoading] = useState(false)
	const [restrictedMed, setRestrictedMed] = useState({} as any)

	useEffect(() => {
		getRestrictedMed()
	}, [])

	const getRestrictedMed = async () => {
		setLoading(true)
		input?.id && patientService.getRestrictedMed(patientDetail?.id, input?.id, input?.type, input?.propagation).then((response: any) => {
			if (Object.keys(response?.data).length > 0) {
				setRestrictedMed(response?.data || {})
			}
			setLoading(false)
		}).catch(() => setLoading(false))
	}

	return <>
		{open &&
			<Draggable ref={conextRef} defaultPosition={{ x: 80, y: 20 }} handle='.med-rest-dialog'>
				<Dialog
					id={`med-rest-${input?.name}}`}
					fullWidth={true}
					maxWidth="md"
					open={open}
					onClose={handleClose}
					hideBackdrop
					disableScrollLock
					sx={{
						position: "absolute",
						left: '15%',
						top: '12%'
					}}
				>
					<DialogTitle className={classes.contextualHelpHeader} classes={{ root: 'med-rest-dialog' }}>
						<Grid container justifyContent="space-between">
							<Grid item>
								<Typography color="textSecondary">
									<small>{`Med restriction / Treatments`}</small>
								</Typography>
								<Typography id="modal-modal-title" variant="h6">
									<strong>{input?.name}</strong>
								</Typography>
							</Grid>
							<Grid item>
								<IconButton size="small" onClick={() => handleClose()}>
									<CloseOutlinedIcon />
								</IconButton>
							</Grid>
						</Grid>
					</DialogTitle>
					{loading && <LinearProgress />}
					<DialogContent className={classes.dialogHeaderBox} sx={{ marginBottom: '1rem !important' }}>
						{Object.keys(restrictedMed)?.length > 0 &&
							(Object.keys(restrictedMed?.patient_aa_forms)?.length > 0 ||
								Object.keys(restrictedMed?.aa_forms)?.length > 0)
							? <Grid container direction="column" spacing={1} className={classes.dialogHeader} sx={{ padding: '0.5rem 0.2rem !important' }}>
								<Grid item className={classes.fullWidth}>
									{[...restrictedMed?.patient_aa_forms || [], ...restrictedMed?.aa_forms || []]
										?.sort()?.map((item: any) => (
											<Grid item key={item}>
												<Typography key={item} variant="body2">{item}{restrictedMed?.patient_aa_forms?.find((i: any) => i === item) ? ' *' : ''}
												</Typography>
											</Grid>
										))}
								</Grid>
							</Grid>
							: <Box pt={2}><EmptyCardComponent /></Box>
						}
					</DialogContent>
				</Dialog>
			</Draggable>
		}
	</>
}

export default withStyles(stylesheet)(RestrictedMedDialogComponent)