import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ProgramChecksState {
    program_checks: any[]
}

const initialState: ProgramChecksState = {
    program_checks: [],
}

export const programChecksSlice = createSlice({
    name: 'program_checks',
    initialState,
    reducers: {
        setProgramChecksStore: (state, action: PayloadAction<any>) => {
            state.program_checks = action.payload
        }
    }
})

export const { setProgramChecksStore } = programChecksSlice.actions

export default programChecksSlice.reducer