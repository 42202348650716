import React, { memo, useRef } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { ClassNameMap, withStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { RootState } from '../../../../redux/store'
import stylesheet from '../detail-container.stylesheet'
import Helper from '../../../../utils/helper'
import EmptyCardComponent from '../common/empty-card.component'
import ProgramLifestyleTypeComponent from './program-lifestyle-type.component'

interface IProps {
  input: any
  classes: ClassNameMap
}
const ProgramLifestylesComponent: React.FC<IProps> = ({ input, classes }) => {
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const inputElTaking = useRef(null) as any
  const inputElNotTaking = useRef(null) as any

  const getLifestyleListByType = (data: any, type: any) => {
    return <Accordion square defaultExpanded className={classes.patientAccrodionBg}>
      <AccordionSummary
        ref={type === 'active' ? inputElNotTaking : inputElTaking}
        expandIcon={<ExpandMoreIcon className={classes.patientAccrodionExpandIcon} />}
      >
        <Typography className={classes.subTitle}>{Helper.stageName(type)}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item className={classes.fullWidth}>
            <ProgramLifestyleTypeComponent
              input={{
                data: data,
                type: type
              }}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion >
  }

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center"  >
      {patientDetail?.medications?.lifestyles !== undefined && Object.keys(patientDetail?.medications?.lifestyles)?.length > 0 && !input?.actionProgram &&
        <Grid item className={classes.fullWidth}>
          {getLifestyleListByType(patientDetail?.medications?.lifestyles, 'active')}
        </Grid>
      }
      {patientDetail?.medications?.stopped_lifestyles && Object.keys(patientDetail?.medications?.stopped_lifestyles)?.length > 0 && !input?.actionProgram &&
        <Grid item className={classes.fullWidth}>
          {getLifestyleListByType(patientDetail?.medications?.stopped_lifestyles, 'completed')}
        </Grid>
      }
      {!input?.actionProgram &&
        (patientDetail?.medications?.lifestyles && Object.keys(patientDetail?.medications?.lifestyles)?.length < 1 &&
          patientDetail?.medications?.stopped_lifestyles && Object.keys(patientDetail?.medications?.stopped_lifestyles)?.length < 1
        ) && <EmptyCardComponent />
      }
    </Grid>
  )
}

export default withStyles(stylesheet)(memo(ProgramLifestylesComponent))
