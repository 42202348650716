import React, { useState, useEffect, memo } from 'react'
import { Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { ClassNameMap, withStyles } from '@mui/styles'
import { useParams } from 'react-router-dom'
import { RootState } from '../../../../redux/store'
import { useReferralAction } from '../../../../utils/referral-hook'
import stylesheet from '../detail-container.stylesheet'
import EmptyCardComponent from '../common/empty-card.component'
import CardLayoutComponent from '../common/card-layout.component'
import moment from 'moment'
import ToxicityDialogComponent from '../common/toxicity-dialog.component'

interface IProps {
  input: any
  classes: ClassNameMap
}
const ProgramToxEvaluationComponent: React.FC<IProps> = ({ input, classes }) => {
  const [data, setData] = useState([] as any)
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const urlParams: any = useParams()
  const patientId: string = urlParams.patientId
  const [openToxicity, setOpenToxicity] = useState(false)
  const [currToxicity, setCurrToxicity] = useState({})
  const { onSetView, onDataSubmit } = useReferralAction()

  useEffect(() => {
    const stage = patientDetail?.medications?.patient_toxicities?.map((e: any) => {
      return { ...e, mode: 'view' }
    })
    setData(stage)
  }, [patientDetail])


  const setIcons = () => {
    return input?.isActionItems ? [] :
      [
        { iconType: 'edit', callback: onSetDetail },
        { iconType: 'more', callback: outer },
      ]
  }

  const outer = (_id: any, _mode: string) => {
    setData(onSetView(_id, _mode, data))
  }

  const handleCloseDialog = () => {
    setOpenToxicity(false)
    setCurrToxicity({})
  }

  const onSetDetail = (_id: number) => {
    setData(data?.map((el: any) => (el.id === _id ? { ...el, mode: 'view' } : el)))
    setOpenToxicity(true)
    setCurrToxicity(data?.filter((e: { id: any }) => e.id === _id)[0] || {})
  }

  const getToxStaus = (is_active: boolean) => {
    return <Typography className={classes.subHeaderTitle} variant="body2" component="span">
      Status: {is_active ? 'Active' : 'Inactive'}
    </Typography>
  }

  const getToxList = (data: any) => {
    return data && data
      ?.sort((a: any, b: any) => a?.medication?.name > b?.medication?.name ? 1 : -1)
      ?.map((d: any, i: any) => {
        return <React.Fragment key={`prg-monitor-${i}`}>
          {d.mode === 'view' && (
            <>
              <CardLayoutComponent
                id={d.id}
                leftHeader={d?.toxicity_name}
                rightHeader={''}
                subHeader={getToxStaus(d?.is_active)}
                bottomText={moment.unix(d.created_on).format("MM/DD/YY")}
                source={d.medication?.name || ''}
                needs_confirmation={d.needs_confirmation || undefined}
                needs_update={d.needs_update || undefined}
                programs={d.programs || undefined}
                icons={setIcons()}
                onRefClick={undefined}
                is_referred={d.is_referred || false}
                is_overridden={false}
                entityRef='toxicities'
                patientId={patientId}
                category={'medications'}
              />
            </>
          )}
        </React.Fragment>
      })
  }

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
      {data && Object.keys(data)?.length > 0 && !input?.actionProgram &&
        <Grid item className={classes.fullWidth}>
          {getToxList(data)}
        </Grid>
      }
      {!input?.actionProgram && data && Object.keys(data)?.length < 1 &&
        <EmptyCardComponent />
      }
      {openToxicity &&
        <ToxicityDialogComponent
          open={openToxicity}
          handleClose={handleCloseDialog}
          input={currToxicity}
        />}
    </Grid>
  )
}

export default withStyles(stylesheet)(memo(ProgramToxEvaluationComponent))
