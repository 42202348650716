import React, { useState, useRef, useEffect } from 'react'
import {
  Grid, LinearProgress, DialogTitle, DialogContent, Dialog, Typography, Box,
} from '@mui/material'
import stylesheet from '../detail-container.stylesheet'
import { ClassNameMap, withStyles } from '@mui/styles'
import Draggable from "react-draggable"
import DialogTitleHeaderComponent from '../common/dialog-title-header.component'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'

interface IProps {
  open: boolean,
  item: any,
  handleDialogClose: any,
  classes: ClassNameMap
}

const ErrosDialogComponent: React.FC<IProps> = ({ open, item, handleDialogClose, classes }) => {
  const showErrRef = useRef(null) as any
  const [isLoading, setIsLoading] = useState(true)
  const program_enrollments = useSelector((state: RootState) => state.patientDetail.patientDetail?.program_enrollments || [])

  useEffect(() => {
    setTimeout(function () {
      setIsLoading(false)
    }, 650);
  })

  return (
    <Draggable ref={showErrRef} defaultPosition={{ x: 0, y: 0 }} handle='.errors-dialog'>
      <Dialog
        id={item.title}
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleDialogClose}
        aria-labelledby={item.title}
        hideBackdrop
        disableScrollLock
        onKeyUp={(e) => {
          if (e.key === 'Enter')
            handleDialogClose()
        }}
        sx={{
          position: "absolute",
          left: '15%',
          top: '20%'
        }}
      >
        <DialogTitle className={classes.addPatientQuestionHeaderSection} classes={{ root: 'errors-dialog' }}>
          <DialogTitleHeaderComponent title={item?.title} handleDialogClose={handleDialogClose} />
        </DialogTitle>
        {isLoading && <LinearProgress />}
        <DialogContent className={classes.contextualHelpContainer}>
          <Box className={classes.errorDataDialogHeaderBox}>
            <Grid container spacing={1} direction={"column"} className={classes.contextualHelpContainer}>
              {item?.data && Object.keys(item?.data)?.map((i: any, key: any) => (
                <Grid item key={`err-${key}`}>
                  <Typography variant="subtitle2" className={classes.conditionsWordBreak}>
                    {program_enrollments.find((p: any) => p.program === i)?.program_display_name || i}
                  </Typography>
                  {Object.keys(item?.data[i])?.length > 0 &&
                    <Box className={classes.zeroPadding}>
                      <Typography variant="body2" className={[classes.desc, classes.conditionsWordBreak].join(' ')}>
                        {item?.data[i]?.join(', ')}
                      </Typography>
                    </Box>
                  }
                </Grid>
              ))}
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </Draggable>
  );
};

export default withStyles(stylesheet)(ErrosDialogComponent)
