import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'

export const useCurrentUser = () => {
  const loggedInUser = useSelector(
    (state: RootState) => state.commonData.loggedInUser
  )

  const isNavigator: boolean = loggedInUser?.roles?.includes('NAVIGATOR')
  const isProvider: boolean = loggedInUser?.roles?.includes('PROVIDER')

  const currentRole = loggedInUser?.roles
    ?.filter((r: any) => r !== 'Everyone')
    .toString()

  return {
    currentRole,
    isNavigator,
    isProvider,
  }
}
