import React, { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, LinearProgress, Typography } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EmptyCardComponent from '../common/empty-card.component'
import { PatientService } from '../../../../services/patient.service'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'


interface IProps {
  id: any
  medType: string
  propagation: any
  classes: ClassNameMap
}

const RestrictedMedComponent: React.FC<IProps> = ({ id, medType, propagation, classes, }) => {
  const [isAAFormulation, setIsAAFormulation] = useState(false)
  const [expandAAForm, setExpandAAForm] = useState(false)
  const [loading, setLoading] = useState(false)
  const patientService = new PatientService()
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const [restrictedMed, setRestrictedMed] = useState({} as any)

  useEffect(() => {
    handleAAFormClick('', false)
    setIsAAFormulation(false)
  }, [id, medType, propagation, classes])

  const handleAAFormClick = (e: any, expanded: any) => {
    if (expanded && e) {
      e.stopPropagation();
      e.preventDefault();
      handleRestrictedMed()
      setExpandAAForm(true)
    } else
      setExpandAAForm(false)
  }

  const getRestrictedMed = async () => {
    setLoading(true)
    id && patientService.getRestrictedMed(patientDetail?.id, id, medType, propagation).then((response: any) => {
      if (Object.keys(response?.data).length > 0) {
        setRestrictedMed(response?.data || {})
      }
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const handleRestrictedMed = () => {
    setIsAAFormulation(true)
    getRestrictedMed()
  }

  return (
    <Box pt={1}>
      {loading && <LinearProgress />}
      <Accordion square
        expanded={expandAAForm}
        onChange={(e, expanded) => handleAAFormClick(e, expanded)}
        className={classes.zeroTopPadding}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
          disabled={!id}
          aria-controls={`affected-med`}
          id={`affected-med`}
          className={id ? classes.activeBtn : classes.chipDefault}
        >
          <Typography variant="subtitle2" >{`meds that will be restricted`}</Typography>
        </AccordionSummary>
        {isAAFormulation &&
          <AccordionDetails className={classes.zeroPadding}>
            <Box className={classes.restrictMedHeaderBox}>
              {Object.keys(restrictedMed)?.length > 0 &&
                (Object.keys(restrictedMed?.patient_aa_forms)?.length > 0 ||
                  Object.keys(restrictedMed?.aa_forms)?.length > 0)
                ? <Grid container direction="column" spacing={1} className={classes.aaFormContainer}>
                  <Grid item>
                    {[...restrictedMed?.patient_aa_forms || [], ...restrictedMed?.aa_forms || []]
                      ?.sort()?.map((item: any) => (
                        <Grid item key={item}>
                          <Typography key={item} variant="body2">
                            {item}{restrictedMed?.patient_aa_forms?.find((i: any) => i === item) ? ' *' : ''}
                          </Typography>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
                : <Box p={1}><EmptyCardComponent /></Box>
              }
            </Box>
          </AccordionDetails>
        }
      </Accordion>
    </Box>
  )
}

export default withStyles(stylesheet)(RestrictedMedComponent)
