import React, { useState } from 'react'
import {
  Box, Grid, Typography, List, ListItem, Button, CircularProgress
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import ToxicitySubComponent from '../care-plan/toxicity-sub-careplan.component'
import GetSubstituteComponent from '../care-plan/get-substitute.component';
import InitiateMonitoringComponent from '../care-plan/inititiate-monitoring.component'
import { useSelector } from 'react-redux'
import { RootState, useAppThunkDispatch } from '../../../../redux/store'
import { PatientService } from '../../../../services/patient.service'
import Helper from '../../../../utils/helper'
import { toast } from 'react-toastify'
import { checkCondition } from '../common/condition-check'
import { setSelectedCarePlan } from '../../../../redux/common-data-slice'
import moment from 'moment'

interface IProps {
  classes: ClassNameMap
  reaction?: any
  outerSubmit?: any
  item?: any,
  evalInfo?: any,
  configItem?: any
  mapState?: any
}

const ToxicityComponent: React.FC<IProps> = ({ classes, reaction, outerSubmit, item, evalInfo, configItem, mapState }) => {
  const [openLabReportCard, setOpenLabReportCard] = useState(true)
  const [substitute] = useState(false)
  const [montitoring] = useState(false)
  const [number] = useState(false)
  const [heading] = useState(true)
  const [loadingEval, setLoadingEval] = useState(false)
  const otherCondition = useSelector((state: RootState) => state.commonData.otherConditions)
  const selectedCarePlan = useSelector((state: RootState) => state.commonData.selectedCarePlan)
  const [runProgramEvalData, setRunProgramEvalData] = useState(evalInfo || selectedCarePlan || item || {} as any)
  const patientService = new PatientService()
  const dispatch = useAppThunkDispatch()
  const navigateBack = () => setOpenLabReportCard(true)

  const programEvaluationCall = async (_item: any) => {
    setLoadingEval(true)
    try {
      const response = await patientService.runProgramEvaluation(_item.id)
      setRunProgramEvalData({ ...response.data })
      dispatch(setSelectedCarePlan(response.data))
      setLoadingEval(false)
    } catch (error) {
      setLoadingEval(false)
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    }
  }

  const getToxByType = (name: any, type: any) => {
    return <Grid container direction="column" className={classes.evalSubBox}>
      <Grid item>{getLabel(name)}</Grid>
      <Grid item className={classes.fullWidth}>
        <Grid container direction="column" className={classes.evalSubBox}>
          {runProgramEvalData?.evaluation_results?.map((currItem: any, key: any) => (
            <Grid item key={`efficacy-${key}`} className={classes.fullWidth}>
              {currItem[type] && getToxCriteriaByGroup(currItem[type])}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid >
  }

  const getIntreim = (name: any, dataObj: any) => {
    return <Grid container direction="column" className={classes.evalInterim}>
      <Grid item>{getLabel(name)}</Grid>
      <Grid item className={classes.evalSubBox}>
        <Grid container direction="column" className={classes.evalSubBox}>
          {dataObj.map((currItem: any, key: any) => (
            <Grid item key={`interim-${currItem.id}`}>
              <Typography className={classes.evalBoxVal} variant='subtitle2'>{Helper.toCamelCase(currItem?.display_name)}</Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  }

  const getCriteriaList = (criteriaObj: any, isValue: boolean) => criteriaObj && Object.keys(criteriaObj)?.map((m: any, key: any) => {
    const { value, dependents } = criteriaObj[m]
    const depenList = dependents && Object.keys(dependents)?.map((key: any) => `${key}: ${dependents[key]}`).join(', ')

    return value === isValue && <List className={classes.criteriaList}>
      {renderToxList(`${m} ${depenList && `: [${depenList}]`}`)}
    </List>
  })

  const getToxCriteriaByGroup = (criteriaObj: any) => {
    return (Object.keys(criteriaObj)?.length > 0) && <Grid container direction='row' spacing={2} className={classes.evalSubBox}>
      <Grid item xs={6}>
        <Typography className={classes.evalBoxVal}><u>True</u></Typography>
        {getCriteriaList(criteriaObj, true)}
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.evalBoxVal}><u>False</u></Typography>
        {getCriteriaList(criteriaObj, false)}
      </Grid>
    </Grid>
  }

  const renderToxList = (val: any) => <ListItem className={classes.puList}>
    <Typography className={classes.evalBoxVal}>{val}</Typography>
  </ListItem>

  const getToxicityEval = () => {
    return <Grid container direction="row">
      <Grid item>{getLabel('Toxicity evaluation')}</Grid>
      <Grid item xs={6}>
        <Typography className={classes.toxicityEvalBoxVal}>
          {getEvalResultByKey('toxicity_evaluation')}
        </Typography>
      </Grid>
    </Grid>
  }

  const getRecommendation = (recObj: any) => {
    if (recObj) {
      return <Grid item>
        <List className={classes.zeroPadding}>
          {recObj && recObj.map((key: any) => (
            <React.Fragment key={`recom-${key}`}>
              {recObj?.length === 1 ?
                <Typography className={classes.evalBoxVal}>{key}</Typography>
                : renderToxList(key)
              }
            </React.Fragment>
          ))}
        </List>
      </Grid>
    }
  }

  const getEvalResultByKey = (key: any) => key
    ? runProgramEvalData?.evaluation_results?.map((currItem: any) => currItem[key]).join(', ')
    : ''

  const geteEvaluationDate = () => {
    if (runProgramEvalData?.care_plan?.last_evaluation_date)
      return moment.unix(runProgramEvalData?.care_plan?.last_evaluation_date).format('MMM DD, YYYY hh:mm a')
    else if (item?.evaluation_date)
      return moment.unix(item?.evaluation_date).format('MMM DD, YYYY hh:mm a')
    else if (runProgramEvalData?.evaluation_results)
      return moment.unix(runProgramEvalData?.evaluation_results[0]?.evaluation_date).format('MMM DD, YYYY hh:mm a')
  }

  const getNextStage = () => {
    let str = getEvalResultByKey('next_stage')
    return str?.replace('med', 'Medication') || ''
  }

  const getLabel = (val: any) => <Typography className={classes.evalBoxLabel} variant="body2" color="textSecondary">{val}</Typography>

  return (
    <>
      {openLabReportCard ? (
        <Grid container direction='row' justifyContent="space-between">
          <Grid item style={{ width: '70%' }}>
            <Grid className={classes.evalContent} container spacing={1}>
              <Grid item className={classes.fullWidth}>
                <Grid container direction="row" className={classes.evalToxCard}>
                  <Grid item>{getLabel('Last evaluation date')}</Grid>
                  <Grid item>
                    <Typography className={classes.toxicityEvalBoxVal}>{geteEvaluationDate()}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.fullWidth}>
                <Grid container direction="column">
                  <Grid item>{getLabel('Recommendations')}</Grid>
                  <Grid item className={classes.evalSubBox}>
                    <Grid container direction="column" className={classes.evalSubBox}>
                      {runProgramEvalData?.evaluation_results?.map((currItem: any, key: any) => (
                        <Grid item className={classes.fullWidth} key={`recom-${key}`}>
                          {getRecommendation(currItem?.recommendation)}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.fullWidth}>
                <Grid container spacing={1} direction="column">
                  <Grid item className={classes.fullWidth}>{getToxicityEval()}</Grid>
                  <Grid item className={classes.fullWidth}>{getToxByType('Toxicity criteria', 'toxicity_criteria')}</Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.fullWidth}>
                <Grid container spacing={1} direction="column">
                  <Grid item className={classes.fullWidth}>
                    <Grid container direction="row">
                      <Grid item>{getLabel('Efficacy evaluation')}</Grid>
                      <Grid item className={classes.fullWidth}>
                        <Typography className={classes.toxicityEvalBoxVal}>{getEvalResultByKey('evaluation')}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.fullWidth}>{getToxByType('Efficacy criteria', 'efficacy_criteria')}</Grid>
                  <Grid item className={classes.fullWidth}>{getToxByType('Inefficacy criteria', 'inefficacy_criteria')}</Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.fullWidth}>
                {getIntreim('Interim diagnosis', otherCondition)}
              </Grid>
              <Grid item className={classes.fullWidth}>
                <Grid container direction="row" className={classes.evalToxCard}>
                  <Grid item>{getLabel('Next stage')}</Grid>
                  <Grid item>
                    <Typography className={classes.toxicityEvalBoxVal}>{getNextStage()}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ width: '30%' }}>
            {configItem && !['data_intake', 'enrollment', 'med_baseline', 'med_initiation'].includes(configItem.stage) &&
              <Grid container direction='row' >
                {loadingEval && <Grid item><CircularProgress className={classes.loadingEval} /></Grid>}
                <Grid item>
                  <Typography variant="subtitle2" color="textSecondary">
                    <Button
                      disabled={loadingEval}
                      variant="outlined" size="small"
                      onClick={() => programEvaluationCall(item)}
                    >
                      Run Program Evaluation
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            }
          </Grid>
        </Grid >
      ) : (
        <Box p={1} mb={2}>
          <ToxicitySubComponent item={'item'} outerSubmit={'outersubmit'} outer={'outer'} compKey={'compKey'} classes={classes} reaction={reaction} navigateBack={navigateBack} heading={heading} />
          {checkCondition(substitute, <GetSubstituteComponent classes={classes} />)}
          {checkCondition(montitoring, <InitiateMonitoringComponent classes={classes} number={number} />)}
        </Box>
      )
      }
    </>
  )
}

export default withStyles(stylesheet)(ToxicityComponent)
