import React, { useState } from 'react'
import stylesheet from '../detail-container.stylesheet'
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material'
import {
  ClassNameMap, withStyles
} from '@mui/styles'
import MedicationsComponent from '../common/medications.component'
import { PatientService } from '../../../../services/patient.service'

interface IProps {
  input: any,
  outer: any,
  handleP: any,
  selectedProgram: any,
  uiResource: any,
  patientInfo: any,
  passData: any,
  changeOption: any,
  currentOption: any,
  classes: ClassNameMap
}

const ProgramSubmedicationsComponent: React.FC<IProps> = ({
  classes,
  outer,
  selectedProgram,
  uiResource,
  patientInfo,
  input,
  handleP,
  passData,
  changeOption,
  currentOption,
}) => {

  const patientService = new PatientService()
  const options = ['TAKING', 'NOT_TAKING', 'TAKING_DIFFERENTLY']
  const [decision, setDecision] = useState(options[0]);

  const postConfirmations = (
    values: any,
    _mode: any,
    _typeOut: any,
    medicinedata: any,
    dosageInfo: any
  ) => {

    let regularity = ''
    let mydata = ''
    if (_typeOut === 'save' || _typeOut === 'confirm') {
      try {
        let payload = {
          med_id: medicinedata.med_id,
          med_type: medicinedata.med_type,
          dose: values.dose,
          unit: values.unit,
          delivery_method: values.delivery_method,
          intake_quantity: values.intake_quantity,
          intake_regularity: decision,
          route: values.route,
          frequency: values.frequency,
          dosage_info: dosageInfo,
          source_type: medicinedata.source_type,
        }
        Object.keys(uiResource.MEDICATION_UNITS).forEach((_key, _val) => {
          if (uiResource.MEDICATION_UNITS[_key] == values.unit) {
            payload.unit = _key
          }
        })
        Object.keys(uiResource.MEDICATION_DOSAGE_FREQUENCY).forEach((_key, _val) => {
          if (
            uiResource.MEDICATION_DOSAGE_FREQUENCY[_key] == values.frequency
          ) {
            payload.frequency = _key
          }
        })
        Object.keys(uiResource.MEDICATION_INTAKE_ROUTES).forEach((_key, _val) => {
          if (uiResource.MEDICATION_INTAKE_ROUTES[_key] == values.route) {
            payload.route = _key
          }
        })
        Object.keys(uiResource.MEDICATION_DELIVERY_METHODS).forEach((_key, _val) => {
          if (
            uiResource.MEDICATION_DELIVERY_METHODS[_key] ==
            values.delivery_method
          ) {
            payload.delivery_method = _key
          }
        })

        patientService
          .putOtherMedication(patientInfo.id, medicinedata.id, payload)
          .then((response: any) => {
            handleP(response?.data.id, "view");
            passData(values, _mode, _typeOut, medicinedata, dosageInfo, response, _mode);

          })
          .catch((err: any) => {
            console.error(err)
          })
      } catch (error: any) {
        console.error(error)
      }
    }
  }
  return (
    <>
      <Box mb={1} className={classes.fullWidth}>
        <Card variant='outlined'
          className={[classes.questionCardContent, classes.fullWidth].join(' ')}
        >
          <CardContent className={classes.zeroPadding}>
            <Box p={2} className={classes.questionHeaderSection}>
              <Grid item>
                <Typography
                  variant="subtitle2"
                  className={classes.nameLineHeight}
                >
                  {input.name}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >{`${input.intake_quantity}, By ${input.d.c_schedule?.route}, ${input.d.c_schedule?.frequency}`}</Typography>
                {input.source_type === 'imported' ? (
                  <Box p={0}>
                    <Typography
                      variant="body2"
                      className={classes.nameLineHeight}
                    >
                      {' '}
                      Let us know if you are taking it, not taking or
                      taking it differently.
                    </Typography>
                  </Box>
                ) : null}
                <Box mb={1} className={classes.subMedicationImportedSource}>
                  {input.source_type === 'imported' ? (
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      {options.map((r) => (
                        <Button
                          variant="contained"
                          name={r}
                          size="small"
                          onClick={(e) => {
                            setDecision(e.currentTarget.name);
                          }}
                          className={
                            currentOption === r
                              ? [
                                classes.questionSubmitAnsButton,
                                classes.questionSubmitAnsButtonActive,
                              ].join(' ')
                              : classes.questionSubmitAnsButton
                          }
                        >
                          <strong>
                            <small>{r}</small>{' '}
                          </strong>{' '}
                        </Button>
                      ))}
                    </Grid>
                  ) : null}
                </Box>
              </Grid>
            </Box>
            {decision === 'TAKING' &&
              input.source_type === 'imported' && input.program === selectedProgram && (
                <MedicationsComponent
                  medicineInfo={input}
                  writeMode={false}
                  noDropDown="none"
                  heading=""
                  handleClose={handleP}
                  differently={true}
                  classes={classes}
                  input={input}
                  outer={outer}
                  outerSubmit={postConfirmations}
                  currentStage={''}
                  patientId={patientInfo.id}
                  uiResource={uiResource}
                />
              )}
            {decision === 'NOT_TAKING' &&
              input.source_type === 'imported' && input.program === selectedProgram && (
                <MedicationsComponent
                  medicineInfo={input}
                  writeMode={false}
                  noDropDown="none"
                  heading=""
                  handleClose={handleP}
                  differently={true}
                  classes={classes}
                  input={input}
                  outer={outer}
                  outerSubmit={postConfirmations}
                  currentStage={''}
                  patientId={patientInfo.id}
                  uiResource={uiResource}
                />
              )}
            {((decision === 'TAKING_DIFFERENTLY' &&
              input.source_type === 'imported') ||
              input.source_type === 'reported') && input.program === selectedProgram && (
                <MedicationsComponent
                  medicineInfo={input}
                  writeMode={true}
                  noDropDown="none"
                  heading=""
                  handleClose={handleP}
                  differently={true}
                  classes={classes}
                  input={input}
                  outer={outer}
                  outerSubmit={postConfirmations}
                  currentStage={''}
                  patientId={patientInfo.id}
                  uiResource={uiResource}
                />
              )}
          </CardContent>
        </Card>
      </Box>

    </>
  )
}

export default withStyles(stylesheet)(ProgramSubmedicationsComponent)
