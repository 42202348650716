import React, { useState, useEffect, memo } from 'react'
import CardLayoutComponent from '../common/card-layout.component'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { ClassNameMap, withStyles } from '@mui/styles'
import { RootState } from '../../../../redux/store'
import { PatientService } from '../../../../services/patient.service'
import DeleteCard from '../common/delete-card-component'
import NotesComponent from '../common/notes.component'
import ReferralComponent from '../common/referral-card.component'
import { useReferralAction } from '../../../../utils/referral-hook'
import stylesheet from '../detail-container.stylesheet'
import LifestyleDialogComponent from '../common/lifestyle-dialog.component'
import Helper from '../../../../utils/helper'
import EmptyCardComponent from '../common/empty-card.component'
import { useUpdateDataAction } from '../../../../utils/update-details-hook'

interface IProps {
  input: any
  classes: ClassNameMap
}
const ProgramLifestyleTypeComponent: React.FC<IProps> = ({ input, classes }) => {
  const [data, setData] = useState(input?.data || [] as any)
  const patientDetail = useSelector(
    (state: RootState) => state.patientDetail.patientDetail
  )
  const urlParams: any = useParams()
  const patientId: string = urlParams.patientId
  const patientService = new PatientService()
  const [openLifestyle, setOpenLifestyle] = useState(false)
  const [currLifestyle, setCurrLifestyle] = useState({})
  const [selectedNotes, setSelectedNotes] = useState([] as number[])
  const { onSetView, onDataSubmit } = useReferralAction()
  const { updatePatientData } = useUpdateDataAction(patientDetail.id)

  useEffect(() => {
    const stage = input?.data?.map((e: any) => {
      return { ...e, mode: 'view' }
    })
    setData(stage)
  }, [input])

  const handleLifestyleClose = () => setOpenLifestyle(false)

  const formatDates = (d: any) => {
    const sdate = d.start_date ? 'Started: ' + moment.unix(d.start_date).format('MM/DD/YY') : ''
    const duration = d.referral_interval ? ', Duration: ' + d.referral_interval : ''
    const retVal = sdate && duration ? sdate + duration : (sdate ? sdate : (duration ? duration : ''))
    return retVal
  }

  const setOtherIcons = () => {
    return input.type === 'completed'
      ? [
        { iconType: 'edit', callback: onSetDetail },
        { iconType: 'description', callback: onNotesClick },
        { iconType: 'more', callback: outer },
      ]
      : [
        { iconType: 'edit', callback: onSetDetail },
        { iconType: 'delete', callback: onDelIconClick },
        { iconType: 'description', callback: onNotesClick },
        { iconType: 'more', callback: outer },
      ]
  }

  const onNotesClick = (_id: any) => {
    if (selectedNotes.includes(+_id)) {
      setSelectedNotes([...selectedNotes.filter((f) => f !== +_id)])
    } else {
      setSelectedNotes([...selectedNotes, +_id])
    }
  }

  const onDelIconClick = (_id: any) => {
    setData(
      data.map((el: any) => (el.id === _id ? { ...el, mode: 'delete' } : el))
    )
  }

  const outer = (_id: any, _mode: string) => {
    setData(onSetView(_id, _mode, data))
  }

  const confirmDelete = (_id: any) => {
    patientService
      .deleteLifestyleDetails(patientId, _id)
      .then((_response: any) => {
        if (_response?.data === 'success') {
          setData(data.filter((e: { id: any }) => e.id !== _id))
        }
      })
      .catch((err: any) => {
        toast.error(Helper.getErrorText(err), Helper.bottom_center())
      })
  }

  const getLifestyleData = (_id: number) => {
    _id && patientService.getLifestyleDetails(patientDetail.id, _id).then((res: any) => {
      if (res?.data?.lifestyle) {
        setCurrLifestyle({ ...res?.data?.lifestyle, type: input.type })
        setOpenLifestyle(true)
      }
    }).catch((err: any) => toast.error(Helper.getErrorText(err), Helper.bottom_center()))
  }

  const onSetDetail = (_id: number) => {
    setData(data.map((el: any) => (el.id === _id ? { ...el, mode: 'view' } : el)))
    getLifestyleData(_id)
  }

  const closeDelete = (_id: number) => setData(data.map((el: any) => (el.id === _id ? { ...el, mode: 'view' } : el)))

  const handleLifestyle = (_id: number) => {
    setCurrLifestyle({})
    if (_id) {
      setData(data.filter((el: any) => el.id !== _id))
      updatePatientData({ isMeasurement: true, isTreatments: true })
    } else {
      toast.error('Failed to stop monitor.', Helper.bottom_center())
    }
  }

  const onRefClick = (_id: any, _mode: string) => {
    setData(onSetView(_id, _mode, data))
  }

  const onRefSubmit = (_id: any, date: number, is_referred: boolean, referral_id: any) => {
    setData(onDataSubmit(_id, date, is_referred, referral_id, data))
  }

  const getLifestyleList = (data: any) => {
    return data && [...data]
      ?.sort((a: any, b: any) => (a?.display_name).toLowerCase() > (b?.display_name).toLowerCase() ? 1 : -1)
      ?.map((d: any, i: any) => {
        return <React.Fragment key={`prg-monitor-${i}`}>
          {d.mode === 'view' && (
            <>
              <CardLayoutComponent
                id={d.id}
                name={d?.name}
                leftHeader={d.display_name || d?.name}
                rightHeader={''}
                subHeader={formatDates(d)}
                bottomText={''}
                needs_confirmation={d.needs_confirmation || undefined}
                needs_update={d.needs_update || undefined}
                programs={d.programs || undefined}
                icons={setOtherIcons()}
                onRefClick={() => outer(d.id, 'ref')}
                is_referred={d.is_referred || false}
                is_overridden={false}
                entityRef='program_lifestyle'
                patientId={patientId}
                category={'medications'}
              />
              {selectedNotes.includes(d.id) && (
                <NotesComponent
                  entityId={d.id}
                  entityRef={'program_lifestyle'}
                  cancelBtn={true}
                  onCancel={onNotesClick}
                >
                  Notes
                </NotesComponent>
              )}
            </>
          )}
          {d.mode === 'delete' && (
            <DeleteCard
              id={d.id}
              header={d.display_name}
              closeDelete={() => closeDelete(d.id)}
              outerDelete={() => confirmDelete(d.id)}
            />
          )}
          {d.mode === 'ref' && (
            <ReferralComponent
              entityRef='program_lifestyle'
              input={d}
              patientId={patientDetail.id}
              leftHeader={Helper.getRXnorm(d.name, d?.rxnorm)}
              rightHeader={''}
              subHeader={''}
              programs={d.programs}
              closeRef={() => onRefClick(d.id, 'view')}
              onRefSubmit={onRefSubmit}
            />
          )}
        </React.Fragment>
      })
  }

  return (<>
    {Object.keys(data)?.length > 0
      ? <>
        {getLifestyleList(data)}
        {openLifestyle &&
          <LifestyleDialogComponent
            open={openLifestyle}
            handleClose={handleLifestyleClose}
            handleAction={handleLifestyle}
            input={currLifestyle}
          />
        }
      </>
      : <EmptyCardComponent />
    }
  </>)
}

export default withStyles(stylesheet)(memo(ProgramLifestyleTypeComponent))