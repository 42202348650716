import { Box, Button, Grid, Typography } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import { useEffect, useState } from 'react';
import { environment } from '../../../../environments/environment';
import Helper from '../../../../utils/helper';
import stylesheet from '../detail-container.stylesheet'
import RejectRestrictComponent from './reject-restrict-decision.component';

interface IProps {
  item: any
  outerSubmit: any
  outer: any
  compKey: string
  value: any
  currentRole: string
  classes: ClassNameMap
}

const ProviderDecisionComponent: React.FC<IProps> = ({
  item,
  outerSubmit,
  outer,
  compKey,
  value,
  currentRole,
  classes,
}) => {
  const [status, setStatus] = useState(item.appr_wrfl_stat);
  const onBtnClick = (_param: string) => {
    setStatus(_param)
    outerSubmit(compKey, _param)
  }

  const onBackClick = () => {
    setStatus(undefined)
    outer('med_restriction')
    outer(compKey)
  }

  useEffect(() => {
    if (status === 'Restrict') {
      setStatus(undefined)
      outer(compKey)
    }
  }, [value])

  return (<>
    {Helper.checkGroup(environment.okta.providerGroup, currentRole) &&
      <Grid>
        {status === 'Restrict' &&
          <Box pl={2}>
            <RejectRestrictComponent
              item={item}
              outerSubmit={outerSubmit}
              outer={onBackClick}
              handleClose={{}}
              handleSubmit={{}}
              compKey={compKey}
              parentComp=''
            />
          </Box>
        }
        <Box p={2} className={status === 'Restrict' ? classes.displayNone : classes.subModalBackground} >
          <Grid spacing={1} container>
            <Grid item sm={12}>
              <Typography variant="subtitle2">
                Provider decision is pending.
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                onClick={() => onBtnClick('Approved')}
                className={status === 'Approved' ? classes.dialogBtnActionSelected : classes.dialogBtnAction}          >
                Approve
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                onClick={() => onBtnClick('Rejected')}
                className={status === 'Rejected' ? classes.dialogBtnActionSelected : classes.dialogBtnAction}
              >
                Reject
              </Button>
            </Grid>
            {!['med_evaluation', 'early_tox_check'].includes(item.stage) &&
              <Grid item>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => onBtnClick('Restrict')}
                  className={classes.dialogBtnAction}
                >
                  {`Reject & restrict`}
                </Button>
              </Grid>
            }
          </Grid>
        </Box>
      </Grid>
    }
    {
      Helper.checkGroup(environment.okta.navigatorGroup, currentRole) &&
      <Box p={1} className={classes.subModalBackground}>
        <Typography variant='subtitle2'>Waiting for provider approval</Typography>
      </Box>
    }
  </>)
}

export default withStyles(stylesheet)(ProviderDecisionComponent)
