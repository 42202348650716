import React, { useEffect, useState } from 'react'
import { Tooltip, Slider } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { RouteComponentProps } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  slider: {
    color: '#f16522 !important',
    '& .MuiSlider-mark': {
      color: '#f16522 !important',
      backgroundColor: '#f16522 !important',
    },
    '& .MuiSlider-track': {
      color: '#f16522 !important',
    },
    '& .MuiSlider-thumb': {
      color: '#f16522 !important',
    },
  },
  disableSlider: {
    color: '#CCCCCC !important',
    '& .MuiSlider-mark': {
      color: '#CCCCCC !important',
    },
    '& .MuiSlider-track': {
      color: '#CCCCCC !important',
    },
    '& .MuiSlider-thumb': {
      color: '#CCCCCC !important',
    },
  },
  unanswredSlider: {
    color: '#f16522 !important',
    '& .MuiSlider-mark': {
      color: '#f16522 !important',
    },
    '& .MuiSlider-track': {
      color: '#f16522 !important',
    },
    '& .MuiSlider-thumb': {
      color: '#f16522 !important',
    },
  },
  sliderWrapper: {
    width: '-webkit-fill-available',
  },
  extraPadding: {
    paddingLeft: '20px',
    width: '-webkit-fill-available',
  },
  markDiv: {
    textAlign: 'center',
  },
  markText: {
    margin: 'auto',
  },
  markLabel: {
    margin: 'auto',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100px',
    display: 'inline-block',
    textAlign: 'left',
    position: 'absolute',
    marginLeft: '-1.4rem',
    fontSize: '0.8rem'
  },
  customWidth: {
    fontSize: '15px',
  },
  hideTooltip: {
    display: 'none',
  },
}))

interface IProps extends RouteComponentProps {
  question: any
  uniqueId: string
  onInChange: Function
  isAnswerSelected: Function
  history: any
  location: any
  match: any
}

const valuetext = (value: number) => `${value}`

const ScaleStage: React.FC<IProps> = ({
  question,
  uniqueId,
  onInChange,
  isAnswerSelected,
}) => {
  const classes = useStyles()
  const [, setOptionsArray] = useState([] as any)
  const [defaultVal, setDefaultVal] = useState(0)
  const [isSubmitted, setIsSubmitted] = useState(false)
  var initialScale: any = []
  let [marks, setMarks] = useState([] as any)

  const isAnswered = () => {
    let a = isAnswerSelected(question.id, '', question.component, initialScale)
    setDefaultVal(question?.options.indexOf(a?.value))
    setIsSubmitted(a && a.answered)
  }

  const checkValueText = (selectedValue: any) => {
    showFlag && !isSubmitted && setShowFlag(false)
    return onInChange(selectedValue, question.id, question)
  }

  const getScaleTooltip = (label: any) => {
    return (
      <Tooltip
        title={label}
        classes={
          label?.length > 5
            ? { tooltip: classes.customWidth }
            : { tooltip: classes.hideTooltip }
        }
      >
        <span
          className={label?.length > 5 ? classes.markLabel : classes.markText}
        >
          {question.options.length > 5 && label?.length > 10
            ? label.substring(0, 7) + '...'
            : label}
        </span>
      </Tooltip>
    )
  }

  useEffect(() => {
    setOptionsArray(question.options)

    question.options.map((ele: any, index: any) => {
      let scaleLabel = ele.split(/[:]/)
      const scaleTooltipLabel = scaleLabel[1] ? scaleLabel[1] : scaleLabel[0]
      initialScale.push({
        value: index,
        label: getScaleTooltip(scaleTooltipLabel),
      })
      return initialScale
    })
    setMarks(initialScale)
    isAnswered()
  }, [onInChange, isAnswerSelected])

  const [showFlag, setShowFlag] = useState(true)
  let max = question?.options?.length || 0
  if (
    marks &&
    marks[0] !== undefined &&
    question.options.length > 1 &&
    marks[0].value !== undefined &&
    marks[0].value == 0
  )
    max = max - 1

  return (
    <div className={classes.extraPadding}>
      <div id={question.id} className={classes.sliderWrapper}>
        {defaultVal !== undefined && (
          <Slider
            className={
              (!isSubmitted && defaultVal == -1)
                ? classes.disableSlider
                : !showFlag && !isSubmitted
                  ? classes.unanswredSlider
                  : isSubmitted
                    ? classes.slider
                    : classes.disableSlider
            }
            value={defaultVal}
            getAriaValueText={valuetext}
            aria-label="non-linear-slider"
            step={null}
            marks={marks}
            min={marks && marks[0] && marks[0].value ? marks[0].value : 0}
            max={max}
            onChange={(e: any, value: any) => {
              setDefaultVal(value)
              checkValueText(value)
            }}
          />
        )}
      </div>
    </div>
  )
}

export default ScaleStage
