import React, { useState, useRef, useEffect } from 'react'
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress,
  CircularProgress, TextField, Grid, Box
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import { checkCondition } from './condition-check';
import Draggable from "react-draggable"
import DialogTitleHeaderComponent from './dialog-title-header.component'

interface IProps {
  open: boolean
  isCloseoutNote: boolean
  handleConfirmNoteClose: any
  submitNote: any
  content: any
  classes: ClassNameMap
}

const GeneratedNotesDialogComponent: React.FC<IProps> = ({ open, isCloseoutNote, handleConfirmNoteClose, submitNote, content, classes }) => {
  const isAddPrRef = useRef(null) as any
  const [loading, setLoading] = useState(true)
  const [isSubmitting, setisSubmitting] = useState(false)
  const [visitNoteText, setvisitNoteText] = useState(content)

  const handleChange = (event: any) => {
    setvisitNoteText(event.target.value)
  }

  useEffect(() => {
    if (content != null) {
      setLoading(false)
    }
    else {
      setLoading(true)
    }
    setvisitNoteText(content)
  }, content)

  const setSubmitData = () => {
    setisSubmitting(true)
    submitNote(visitNoteText)
  }

  return (
    <Draggable ref={isAddPrRef} defaultPosition={{ x: 0, y: 0 }} handle='.generated-notes-dialog'>
      <Dialog fullWidth={true}
        maxWidth='sm'
        open={open}
        hideBackdrop
        onClose={handleConfirmNoteClose}
        aria-labelledby="max-width-dialog-title"
        onKeyUp={(e) => {
          if (e.key === 'Enter' && !isSubmitting)
            setSubmitData()
        }}
        sx={{
          position: "absolute",
          left: '14rem',
          top: '8rem'
        }}
      >
        <DialogTitle className={classes.questionHeaderSection} classes={{ root: 'generated-notes-dialog' }}>
          <DialogTitleHeaderComponent
            title={loading ? `Please wait while ${isCloseoutNote ? 'closeout ' : ''}note is loading...` :
              isCloseoutNote ? 'Closeout note' : 'Visit note'}
            handleDialogClose={handleConfirmNoteClose}
          />
        </DialogTitle>
        {checkCondition(loading, <LinearProgress />)}
        <DialogContent className={classes.contextualHelpContext} sx={{ padding: '0px !important', marginBottom: '0.5rem' }}>
          <Box p={'0.8rem 1.5rem'} className={classes.dialogHeaderBox}>
            {checkCondition(!loading, <TextField
              id="generated-notes-basic"
              value={visitNoteText}
              variant="outlined"
              size="small"
              InputProps={{ style: { fontSize: '0.8rem' } }}
              InputLabelProps={{ style: { fontSize: '0.8rem' } }}
              multiline={true}
              maxRows={50}
              onChange={handleChange}
              className={classes.fullWidth}
            />
            )}
          </Box>
        </DialogContent>
        {checkCondition((!loading && !isCloseoutNote), <DialogActions className={classes.cardLabInfo}>
          {checkCondition(isSubmitting, <CircularProgress size={24} />)}
          <Button
            variant="outlined"
            size="small"
            disabled={isSubmitting}
            onClick={setSubmitData}
          >
            {`Submit`}
          </Button>
          <Button variant="outlined" size="small" onClick={() => handleConfirmNoteClose()}>
            {`Cancel`}
          </Button>
        </DialogActions>
        )}
      </Dialog>
    </Draggable>
  )
}

export default withStyles(stylesheet)(GeneratedNotesDialogComponent)