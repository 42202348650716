export const compsConfig = [
  {
    id: 'NOT_REC',
    role: '',
    stage: '',
    source_type: '',
    actions: [
      { title: 'Submit', returnFun: {} },
      { title: 'Cancel', returnFun: {} },
    ],
    focusTabIndex: 0,
    tabs: [
      {
        title: 'Currently Taking',
        index: 0,
        comp: 'DetailsComponent_Key',
        props: {},
      },
      { title: 'Notes', index: 1, comp: 'NotesComponent_Key', props: {} },
      { title: 'Referral', index: 2, comp: 'ReferralComponent_Key', props: {} },
      {
        title: 'History',
        index: 3,
        comp: 'MedHistoryComponent_Key',
        props: {},
      },
    ],
  },
  {
    id: 'P_MB',
    role: 'PROVIDER',
    stage: 'med_baseline',
    source_type: '',
    actions: [
      { title: 'Submit', returnFun: {} },
      { title: 'Cancel', returnFun: {} },
    ],
    focusTabIndex: 0,
    tabs: [
      {
        title: 'Currently Taking',
        index: 0,
        comp: 'DetailsComponent_Key',
        props: {},
      },
      {
        title: 'Rationale',
        index: 1,
        comp: 'RationaleComponent_Key',
        props: {},
      },
      {
        title: 'Recommendation',
        index: 2,
        comp: 'ScheduleCarePlanComponent_Key',
        props: {},
      },
      {
        title: 'Medication Evaluation',
        index: 3,
        comp: 'ToxicityComponent_Key',
        props: {},
      },
      { title: 'Notes', index: 4, comp: 'NotesComponent_Key', props: {} },
      { title: 'Referral', index: 5, comp: 'ReferralComponent_Key', props: {} },
      {
        title: 'History',
        index: 6,
        comp: 'MedHistoryComponent_Key',
        props: {},
      },
    ],
  },
  {
    id: 'P_O_MB',
    role: 'PROVIDER',
    stage: '',
    source_type: '',
    actions: [
      { title: 'Save & Confirm', returnFun: {} },
      { title: 'Save', returnFun: {} },
      { title: 'Cancel', returnFun: {} },
    ],
    focusTabIndex: 0,
    tabs: [
      {
        title: 'Instructions',
        index: 0,
        comp: 'DetailsComponent_Key',
        props: {},
      },
      {
        title: 'Schedules',
        index: 1,
        comp: 'ScheduleCarePlanComponent_Key',
        props: {},
      },

      { title: 'Notes', index: 2, comp: 'NotesComponent_Key', props: {} },
      { title: 'Referral', index: 3, comp: 'ReferralComponent_Key', props: {} },
      {
        title: 'History',
        index: 4,
        comp: 'MedHistoryComponent_Key',
        props: {},
      },
    ],
  },
  {
    id: 'MED_RECON',
    role: 'PROVIDER',
    stage: '',
    source_type: '',
    actions: [
      { title: 'Save & Confirm', returnFun: {} },
      { title: 'Save', returnFun: {} },
      { title: 'Cancel', returnFun: {} },
    ],
    focusTabIndex: 0,
    tabs: [
      {
        title: 'Instructions',
        index: 0,
        comp: 'DetailsComponent_Key',
        props: {},
      },
      {
        title: 'Schedules',
        index: 1,
        comp: 'ScheduleCarePlanComponent_Key',
        props: {},
      },
      {
        title: 'Med Reconcilation',
        index: 2,
        comp: 'reconcilation_Key',
        props: {},
      },
    ],
  },
  {
    id: 'N_MB',
    role: 'NAVIGATOR',
    stage: 'med_baseline',
    source_type: '',
    actions: [
      { title: 'Submit', returnFun: {} },
      { title: 'Cancel', returnFun: {} },
    ],
    focusTabIndex: 0,
    tabs: [
      {
        title: 'Currently Taking',
        index: 0,
        comp: 'DetailsComponent_Key',
        props: {},
      },
      {
        title: 'Rationale',
        index: 1,
        comp: 'RationaleComponent_Key',
        props: {},
      },
      {
        title: 'Recommendation',
        index: 2,
        comp: 'ScheduleCarePlanComponent_Key',
        props: {},
      },
      {
        title: 'Medication Evaluation',
        index: 3,
        comp: 'ToxicityComponent_Key',
        props: {},
      },
      { title: 'Notes', index: 4, comp: 'NotesComponent_Key', props: {} },
      { title: 'Referral', index: 5, comp: 'ReferralComponent_Key', props: {} },
      {
        title: 'History',
        index: 6,
        comp: 'MedHistoryComponent_Key',
        props: {},
      },
    ],
  },
  {
    id: 'P_DI',
    role: 'PROVIDER',
    stage: 'data_intake',
    source_type: '',
    actions: [
      { title: 'Submit', returnFun: {} },
      { title: 'Cancel', returnFun: {} },
    ],
    focusTabIndex: 0,
    tabs: [
      {
        title: 'Currently Taking',
        index: 0,
        comp: 'DetailsComponent_Key',
        props: {},
      },
      { title: 'Notes', index: 1, comp: 'NotesComponent_Key', props: {} },
      { title: 'Referral', index: 2, comp: 'ReferralComponent_Key', props: {} },
      {
        title: 'History',
        index: 3,
        comp: 'MedHistoryComponent_Key',
        props: {},
      },
    ],
  },
  {
    id: 'P_EN',
    role: 'PROVIDER',
    stage: 'enrollment',
    source_type: '',
    actions: [
      { title: 'Submit', returnFun: {} },
      { title: 'Cancel', returnFun: {} },
    ],
    focusTabIndex: 0,
    tabs: [
      {
        title: 'Currently Taking',
        index: 0,
        comp: 'DetailsComponent_Key',
        props: {},
      },
      { title: 'Notes', index: 1, comp: 'NotesComponent_Key', props: {} },
      { title: 'Referral', index: 2, comp: 'ReferralComponent_Key', props: {} },
      {
        title: 'History',
        index: 3,
        comp: 'MedHistoryComponent_Key',
        props: {},
      },
    ],
  },
  {
    id: 'N_DI',
    role: 'NAVIGATOR',
    stage: 'data_intake',
    source_type: '',
    actions: [
      { title: 'Submit', returnFun: {} },
      { title: 'Cancel', returnFun: {} },
    ],
    focusTabIndex: 0,
    tabs: [
      {
        title: 'Currently Taking',
        index: 0,
        comp: 'DetailsComponent_Key',
        props: {},
      },
      { title: 'Notes', index: 1, comp: 'NotesComponent_Key', props: {} },
      { title: 'Referral', index: 2, comp: 'ReferralComponent_Key', props: {} },
      {
        title: 'History',
        index: 3,
        comp: 'MedHistoryComponent_Key',
        props: {},
      },
    ],
  },
  {
    id: 'N_EN',
    role: 'NAVIGATOR',
    stage: 'enrollment',
    source_type: '',
    actions: [
      { title: 'Submit', returnFun: {} },
      { title: 'Cancel', returnFun: {} },
    ],
    focusTabIndex: 0,
    tabs: [
      {
        title: 'Currently Taking',
        index: 0,
        comp: 'DetailsComponent_Key',
        props: {},
      },
      { title: 'Notes', index: 1, comp: 'NotesComponent_Key', props: {} },
      { title: 'Referral', index: 2, comp: 'ReferralComponent_Key', props: {} },
      {
        title: 'History',
        index: 3,
        comp: 'MedHistoryComponent_Key',
        props: {},
      },
    ],
  },
  {
    id: 'P_MI',
    role: 'PROVIDER',
    stage: 'med_initiation',
    source_type: '',
    actions: [
      { title: 'Submit', returnFun: {} },
      { title: 'Cancel', returnFun: {} },
    ],
    focusTabIndex: 0,
    tabs: [
      {
        title: 'Currently Taking',
        index: 0,
        comp: 'DetailsComponent_Key',
        props: {},
      },
      {
        title: 'Rationale',
        index: 1,
        comp: 'RationaleComponent_Key',
        props: {},
      },
      {
        title: 'Recommendation',
        index: 2,
        comp: 'ScheduleCarePlanComponent_Key',
        props: {},
      },
      {
        title: 'Medication Evaluation',
        index: 3,
        comp: 'ToxicityComponent_Key',
        props: {},
      },
      { title: 'Notes', index: 4, comp: 'NotesComponent_Key', props: {} },
      { title: 'Referral', index: 5, comp: 'ReferralComponent_Key', props: {} },
      {
        title: 'History',
        index: 6,
        comp: 'MedHistoryComponent_Key',
        props: {},
      },
    ],
  },
  {
    id: 'N_MI',
    role: 'NAVIGATOR',
    stage: 'med_initiation',
    source_type: '',
    actions: [
      { title: 'Submit', returnFun: {} },
      { title: 'Cancel', returnFun: {} },
    ],
    focusTabIndex: 0,
    tabs: [
      {
        title: 'Currently Taking',
        index: 0,
        comp: 'DetailsComponent_Key',
        props: {},
      },
      {
        title: 'Rationale',
        index: 1,
        comp: 'RationaleComponent_Key',
        props: {},
      },
      {
        title: 'Recommendation',
        index: 2,
        comp: 'ScheduleCarePlanComponent_Key',
        props: {},
      },
      {
        title: 'Medication Evaluation',
        index: 3,
        comp: 'ToxicityComponent_Key',
        props: {},
      },
      { title: 'Notes', index: 4, comp: 'NotesComponent_Key', props: {} },
      { title: 'Referral', index: 5, comp: 'ReferralComponent_Key', props: {} },
      {
        title: 'History',
        index: 6,
        comp: 'MedHistoryComponent_Key',
        props: {},
      },
    ],
  },
  {
    id: 'N_TC',
    role: 'NAVIGATOR',
    stage: 'early_tox_check',
    source_type: '',
    actions: [
      { title: 'Submit', returnFun: {} },
      { title: 'Cancel', returnFun: {} },
    ],
    focusTabIndex: 3,
    tabs: [
      {
        title: 'Currently Taking',
        index: 0,
        comp: 'DetailsComponent_Key',
        props: {},
      },
      {
        title: 'Rationale',
        index: 1,
        comp: 'RationaleComponent_Key',
        props: {},
      },
      {
        title: 'Recommendation',
        index: 2,
        comp: 'ScheduleCarePlanComponent_Key',
        props: {},
      },
      {
        title: 'Medication Evaluation',
        index: 3,
        comp: 'ToxicityComponent_Key',
        props: {},
      },
      { title: 'Notes', index: 4, comp: 'NotesComponent_Key', props: {} },
      { title: 'Referral', index: 5, comp: 'ReferralComponent_Key', props: {} },
      {
        title: 'History',
        index: 6,
        comp: 'MedHistoryComponent_Key',
        props: {},
      },
    ],
  },
  {
    id: 'P_TC',
    role: 'PROVIDER',
    stage: 'early_tox_check',
    source_type: '',
    actions: [
      { title: 'Submit', returnFun: {} },
      { title: 'Cancel', returnFun: {} },
    ],
    focusTabIndex: 3,
    tabs: [
      {
        title: 'Currently Taking',
        index: 0,
        comp: 'DetailsComponent_Key',
        props: {},
      },
      {
        title: 'Rationale',
        index: 1,
        comp: 'RationaleComponent_Key',
        props: {},
      },
      {
        title: 'Recommendation',
        index: 2,
        comp: 'ScheduleCarePlanComponent_Key',
        props: {},
      },
      {
        title: 'Medication Evaluation',
        index: 3,
        comp: 'ToxicityComponent_Key',
        props: {},
      },
      { title: 'Notes', index: 4, comp: 'NotesComponent_Key', props: {} },
      { title: 'Referral', index: 5, comp: 'ReferralComponent_Key', props: {} },
      {
        title: 'History',
        index: 6,
        comp: 'MedHistoryComponent_Key',
        props: {},
      },
    ],
  },
  {
    id: 'N_ME',
    role: 'NAVIGATOR',
    stage: 'med_evaluation',
    source_type: '',
    actions: [
      { title: 'Submit', returnFun: {} },
      { title: 'Cancel', returnFun: {} },
    ],
    focusTabIndex: 1,
    tabs: [
      {
        title: 'Currently Taking',
        index: 0,
        comp: 'DetailsComponent_Key',
        props: {},
      },
      {
        title: 'Medication Evaluation',
        index: 1,
        comp: 'ToxicityComponent_Key',
        props: {},
      },
      { title: 'Notes', index: 2, comp: 'NotesComponent_Key', props: {} },
      { title: 'Referral', index: 3, comp: 'ReferralComponent_Key', props: {} },
      {
        title: 'History',
        index: 4,
        comp: 'MedHistoryComponent_Key',
        props: {},
      },
    ],
  },
  {
    id: 'P_ME',
    role: 'PROVIDER',
    stage: 'med_evaluation',
    source_type: '',
    actions: [
      { title: 'Submit', returnFun: {} },
      { title: 'Cancel', returnFun: {} },
    ],
    focusTabIndex: 1,
    tabs: [
      {
        title: 'Currently Taking',
        index: 0,
        comp: 'DetailsComponent_Key',
        props: {},
      },
      {
        title: 'Medication Evaluation',
        index: 1,
        comp: 'ToxicityComponent_Key',
        props: {},
      },
      { title: 'Notes', index: 2, comp: 'NotesComponent_Key', props: {} },
      { title: 'Referral', index: 3, comp: 'ReferralComponent_Key', props: {} },
      {
        title: 'History',
        index: 4,
        comp: 'MedHistoryComponent_Key',
        props: {},
      },
    ],
  },
  {
    id: 'N_MS',
    role: 'NAVIGATOR',
    stage: 'maintenance_surveillance',
    source_type: '',
    actions: [
      { title: 'Submit', returnFun: {} },
      { title: 'Cancel', returnFun: {} },
    ],
    focusTabIndex: 0,
    tabs: [
      {
        title: 'Currently Taking',
        index: 0,
        comp: 'DetailsComponent_Key',
        props: {},
      },
      {
        title: 'Rationale',
        index: 1,
        comp: 'RationaleComponent_Key',
        props: {},
      },
      {
        title: 'Recommendation',
        index: 2,
        comp: 'ScheduleCarePlanComponent_Key',
        props: {},
      },
      {
        title: 'Medication Evaluation',
        index: 3,
        comp: 'ToxicityComponent_Key',
        props: {},
      },
      { title: 'Notes', index: 4, comp: 'NotesComponent_Key', props: {} },
      { title: 'Referral', index: 5, comp: 'ReferralComponent_Key', props: {} },
      {
        title: 'History',
        index: 6,
        comp: 'MedHistoryComponent_Key',
        props: {},
      },
    ],
  },
  {
    id: 'P_MS',
    role: 'PROVIDER',
    stage: 'maintenance_surveillance',
    source_type: '',
    actions: [
      { title: 'Submit', returnFun: {} },
      { title: 'Cancel', returnFun: {} },
    ],
    focusTabIndex: 0,
    tabs: [
      {
        title: 'Currently Taking',
        index: 0,
        comp: 'DetailsComponent_Key',
        props: {},
      },
      {
        title: 'Rationale',
        index: 1,
        comp: 'RationaleComponent_Key',
        props: {},
      },
      {
        title: 'Recommendation',
        index: 2,
        comp: 'ScheduleCarePlanComponent_Key',
        props: {},
      },
      {
        title: 'Medication Evaluation',
        index: 3,
        comp: 'ToxicityComponent_Key',
        props: {},
      },
      { title: 'Notes', index: 4, comp: 'NotesComponent_Key', props: {} },
      { title: 'Referral', index: 5, comp: 'ReferralComponent_Key', props: {} },
      {
        title: 'History',
        index: 6,
        comp: 'MedHistoryComponent_Key',
        props: {},
      },
    ],
  },
]
