import { StyleRules } from '@mui/styles'

const stylesheet: StyleRules = {
  root: {
    minWidth: 275,
    margin: '50px 50px'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 5,
  },
  linkStyle: {
    textDecoration: 'none'
  },
  pageHeader: {
    margin: '20px 20px'
  },
  iconsStyle: {
    paddingTop: '0px',
    border: 0,
    float: 'left',
    marginRight: '15px',
    fill: '#6d6e71'
  },
  headerMenu: {
    width: '200px',
    fontFamily: 'Helvetica',
    fontSize: '19px',
    color: '#000000',
    textDecoration: 'none solid rgb(120, 120, 120)',
    lineHeight: '23px',
    marginBottom: '10px'
  },
  btnContainer: {
    padding: '0.5rem 1.375rem 1rem 0 !important',
    backgroundColor: '#F0F1F2'
  },
  fullWidth: {
    width: '100%'
  },
  questionnaireContainer: {
    backgroundColor: "#f0f1f2",
    padding: "0 !important",
    marginBottom: '0px !important'
  },
  zeroBottomMargin: {
    marginBottom: '0px'
  },
  questionnaireCardContainer: {
    padding: '0.5rem 1rem 1rem 1rem !important',
    maxHeight: '28rem',
    overflow: 'scroll'
  },
  infoIcon: {
    color: '#CCCCCC !important',
    paddingLeft: '0.1rem !important',
  },
  infoIconFont: {
    fontSize: '14px !important'
  },
  patientAccrodionBg: {
    backgroundColor: '#f1f1f1 !important'
  },
  boxBorder: {
    background: 'transparent linear-gradient(180deg, #f8f5f50d 0%, #a89c9c40 34%, #999999 78%, #FFF 100%) 0% 0% no-repeat padding-box !important',
    backgroundSize: '100% 5px !important',
    minHeight: '0.18rem'
  },
};

export default stylesheet;