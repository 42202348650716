import {
  Box, Button, ButtonGroup, Card, CardContent, CircularProgress,
  Grid, Typography, TextField, Autocomplete
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { PatientService } from '../../../../services/patient.service'
import moment from 'moment'
import stylesheet from '../detail-container.stylesheet'
import { checkCondition } from '../common/condition-check'
import { sanitizedData } from '../../../../utils/sanitize-html'
import DatePickerComponent from '../../../../utils/datepicker.component'

interface IProps {
  input: any
  name: any
  outer: any
  outerSubmit: any
  noDropDown: any
  edit: boolean
  handleClose: any
  classes: ClassNameMap
  errorMessage: any
}

const QuestionOCComponent: React.FC<IProps> = ({
  input,
  name,
  outer,
  outerSubmit,
  noDropDown,
  edit,
  handleClose,
  classes,
  errorMessage,
}) => {
  const [notes, setNotes] = useState('')
  const [open, setOpen] = useState(false)
  const [recordedDate, setRecordedDate] = useState(
    checkCondition(
      input && input.date_recorded !== null,
      moment.unix(input.date_recorded).format('YYYY-MM-DDTHH:mm'),
      'YYYY-MM-DDTHH:mm'
    )
  )
  const [selectedOption, setSelectedOption] = useState(null)
  const [isError, setIsError] = useState(false)
  const [query, setQuery] = useState('')
  const [dropdownOptions, setDropdownOptions] = useState([] as any)
  const patientService = new PatientService()
  const [loading, setLoading] = useState(false)
  const [noteErr, setNoteErr] = useState({ error: false, msg: '' })
  const { validateSanitizeInput } = sanitizedData()

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value)
    setNoteErr({ error: false, msg: '' })
  }

  const sanitize = () => {
    const sanitizeData = validateSanitizeInput(notes)
    setNoteErr({ error: sanitizeData.error, msg: sanitizeData.msg })
  }

  const debounce = (func: any, delay: any) => {
    let debounceTimer: any
    return function () {
      const context = func
      const args = arguments
      clearTimeout(debounceTimer)
      debounceTimer = setTimeout(() => func.apply(context, args), delay)
    }
  }

  useEffect(() => {
    let active = true
    if (active && query.length >= 2) {
      try {
        setLoading(true)
        patientService.getMedicationReactionBySearchDiagnosis(query).then((res) => {
          setDropdownOptions(res.data)
          setLoading(false)
        })
      } catch (error) {
        setDropdownOptions([])
        setLoading(false)
        console.error(error)
      }
    }
    return () => {
      active = false
    }
  }, [query])

  const onCloseClick = (mode: string) => {
    outer(input.id, mode)
  }

  const onSubmit = (_mode: string) => {
    outerSubmit(_mode, selectedOption, recordedDate, notes)
    setIsError(true)
  }

  const onEditSubmit = () => {
    outerSubmit(recordedDate, input.id, notes)
    handleClose()
  }

  const defaultValue = {
    name: name.display_name,
  }
  return (
    <>
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        direction="row"
        className={classes.fullWidth}
      >
        <Box mb={1} className={classes.customCard}>
          <Card variant="outlined" className={classes.questionCardContent}>
            <>
              <Box>
                <CardContent>
                  <Autocomplete
                    disabled={edit}
                    id="combo-box-demo"
                    open={open}
                    onOpen={() => {
                      setOpen(true)
                      setDropdownOptions([])
                      setQuery('')
                      setIsError(false)
                    }}
                    onClose={() => {
                      setOpen(false)
                      setDropdownOptions([])
                      setQuery('')
                      setIsError(false)
                    }}
                    options={dropdownOptions}
                    getOptionLabel={(option: any) => option.name}
                    style={{
                      display: noDropDown,
                    }}
                    className={classes.autoCompleteDropDowns}
                    loading={loading}
                    value={checkCondition(edit, defaultValue, selectedOption)}
                    onChange={(_e, newValue: any) => {
                      setSelectedOption(newValue)
                      setIsError(false)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        onChange={debounce((e: any) => {
                          setQuery(e.target.value)
                          setIsError(false)
                        }, 1000)}
                        label="Search and select condition from lookup"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {checkCondition(
                                loading,
                                <CircularProgress color="inherit" size={20} />,
                                null
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                  {checkCondition(
                    isError &&
                    errorMessage === 'Record already exists for the patient',
                    <Typography variant="caption" className={classes.errorText}>
                      Record already exists for the patient
                    </Typography>
                  )}
                  <DatePickerComponent
                    label="When did this happen last time"
                    type="datetime-local"
                    variant={'standard'}
                    cssClass={classes.datePicker}
                    defaultValue={recordedDate}
                    onCalendarChange={(e: any) => {
                      setRecordedDate(e.target.value)
                    }}
                  />
                  <Box>
                    <TextField
                      id="notes-basic"
                      label="Take notes here"
                      value={notes}
                      variant="outlined"
                      size="small"
                      className={classes.fullWidth}
                      InputProps={{ style: { fontSize: 15 } }}
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      onChange={handleNoteChange}
                      onBlur={sanitize}
                      error={noteErr.error}
                      helperText={noteErr.msg}
                      multiline={true}
                      rows="3"
                    />
                  </Box>
                </CardContent>
              </Box>
            </>
            <Box>
              <Grid
                container
                xs={12}
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <ButtonGroup
                  className={classes.questionButtonStyle}
                  size="small"
                  variant="text"
                  aria-label="small outlined button group"
                >
                  <Button
                    disabled={
                      noteErr.error ||
                      checkCondition(
                        edit || selectedOption !== null,
                        false,
                        true
                      )
                    }
                    onClick={checkCondition(
                      edit,
                      () => onEditSubmit(),
                      () => onSubmit('view')
                    )}
                    className={classes.twoButtonWidth}
                  >
                    <strong>Save</strong>
                  </Button>
                  <Button
                    onClick={checkCondition(
                      edit,
                      () => onCloseClick('view'),
                      handleClose
                    )}
                    className={classes.twoButtonWidth}
                  >
                    <strong>Cancel</strong>
                  </Button>
                </ButtonGroup>
              </Grid>
            </Box>
          </Card>
        </Box>
      </Grid>
    </>
  )
}

export default withStyles(stylesheet)(QuestionOCComponent)
