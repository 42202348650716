import { Grid, Typography, Button, CircularProgress, Box, ListItem, List } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import React, { useState } from 'react'
import stylesheet from '../detail-container.stylesheet'
import { PatientService } from '../../../../services/patient.service'
import Helper from '../../../../utils/helper'
import { toast } from 'react-toastify'
import moment from 'moment'

interface IProps {
  classes: ClassNameMap
  item: any,
}

const MonitorEvaluationComponent: React.FC<IProps> = ({ classes, item }) => {
  const [loadingEval, setLoadingEval] = useState(false)
  const [monitorEvalData, setMonitorEvalData] = useState(item || {} as any)
  const patientService = new PatientService()

  const handleMonitorEval = () => {
    setLoadingEval(true)
    patientService.getMonitorvaluation(item?.id).then((response: any) => {
      setMonitorEvalData(response?.data?.monitor)
      setLoadingEval(false)
    }).catch((err: any) => {
      setLoadingEval(false)
      toast.error('Data not avaiable.', Helper.bottom_center())
    })
  }

  const getStageCriteria = (name: any) => {
    return <Grid item className={classes.fullWidth}>
      <Grid container direction="row" className={classes.evalToxCard}>
        <Grid item>{getLabel(name)}</Grid>
        <Grid item className={classes.fullWidth}>
          <Grid container direction="row" className={classes.evalSubBox}>
            {getToxCriteriaByGroup(monitorEvalData?.rationale?.stage_termination_criteria)}
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  }


  const getCriteriaList = (criteriaObj: any, isValue: boolean) => criteriaObj && Object.keys(criteriaObj)?.map((m: any, key: any) => {
    const { value, dependents } = criteriaObj[m]
    const depenList = dependents && Object.keys(dependents)?.map((key: any) => `${key}: ${dependents[key]}`).join(', ')

    return value === isValue && <List className={classes.criteriaList}>
      {renderToxList(`${m} ${depenList && `: [${depenList}]`}`)}
    </List>
  })

  const getToxCriteriaByGroup = (criteriaObj: any) => {
    return (Object.keys(criteriaObj)?.length > 0) && <Grid container spacing={2} direction='row' className={classes.evalSubBox}>
      <Grid item xs={6}>
        <Typography className={classes.evalBoxVal}><u>True</u></Typography>
        {getCriteriaList(criteriaObj, true)}
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.evalBoxVal}><u>False</u></Typography>
        {getCriteriaList(criteriaObj, false)}
      </Grid>
    </Grid>
  }

  const renderToxList = (val: any) => <ListItem className={classes.puList}>
    <Typography className={classes.evalBoxVal}>{val}</Typography>
  </ListItem>

  const getLabel = (val: any) => <Typography className={classes.evalBoxLabel} variant="body2" color="textSecondary">{val}</Typography>

  const renderdata = (label: any, val: any) => {
    if (val) {
      if (label === 'Meets stage term cri')
        val = val?.toString()
      else if (label === 'Measurements') {
        let measurementData = ''
        Object.keys(val)?.length > 0 && Object.keys(val)?.map((item: any, index: any) => {
          measurementData += `${item} : ${val[item]}${(index + 1) < Object.keys(val)?.length ? ',  ' : ''} `
        })
        val = measurementData
      }
    }

    return <Grid item className={classes.fullWidth}>
      <Grid container direction="row" className={classes.evalToxCard}>
        <Grid item>{getLabel(label)}</Grid>
        <Grid item>
          <Typography className={classes.toxicityEvalBoxVal}>
            {val}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  }
  return (
    <Box mb={2}>
      <Grid container direction='row' spacing={1} justifyContent={'space-between'}>
        <Grid item xs={8}>
          {Object.keys(monitorEvalData)?.length > 0 && (
            <Grid className={classes.evalContent} container spacing={1}>
              {renderdata('Name', monitorEvalData?.display_name)}
              {renderdata('Start date', moment.unix(monitorEvalData?.start_date).format('MMM DD, YYYY hh:mm a'))}
              {renderdata('End date', monitorEvalData?.end_date !== null && moment.unix(monitorEvalData?.end_date).format('MMM DD, YYYY hh:mm a'))}
              {renderdata('Due date text', monitorEvalData?.due_date_txt)}
              {Object.keys(monitorEvalData?.rationale)?.length > 0
                ? <>
                  {renderdata('Measurements', monitorEvalData?.rationale?.measurements)}
                  {renderdata('Meets stage term cri', monitorEvalData?.rationale?.meets_stage_term_cri)}
                  {getStageCriteria('Stage termination criteria')}
                </>
                : <>
                  {renderdata('Measurements', '')}
                  {renderdata('Meets stage term cri', '')}
                  {renderdata('Stage termination criteria', '')}
                </>
              }
              {renderdata('Surveys', monitorEvalData?.surveys)}
              {renderdata('Type', monitorEvalData?.type)}
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Grid container direction='row' spacing={1} >
            {loadingEval && <Grid item><CircularProgress className={classes.loadingEval} /></Grid>}
            <Grid item>
              {item?.type !== 'completed' &&
                <Typography variant="subtitle2" color="textSecondary">
                  <Button variant="outlined" size="small" onClick={handleMonitorEval}>
                    Run Monitor Evaluation
                  </Button>
                </Typography>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withStyles(stylesheet)(MonitorEvaluationComponent)
