import React, { useEffect, useState } from 'react'
import {
  Avatar, Container, Grid, Typography, IconButton, Tooltip, Skeleton, Badge
} from '@mui/material'
import { ClassNameMap, withStyles, makeStyles, } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import avatar from '../../../../assets/Profile-pic1.jpg'
import { ReactComponent as ExpanIcon } from '../../../../assets/ExpanIcon.svg'
import { ReactComponent as CollapseIcon } from '../../../../assets/CollapseIcon.svg'
import { ReactComponent as CollapseHoverIcon } from '../../../../assets/CollapseHoverIcon.svg'
import { ReactComponent as ExpandHoverIcon } from '../../../../assets/ExpandHoverIcon.svg'
import { useSelector } from 'react-redux'
import { RootState, useAppThunkDispatch } from '../../../../redux/store'
import { setAddValue } from '../../../../redux/add-control-slice'
import { checkCondition } from '../common/condition-check'
import EditIcon from '@mui/icons-material/Edit'
import EditProfileModal from './edit-profile-modal'
import moment from 'moment'

const useStyles = makeStyles(() => ({
  paddingClass: {
    padding: '0 2rem 0 2rem',
    // [theme.breakpoints.up('lg')]: {
    //   padding: '0 2rem 0 2rem',
    // },
    // [theme.breakpoints.down('xs')]: {
    //   margin: 0,
    //   padding: '0 0 0 0.5rem',

    '& > .MuiGrid-item': {
      padding: '0 0 0.5rem 0',
      margin: 0,
    },
    //   },
  },
}))

interface IProps {
  classes: ClassNameMap
  handleClick: any
}

const MultiProgramInfoHeaderComponent: React.FC<IProps> = ({
  classes,
  handleClick,
}) => {
  const classess = useStyles()
  const patientDetails = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const dispatch = useAppThunkDispatch()
  const addButtons: any = useSelector((state: RootState) => state.addButtons)
  const [showTimeline, setShowTimeline] = useState(false)
  const [editProfile, setEditProfile] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (isOpen)
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [isOpen])

  const handleDialogClose = () => setEditProfile(false)

  const ShowJourneyIcon = checkCondition(
    isOpen,
    checkCondition(showTimeline, CollapseHoverIcon, CollapseIcon),
    checkCondition(showTimeline, ExpandHoverIcon, ExpanIcon)
  )

  const getPendingCount = () => {
    let count = 0
    if (patientDetails?.birthdate === null || patientDetails?.birthdate === '')
      count++
    if (patientDetails?.c_sex === null || patientDetails?.c_sex === '')
      count++
    return count
  }

  const renderEdit = () => <EditIcon
    className={classes.editProfileIcon}
    onClick={() => setEditProfile(true)}
    sx={{ fontSize: '18px', color: patientDetails.needs_update === true ? '#FF7015' : 'inherit' }}
  />

  const handleAddHidePatientJourney = () => {
    const temp = [
      ...addButtons?.addControl.map((e: any) =>
        checkCondition(e.key === 'PA-JOR', { ...e, value: !e.value }, e)
      ),
    ]
    dispatch(setAddValue(temp))
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Container maxWidth="xl" disableGutters={true}>
        <Grid container spacing={1} className={`${classess.paddingClass}`} justifyContent="space-around">
          <Grid item style={{ width: '30%' }} className={classes.topHead}>
            <IconButton
              disabled={patientDetails.sf_patient_deeplink === null}
              href={patientDetails.sf_patient_deeplink}
              target="_blank"
              style={{ padding: '0px' }}
            >
              <Avatar
                variant="square"
                className={classes.avatarPic}
                src={avatar}
                style={patientDetails.sf_patient_deeplink
                  ? { borderColor: '#3C97DD' }
                  : { borderColor: '#CBC7C780' }
                }
              />
            </IconButton>
            <div className={classes.topNameDet}>
              {checkCondition(
                patientDetails && (patientDetails.given_name || patientDetails.family_name),
                <Grid container justifyContent="space-between" className={classes.multiProgramInfoContainer}>
                  <Grid item className={classes.multiProgramInfoHeader}>
                    <Typography className={[classes.topBarDetails1, classes.patientNameTop].join(' ')}>
                      {`${patientDetails.given_name} ${patientDetails.family_name || ''}`}
                    </Typography>
                    {((patientDetails?.birthdate === null || patientDetails?.birthdate === '')
                      || (patientDetails?.c_sex === null || patientDetails?.c_sex === '')
                    ) ?
                      <Badge badgeContent={getPendingCount()}
                        variant="standard"
                        color="warning"
                        sx={{ margin: '0.3rem 0.6rem 0 0' }}
                      >
                        {renderEdit()}
                      </Badge>
                      : renderEdit()
                    }
                  </Grid>
                </Grid>,
                <Skeleton animation="wave" />
              )}
              <Typography className={classes.ageGenderMrn}>
                {patientDetails.race || patientDetails.sex || patientDetails.birthdate
                  ? (
                    <>
                      {checkCondition(patientDetails.sex, `${patientDetails.sex}, `, '')}
                      {' '}
                      {checkCondition(patientDetails.birthdate, `${moment(patientDetails.birthdate).format('MMM DD YYYY')} `, '')}
                      {' '}
                      {checkCondition(patientDetails.age, `(${patientDetails.age} yrs), `, '')}
                      {' '}
                      {checkCondition(patientDetails.ref_id, `${patientDetails.ref_id} `, '')}
                    </>
                  ) : (
                    <>-</>
                  )}
              </Typography>
            </div>
            <ShowJourneyIcon
              onClick={(_e: any) => handleAddHidePatientJourney()}
              onMouseOver={() => setShowTimeline(true)}
              onMouseOut={() => setShowTimeline(false)}
              className={classes.downArrowIcon}
            />
          </Grid>
          <Grid item style={{ width: '18%' }} className={classes.email}>
            <Typography className={classes.topBarHeads}>Synced on</Typography><br />
            <Typography className={classes.topBarDetails1}>
              {patientDetails?.last_sync_date
                ? moment.unix(patientDetails?.last_sync_date).format("MMM DD, YYYY h:mm a")
                : '-'
              }
            </Typography>
          </Grid>
          <Grid item style={{ width: '19%' }} className={classes.email}>
            <Typography className={classes.topBarHeads}>Email</Typography><br />
            {patientDetails && patientDetails.email ? (
              <Tooltip title={patientDetails?.email} placement="bottom">
                <Typography title={patientDetails?.email} className={classes.topBarDetails1}>
                  {patientDetails.email}
                </Typography>
              </Tooltip>
            ) : (
              <Typography className={classes.topBarDetails1}> - </Typography>
            )}
          </Grid>
          <Grid item style={{ width: '15%' }} className={classes.email}>
            <Typography className={classes.topBarHeads}>Mobile</Typography><br />
            <Tooltip title={patientDetails?.phone} placement="bottom">
              <Typography className={classes.topBarDetails1} title={patientDetails?.phone}>
                {checkCondition(patientDetails.phone, patientDetails.phone, '-')}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item style={{ width: '18%' }} className={classes.email}>
            <Typography className={classes.topBarHeads}>MRN</Typography> <br />
            <Tooltip title={patientDetails?.mrns?.join()} placement="bottom">
              <Typography className={classes.topBarDetails1}>{patientDetails?.mrns?.join() || '-'}</Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Container>
      {editProfile &&
        <EditProfileModal
          open={editProfile}
          patientId={patientDetails.id}
          handleDialogClose={handleDialogClose}
        />
      }
    </>
  )
}

export default withStyles(stylesheet)(MultiProgramInfoHeaderComponent)
