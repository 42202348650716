import React, { useState, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import ToolbarComponent from './toolbar.component';
import DrawerComponent from './drawer.component';
import { RootState, useAppThunkDispatch } from '../../redux/store';
import { resetEncryptedToken, resetLoggedInUser, setEncryptedToken, setLoggedInUser } from '../../redux/common-data-slice';
import { useSelector } from 'react-redux';
import { PatientService } from '../../services/patient.service';
import { withStyles } from '@mui/styles'
import stylesheet from '../patients/detail/detail-container.stylesheet'
import { ClassNameMap } from '@mui/styles'

interface IProps extends RouteComponentProps {
  setCorsErrorModalOpen: any;
  history: any;
  location: any;
  match: any;
  classes: ClassNameMap;
}

const OktaNavbarComponent: React.FC<IProps> = ({ setCorsErrorModalOpen, classes }) => {
  const [left, setLeft] = useState(false);
  const { authState, oktaAuth } = useOktaAuth();
  const dispatch = useAppThunkDispatch();
  const loggedInUser = useSelector(
    (state: RootState) => state.commonData.loggedInUser
  )

  const history = useHistory();

  const patientService = new PatientService()

  const renewedToken = (key: any, newToken: any, oldToken: any) => {
    if (key === 'accessToken') {
      tokenVerificationAPI('renewed')
    }
    console.log('Token with key', key, 'has been renewed', +new Date());
  }

  const expiredToken = (key: any) => {
    console.log('Token with key', key, ' has expired:', +new Date());
  }

  const tokenVerificationAPI = async (_status: string) => {
    try {
      console.log(_status);
      const res = await patientService.tokenVerification()
      dispatch(setEncryptedToken({ token: res.data.token, status: _status }))
    } catch (error) {
      console.error(error)
    }
  }

  const errorToken = (err: any) => {
    console.log('TokenManager error:', err);
    dispatch(resetEncryptedToken())
    history.push('/');
  }

  useEffect(() => {
    oktaAuth.tokenManager.on('renewed', renewedToken);
    oktaAuth.tokenManager.on('expired', expiredToken);
    oktaAuth.tokenManager.on('error', errorToken);
    return () => {
      oktaAuth.tokenManager.off('renewed', renewedToken);
      oktaAuth.tokenManager.off('expired', expiredToken);
      oktaAuth.tokenManager.off('error', errorToken);
    };
  }, [])

  useEffect(() => {
    //console.log(authState, oktaAuth)
    if (!authState?.isAuthenticated) {
      dispatch(resetLoggedInUser())
    } else {
      if (!loggedInUser.email) {
        oktaAuth.getUser().then((info: any) => {
          dispatch(setLoggedInUser({ ...info, roles: authState?.accessToken?.claims['Groups'] }))
        }).catch((err) => console.error(err));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, oktaAuth])


  const toggleDrawer = (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setLeft(false);
  };

  const openDrawer = () => {
    setLeft(true);
  };

  return (
    <>
      {authState?.isAuthenticated && (
        <div className={classes.navbar}>
          <ToolbarComponent
            openDrawerHandler={openDrawer}
            history={''}
            location={window.location}
            match={''}
            isOktaEnabled={'true'}
          />

          <DrawerComponent
            left={left}
            toggleDrawerHandler={toggleDrawer}
            history={''}
            location={''}
            match={''}
          />
        </div>
      )}
    </>
  );
};

export default withStyles(stylesheet)(OktaNavbarComponent);
