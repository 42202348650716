import React from 'react';
import { Grid } from '@mui/material';
import { RouteComponentProps } from 'react-router-dom';

interface IProps extends RouteComponentProps {
  history: any;
  location: any;
  match: any;
}

const PatientHomePageComponent: React.FC<IProps> = ({ history, location, match }) => {

  return (
    <Grid container spacing={0} data-testid='patient-homepage-testId'>
      <Grid container item lg={12} direction='column'>
        <span>Patient Home Page</span>
      </Grid>

    </Grid>
  );
};

export default PatientHomePageComponent;
