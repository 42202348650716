import React, { memo, useEffect, useState, useRef } from "react";
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import HistoryPDIComponent from "./history-pdi.component";
import stylesheet from "../detail-container.stylesheet";
import CardLayoutComponent from "../common/card-layout.component";
import moment from "moment";
import NotesComponent from "../common/notes.component";
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "../../../../redux/store";
import ReferralComponent from '../common/referral-card.component'
import { useDispatchAPIs } from '../../../../utils/use-dispatchApis-hook'
import { useReferralAction } from '../../../../utils/referral-hook'
import { useOverrideAction } from '../../../../utils/override-hook'
import EmptyCardComponent from "../common/empty-card.component"
import ManualOverrideComponent from "../common/manual-override.component"
import { useCheckConfirmCount } from "../../../../utils/use-checkconfirmationcount-hook";
import MeasurementCardComponent from "./measurement-card.component"
import DeleteCardComponent from "../common/delete-card-component";
import Helper from "../../../../utils/helper";
import { toast } from "react-toastify";
import { PatientService } from "../../../../services/patient.service";
import { useUpdateDataAction } from "../../../../utils/update-details-hook";
interface IProps {
  input: any;
  classes: ClassNameMap;
}

const ProgramDataIntakeComponent: React.FC<IProps> = ({ input, classes }) => {
  const [data, setData] = useState([] as any);
  const [, setCount] = useCheckConfirmCount(input.patientId)
  const [selectedNotes, setSelectedNotes] = useState([] as number[])
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const patientService = new PatientService()
  const selectedProgram = useSelector((state: RootState) => state.commonData.selectedProgram)
  const { onSetView, onDataSubmit } = useReferralAction()
  const { updatePatientData } = useUpdateDataAction(patientDetail.id)
  const { onSetOverView, onDataOverSubmit } = useOverrideAction()
  const intakeRef = useRef(null);
  const { multipleProgramCounts } = useDispatchAPIs(patientDetail.id)

  useEffect(() => {
    initialiseData(patientDetail?.measurements?.program_checks);
  }, [patientDetail]);


  const initialiseData = (info: any) => {
    const stage = info?.map((e: any) => { return { ...e, mode: "view" } })
    setData(stage)
  };

  const outer = (id: any, mode: string) => {
    if (mode === 'view_delete_history') {
      let currData = data.map((el: any) => {
        if (el.id === id) {
          let currObj = { ...el }
          if (currObj?.historyData)
            delete currObj['historyData']
          return { ...currObj, mode: 'history' }
        }
        else
          return el
      })
      setData(currData)
    } else if (mode === 'delete') {
      setData(data?.map((el: any) => (el.id === id?.id ? { ...el, historyData: id?.historyData, mode: 'delete' } : el)))
    } else if (mode === 'edit_history') {
      setData(data?.map((el: any) => (el.mode === 'history' ? { ...el, historyData: id?.historyData, mode: mode } : el)))
    }
    else
      setData(data?.map((el: any) => (el.id === id ? { ...el, mode: mode } : el)))
  }

  const confirmDelete = (id: any, rec_id: number) => {
    rec_id && patientService.deleteMeasurementsHistory(patientDetail?.id, [rec_id]).then((res: any) => {
      if (res?.data.message === 'success') {
        updatePatientData({ isMeasurement: true })
      }
    }).catch((error: any) => toast.error(Helper.getErrorText(error), Helper.bottom_center()))
  }

  const onSetHistory = (_id: string) => outer(_id, "history")
  const onSetEdit = (_id: string) => outer(_id, "edit")

  const setIcons = () => {
    return input.isActionItems ? []
      : [
        { iconType: "edit", callback: onSetEdit },
        { iconType: "visible", callback: onSetHistory },
        { iconType: "description", callback: onNotesClick },
        { iconType: "more", callback: {} },
      ]
  }

  const rightHeaderCal = (d: any) => {
    return (
      <Typography variant="body1" className={classes.desc}
        sx={{ paddingTop: '2px !important' }}
        color={((!d.value_range?.min && !d.value_range?.max) || d.value === null)
          ? "initial"
          : (+d.value ?? 0) >= (d.value_range?.min ?? -99999) &&
            (+d.value ?? 0) <= (d.value_range?.max ?? 99999)
            ? "initial"
            : "red"

        }
        align="right"
      >
        <strong>{d.value ? d.value : "-"}</strong>
      </Typography>
    );
  };

  const subHeaderCal = (d: any) => {
    if (d?.value_range?.min === null && d?.value_range?.max === null)
      return d?.unit ? d.unit : ''

    let vrmin = ''
    let vrmax = ''
    if (d?.value_range?.min !== null) vrmin = d?.value_range?.min
    if (d?.value_range?.max !== null) vrmax = d?.value_range?.max
    let unit = d?.unit ? ` ${d?.unit}` : ''
    return <>({vrmin}{vrmin && vrmax ? '-' : ''}{vrmax}{unit})</>
  }

  const bottomTextCal = (d: any) => {
    const date = (d.is_referred)
      ? moment.unix(d.referred_on).format("MM/DD/YY")
      : d.date_recorded
        ? moment.unix(d.date_recorded).format("MM/DD/YY")
        : ""

    const days = (d?.next_update_in_days > 0 && d?.next_update_in_days <= 90)
      ? <Typography
        className={classes.subHeaderTitle}
        variant="subtitle2"
        component="span"
        color={d?.next_update_in_days < 14 ? 'red' :
          d?.next_update_in_days < 90
            ? 'gray'
            : 'inherit'}
        align="right"
      >
        <small>({d?.next_update_in_days})&nbsp;</small>
      </Typography>
      : ''

    return (d?.next_update_in_days > 90)
      ? <Tooltip title={`Next update: ${d?.next_update_in_days} days`}>
        <Typography
          component={'span'}
          className={classes.subHeaderTitle}
          variant="subtitle2"
          color="textSecondary"
        >
          <small>{date}</small>
        </Typography>
      </Tooltip>
      : <Tooltip title={`Next update: ${d?.next_update_in_days} days`}>
        <Typography
          component={'span'}
          className={classes.subHeaderTitle}
          variant="subtitle2"
          color="textSecondary"
        >
          {days}<small>{date}</small>
        </Typography>
      </Tooltip>
  };

  const onNotesClick = (_id: any) => {
    if (selectedNotes.includes(+_id)) {
      setSelectedNotes([...selectedNotes.filter((f) => f !== +_id)]);
    } else {
      setSelectedNotes([...selectedNotes, +_id]);
    }
  };

  const onRefSubmit = (_id: any, date: number, is_referred: boolean, referral_id: any) => {
    const updatedData = onDataSubmit(_id, date, is_referred, referral_id, data)
    setData(updatedData)
    // dispatch(setProgramChecksStore(updatedData))
  }

  const onRefClick = (_id: any, _mode: string) => {
    setData(onSetView(_id, _mode, data))
  }

  const onOverrideSubmit = (_id: any, date: number, is_overridden: boolean, overridenPrograms: any[]) => {
    const updatedData = onDataOverSubmit(_id, date, is_overridden, data, overridenPrograms)
    setData(updatedData)
    // dispatch(setProgramChecksStore(updatedData))

    if (is_overridden) {
      setCount(true, false)
      multipleProgramCounts(
        [{
          "previous": true,
          "current": false,
          "programs": overridenPrograms
        }]
      )
    } else {
      setCount(false, true)
      multipleProgramCounts(
        [{
          "previous": false,
          "current": true,
          "programs": overridenPrograms
        }])
    }
  }

  const onOverrideClick = (_id: any, _mode: string) => {
    setData(onSetOverView(_id, _mode, data))
  }

  const getSyncStatus = (last_sync_date: any, sync_type = '') => last_sync_date
    ? (`Sync ${sync_type} on ${moment.unix(last_sync_date).format("MMM DD, YYYY h:mm a")}`)
    : ''

  const renderPrgCheksList = () => {
    let prgCheckData = input?.isActionItems === true
      ? data?.filter((i: { needs_update: any, is_overridden: any, programs: any, overridden_programs: any }) => (
        i.needs_update === true &&
        i.programs?.some((s: string) => s === input?.actionProgram) &&
        !i.overridden_programs?.includes(input?.actionProgram)
      ))
      : data

    return <>
      {[...prgCheckData]?.sort((a: any, b: any) => (a?.display_name).toLowerCase() > (b?.display_name).toLowerCase() ? 1 : -1)
        ?.map((d: any, key: any) => (
          <React.Fragment key={`data-intake-${key}`}>
            {(d.mode === "view" && d.programs.filter((f: string) => selectedProgram.includes(f)).length > 0) && (
              <>
                <CardLayoutComponent
                  id={d.id}
                  name={d?.name}
                  leftHeader={(d?.display_name || d?.name)}
                  rightHeader={rightHeaderCal(d)}
                  subHeader={subHeaderCal(d)}
                  bottomText={bottomTextCal(d)}
                  needs_confirmation={d.needs_confirmation || undefined}
                  needs_update={d.needs_update || undefined}
                  icons={setIcons()}
                  programs={d.programs}
                  overridenPrograms={d.overridden_programs}
                  onRefClick={() => onRefClick(d.id, 'ref')}
                  onOverrideClick={() => onOverrideClick(d.id, 'override')}
                  is_referred={d.is_referred}
                  is_overridden={d.is_overridden || undefined}
                  last_sync_date={getSyncStatus(d?.last_sync_date, d?.sync_type || '')}
                  entityRef='measurement'
                  patientId={patientDetail.id}
                  category={'measurements'}
                />
                {selectedNotes.includes(d.id) && (
                  <NotesComponent
                    entityId={d.id}
                    entityRef={"measurement"}
                    cancelBtn={true}
                    onCancel={onNotesClick}
                  >
                    Notes
                  </NotesComponent>
                )}
              </>
            )}
            {d.mode === "history" && (
              <Grid container direction="column">
                <Box className={classes.fullWidth}>
                  <HistoryPDIComponent
                    input={{ ...d, ref: 'measurements' }}
                    name={(d?.display_name || d?.name)}
                    outer={outer}
                    patientId={patientDetail.id}
                  />
                </Box>
              </Grid>
            )}
            {d.mode === "delete" &&
              <DeleteCardComponent
                id={-1}
                header={d?.historyData?.date_recorded
                  ? `Date: ${moment.unix(d?.historyData.date_recorded).format("MMM DD, YYYY h:mm a")}, ${d?.display_name || d?.historyData?.name}: ${d?.historyData.value} ${d?.historyData?.unit}`
                  : ''
                }
                closeDelete={() => outer(d?.id, 'view_delete_history')}
                outerDelete={() => confirmDelete(d?.id, d?.historyData?.rec_id)}
              />
            }
            {['edit', 'edit_history'].includes(d.mode) &&
              <Box pt={1} pb={1} className={classes.fullWidth}>
                <MeasurementCardComponent
                  input={d.mode === 'edit_history'
                    ? data.find((el: any) => (el.id === d.id))?.historyData || {}
                    : data.find((el: any) => (el.id === d.id))
                  }
                  mode={d.mode}
                  outer={outer}
                />
              </Box>
            }
            {d.mode === 'ref' && (
              <ReferralComponent
                entityRef='measurement'
                input={d}
                patientId={patientDetail.id}
                leftHeader={(d?.display_name || d?.name)}
                rightHeader={rightHeaderCal(d)}
                subHeader={subHeaderCal(d)}
                programs={d.programs}
                closeRef={() => onRefClick(d.id, 'view')}
                onRefSubmit={onRefSubmit}
              />
            )}
            {d.mode === 'override' && (
              <ManualOverrideComponent
                entityRef='measurement'
                input={d}
                leftHeader={(d?.display_name || d?.name)}
                rightHeader={rightHeaderCal(d)}
                subHeader={subHeaderCal(d)}
                programs={d.programs}
                closeOverride={() => onOverrideClick(d.id, 'view')}
                onOverrideSubmit={onOverrideSubmit}
              />
            )}
          </React.Fragment>
        ))}
      {prgCheckData?.length < 1 && input?.isActionItems !== true && <EmptyCardComponent />}
    </>
  }

  return (
    <Grid container direction="row" ref={intakeRef}>
      {renderPrgCheksList()}
    </Grid>
  );
};

export default memo(withStyles(stylesheet)(ProgramDataIntakeComponent))