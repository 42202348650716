import { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  getPatientInfo,
  updatePatientDetail,
} from '../redux/patient-detail-slice'
import { setProgramChecksStore } from '../redux/program-checks-slice'
import { setOtherMeasurementsStore } from '../redux/other-measurement-slice'
import { setProgramCondition } from '../redux/program-condition-slice'
import { getUpdatedStage } from '../redux/stage-change-slice'
import { RootState, useAppThunkDispatch } from '../redux/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { setSelectedProgram } from '../redux/common-data-slice'
import { setMultiProgramConfirm } from '../redux/multi-program-confirmation-slice'
import { toast } from 'react-toastify'
import Helper from './helper'
import { setProgramCalculatorsStore } from '../redux/program-calculator-slice'
import { useUpdateDataAction } from './update-details-hook'

export const useDispatchAPIs = (patientId: string) => {
  const dispatch = useAppThunkDispatch()
  const _multiProgramConfirm = useSelector(
    (state: RootState) => state.multiProgramConfirm
  )
  const patientDetails = useSelector(
    (state: RootState) => state.patientDetail.patientDetail
  )

  const [value] = useState(_multiProgramConfirm)
  const { updatePatientData } = useUpdateDataAction(patientDetails.id)

  const getMultiProgramCounts = async (data: any) => {
    const temp = data?.patient?.program_enrollments?.map(
      (e: { program: any }) => e.program
    )
    const allArray = [
      ...Object.values(data.patient.conditions),
      ...Object.keys(data.patient.medications)
        .filter(
          (f) =>
            ![
              'care_plans',
              'non_synced_medications',
              'last_sync_timestamp',
              'is_med_reconciled',
              'lifestyles',
            ].includes(f)
        )
        .map((g) => data.patient.medications[g]),
      ...data.patient.medications.care_plans.map(
        (e: { medications: any }) => e.medications
      ),
      ...Object.keys(data.patient.measurements)
        .filter((f) => f !== 'program_targets')
        .map((g) => data.patient.measurements[g]),
    ]
    let pCounts = temp.map((r: string) => {
      return {
        program: r,
        enrollment_id: data?.patient?.program_enrollments?.find(
          (f: { program: string }) => f.program === r
        )?.enrollment_id,
        pending: allArray.reduce(
          (a, b) =>
            b?.reduce(
              (
                t: number,
                u: {
                  needs_confirmation: any
                  is_overridden: any
                  overridden_programs: any[]
                  needs_update: any
                  programs: string | string[]
                }
              ) =>
                (u.needs_confirmation || u.needs_update) &&
                  u.programs?.includes(r) &&
                  !u.overridden_programs?.includes(r)
                  ? t + 1
                  : t,
              +a
            ),
          0
        ),
        overridenCount: allArray.reduce(
          (a, b) =>
            b?.reduce(
              (
                t: number,
                u: {
                  is_overridden: any
                  overridden_programs: string | string[]
                }
              ) =>
                u.is_overridden && u.overridden_programs?.includes(r)
                  ? t + 1
                  : t,
              +a
            ),
          0
        ),
      }
    })

    const ptArr = Array.from(
      new Set(
        data.patient.measurements?.program_targets
          ?.filter((t: any) => t.needs_update && !t.is_overridden)
          .map((m: { program: any }) => m)
      )
    )

    if (ptArr.length > 0) {
      pCounts = pCounts.map((e: { pending: number; program: any }) => {
        return {
          ...e,
          pending:
            e.pending +
            data.patient.measurements?.program_targets?.filter(
              (f: any) =>
                f.program === e.program &&
                (f.needs_confirmation || (f.needs_update && !f.is_overridden))
            ).length || 0,
        }
      })
    }
    return pCounts
  }

  const dispatchRequiredStates = (res: any) => {
    dispatch(
      setSelectedProgram(
        res.payload.data?.patient?.program_enrollments
          ?.filter((f: { selected: boolean }) => f.selected === true)
          .map((e: { program: any }) => e.program)
      )
    )

    getMultiProgramCounts(res.payload.data).then((response) => {
      const pCounts = res.payload.data.patient.pending_action_counts || {}
      const currPendingList = response.map((i: any) => {
        let currP =
          Object.keys(pCounts)?.map((p: any) =>
            i.program === p ? pCounts[p] : ''
          ) || []
        currP = currP?.filter((i: any) => i !== '')
        return currP && currP.length > 0 ? { ...i, pending: currP[0] } : i
      })
      dispatch(setMultiProgramConfirm(currPendingList))
    })
  }

  const checkForStageChange = (tempMultiProgramConfirm: any) => {
    return _multiProgramConfirm.filter(
      (f) =>
        f.pending > 0 &&
        tempMultiProgramConfirm.find(
          (m: { program: string }) => m.program === f.program
        ).pending <= 0
    )
  }

  const setProgramConfirmCount = (
    prevValue: boolean,
    currentValue: boolean,
    input: string[],
    forceStageUpdate: boolean = false,
    overrideData: any[] = []
  ) => {
    if (forceStageUpdate === true) {
      // dispachAPIs()
    } else {
      if (prevValue === true && currentValue === false) {
        const temp: any = _multiProgramConfirm.map((e) => {
          return {
            ...e,
            pending: e.pending - input.filter((f) => f === e.program).length,
          }
        })

        dispatch(setMultiProgramConfirm(temp))
        if (
          temp.some((s: { pending: number }) => s.pending <= 0) ||
          temp.some((s: { overridenCount: number }) => s.overridenCount > 0)
        ) {
          const checks = checkForStageChange(temp)

          if (checks.length > 0) {
            if (patientDetails.needs_update === false) {
              updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true, })
            } else {
              toast.error(
                'Patient profile needs to update',
                Helper.bottom_center()
              )
            }
          }
        }
      } else if (prevValue === false && currentValue === true) {
        dispatch(
          setMultiProgramConfirm(
            _multiProgramConfirm.map((e) => {
              return {
                ...e,
                pending:
                  e.pending + input.filter((f) => f === e.program).length,
              }
            })
          )
        )
      }
    }
  }

  const multipleProgramCounts = (data: any) => {
    const newSetData = _multiProgramConfirm
      .map((e) => {
        return {
          program: e.program,
          enrollment_id: e.enrollment_id,
          pending:
            e.pending -
            data.filter(
              (f: {
                programs: string | string[]
                current: any
                previous: any
              }) => f.programs.includes(e.program) && !f.current && f.previous
            ).length,
        }
      })
      .map((e) => {
        return {
          program: e.program,
          enrollment_id: e.enrollment_id,
          pending:
            e.pending +
            data.filter(
              (f: {
                programs: string | string[]
                current: any
                previous: any
              }) => f.programs.includes(e.program) && f.current && !f.previous
            ).length,
        }
      })
    dispatch(setMultiProgramConfirm(newSetData))

    if (
      newSetData.some((s: { pending: number }) => s.pending <= 0)
    ) {
      // two checks - pending === 0 or overidecount !== 0
      const checks = checkForStageChange(newSetData)
      if (checks.length > 0) {
        if (patientDetails.needs_update === false) {
          updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
        } else {
          toast.error('Patient profile needs to update', Helper.bottom_center())
        }
      }
    }
  }

  return {
    value,
    //  dispachUpdateStageAPIsByHook,
    // dispatchPatientInfoByHook,
    dispatchRequiredStates,
    setProgramConfirmCount,
    multipleProgramCounts,
  }
}
