import React, { useEffect, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { RouteComponentProps } from 'react-router-dom';
import OktaSignIn from '@okta/okta-signin-widget'
import { setAccessToken } from '../../redux/auth-slice';
import { useSelector } from 'react-redux';
import { RootState, useAppThunkDispatch } from '../../redux/store';
//import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import '../../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { setLoggedInUser } from '../../redux/common-data-slice';
import { Box } from '@mui/material';

interface IProps extends RouteComponentProps {
  history: any;
  location: any;
  match: any;
  config: any;
}

const LoginComponent: React.FC<IProps> = ({
  history,
  location,
  match,
  config
}) => {
  const { oktaAuth } = useOktaAuth();
  const dispatch = useAppThunkDispatch();
  const loggedInUser = useSelector(
    (state: RootState) => state.commonData.loggedInUser
  )
  const widgetRef = useRef();
  useEffect(() => {
    if (!widgetRef.current) {
      return;
    }
    const widget = new OktaSignIn(config);

    // widget.on('afterError', (_context: any, error: any) => {
    //   // if (isCorsError(error)) {
    //   //   setCorsErrorModalOpen(true);
    //   // }
    // });

    // additional step to handle the external IDP login session
    widget.authClient.session.exists().then(function (sessionExists: any) {
      if (sessionExists) {
        widget.authClient.token.getWithoutPrompt().then(function (response: any) {
          widget.authClient.tokenManager.setTokens(response.tokens);
          const TARGET_EPIC_CALLBACK = localStorage.getItem('TARGET_EPIC_CALLBACK')
          localStorage.removeItem('TARGET_EPIC_CALLBACK')
          if (TARGET_EPIC_CALLBACK) {
            window.location.href = TARGET_EPIC_CALLBACK
          }
          else {
            window.location.href = '/'
          }
        });
      }
      else {
        widget.renderEl(
          { el: widgetRef.current },
          (res: any) => {
            dispatch(setAccessToken(res.tokens));
            dispatch(setLoggedInUser({ ...loggedInUser, roles: res.tokens.accessToken.claims['Groups'] }))
            oktaAuth.handleLoginRedirect(res.tokens);
          },
          (err: any) => {
            throw err;
          }
        );
      }
    });

    return () => widget.remove();
  }, [oktaAuth]);

  return (
    <Box
      sx={{ padding: '3rem' }}
      id="okta-login-container"
      ref={widgetRef}
    />
  );
};

export default LoginComponent;
