import { TextField } from '@mui/material'
import { withStyles } from '@mui/styles'
import stylesheet from '../components/patients/detail/detail-container.stylesheet'
import moment from 'moment'

interface IProps {
  label?: string
  disabled?: any
  name?: string
  type: string
  cssClass: any
  inputPropCss?: any
  variant?: any
  value?: any
  defaultValue?: any
  onCalendarChange: any
}

const DatePickerComponent: React.FC<IProps> = ({
  label,
  name,
  disabled,
  type,
  cssClass,
  inputPropCss,
  variant,
  value,
  defaultValue,
  onCalendarChange,
}) => {

  return (
    <TextField
      label={label}
      name={name}
      disabled={disabled || false}
      type={type}
      className={cssClass}
      InputProps={{
        className: inputPropCss,
      }}
      InputLabelProps={{ shrink: true }}
      variant={variant}
      value={value}
      defaultValue={defaultValue}
      onChange={onCalendarChange}
      inputProps={{
        max: moment(new Date()).format('YYYY-MM-DD'),
      }}
    />
  )
}

export default withStyles(stylesheet)(DatePickerComponent)
