import React, { useState } from 'react';
import { Box, Button, FormControl, Grid, MenuItem, Select, Typography, InputLabel } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import RejectRestrictDecisionComponent from './reject-restrict-decision.component';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
interface IProps {
	item: any
	stageActions: any
	outerSubmit: any
	outer: any
	compKey: string
	classes: ClassNameMap
}

const NextStageComponent: React.FC<IProps> = ({
	item,
	stageActions,
	outerSubmit,
	outer,
	compKey,
	classes,
}) => {
	const [status, setStatus] = useState(item.stage_approval_status);
	const [actionList, setActionList] = useState(stageActions || []);

	const multiProgramConfirm = useSelector(
		(state: RootState) => state.multiProgramConfirm
	)

	const onBtnClick = (_param: string) => {
		setStatus(_param)
		outerSubmit('stage_approval_status', _param)
		if (_param === 'Approved') {
			outer('med_restriction')
			outer('med_action')
		}
	}

	const handleActionChange = (e: any) => {
		if (e?.target?.value) {
			outerSubmit('med_action', e.target.value)
		}
	}

	const onBackClick = () => {
		setStatus(undefined)
		outer('med_restriction')
		outer('approval_status')
		outer(compKey)
	}

	const checkStageCount = (multiProgramConfirm: any) => ['med_evaluation', 'early_tox_check'].includes(item.stage)
	//&& (multiProgramConfirm.find((m: any) => m.program === item.program)?.pending <= 1)


	return (
		<>
			{status === 'Restrict' &&
				<Box pl={2}>
					<RejectRestrictDecisionComponent
						item={item}
						outerSubmit={outerSubmit}
						outer={onBackClick}
						handleClose={{}}
						handleSubmit={{}}
						compKey={compKey}
						parentComp=''
					/>
				</Box>
			}
			<Box p={2} className={status === 'Restrict' ? classes.displayNone : classes.subModalBackground}  >
				<Grid container spacing={1}>
					<Grid item >
						<Typography variant="h6" color="textSecondary">
							<Button variant="outlined"
								className={status === 'Approved' ? classes.dialogBtnActionSelected : classes.dialogBtnAction}
								size="small" onClick={() => onBtnClick('Approved')}>
								Approve
							</Button>
						</Typography>
					</Grid>
					<Grid item>
						<Button
							variant="outlined"
							size="small"
							disabled={!checkStageCount(multiProgramConfirm)}
							onClick={() => onBtnClick('Rejected')}
							className={status === 'Rejected' ? classes.dialogBtnActionSelected : classes.dialogBtnAction}
						>
							Reject
						</Button>
					</Grid>
					<Grid item>
						<FormControl className={classes.formControlStage}>
							<InputLabel id="med-reject-label" className={classes.stageLabel}
								//	style={{ color: checkStageCount(multiProgramConfirm) ? '#D87C4E' : 'gray' }}
								sx={{ color: status !== 'Rejected' ? 'gray' : '#D87C4E' }}
							>
								Next action
							</InputLabel>
							<Select
								id="med-reject"
								labelId="med-reject-label"
								sx={{
									width: '6.8rem',
									paddingTop: '0px',
									fontSize: '0.8rem',
									'&:before, &:after, & .MuiOutlinedInput-notchedOutline': {
										border: 'none !important',
									},
								}}
								//	disabled={!checkStageCount(multiProgramConfirm)}
								disabled={status !== 'Rejected'}
								onChange={(e) => handleActionChange(e)}
								label="Next action"
								className={classes.nextActionSelect}
								inputProps={{
									classes: {
										icon: classes.icon,
										'aria-label': 'Next Action'
									},
								}}
							>
								{Object.keys(actionList)?.sort()?.map((item: any) =>
									<MenuItem key={item} value={item} className={classes.actionMenu}>
										{actionList[item]}
									</MenuItem>
								)}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</Box>
		</>)
}

export default withStyles(stylesheet)(NextStageComponent)
