import { Card, CardContent, Grid, } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import React, { useEffect, useState } from "react";
import stylesheet from "../detail-container.stylesheet";
import { PatientService } from "../../../../services/patient.service";
import CardLayoutComponent from "../common/card-layout.component";
import moment from "moment";
import NotesComponent from "../common/notes.component";
import DeleteCard from "../common/delete-card-component";
import { useParams } from "react-router-dom";
import { RootState, useAppThunkDispatch } from "../../../../redux/store";
import { setAddValue } from "../../../../redux/add-control-slice";
import { useSelector } from "react-redux";
import ReferralComponent from '../common/referral-card.component'
import { useReferralAction } from '../../../../utils/referral-hook'
import { toast } from 'react-toastify'
import Helper from '../../../../utils/helper'
import EmptyCardComponent from "../common/empty-card.component"
import RejectRestrictComponent from '../care-plan/reject-restrict-decision.component'
import { checkCondition } from '../common/condition-check';
import RestrictedMedDialogComponent from '../common/restricted-med-dialog.component';

interface IProps {
  patientInfo: any;
  classes: ClassNameMap;
}

const MedIntolerance: React.FC<IProps> = ({ patientInfo, classes }) => {
  const [selectedId, setSelectedId] = useState(0);
  const [data, setData] = useState([] as any);
  const patientService = new PatientService();
  const [isRestrictedMed, setIsRestrictedMed] = useState(false)
  const [currMed, setCurrMed] = useState({} as any)
  const urlParams: any = useParams();
  const patientId: string = urlParams.patientId;
  const [selectedNotes, setSelectedNotes] = useState([] as number[]);
  const addButtons = useSelector((state: RootState) => state.addButtons);
  const dispatch = useAppThunkDispatch();
  const { onSetView, onDataSubmit } = useReferralAction()

  useEffect(() => {
    const intolerances = patientInfo?.medications?.med_intolerances?.map(
      (e: any) => {
        return { ...e, mode: "view" };
      }
    );
    setData(intolerances);
  }, [patientInfo]);

  const handleClose = (id: any) => {
    if (id) {
      outer(id, 'view')
    } else {
      handleAddHideShow();
      outer(selectedId, 'view')
    }
  };
  const handleEdit = (_id: string) => {
    setData(
      data.map((el: any) => (el.id === _id ? { ...el, mode: "view" } : el))
    );
  };
  const outer = (_id: any, _mode: string) => {
    setData(
      data.map((el: any) => (el.id === _id ? { ...el, mode: _mode } : el))
    );
  };

  const formatSubHeader = (d: any) => {
    const minPropagation = checkCondition(d.min_unacceptable_daily_dose, `, Min unacceptable:${d.min_unacceptable_daily_dose} ${d.min_unacceptable_unit}`, '')
    const maxPropagation = checkCondition(d.max_acceptable_daily_dose, `, Max acceptable:${d.max_acceptable_daily_dose} ${d.max_acceptable_unit}`, '')
    return `Propagation: ${d.propagation_status}${maxPropagation}${minPropagation}`
  }

  const setIconsWithDelete = () => {
    return [
      { iconType: "edit", callback: onSetDetail },
      { iconType: "description", callback: onNotesClick },
      { iconType: "delete", callback: onDeleteClick },
      { iconType: "more", callback: {} },
    ];
  };
  const onSetDetail = (_id: number) => {
    setData(
      data.map((el: any) => (el.id === _id ? { ...el, mode: "edit" } : el))
    );
    setSelectedId(_id);
  };
  const onDeleteClick = (_id: number) => {
    setData(
      data.map((el: any) => (el.id === _id ? { ...el, mode: "delete" } : el))
    );
  };
  const outerDelete = (_id: any) => {
    patientService
      .deleteMedIntolerances(patientId, _id)
      .then((_response: any) => {
        setData(data.filter((e: { id: any }) => e.id !== _id));
      })
      .catch((err: any) => {
        toast.error(Helper.getErrorText(err), Helper.bottom_center())
      });
  };
  const onNotesClick = (_id: any) => {
    if (selectedNotes.includes(+_id)) {
      setSelectedNotes([...selectedNotes.filter((f) => f !== +_id)]);
    } else {
      setSelectedNotes([...selectedNotes, +_id]);
    }
  };

  const handleSubmit = async (values: any, state: string, item: any) => {
    try {
      if (state === 'add') {
        const addRes = await patientService.postMedicationReaction(patientInfo.id, values)
        setData([...data, { ...addRes.data, mode: "view" }]);
        handleAddHideShow();
      } else {
        const editRes = await patientService.putMedicationReaction(patientInfo.id, item.id, values)
        setData([
          ...data.map((e: any) =>
            e.id === editRes?.data?.id ? { ...editRes.data, mode: "view" } : e),
        ]);
      }
    } catch (error) {
      toast.error(Helper.getErrorText(error), Helper.bottom_center())
    }
  }

  const handleAddHideShow = () => {
    const temp = [
      ...addButtons?.addControl.map((e: any) =>
        e.key === "MD-PRM" ? { ...e, value: false } : e
      ),
    ];
    dispatch(setAddValue(temp));
  };
  const formatConfirmDate = (is_referred: boolean, date_recorded: number, referred_on: number) => {
    if (date_recorded !== null) {
      return is_referred
        ? moment.unix(referred_on).format('MM/DD/YY')
        : moment.unix(date_recorded).format('MM/DD/YY')
    }
    return ''
  }

  const onRefClick = (_id: any, _mode: string) => {
    setData(onSetView(_id, _mode, data))
  }
  const onRefSubmit = (_id: any, date: number, is_referred: boolean, referral_id: any) => {
    setData(onDataSubmit(_id, date, is_referred, referral_id, data))
  }

  const getMedDetails = (d: any) => {
    return {
      med_id: d.medicine_details?.med_id,
      name: (d?.med_display_name && d?.med_display_name !== null ? d?.med_display_name : d?.medicine_details?.name),
      'med_type': d?.med_type
    }
  }

  const handleRestrictedMed = (item: any) => {
    setCurrMed({})
    setIsRestrictedMed(true)
    setCurrMed(item)
  }

  const handleRestMedClose = () => {
    setIsRestrictedMed(false)
    setCurrMed({})
  }

  return (
    <Grid container direction="row">
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item className={classes.fullWidth}>
          {addButtons?.addControl.find(
            (e: { key: string }) => e.key === "MD-PRM"
          ).value ? (
            <Card variant='outlined' className={[classes.questionCardContent, classes.medIntoleranceCard1].join(' ')}>
              <CardContent className={classes.zeroPadding}>
                <RejectRestrictComponent
                  item={{}}
                  outerSubmit={{}}
                  outer={{}}
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  compKey={'med_intolerance'}
                  parentComp="med_int"
                />
              </CardContent>
            </Card>
          ) : null}
        </Grid>
      </Grid>
      {data?.map((d: any, i: any) => (
        <React.Fragment key={`med-intol-${i}`}>
          {d.mode === "view" && (
            <>{console.log(d)}
              <CardLayoutComponent
                id={d.id}
                name={d?.name}
                leftHeader={getMedDetails(d)} //d?.medicine_details}
                rightHeader={d.reaction_details.name}
                subHeader={formatSubHeader(d)}
                bottomText={formatConfirmDate(d.is_referred, d.date_recorded, d.referred_on)}
                needs_update={d.needs_update || undefined}
                icons={setIconsWithDelete()}
                needs_confirmation={false}
                onRefClick={() => onRefClick(d.id, 'ref')}
                is_referred={d.is_referred}
                is_overridden={d.is_overridden || undefined}
                onMedDetailsClick={() => handleRestrictedMed(d)}
                entityRef='reported_med_intolerance'
                patientId={patientId}
                category={'medications'}
              />
              {selectedNotes.includes(d.id) && (
                <NotesComponent
                  entityId={d.id}
                  entityRef={"reported_med_intolerance"}
                  cancelBtn={true}
                  onCancel={onNotesClick}
                >
                  Notes
                </NotesComponent>
              )}
            </>
          )}
          {d.mode === 'ref' && (
            <ReferralComponent
              entityRef='reported_med_intolerance'
              input={d}
              patientId={patientId}
              leftHeader={d?.med_display_name || d.medicine_details.name}
              rightHeader={<strong>{d.reaction_details.name}</strong>}
              subHeader={formatSubHeader(d)}
              programs={d.programs}
              closeRef={() => onRefClick(d.id, 'view')}
              onRefSubmit={onRefSubmit}
            />
          )}
          {d.mode === "delete" && (
            <>
              <DeleteCard
                id={d.id}
                header={d?.med_display_name || d.medicine_details.name}
                closeDelete={handleEdit}
                outerDelete={outerDelete}
              />
            </>
          )}
          {d.mode === 'edit' && <>
            <Card variant='outlined' className={[classes.questionCardContent, classes.medIntoleranceCard2].join(' ')}>
              <CardContent className={classes.zeroPadding}>
                <RejectRestrictComponent
                  item={d}
                  outerSubmit={{}}
                  outer={{}}
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  compKey={'med_intolerance'}
                  parentComp="med_int">
                </RejectRestrictComponent>
              </CardContent>
            </Card>
          </>
          }
        </React.Fragment>
      ))}
      {(data?.length == 0 && !addButtons?.addControl.find(
        (e: { key: string }) => e.key === "MD-PRM"
      ).value) &&
        <EmptyCardComponent />
      }
      {isRestrictedMed && currMed?.med_id &&
        <RestrictedMedDialogComponent
          open={isRestrictedMed}
          handleClose={() => handleRestMedClose()}
          input={{
            id: currMed?.med_id,
            name: currMed?.med_display_name && currMed?.med_display_name !== null
              ? currMed?.med_display_name
              : currMed?.medicine_details?.name || currMed?.med_name,
            type: currMed?.med_type,
            propagation: currMed?.propagation_status
          }}
        />
      }
    </Grid>
  );
};
export default withStyles(stylesheet)(MedIntolerance);
