import {
  Box, Card, CardContent, Typography, Grid, TableBody, TableRow, TableCell, Table,
  TableHead, TableContainer, Paper,
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import stylesheet from '../detail-container.stylesheet'
import { PatientService } from '../../../../services/patient.service'
import Helper from '../../../../utils/helper'
import { useCurrentUser } from '../../../../utils/use-current-user'
import CardLayoutComponent from '../common/card-layout.component'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import ReactJson from 'react-json-view'
import MedicationsComponent from '../common/medications.component'
import { toast } from 'react-toastify'
import { checkCondition } from '../common/condition-check'
import IngredientTooltip from '../common/ingredient-tooltip'
interface IProps {
  key: string
  dataObj: any,
  outerSubmit: any,
  compKey: string,
  mapState: any
  classes: ClassNameMap
}

const MedReconcilateComponent: React.FC<IProps> = ({
  key,
  dataObj,
  outerSubmit,
  compKey,
  mapState,
  classes
}) => {
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const uiResource = useSelector((state: RootState) => state.metaData.metadata)
  const patientService = new PatientService()
  const [data, setData] = useState({} as any)
  const { currentRole } = useCurrentUser()

  useEffect(() => {
    patientService.getMedReconcilation(patientDetail.id, '').then((res: any) => {
      updateMedList(res?.data)
    })
  }, [])

  const updateMedList = (item: any) => {
    const stage = item?.map((e: any) => {
      return {
        ...e,
        medication: Object.keys(e?.medication)?.length > 0 ? { ...e?.medication, mode: 'view' } : e?.medication,
        non_synced_medication: Object.keys(e?.non_synced_medication)?.length > 0 ? { ...e?.non_synced_medication, mode: 'view' } : e.non_synced_medication,
      }
    })
    setData({ medList: stage, approvedMedList: [] })
  }

  const handleMedClose = (_id: any) => {
    updateMode('', 'view')
  }

  const updateMode = (_id: any, mode: any) => {
    mode = _id !== '' ? mode : 'view'
    let currItem = [] as any

    currItem = data?.medList?.map((el: any) => {
      if (Object.keys(el?.medication)?.length > 0) {
        return el?.medication?.id === _id
          ? { ...el, medication: { ...el?.medication, mode: mode } }
          : el
      } else if (Object.keys(el?.non_synced_medication)?.length > 0) {
        return el?.non_synced_medication?.id === _id
          ? { ...el, non_synced_medication: { ...el?.non_synced_medication, mode: mode } }
          : el
      }
    })
    setData({ ...data, medList: currItem })
  }

  const onSetDetail = (_id: number) => updateMode(_id, 'edit')

  const setIcons = (isExistingMed: any) => {
    if (isExistingMed === true) {
      return [
        { iconType: 'edit', callback: onSetDetail },
        { iconType: 'more', callback: {} },
      ]
    } else
      return []
  }

  const outer = (_id: string, _mode: string) => {
    if (_mode === 'view')
      updateMode(_id, 'view')
  }

  const getIntakeReg = (reg: string) => Object.keys(uiResource.INTAKE_REGULARITIES)?.find((key: any) => uiResource.INTAKE_REGULARITIES[key] === reg)

  const confirmMedicine = (
    _typeOut: any,
    medicinedata: any,
    _mode: any,
    action?: string
  ) => {
    return
  }

  const postConfirmations = (
    values: any,
    _mode: any,
    _typeOut: any,
    medicinedata: any,
    dosageInfo: any,
    intake: any
  ) => {
    return null
    try {
      return
      let payload: any
      if (intake === 'not taking') {
        payload = {
          med_id: medicinedata.med_id,
          med_type: medicinedata.med_type,
          intake_regularity: getIntakeReg(intake),
          user_role: currentRole,
          notes: values.note,
          prescription_taken: values.prescription_taken
        }
      } else {
        payload = {
          med_id: medicinedata.med_id,
          med_type: medicinedata.med_type,
          num_units: values.num_units,
          delivery_method: values.delivery_method,
          intake_quantity: values.intake_quantity,
          intake_regularity: getIntakeReg(intake),
          frequency: values.frequency,
          dosage_info: dosageInfo,
          source_type: medicinedata.source_type,
          notes: values.note,
          user_role: currentRole,
          prescription_taken: values.prescription_taken
        }
        if (medicinedata?.care_plan_id)
          payload = { ...payload, schedule_name: values?.schedule_name, }
      }

      patientService
        .putOtherMedication(patientDetail.id, medicinedata.id, payload)
        .then((response: any) => {
          // setMedList(
          //   data.map((el: any) =>
          //     el.id === medicinedata.id
          //       ? {
          //         ...response.data,
          //         mode: _mode,
          //         programs: el.programs,
          //         date_recorded: response.data.date_recorded,
          //       }
          //       : el
          //   )
          // )
          if (_typeOut === 'confirm') {
            confirmMedicine(_typeOut, response.data, _mode)
          } else if (_typeOut === 'save') {
            const tempObj = data.find((f: { id: string }) => f.id === response.data.id)
            if (tempObj?.programs?.length) {
              //setProgramConfirmCount(tempObj?.needs_confirmation, response.data.needs_confirmation, tempObj?.programs)
            }
            //toast.success(`Saved successfully.`, Helper.bottom_center())
          }
        })
        .catch((error: any) => {
          toast.error(Helper.getErrorText(error), Helper.bottom_center())
        })
    } catch (error: any) {
      console.error(error)
    }
  }

  const formatSubHeaderImportedMed = (d: any) => {
    const num_units = d.pu_order?.num_units ? d.pu_order?.num_units : ''
    const formulation = d.pu_order?.formulation_display_name ? `${d.pu_order?.formulation_display_name},` : ''
    const route = d.pu_order?.route_display_name ? `${d.pu_order?.route_display_name},` : ''
    const frequency = uiResource.MEDICATION_DOSAGE_FREQUENCY[d.pu_order?.frequency] ?
      uiResource.MEDICATION_DOSAGE_FREQUENCY[d.pu_order?.frequency] : ''
    const text = `${num_units}${formulation}${route}${frequency}` ? `${num_units} ${formulation} ${route} ${frequency}` : 'Details not available'
    return <>{text}</>
  }

  const formatMedDetails = (pu_rec: any[], dosage_info: any[]) => {
    return checkCondition(Object.keys(pu_rec).length > 0, getMedDetails(pu_rec, dosage_info), '')
  }

  const getMedDetails = (temp: any, dosage_info: any) => {
    const number = temp?.num_units || ''
    const formulation = temp?.formulation_display_name || ''
    const route = temp?.route_display_name || ''
    const frequency = uiResource.MEDICATION_DOSAGE_FREQUENCY[temp?.frequency] || ''
    // const directions = temp?.directions?.init || ''
    const dosageInfo = (dosage_info) && dosage_info?.[0]?.medicationTimings
      ? uiResource.MEDICATION_TIMINGS[dosage_info[0]['medicationTimings']]
      : ''
    const text = `${number}${formulation}${route}${frequency}` ? `${number} ${formulation} ${route} ${Helper.toLowerlCase(frequency)} ${dosageInfo}` : 'Details not available'
    return `${text}`
  }


  const formatSubHeader = (_d: any) => {
    const sub = checkCondition(_d.intake_started_on === null, 'Start', 'Taking')
    const titrate = checkCondition(_d.intake_started_on !== null && !_d.titration_status.includes('discontinue'), 'Taking', 'Stop')

    if (_d?.pu_order && _d.pu_order !== undefined && Object.keys(_d.pu_order).length > 0) {
      const initiateMed = getMedDetails(_d.pu_order, _d?.dosage_info)
      if (_d.titration_status === 'initiate_current') {
        const text = `continue ${getMedDetails(_d.pu_order, _d?.dosage_info)}`
        return <>{text}<IngredientTooltip ingredients={_d.calc_dose} /></>
      } else {
        const text = _d.titration_status === 'initiate'
          ? `${sub} ${initiateMed}`
          : `${titrate} ${getMedDetails(_d.pu_order, _d?.dosage_info)}`
        return <>{text}<IngredientTooltip ingredients={_d.calc_dose} /></>
      }
    } else {
      if (Object.keys(_d.pu_rec)?.length > 0 && _d.titration_status != 'initiate_current') {
        const puOrderMedDetails = getMedDetails(_d?.pu_order, _d?.dosage_info)
        const initiateMed = checkCondition(_d.intake_started_on === null, formatMedDetails(_d.pu_rec, _d?.dosage_info), puOrderMedDetails)
        const text = checkCondition(_d.titration_status === 'initiate', `${sub} ${initiateMed}`, `${titrate} ${puOrderMedDetails}`)
        return <>{text}<IngredientTooltip ingredients={_d.calc_dose} /></>
      } else {
        return 'Details not available'
      }
    }
  }

  const formatSubHeader1 = (d: any) => {
    if (Object.keys(d.pu_rec)?.length > 0) {
      if (d.titration_status != 'initiate_current') {
        const medDetails = checkCondition(d.intake_started_on === null && d.pu_rec !== undefined, formatMedDetails(d.pu_rec, d?.dosage_info), '')
        const text = checkCondition(d.intake_started_on === null && d.pu_rec !== undefined, `Start ${medDetails}`, '')
        return <>{text}</>
      }
    } else {
      return 'Details not available'
    }
  }

  const cardComponent = (d: any, changedColList: any, isExistingMed = false) => {
    if (d.is_recommended == true) {
      return <CardLayoutComponent
        id={d.id}
        name={d?.name}
        leftHeader={checkCondition(d.titration_status === 'initiate', Helper.getRXnorm(d.pu_med_name, d.rxnorm), Helper.getRXnorm(d.pu_order?.name, d.taking_med_rxnorm))}
        subHeader={formatSubHeader(d)}
        leftHeader1={checkCondition((d.titration_status)?.includes('discontinue') || (d.titration_status)?.includes('initiate_current'), '', checkCondition(d.intake_started_on === null && d.titration_status !== 'initiate' && d.pu_order?.name !== d.pu_med_name, `${checkCondition(d.pu_med_name !== null, Helper.getRXnorm(d.pu_med_name, d.rxnorm), Helper.getRXnorm(d.name, d.rxnorm))} `, ``))}
        subHeader1={checkCondition((d.titration_status)?.includes('discontinue') || (d.titration_status)?.includes('initiate_current'), '', checkCondition(d.titration_status !== 'initiate' && d.intake_started_on === null, formatSubHeader1(d), ''))}
        rightHeader={''}
        bottomText={''}
        source={`${d?.source_type} - ${d?.source_system ? d?.source_system : 'ATMAN'}`}
        needs_confirmation={
          d.source_type === 'imported'
            ? d.needs_confirmation || undefined
            : undefined
        }
        needs_update={d.needs_update || undefined}
        icons={[]}
        is_recommended={d.is_recommended}
        programs={d.programs}
        onRefClick={() => undefined}
        onMedDetailsClick={undefined}
        is_referred={d.is_referred}
        is_overridden={false}
        entityRef="medication"
        patientId={patientDetail.id}
        is_care_plan_non_recc={
          !(d.is_recommended || d.is_referred)
        }
        category={'medications'}
        titration_status={d.titration_status}
        recommendation_source={d?.recommendation_source}
      />
    }
    else {
      return <CardLayoutComponent
        id={d.id}
        leftHeader={Helper.getRXnorm(d.pu_med_name ? d.pu_med_name : d.name, d?.rxnorm)}
        rightHeader={d.intake_regularity?.charAt(0).toUpperCase() + d.intake_regularity?.slice(1) || ''}
        subHeader={formatSubHeaderImportedMed(d)}
        bottomText={''}
        needs_confirmation={d.needs_confirmation || undefined}
        needs_update={d.needs_update || undefined}
        icons={Object.keys(changedColList)?.length > 0 ? setIcons(isExistingMed) : []}
        programs={d.programs || undefined}
        onRefClick={() => undefined}
        is_referred={false}
        is_overridden={false}
        is_new_med={d.is_new_med || undefined}
        onOverrideClick={undefined}
        onMedDetailsClick={undefined}
        entityRef={isExistingMed
          ? 'med_reconcilation'
          : d?.med_id !== null && d?.is_quarantine == false
            ? 'med_reconcilation_mapped'
            : 'med_reconcilation_quarantine'
        }
        patientId={patientDetail.id}
        category={'medications'}
      />
    }
  }

  const renderMedCard = (d: any, changedColList: any, isExistingMed = false) => {
    return <>
      {cardComponent(d, changedColList, isExistingMed)}
      {(d.mode === 'edit') && (
        <Box mb={1} className={classes.fullWidth}>
          <Card variant='outlined'
            className={
              data.needs_confirmation
                ? [
                  classes.confirmCardBackground,
                  classes.questionCardContent,
                  classes.fullWidth
                ].join(' ')
                : classes.questionCardContent
            }
          >
            <CardContent className={classes.zeroPadding}>
              <Box p={2} pb={0}>
                <Grid item>
                  <Typography variant="subtitle2" className={classes.nameLineHeight}>
                    {d.pu_med_name ? d.pu_med_name : d.name}
                  </Typography>
                  <Typography color="textSecondary" variant="subtitle2">{formatSubHeader(d)}</Typography>
                </Grid>
              </Box>
              <MedicationsComponent
                action='edit'
                medicineInfo={{ ...d, is_recommended: patientDetail?.is_recommended || false }}
                writeMode={(d.intake_regularity === 'not taking') ? false : true}
                noDropDown="none"
                heading=""
                handleClose={handleMedClose}
                patientId={patientDetail.id}
                currentStage={''}
                differently={true}
                classes={classes}
                input={{ ...d, isMedReconcile: true }}
                outer={outer}
                outerSubmit={postConfirmations}
                uiResource={uiResource}
              />
            </CardContent>
          </Card>
        </Box>
      )}
      {changedColList?.length > 0 &&
        <Box className={classes.cardsContentPadding}>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Typography variant="body2" className={classes.subTitle}>Changes</Typography>
            </Grid>
            <Box pb={1}>
              <CardContent className={classes.confirmCardContent}>
                <Grid container spacing={1} direction='column'>
                  {changedColList?.map((currItem: any) => {
                    return <Grid item>
                      <Typography variant="h5" className={classes.desc}>{currItem}:
                        {typeof d[currItem] === 'string' ?
                          <Typography component={'span'} variant="h5" className={classes.desc}> {d[currItem]} </Typography>
                          :
                          <ReactJson
                            src={d[currItem]}
                            name={false}
                            collapsed={true}
                            displayDataTypes={false}
                            displayObjectSize={false}
                          />
                        }
                      </Typography>
                    </Grid>
                  })}
                </Grid>
              </CardContent>
            </Box>
          </Grid>
        </Box>
      }
    </>
  }

  return (<Box sx={{ paddingTop: '1rem' }}>
    <Grid container spacing={0} direction="column" className={classes.contextualHelpContainer}>
      <Grid item className={classes.fullWidth}>
        {data && Object.keys(data)?.length > 0 &&
          <TableContainer component={Paper} style={{ maxHeight: '30rem' }}>
            <Table stickyHeader size="small" aria-label="med-reconcilate-table">
              <TableHead>
                <TableRow className={classes.medHistoryTitles}>
                  <TableCell className={[classes.tableReconcileHeader, classes.reconcileHeader].join(' ')} width='48%' align='center'>Imported</TableCell>
                  <TableCell className={[classes.tableReconcileHeader, classes.reconcileHeader].join(' ')} width={'48%'} align='center'>Existing</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(data?.medList)?.map((item: any, index: any) => {
                  return <TableRow key={`imported-${index}`}>
                    <TableCell className={classes.tableReconcileHeader}>
                      {renderMedCard(item?.non_synced_medication, item?.changed_columns_list, false)}
                    </TableCell>
                    <TableCell className={classes.tableReconcileHeader}>
                      {renderMedCard(item?.medication, item?.changed_columns_list, true)}
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Grid>
    </Grid >
  </Box>
  )
}

export default withStyles(stylesheet)(MedReconcilateComponent)


