import { useEffect, useRef, useState } from 'react'
import {
  Box, Grid, Typography, Button, MenuItem, FormControl, Select, Card,
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, debounce, CircularProgress, Stack,
} from '@mui/material'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { withStyles, ClassNameMap } from '@mui/styles'
import Autocomplete from '@mui/material/Autocomplete'
import Draggable from 'react-draggable'
import { toast } from 'react-toastify'
import stylesheet from '../detail-container.stylesheet'
import { PatientService } from '../../../../services/patient.service'
import Helper from '../../../../utils/helper'
import { RootState } from '../../../../redux/store'
import { useSelector } from 'react-redux'
import ProviderConfirmationDialogComponent from '../common/provider-confirmation-dialog.component';
import RationaleCarePlanComponent from '../medications/rationale-care-plan.component';
import DialogTitleHeaderComponent from '../common/dialog-title-header.component';
import { useUpdateDataAction } from '../../../../utils/update-details-hook'
interface IProps {
  open: boolean
  careplanId: any
  currPrg: any
  patientId: any
  onProvierSubmit: any
  handleClose: any
  classes: ClassNameMap
}

const ProviderRecommendationComponent: React.FC<IProps> = ({
  open,
  careplanId,
  currPrg,
  patientId,
  onProvierSubmit,
  handleClose,
  classes,
}) => {
  const patientService = new PatientService()
  const scheduleObj = { c_rationale: {}, meets_exclusion: false, meets_inclusion: false, schedules: [] }
  const [scheduleData, setScheduleData] = useState(scheduleObj as any)
  const [medOpen, setMedOpen] = useState(false)
  const [medicine, setMedicine] = useState([] as any)
  const [loading, setLoading] = useState(false)
  const [loadingSch, setLoadingSch] = useState(false)
  const [query, setQuery] = useState('')
  const [confirmSchedule, setConfirmSchedule] = useState(false)
  const [values, setValues] = useState({ care_plan_id: careplanId || '' } as any)
  const uiResource = useSelector((state: RootState) => state.metaData.metadata)
  const [frequency, setFrequency] = useState({} as any)
  const providerRecomRef = useRef(null) as any
  const handleScheduleConfirmClose = () => setConfirmSchedule(false)
  const confirmScheduleStatus = (isSubmit: boolean, notes = '') => handleSubmit(true, notes)
  const { updatePatientData } = useUpdateDataAction(patientId)

  const handleSubmit = (is_meets_exclusion = false, notes = '') => {
    if (scheduleData?.meets_exclusion && !confirmSchedule) {
      setConfirmSchedule(true)
      return
    }

    if (is_meets_exclusion) setConfirmSchedule(false)

    let payload = {
      ...values,
      c_rec_schedules: scheduleData?.schedules,
      c_rationale: scheduleData?.c_rationale
    }
    if (notes) payload = { ...payload, notes: notes }

    patientService
      .postProviderMedications(patientId, payload)
      .then((response: any) => {
        handleClose()
        updatePatientData({ isMeasurement: true, isTreatments: true })
      }).catch((error) => toast.error(Helper.getErrorText(error), Helper.bottom_center()))
  }

  const getMedicationSchedule = () => {
    setLoadingSch(true)
    values.med_id !== undefined && values.num_units !== '' && values.frequency &&
      patientService
        .getMedicationSchedule(patientId, `${values.med_id}?program_name=${currPrg}&num_units=${values.num_units}&frequency=${values.frequency}`)
        .then((response: any) => {
          setLoadingSch(false)
          setScheduleData(response?.data)
        })
        .catch(() => {
          setLoadingSch(false)
          setScheduleData(scheduleObj)
        })
  }

  useEffect(() => {
    let active = true;
    if (active && query.length >= 2) {
      try {
        setLoading(true)
        patientService.getPrescriptionUnit(query).then((response: any) => {
          setMedicine(response.data)
          setLoading(false)
        })
      } catch (error) {
        setLoading(false)
      }
    }
    return () => {
      active = false;
    };
  }, [query]);

  useEffect(() => {
    if (values?.name && values?.num_units && values?.frequency && Object.keys(frequency)?.length > 0) {
      getMedicationSchedule()
    } else resetFrequency()
  }, [values])

  const getFrequencyList = (unit = '') => {
    setScheduleData(scheduleObj)
    setFrequency({})

    values.med_id && unit !== '' && patientService
      .getMedFrequencyByUnit(`${values.med_id}?program_name=${currPrg}&num_units=${unit}`)
      .then((response: any) => {
        setFrequency((response?.data && Object.keys(response?.data)?.length >= 1)
          ? response?.data
          : {})
      }).catch(() => resetFrequency())
  }

  const resetFrequency = () => {
    setScheduleData(scheduleObj)
    setFrequency({})
  }

  const onCardSelection = (e: number) => {
    const chosenSch: any = scheduleData?.schedules.map((m: any, index: number) => index === e ? { ...m, chosen: true } : { ...m, chosen: false })
    setScheduleData({ ...scheduleData, schedules: chosenSch })
  }

  const getConditionsList = (val: any) => {
    let isCriteria = false
    const checkCriteria = (flag: boolean) => {
      if (flag) isCriteria = true
    }

    const conditions = Object.keys(val)?.map((i: any, index: any) => {
      if (typeof (val[i]) === 'object') {
        const currItem = val[i]
        return Object.keys(currItem)?.map((item: any, indx: any) =>
          <Typography component="span" className={classes.scheduleDoseInfo}>
            {!isCriteria && checkCriteria(currItem[item])}
            <Typography component="span" className={classes.scheduleDoseInfo} color={!currItem[item] ? 'error' : 'green'}>
              <small>{item}</small>
            </Typography>
            {(Object.keys(currItem)?.length > (indx + 1) || Object.keys(val)?.length > (index + 1)) && ', '}
          </Typography>
        )
      }
      else {
        return <Typography component="span" className={classes.scheduleDoseInfo}>
          {!isCriteria && checkCriteria(val[i])}
          <Typography component="span" className={classes.scheduleDoseInfo} color={!val[i] ? 'error' : 'green'}>
            <small>{i}</small>
          </Typography>
          {Object.keys(val)?.length > (index + 1) && ', '}
        </Typography>
      }
    })

    return <>
      <Typography className={classes.scheduleDoseInfo}>
        <small>{'Condition criteria met?'} </small>
        <Typography variant="caption" className={classes.scheduleDoseInfo} color={isCriteria ? 'green' : 'red'}>
          <small>{isCriteria ? 'YES' : 'NO'}</small>
        </Typography>
      </Typography>
      <Typography className={classes.scheduleDoseInfo}>{conditions}</Typography>
    </>
  }

  const getDoseFreq = (dose: any, freq: any) => {
    return typeof (dose) === 'object' ? `[${dose?.join(', ')}]${freq}` : `${dose}${freq}`
  }

  const tableFormation = (e: any) => {
    return <Box sx={{ padding: '0.3rem 0.5rem' }}>
      <Box>
        <Typography className={classes.scheduleTitle} variant="subtitle2">
          {e.schedule_display_name || e.name} {e.recommended &&
            <CheckCircleOutlineOutlinedIcon className={classes.recommendedCheckCircle} titleAccess='Recommended' />
          }
        </Typography>
        <Typography className={classes.scheduleDoseInfo} color="GrayText">
          <small>take {e.route === 'oral' ? 'orally' : e.route}</small>
        </Typography>
      </Box>
      <Box pt={0.7} pb={1}>
        {Object.keys(e?.steps)?.map((i: any) => (
          <Stack direction={'row'}>
            {(i === getDoseFreq(e.starting_dose, e.starting_frequency) && i === getDoseFreq(e.ending_dose, e.ending_frequency))
              ? <Typography className={classes.scheduleIcon}>&#8596;</Typography>
              : i === getDoseFreq(e.starting_dose, e.starting_frequency)
                ? <Typography className={classes.scheduleIcon}>&#x2192;</Typography>
                : i === getDoseFreq(e.ending_dose, e.ending_frequency)
                  ? <Typography className={classes.scheduleIcon}>&#x2190;</Typography>
                  : ''
            }
            <Typography className={classes.scheduleDoseInfo}>
              <small>
                {e?.steps[i]}
                {(i === getDoseFreq(e.dose, e.frequency)) && ' - '}
              </small>
            </Typography>
            <Typography className={classes.scheduleDoseInfo}
              sx={{
                paddingLeft: '0.2rem',
                color: i === getDoseFreq(e.dose, e.frequency) ? 'green' : 'inherit'
              }}>
              <small>{i === getDoseFreq(e.dose, e.frequency) ? 'start' : ''}</small>
            </Typography>
          </Stack>
        ))}
      </Box>
      {getConditionsList(e?.condition || [])}
    </Box >
  }

  const handleMedDropdown = (flag: boolean) => {
    setMedOpen(flag)
    setMedicine([])
    setQuery('')
  }

  return (<>
    <Draggable ref={providerRecomRef} handle='.provider-rec-med-dialog'>
      <Dialog
        id={'provider-recom-id'}
        fullWidth={true}
        maxWidth='md'
        open={open}
        onClose={handleClose}
        aria-labelledby="Provider Recommendation"
        hideBackdrop
        disableScrollLock
        disableEscapeKeyDown
        onKeyUp={(e) => {
          if (e.key === 'Enter' && !(
            !(values?.name && values?.num_units && values?.frequency &&
              scheduleData?.schedules?.filter((m: any) => m.chosen === true)?.length > 0)
            || confirmSchedule
          ))
            handleSubmit(false, '')
        }}
        sx={{
          position: "absolute",
          left: '14rem',
          top: '8rem'
        }}
      >
        <DialogTitle className={classes.addPatientQuestionHeaderSection} classes={{ root: 'provider-rec-med-dialog' }}>
          <DialogTitleHeaderComponent
            title='Add medication recommendation'
            handleDialogClose={handleClose}
          />
        </DialogTitle>
        <DialogContent sx={{ padding: '20px 0px 0px 20px !important' }}>
          <Box className={classes.dialogHeaderBox}>
            <Grid spacing={1} container className={classes.containerPadding}>
              <Grid item sm={12} className={classes.medBox}>
                <Autocomplete
                  id="prov-recom-med"
                  open={medOpen}
                  onOpen={() => handleMedDropdown(true)}
                  onClose={() => handleMedDropdown(false)}
                  options={medicine as any}
                  getOptionLabel={(option: any) => Helper.getRXnorm(option?.name, option?.rxcui)}
                  sx={{
                    display: "noDropDown ",
                    width: "365px !important",
                  }}
                  className={classes.autoCompleteDropDowns}
                  loading={loading}
                  value={values?.name}
                  onChange={(_event, newValue) => {
                    resetFrequency()
                    setValues({
                      ...values,
                      name: newValue?.name,
                      num_units: '',
                      unit: newValue?.unit,
                      frequency: '',
                      route: newValue?.route,
                      med_id: newValue?.id,
                      med_type: newValue?.med_type
                    })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant={"standard"}
                      onChange={debounce((e: any) => {
                        setQuery(e.target.value);
                        if (e.target.value === "") setMedicine([]);
                      }, 1000)}
                      label="Medicine Selection"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12} className={classes.medBox}>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={2}>
                    <Typography color="textSecondary" variant="subtitle2" className={classes.tooltip1}>
                      No. Taken
                    </Typography>
                    <TextField
                      id="no-taken"
                      variant="standard"
                      size="small"
                      value={values.num_units}
                      onChange={(e) => {
                        getFrequencyList(e.target.value)
                        setValues({ ...values, 'num_units': e.target.value, 'frequency': '' })
                      }}
                      name="num_units"
                      className={classes.medicationTextField}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography color="textSecondary" variant="subtitle2" className={classes.tooltip1}>
                      Unit
                    </Typography>
                    <Typography className={`${classes.tooltip1} ${classes.desc}`} variant="body2">
                      {values.unit || '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      color="textSecondary"
                      className={classes.tooltip1}
                      variant="subtitle2"
                    >
                      Route
                    </Typography>
                    <Typography className={`${classes.tooltip1} ${classes.desc}`} variant="body2">
                      {values?.route || '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography color="textSecondary" className={classes.tooltip1} variant="subtitle2">
                      Frequency
                    </Typography>
                    <FormControl className={classes.fullWidth}>
                      <Select
                        displayEmpty
                        variant="standard"
                        disabled={Object.keys(frequency)?.length <= 0}
                        className={classes.selectEmpty}
                        inputProps={{ "aria-label": "Without label" }}
                        value={values.frequency || ''}
                        placeholder='frequency'
                        name="frequency"
                        MenuProps={{ autoFocus: false }}
                        onChange={(e) => setValues({ ...values, 'frequency': e.target.value })}
                      >
                        {Object.keys(frequency).map(
                          (_key, _index) => (
                            <MenuItem
                              className={classes.formControlItemsPadding}
                              value={_key}
                            >
                              {uiResource.MEDICATION_DOSAGE_FREQUENCY[_key]}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {(scheduleData?.meets_inclusion || scheduleData?.meets_exclusion || Object.keys(scheduleData?.c_rationale)?.length > 0) &&
                <Grid item sm={12} className={classes.medBox}>
                  <Box mt={1}>
                    <Typography variant="h6" color="textSecondary"><small>Rationale</small></Typography>
                    <Typography className={classes.evalBoxLabel} style={{ padding: '3px 0px' }} variant="body2" color="textSecondary">
                      {`Meets exclusion `}
                      <Typography component='span' className={classes.evalBoxVal} variant='body2'
                        color={scheduleData?.meets_exclusion ? 'red !important' : 'inherit'}
                      >
                        {JSON.stringify(scheduleData?.meets_exclusion)}
                      </Typography>
                    </Typography>
                    {Object.keys(scheduleData?.c_rationale)?.length > 0 &&
                      <RationaleCarePlanComponent
                        item={{ 'c_rationale': scheduleData?.c_rationale, isProviderRecomm: true }}
                        compKey='RationaleComponent_Key'
                      />
                    }
                  </Box>
                </Grid>
              }
              <Grid item sm={12} className={classes.medBox}>
                <Box mb={1}>
                  <Typography variant="h6" color="textSecondary"><small>Schedules</small></Typography>
                  <Typography variant="body2" color="textSecondary">
                    <small>{`Will be proposed post you select medication, no taken and frequency.`}</small>
                  </Typography>
                </Box>
                <Box className={classes.fullWidth}>
                  <Grid container spacing={1}>
                    {scheduleData?.schedules.map((e: any, index: any) =>
                      <Grid item key={`prv-rec-sch${index}`} sm={4}>
                        <Card
                          variant="outlined"
                          onClick={() => onCardSelection(index)}
                          className={e.chosen === true ? classes.selectedScheduleCardLayout : classes.grayScheduleCardLayout}
                        >
                          {tableFormation(e)}
                        </Card>
                      </Grid>
                    )}
                  </Grid>
                  {!loadingSch && scheduleData?.schedules?.length < 1 && values?.name && values?.num_units && values?.frequency &&
                    <Typography variant="body2" ><small>{`Schedule is not available.`}</small></Typography>
                  }
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions className={classes.zeroPadding}>
          <Grid container flexDirection={'column'} sx={{ paddingTop: '1rem' }}>
            <Grid item className={classes.boxBorder}>
              <Grid container spacing={2} justifyContent="flex-end" className={classes.containerPadding}>
                <Grid item spacing={1}>
                  <Button
                    variant="outlined"
                    disabled={!(values?.name && values?.num_units && values?.frequency &&
                      scheduleData?.schedules?.filter((m: any) => m.chosen === true)?.length > 0)
                      || confirmSchedule
                    }
                    size="small"
                    onClick={() => handleSubmit(false, '')}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item spacing={1}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Draggable>
    {confirmSchedule && (
      <ProviderConfirmationDialogComponent
        open={confirmSchedule}
        content={`${values?.name} meets exclusion criteria. Are you sure you want to proceed?`}
        //   content={getConfirmMsg()}
        handleConfirmClose={handleScheduleConfirmClose}
        confirmStatus={confirmScheduleStatus}
      />
    )}
  </>
  )
}

export default withStyles(stylesheet)(ProviderRecommendationComponent)
