import React, { memo, useEffect, useState, useRef } from 'react'
import {
  Card, Box, Typography, CardContent, Grid, Accordion, AccordionSummary, AccordionDetails
} from '@mui/material'
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useSelector } from 'react-redux'
import stylesheet from '../../patients/detail/detail-container.stylesheet'
import { RootState, useAppThunkDispatch } from '../../../redux/store'
import FilterProgramChunkComponent from './filter-program-chunk.component'
import {
  setPatientsTable, resetPatientsTable, resetSelectedStage, setProgramFilter
} from '../../../redux/common-data-slice'
import { PatientService } from '../../../services/patient.service'
import { toast } from 'react-toastify'
import Helper from '../../../utils/helper'
import FilterChunkComponent from './filter-chunk.component'

interface IProps {
  filterList: any,
  // classes: ClassNameMap
}

const FilterComponent: React.FC<IProps> = ({ filterList }) => {
  const patientsTable = useSelector((state: RootState) => state.commonData.patientsTable)
  const patientService = new PatientService()
  const classes = makeStyles(stylesheet)();
  const [filterCounts, setFilterCounts] = useState({} as any)
  const [openAccordion, setOpenAccordion] = useState(['cardiology', 'endocrinology', 'pulmonology'] as any)
  const [selectedPrg, setSelectedPrg] = useState([] as any)
  const dispatch = useAppThunkDispatch()
  const inputEl = useRef(null) as any

  useEffect(() => {
    getFilteredCounts()
  }, [patientsTable])

  const setPrgCheckList = (data: any) => data?.map((d: any) => {
    const isCurrPrgChecked = [...selectedPrg]?.find((item: any) => item?.index === d?.index)
    return { ...d, value: d?.name, display_name: d?.display_name, checked: isCurrPrgChecked ? true : false }
  })

  const clearAllFilters = () => {
    dispatch(resetSelectedStage())
    dispatch(resetPatientsTable())
    resetPrograms()
    localStorage.setItem("atman-hospitals", '')
  }

  const resetPrograms = () => {
    setSelectedPrg([])
    setOpenAccordion(['cardiology', 'endocrinology', 'pulmonology'])
    updateFilterData(filterCounts)
  }

  const optionChanged = (name: string, display_name: any, parent: any, subParent: any, prgId: any) => {
    let currParent = filterCounts['programs']?.filter((e: any) => e.display_name === parent)[0]
    let currParentIndex = filterCounts['programs']?.findIndex((e: any) => e.display_name === parent)
    let isPrgChecked
    if (currParentIndex !== -1) {
      let updatedItems = [...currParent['items']]?.map((m: any) => {
        if (m.display_name === subParent) {
          let currSubItem = m?.items.map((i: any) => {
            if (i?.name === name) {
              isPrgChecked = (i?.checked === false || i?.checked === undefined ? true : false)
              return { ...i, checked: isPrgChecked }
            } else {
              return { ...i }
            }
          })
          return { ...m, items: currSubItem }
        }
        else
          return { ...m }
      })

      let allSelectedPrg
      let selectedPrgList = [{}]

      if (isPrgChecked === true) {
        selectedPrgList = [...selectedPrg, {
          parent: parent,
          subParent: subParent,
          program: name,
          display_name: display_name,
          index: prgId
        }]
        setSelectedPrg(selectedPrgList)

        allSelectedPrg = (selectedPrg?.length > 0)
          ? [...selectedPrg].map((p: any) => p.program).join(',') + ',' + name
          : name
      }
      else {
        selectedPrgList = [...selectedPrg]?.filter((item: any) => item?.index !== prgId)
        setSelectedPrg(selectedPrgList)
        allSelectedPrg = [...selectedPrgList].map((p: any) => p.program).join(',')
      }

      let updatedFilterCounts = { ...filterCounts }
      let updatedPrgram = [...filterCounts['programs']]
      updatedPrgram[currParentIndex] = { ...currParent, 'items': updatedItems }
      updatedFilterCounts['programs'] = updatedPrgram

      dispatch(setProgramFilter(updatedFilterCounts))
      dispatch(setPatientsTable({
        ...patientsTable,
        page: 1,
        limit: 25,
        programs: allSelectedPrg,
        selectedPrg: selectedPrgList,
      }))
    }
  }

  useEffect(() => {
    if (patientsTable?.selectedPrg)
      setSelectedPrg(patientsTable?.selectedPrg)
  }, [patientsTable])

  const updateFilterData = (currData: any) => {
    const updatedData = currData?.programs

    if (selectedPrg?.length > 0) {
      const updatedPrograms = updatedData?.map((item: any) => {
        let currParent = selectedPrg?.filter((e: any) => e.parent === item?.display_name)[0]
        let currParentIndex = selectedPrg?.findIndex((e: any) => e.parent === item?.display_name)

        if (currParent && currParentIndex !== -1) {
          let updatedItems = item['items']?.map((m: any) => {
            // check for subParent
            let currSubParent = selectedPrg?.filter((e: any) => e.parent === item?.display_name
              && e.subParent === m.display_name)

            if (currSubParent?.length > 0) {
              let currSubItem = m?.items.map((i: any) => {
                let currProgram = selectedPrg?.filter((e: any) => e.index === i?.index)[0]
                return (i?.name === currProgram?.program)
                  ? { ...i, checked: true }
                  : { ...i, checked: false }
              })
              return { ...m, items: currSubItem }
            }
            else
              return { ...m }
          })
          return { ...item, items: updatedItems }
        } else {
          return { ...item }
        }
      })
      const sortedPrg = updatedPrograms?.length > 2
        ? updatedPrograms?.sort((a: any, b: any) => a.display_name > b.display_name ? 1 : -1)
        : updatedPrograms

      let updatedFilterCounts = { ...currData }
      updatedFilterCounts['programs'] = sortedPrg
      setFilterCounts(updatedFilterCounts)
    } else
      setFilterCounts(currData)
  }

  const getFilteredCounts = () => {
    if (Object.keys(filterCounts)?.length <= 0) {
      patientService.getFilterCounts(patientsTable?.programs || '', patientsTable?.hospitals || '')
        .then((res: any) => {
          if (res?.data)
            updateFilterData(res.data)
        }).catch((error: any) => setError(error))
    } else
      updateFilterData(filterCounts)
  }

  const setError = (error: any) => {
    toast.error(Helper.getErrorText(error), {
      position: 'bottom-center',
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    })
  }

  const handleAccordion = (key: any) => {
    setOpenAccordion(
      openAccordion.indexOf(key) !== -1
        ? openAccordion.filter((item: any) => item !== key)
        : [...openAccordion, key]
    )
  }

  const setDataList = (key: string, input: any = [],) => {
    if (filterCounts && filterCounts[key] !== undefined) {
      return Object.keys(filterCounts[key])?.map((item: any) => {
        let isChecked = false
        if (key === 'hospitals') {
          const hospitalList = localStorage.getItem("atman-hospitals")
          isChecked = hospitalList?.includes(item) || false
        }
        return { name: item, value: item, count: filterCounts[key][item] || 0, checked: isChecked }
      })?.sort((a: any, b: any) => a.name > b.name ? 1 : -1)
    }
  }

  const getProgramTitleCount = (title: any, count = 0) => {
    return <Grid container direction="row" justifyContent="space-between" alignItems="baseline" style={{ flexWrap: "nowrap" }}>
      <Grid item>
        <Typography component={'span'} variant='body2'>
          {Helper.toCamelCase(title)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textSecondary"><small>{(count)}</small></Typography>
      </Grid>
    </Grid>
  }

  const getSubProgramList = (item: any, parent: any) => {
    return item !== undefined && item?.length > 0 && item?.map((d: any, index: any) => (
      <Accordion
        square
        key={`prg-${d?.display_name}-${index}`}
        expanded={openAccordion.includes(d?.display_name) ? true : false}
        className={classes.programCategory}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          ref={inputEl}
          onClick={() => handleAccordion(d?.display_name)}
          sx={{ padding: '0px !important' }}
        >
          {getProgramTitleCount(d?.display_name, d?.count)}
        </AccordionSummary>
        <AccordionDetails sx={{
          '&.MuiAccordionDetails-root.MuiAccordionDetails-root': {
            paddingRight: '2px !important'
          }
        }}>
          <Grid container className={classes.zeroTopPadding}>
            <Grid item className={classes.fullWidth}>
              <FilterProgramChunkComponent
                input={setPrgCheckList(d?.items)}
                optionChanged={(name: any, display_name: any, index: any) => optionChanged(name, display_name, parent, d?.display_name, index)}
                filterCount={d?.count}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    ))
  }

  const getProgram = () => {
    if ((Object.keys(filterCounts)?.length) < 2 && filterCounts?.programs === undefined) return
    const prgList = filterCounts['programs']

    return <Grid container item xs={12} direction="row">
      <Grid item className={classes.fullWidth}>
        {Object.keys(prgList)?.length > 0 && Object.keys(prgList)?.map((key: any, index: any) => (
          <Accordion
            key={`${key}-${index}`}
            square
            expanded={openAccordion.includes(prgList[key]?.display_name) ? true : false}
          >
            <AccordionSummary
              ref={inputEl}
              onClick={() => handleAccordion(prgList[key]?.display_name)}
              expandIcon={<ExpandMoreIcon />}
              sx={{ padding: '0px !important' }}
            >
              {getProgramTitleCount(prgList[key]?.display_name, prgList[key]?.count)}
            </AccordionSummary>
            <AccordionDetails sx={{
              '&.MuiAccordionDetails-root.MuiAccordionDetails-root': {
                padding: '0px !important'
              }
            }}>
              {getSubProgramList(prgList[key]?.items, prgList[key]?.display_name)}
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid >
  }

  return (
    <Box>
      <Card variant="outlined" sx={{ backgroundColor: '#f8f9fa' }}>
        <CardContent >
          <Box pb={1}>
            <Grid container item lg={12} direction="row" justifyContent="space-between" >
              <Grid item>
                <Typography variant="body1"><strong>Filters</strong></Typography>
              </Grid>
              <Grid item>
                {Object.values(patientsTable).splice(2).join('') !== '' ?
                  <Typography variant="subtitle2" className={classes.clearBtn} onClick={() => clearAllFilters()}>
                    <small>Clear All</small>
                  </Typography>
                  : <Typography variant="subtitle2" className={classes.clearBtn}>
                    <small>{``}</small>
                  </Typography>}
              </Grid>
            </Grid>
          </Box>
          <FilterChunkComponent name="Hospitals" stateKey='hospitals' input={setDataList('hospitals')} filterCount={filterCounts?.hospitals} />
          <Box pt={1} pb={1}>
            <Accordion key={`prg`} defaultExpanded square>
              <AccordionSummary
                sx={{ padding: '0px !important', marginLeft: '-8px' }}
                ref={inputEl}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="body2"><strong>Program</strong></Typography>
              </AccordionSummary>
              <AccordionDetails sx={{
                '&.MuiAccordionDetails-root.MuiAccordionDetails-root': {
                  paddingRight: '2px !important'
                }
              }}>
                {getProgram()}
              </AccordionDetails>
            </Accordion>
          </Box>
          {/* <Grid container direction="column" justifyContent="space-between">
              <Grid item><Typography variant="body2"><strong>Program</strong></Typography></Grid>
              <Grid item>{getProgram()}</Grid>
            </Grid> */}
          <FilterChunkComponent name="Stage" stateKey='stage' input={setDataList('stage')} filterCount={filterCounts?.stage} />
          <FilterChunkComponent name="Case Status" stateKey='status' input={setDataList('status')} filterCount={filterCounts?.status} />
        </CardContent>
      </Card>
    </Box>
  )
}

export default memo(FilterComponent);