import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Box, Card, Grid, Stack, Typography } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import { RootState, useAppThunkDispatch } from "../../../../redux/store";
import { setSelectedPU, setSelectedSchedule } from '../../../../redux/common-data-slice'
import stylesheet from "../detail-container.stylesheet";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { checkCondition } from '../common/condition-check';
interface IProps {
  key: string
  data: any,
  outerSubmit: any,
  compKey: string,
  mapState: any
  classes: ClassNameMap
}

const ScheduleCarePlanDialogComponent: React.FC<IProps> = ({ key, data, outerSubmit, compKey, mapState, classes }) => {
  const uiResource = useSelector((state: RootState) => state.metaData.metadata)
  const [schedulesData, setSchedulesData] = useState(mapState.has('c_rec_schedules') ? mapState.get('c_rec_schedules') : data?.c_rec_schedules || []);
  const [activeCard, setActiveCard] = useState({ key: '', name: '' });
  const [chosenSchedule, setChosenSchedule] = useState(false)
  const [chosenPU, setChosenPU] = useState(false)
  const [puSchedulesData, setPuSchedulesData] = useState(mapState.has('pu_recs') ? mapState.get('pu_recs') : []);
  const dispatch = useAppThunkDispatch();
  const selectedSchedule = useSelector((state: RootState) => state.commonData.selectedSchedule)
  const selectedPU = useSelector((state: RootState) => state.commonData.selectedPU)

  useEffect(() => {
    if (data?.is_recommended) {
      const activeScheduleName = selectedSchedule?.name || data?.aa_rec?.name
      let activeSch = schedulesData?.find((c: any) => c.name === activeScheduleName)
      if (activeSch !== undefined)
        setActiveCard({ key: activeSch.name, name: activeSch?.schedule_display_name })
    } else {
      const activeScheduleName = selectedSchedule?.name || data?.c_schedule?.name
      let activeSch = schedulesData?.find((c: any) => c.name === activeScheduleName)
      if (activeSch !== undefined)
        setActiveCard({ key: activeSch.name, name: activeSch?.schedule_display_name })
    }
  }, [])

  useEffect(() => {
    if (activeCard && activeCard?.key !== '') {
      const temp = schedulesData?.map((m: any) => m.name === activeCard.key ? { ...m, chosen: true } : { ...m, chosen: false })
      setSchedulesData(temp)
      if (chosenSchedule) {
        const currSch = schedulesData?.filter((m: any) => m.name === activeCard.key)?.[0] || {}
        outerSubmit(compKey, temp)
        dispatch(setSelectedSchedule(currSch))
      }

      const currSchName = activeCard?.key
      const puList = !chosenSchedule && puSchedulesData?.length > 0
        ? puSchedulesData : data?.pu_recs?.[currSchName] || []
      if (data?.is_recommended) {
        setPuSchedulesData(puList)
        const activePUrxcui = selectedPU?.rxcui !== ''
          ? selectedPU?.rxcui
          : (activeCard?.key === data?.aa_rec?.name)
            ? puList?.find((i: any) => i.rxcui === data?.pu_rec?.rxcui)?.rxcui
            : puList?.find((i: any) => i.chosen)?.rxcui

        onPUCardSelection(activePUrxcui || '', puList)
      }
    }
  }, [activeCard])

  const onCardSelection = (_name: string) => {
    setChosenSchedule(true)
    const currChosenSch = schedulesData?.filter((m: any) => m.name === _name)?.[0] || {}
    const activeSch = { key: currChosenSch.name, name: currChosenSch?.schedule_display_name }
    setActiveCard(activeSch)
  }

  const onPUCardSelection = (rxcui: string, puList: any) => {
    if (rxcui !== '') {
      setChosenPU(true)
      const currPUdata = Object.keys(puList)?.length > 0 ? puList : data?.pu_recs[activeCard.key]
      const curr_pu_rec = currPUdata?.map((m: any) => m.rxcui === rxcui ? { ...m, chosen: true } : { ...m, chosen: false })
      setPuSchedulesData(curr_pu_rec)
      if (!chosenSchedule)
        outerSubmit(compKey, schedulesData)

      outerSubmit('pu_recs', [...curr_pu_rec])
      dispatch(setSelectedPU(currPUdata.find((m: any) => m.rxcui === rxcui)))
    }
  }

  const getConditionsList = (val: any) => {
    let isCriteria = false
    const checkCriteria = (flag: boolean) => {
      if (flag) isCriteria = true
    }

    const conditions = Object.keys(val)?.map((i: any, index: any) => {
      if (typeof (val[i]) === 'object') {
        const currItem = val[i]
        return Object.keys(currItem)?.map((item: any, indx: any) =>
          <Typography component="span" className={classes.scheduleDoseInfo}>
            {!isCriteria && checkCriteria(currItem[item])}
            <Typography component="span" className={classes.scheduleDoseInfo} color={!currItem[item] ? 'error' : 'green'}>
              <small>{item}</small>
            </Typography>
            {(Object.keys(currItem)?.length > (indx + 1) || Object.keys(val)?.length > (index + 1)) && ', '}
          </Typography>
        )
      }
      else {
        return <Typography component="span" className={classes.scheduleDoseInfo}>
          {!isCriteria && checkCriteria(val[i])}
          <Typography component="span" className={classes.scheduleDoseInfo} color={!val[i] ? 'error' : 'green'}>
            <small>{i}</small>
          </Typography>
          {Object.keys(val)?.length > (index + 1) && ', '}
        </Typography>
      }
    })

    return <>
      <Typography className={classes.scheduleDoseInfo}>
        <small>{'Condition criteria met?'} </small>
        <Typography component='span' className={classes.scheduleDoseInfo} color={isCriteria ? 'green' : 'red'}>
          <small>{isCriteria ? 'YES' : 'NO'}</small>
        </Typography>
      </Typography>
      <Typography className={classes.scheduleDoseInfo}>{conditions}</Typography>
    </>
  }

  const renderScheduleDetails = (label: any, val: any, caution = false) => {
    return <Grid item>
      <Typography variant="subtitle2"><small>{label}:</small>
        {label === 'Conditions'
          ? <Typography component='span' variant="subtitle2" style={{ paddingLeft: '0.2rem' }}>
            {getConditionsList(val)}
          </Typography>
          : <Typography
            component='span'
            variant="subtitle2"
            color={['Next dose', 'Next frequency'].includes(label) && val === 'None' ? 'secondary' : 'inherit'}
            style={{ paddingLeft: '0.2rem' }}
          >
            <small>{val}</small>
          </Typography>
        }
      </Typography>
    </Grid>
  }

  const getDose = (dose: any, unit: any, freq: any) => dose === 'NA' ? 'NA' : `${dose} ${unit} ${freq}`

  const getDoseFreq = (dose: any, freq: any) => {
    return typeof (dose) === 'object' ? `[${dose?.join(', ')}]${freq}` : `${dose}${freq}`
  }

  const tableFormation = (e: any) => {
    return <Box sx={{ padding: '0.3rem 0.5rem' }}>
      <Box>
        <Typography className={classes.scheduleTitle} variant="subtitle2">
          {e.schedule_display_name || e.name}
          {e.recommended &&
            <CheckCircleOutlineOutlinedIcon className={classes.recommendedCheckCircle} titleAccess='Recommended' />
          }
        </Typography>
        <Typography className={classes.scheduleDoseInfo} color="GrayText">
          <small>take {e.route === 'oral' ? 'orally' : e.route}</small>
        </Typography>
      </Box>
      <Box pt={0.7} pb={1}>
        {Object.keys(e?.steps)?.map((i: any) => (
          <Stack direction={'row'}>
            {(i === getDoseFreq(e.starting_dose, e.starting_frequency) && i === getDoseFreq(e.ending_dose, e.ending_frequency))
              ? <Typography className={classes.scheduleIcon}>&#8596;</Typography>
              : i === getDoseFreq(e.starting_dose, e.starting_frequency)
                ? <Typography className={classes.scheduleIcon}>&#x2192;</Typography>
                : i === getDoseFreq(e.ending_dose, e.ending_frequency)
                  ? <Typography className={classes.scheduleIcon}>&#x2190;</Typography>
                  : ''
            }
            <Typography className={classes.scheduleDoseInfo}>
              <small>
                {e?.steps[i]}
                {(i === getDoseFreq(e.dose, e.frequency) ||
                  (data?.aa_rec?.name === e.name && data.titration_status === 'uptitrate' &&
                    i === getDoseFreq(e.prior_dose, e.prior_frequency))) &&
                  ' - '
                }
              </small>
            </Typography>
            <Typography className={classes.scheduleDoseInfo}
              sx={{
                paddingLeft: '0.2rem',
                color: i === getDoseFreq(e.dose, e.frequency)
                  ? 'green'
                  : (data?.aa_rec?.name === e.name && data.titration_status === 'uptitrate' &&
                    i === getDoseFreq(e.prior_dose, e.prior_frequency))
                    ? 'red'
                    : 'inherit'
              }}>
              <small>
                {i === getDoseFreq(e.dose, e.frequency)
                  ? data?.is_recommended ? 'start' : 'current'
                  : data?.aa_rec?.name === e.name &&
                    data.titration_status === 'uptitrate' && i === getDoseFreq(e.prior_dose, e.prior_frequency)
                    ? 'stop'
                    : ''
                }
              </small>
            </Typography>
          </Stack>
        ))}
      </Box>
      {getConditionsList(e?.condition || [])}

      {/* {renderScheduleDetails('Prior dose', getDose(e?.prior_dose, e?.unit, uiResource.MEDICATION_DOSAGE_FREQUENCY[e.frequency]))}
        {renderScheduleDetails('Next dose', getDose(e?.next_dose, e?.unit, uiResource.MEDICATION_DOSAGE_FREQUENCY[e?.next_frequency]))}
        {renderScheduleDetails('Ending dose', getDose(e?.ending_dose, e?.unit, uiResource.MEDICATION_DOSAGE_FREQUENCY[e?.ending_frequency]))}
        {renderScheduleDetails('Next titration interval', e?.nextstep_days || 'NA')}
        {renderScheduleDetails('Conditions', e?.condition || [], e?.caution)}
        {renderScheduleDetails('Dose 0', getDose(e?.starting_dose, e?.unit, uiResource.MEDICATION_DOSAGE_FREQUENCY[e?.starting_frequency]))} */}
    </Box >
  }

  const puTableFormation = (e: any) => {
    return <Box sx={{ padding: '0.3rem 0.5rem' }}>
      <Stack direction="column">
        <Typography className={classes.scheduleTitle} variant="subtitle2">
          {e.name} {e.status === 'preferred' &&
            <CheckCircleOutlineOutlinedIcon className={classes.recommendedCheckCircle} titleAccess='System Preferred' />
          }
        </Typography>
        <Typography className={classes.scheduleDoseInfo}>
          <small>{e?.num_units} {e?.formulation_display_name}</small>
        </Typography>
        <Typography className={classes.scheduleDoseInfo}>
          <small>{uiResource.MEDICATION_DOSAGE_FREQUENCY[e.frequency]}</small>
        </Typography>
      </Stack>
    </Box>
  }

  const getScheduleStatus = (e: any) => (e.chosen === true)


  const getPUStatus = (e: any) => {
    if (e.chosen === true) {
      dispatch(setSelectedPU(e))
      return true
    }
  }

  return (
    <Box mb={1} className={classes.fullWidth}>
      {(data?.titration_status)?.includes('discontinue')
        ? <Box p={1} pt={0}>
          <Typography variant='subtitle2'>
            {checkCondition(Object.keys(data.pu_order).length !== 0, data.pu_order?.name && `Stop ${data.pu_order?.name}`, '')}
          </Typography>
        </Box>
        : <Box p={1} pt={0}>
          <Typography variant="subtitle2" className={classes.puSchedulePadding}>
            Schedules for {data?.name}
          </Typography>
          <Grid container spacing={1}>
            {schedulesData?.map((e: any, i: any) =>
              <Grid key={i} item sm={4}>
                <Card variant="outlined"
                  onClick={() => (data?.titration_status &&
                    (data?.titration_status)?.includes('downtitrate')
                    || (data?.titration_status)?.includes('discontinue')
                    || (data?.titration_status)?.includes('prior dose')
                    || (data?.titration_status)?.includes('lowest dose')
                  ) ? undefined
                    : (!e.non_titratable)
                      ? onCardSelection(e.name)
                      : undefined}
                  className={getScheduleStatus(e)
                    ? classes.selectedScheduleCardLayout
                    : (e.non_titratable || (
                      (data?.titration_status)?.includes('downtitrate')
                      || (data?.titration_status)?.includes('discontinue')
                      || (data?.titration_status)?.includes('prior dose')
                      || (data?.titration_status)?.includes('lowest dose')
                    ))
                      ? classes.nonSelectedScheduleCardLayout
                      : classes.scheduleCardLayout
                  }
                >
                  {tableFormation(e)}
                </Card>
              </Grid>
            )}
            {puSchedulesData && puSchedulesData.length > 0 &&
              <Box mb={1} className={classes.fullWidth}>
                <Box p={1} pt={2} >
                  <Typography variant="subtitle2" className={classes.puSchedulePadding}>
                    Prescription Unit choices for {activeCard?.name}
                  </Typography>
                  <Grid container spacing={1}>
                    {puSchedulesData?.map((e: any, index: any) =>
                      <Grid key={index} item sm={4}>
                        <Card variant="outlined"
                          onClick={() => onPUCardSelection(e.rxcui, [])}
                          className={getPUStatus(e)
                            ? classes.selectedScheduleCardLayout
                            : classes.grayScheduleCardLayout}
                        >
                          {puTableFormation(e)}
                        </Card>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Box>
            }
          </Grid>
        </Box>
      }
    </Box>
  )
}

export default React.memo(withStyles(stylesheet)(ScheduleCarePlanDialogComponent));
