export const useOverrideAction = () => {
  const onSetOverView = (_id: string, _mode: string, data: any) => {
    if (data.hasOwnProperty('program_targets_mode')) {
      return { ...data, program_targets_mode: 'override' }
    } else {
      return data?.map((el: any) =>
        el.id === _id ? { ...el, mode: _mode } : el
      )
    }
  }

  //upgrade all overridden programs obj
  const getOverriddenPrgs = (oldprograms = [] as any, currPrgs = [] as any) => {
    const overriddenPrg = [...oldprograms, ...currPrgs]
    return overriddenPrg.filter(
      (elem: any, pos: any) => overriddenPrg.indexOf(elem) == pos
    )
  }

  const onDataOverSubmit = (
    _id: any,
    date: any,
    is_overridden: boolean,
    data: any,
    overridenPrograms: any[]
  ) => {
    return data.map((el: any) =>
      el.id === _id
        ? {
            ...el,
            is_overridden: is_overridden
              ? true
              : el.overridden_programs?.length !== overridenPrograms.length
              ? true
              : false,
            mode: 'view',
            overridden_on: date ? date : el?.overridden_on || null,
            overridden_programs: is_overridden
              ? getOverriddenPrgs(el.overridden_programs, overridenPrograms)
              : el.overridden_programs?.filter(
                  (e: any) => !overridenPrograms.includes(e)
                ) || [],
          }
        : el
    )
  }

  return {
    onSetOverView,
    onDataOverSubmit,
  }
}
