import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ProgramChecksState {
    calculated_psc: any[]
}

const initialState: ProgramChecksState = {
    calculated_psc: [],
}

export const calculatedPSCSlice = createSlice({
    name: 'calculated_psc',
    initialState,
    reducers: {
        setCalculatedPSCStore: (state, action: PayloadAction<any>) => {
            state.calculated_psc = action.payload
        }
    }
})

export const { setCalculatedPSCStore } = calculatedPSCSlice.actions

export default calculatedPSCSlice.reducer