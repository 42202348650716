import { Box, Typography, Grid } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import { useState } from 'react'
import stylesheet from '../detail-container.stylesheet'
import moment from 'moment'
import DatePickerComponent from '../../../../utils/datepicker.component'

interface IProps {
  item: any
  outerSubmit: any
  compKey: string
  classes: ClassNameMap
}

const PatientStartedDateComponent: React.FC<IProps> = ({
  item,
  outerSubmit,
  compKey,
  classes,
}) => {
  const [status, setStatus] = useState(
    item.intake_started_on
      ? moment.unix(item.intake_started_on).format('YYYY-MM-DD')
      : moment.now()
  )
  const onCalendorChange = (_param: any) => {
    setStatus(_param)
    outerSubmit(compKey, moment(_param).unix())
  }
  return (
    <Box className={classes.subModalBackground}>
      <Typography variant="subtitle2">
        Has patient started taking {item.name}? If yes, please select the start
        date.
      </Typography>
      <Box pt={1}>
        <Grid xs={3}>
          <DatePickerComponent
            type="date"
            cssClass={classes.cardLabNotes}
            inputPropCss={classes.cardLabDate}
            variant="outlined"
            value={status}
            onCalendarChange={(e: any) => {
              onCalendorChange(e.target.value)
            }}
          />
        </Grid>
      </Box>
    </Box>
  )
}

export default withStyles(stylesheet)(PatientStartedDateComponent)
