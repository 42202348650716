import { useSelector } from 'react-redux'
import {
  loadingPatientDetail,
  updateEnrollments,
  updatePDConditions,
  updatePDMeasurements,
  updatePDMedications,
} from '../redux/patient-detail-slice'
import { RootState, useAppThunkDispatch } from '../redux/store'
import { PatientService } from '../services/patient.service'
import { setMultiProgramConfirm } from '../redux/multi-program-confirmation-slice'
import Helper from './helper'
import { toast } from 'react-toastify'
import { setSelectedProgram } from '../redux/common-data-slice'

export const useUpdateDataAction = (patientId: string) => {
  const dispatch = useAppThunkDispatch()
  const patientService = new PatientService()
  const multiProgramConfirm = useSelector((state: RootState) => state.multiProgramConfirm)
  const program_enrollments = useSelector((state: RootState) => state.patientDetail.patientDetail?.program_enrollments || [])

  const updatePendingCount = (pending_action_counts: any) => {
    if (Object.keys(pending_action_counts)?.length > 0) {
      const updatedCount = multiProgramConfirm.map((i: any) => {
        let currP =
          Object.keys(pending_action_counts)?.map((p: any) =>
            i.program === p ? pending_action_counts[p] : ''
          ) || []
        currP = currP?.filter((i: any) => i !== '')
        return currP && currP.length > 0 ? { ...i, pending: currP[0] } : i
      })
      dispatch(setMultiProgramConfirm(updatedCount))
      setTimeout(() => {
        dispatch(loadingPatientDetail(''))
      }, 100)
    }
  }

  const updatePatientData = ({
    isMeasurement = false,
    isTreatments = false,
    isConditions = false,
  }) => {
    dispatch(loadingPatientDetail('loading'))
    patientService
      .getPatientInfo(patientId)
      .then((response: any) => {
        const isUpdateStage = JSON.stringify(program_enrollments) !== JSON.stringify(response?.data?.patient?.program_enrollments)
        if (isMeasurement || isUpdateStage)
          dispatch(updatePDMeasurements(response?.data?.patient?.measurements))
        if (isTreatments || isUpdateStage)
          dispatch(updatePDMedications(response?.data?.patient?.medications))
        if (isConditions || isUpdateStage)
          dispatch(updatePDConditions(response?.data?.patient?.conditions))

        updatePendingCount(response?.data?.patient?.pending_action_counts)
        dispatch(updateEnrollments(response?.data?.patient?.program_enrollments))
        dispatch(setSelectedProgram(
          response?.data?.patient?.program_enrollments
            ?.filter((f: { selected: boolean }) => f.selected === true)
            .map((e: { program: any }) => e.program)
        ))
      }).catch((err: any) => {
        dispatch(loadingPatientDetail(''))
        toast.error(Helper.getError(err), Helper.bottom_center())
      })
  }
  return {
    updatePatientData,
  }
}
