import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ProgramCalculatorsState {
  program_calculators: any[]
}

const initialState: ProgramCalculatorsState = {
  program_calculators: [],
}

export const programCalculatorsSlice = createSlice({
  name: 'program_calculators',
  initialState,
  reducers: {
    setProgramCalculatorsStore: (state, action: PayloadAction<any>) => {
      state.program_calculators = action.payload
    },
  },
})

export const { setProgramCalculatorsStore } = programCalculatorsSlice.actions

export default programCalculatorsSlice.reducer
