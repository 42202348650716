import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface OtherMeasurementsState {
  other_measurements: any[]
}

const initialState: OtherMeasurementsState = {
  other_measurements: [] as any,
}

export const otherMeasurementsSlice = createSlice({
  name: 'other_measurements',
  initialState,
  reducers: {
    setOtherMeasurementsStore: (state, action: PayloadAction<any>) => {
      state.other_measurements = action.payload
    },
  },
})

export const { setOtherMeasurementsStore } = otherMeasurementsSlice.actions

export default otherMeasurementsSlice.reducer
