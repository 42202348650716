import React, { useState } from 'react'
import {
  Box, Button, ButtonGroup, Card, CardContent, CircularProgress, Grid, Typography, Badge,
} from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import { PatientService } from '../../../../services/patient.service'
import ColorBubbleComponent from '../common/color-bubble.component'
import { checkCondition } from '../common/condition-check'
import { toast } from 'react-toastify'
import Helper from '../../../../utils/helper'
import { useCurrentUser } from '../../../../utils/use-current-user'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

interface IProps {
  patientId: string
  input: any
  name: string
  outer: any
  outerSubmit: any
  classes: ClassNameMap
}

const QuestionPSCComponent: React.FC<IProps> = ({ patientId, input, name, outer, outerSubmit, classes, }) => {
  const [currentOption, setCurrentOption] = useState('')
  const [loading, setLoading] = useState(false)
  const [notes, setNotes] = useState('')
  const [icdNotes, setIcdNotes] = useState('')
  const [icdCodes, setIcdCodes] = useState([] as any)
  const [checked, setChecked] = useState(false)
  const [reviewChecked, setReviewChecked] = useState(false)
  const [icdChecked, setIcdChecked] = useState(false)
  const [noteErr, setNoteErr] = useState({ error: false, msg: '' })
  const [icdNoteErr, setIcdNoteErr] = useState({ error: false, msg: '' })
  const patientService = new PatientService()
  const { isNavigator } = useCurrentUser()

  const onBtnChange = (_input: string) => setCurrentOption(_input)

  const onConfirmation = (
    _id: string,
    _patientId: string,
    _mode: string,
    _typeOut: string,
    score: string,
    _is_score_different: boolean
  ) => {
    const payload = {
      entity_ref: 'condition_evaluation',
      entity_id: _id,
      confirmed_by: 1, // mocked for future scope
    }
    patientService
      .PostConfirmations(payload)
      .then((response: any) => {
        setLoading(false)
        outerSubmit(
          _id,
          _mode,
          _typeOut,
          score,
          false,
          response.data?.confirmation?.confirmed_on,
          _is_score_different
        )
      })
      .catch((err: any) => {
        setLoading(false)
        toast.error(Helper.getErrorText(err), {
          position: 'top-right',
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        })
      })
  }

  const onBtnSubmit = (_mode: string, _typeOut: string) => {
    setLoading(true)
    onCloseClick('view')

    const icds = icdCodes.map((r: any) => {
      return {
        icd_code: r.icd_code,
        desc: r.desc,
        notes: r.notes.text,
        clarification_received: r.clarification_received,
      }
    })

    const payload = {
      provider_assessment: currentOption,
      data_reviewed: reviewChecked,
      clarification_received: checked,
      provider_notes: notes,
      proposed_icds: icds,
    }

    patientService
      .putProgramConditionsQuestionnaire(patientId, input.id, payload)
      .then((response: any) => {
        const dt = response.data
        if (_typeOut === 'confirm') {
          onConfirmation(input.id, patientId, _mode, _typeOut, dt.score, dt.is_score_different)
        } else {
          setLoading(false)
          outerSubmit(
            dt.id,
            _mode,
            _typeOut,
            dt.score,
            dt.needs_confirmation,
            dt.date_recorded,
            dt.is_score_different
          )
        }
      })
      .catch((errors: any) => {
        setLoading(false)
        toast.error(Helper.getErrorText(errors), {
          position: 'top-right',
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        })
      })
  }
  const onCloseClick = (mode: string) => {
    outer(input.id, mode)
  }

  const handleIcdChange = (d: any, action: string) => {
    switch (action) {
      case 'add':
        setIcdCodes([
          ...icdCodes,
          {
            ...d,
            notes: { text: icdNotes },
            clarification_received: icdChecked,
          },
        ])
        setIcdNotes('')
        setIcdChecked(false)
        break
      case 'update':
        setIcdCodes(
          icdCodes.map((el: any) =>
            el.icd_code === d.icd_code
              ? {
                ...el,
                notes: { text: icdNotes },
                clarification_received: icdChecked,
                active: false,
              }
              : el
          )
        )
        setIcdNotes('')
        setIcdChecked(false)
        break
      case 'delete':
        setIcdCodes(
          icdCodes.filter((e: { icd_code: any }) => e.icd_code !== d.icd_code)
        )
        setIcdNotes('')
        setIcdChecked(false)
        break
      case 'cancel':
        setIcdCodes(icdCodes.map((el: any) => ({ ...el, active: false })))
        setIcdNotes('')
        setIcdChecked(false)
        break
      case 'select':
        setIcdCodes(
          icdCodes.map((el: any) =>
            el.icd_code === d.icd_code
              ? { ...el, active: true }
              : { ...el, active: false }
          )
        )
        setIcdNotes(d.notes.text)
        setIcdChecked(d.clarification_received)
        break
      default:
        setIcdCodes([...icdCodes])
        break
    }
  }

  return (
    <>
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        direction="row"
        className={classes.fullWidth}
      >
        <Box mb={1} className={classes.customCard}>
          <Card variant="outlined" className={classes.questionCardContent}>
            <CardContent className={classes.questionCardSubContent}>
              <Box className={classes.questionHeaderSection} p={1}>
                <Grid container xs={12} direction="row" alignItems="center">
                  <Typography>
                    <strong>{name}</strong>
                    <ColorBubbleComponent selectedProgramList={input.programs || []} />
                  </Typography>
                </Grid>
              </Box>
              {
                loading === true ?
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box m={1}>
                      <CircularProgress size="2rem" />
                    </Box>
                  </Grid>
                  :
                  <>
                    <Box pl={1} pr={1} mb={2} pt={1}>
                      <Grid>
                        <Box pb={1}>
                          <Typography variant="body2">
                            <strong>
                              I agree with the primary diagnosis of {name}
                            </strong>
                          </Typography>
                        </Box>
                        <Grid container justifyContent='space-between'>
                          <div>

                            <Badge
                              invisible={checkCondition(
                                input.evaluation === 'yes',
                                false,
                                true
                              )}
                              badgeContent={
                                <CheckCircleIcon
                                  fontSize="small"
                                  className={classes.bagdeIcon}
                                />
                              }
                            >
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={() => onBtnChange('yes')}
                                className={checkCondition(currentOption === 'yes', [
                                  classes.questionPSCButton,
                                  classes.questionPSCButtonActive,
                                  classes.badgeButton
                                ].join(' '), [classes.questionPSCButton, classes.badgeButton].join(' '))
                                }
                              >
                                {' '}
                                Yes
                              </Button>
                            </Badge>

                            <Badge
                              invisible={checkCondition(
                                input.evaluation === 'no',
                                false,
                                true
                              )}
                              badgeContent={
                                <CheckCircleIcon
                                  fontSize="small"
                                  className={classes.bagdeIcon}
                                />
                              }
                            >
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={() => onBtnChange('no')}
                                className={checkCondition(currentOption === 'no', [
                                  classes.questionPSCButton,
                                  classes.questionPSCButtonActive,
                                  classes.questionPSCNoButton
                                ].join(' '), [classes.questionPSCButton, classes.questionPSCNoButton].join(' '))
                                }
                              >
                                {' '}
                                No
                              </Button>
                            </Badge>

                          </div>
                          <Grid item className={classes.badgeContainer} >
                            {checkCondition(input.recommended_diagnosis,
                              <Badge title={'Recommended'}
                                invisible={checkCondition(
                                  input.recommended_diagnosis,
                                  false,
                                  true
                                )}
                                variant='standard'
                                badgeContent={'R'}
                                classes={{ badge: classes.customBadge }}
                              >
                                <Button
                                  disabled={true}
                                  variant="outlined"
                                  size="small"
                                  className={classes.badgeButton}
                                >
                                  <span className={classes.badgeButtonText} >{input.recommended_diagnosis}</span>
                                </Button>
                              </Badge>, ""
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box>
                      <Grid
                        container
                        xs={12}
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                      >
                        <ButtonGroup
                          className={classes.questionFullButtonStyle}
                          size="small"
                          variant="text"
                          aria-label="small outlined button group"
                        >
                          <Button
                            disabled={
                              icdNoteErr.error ||
                              noteErr.error ||
                              checkCondition(!currentOption || isNavigator, true, false)
                            }
                            onClick={() => onBtnSubmit('view', 'save')}
                            className={classes.questionButtonWidth1}
                          >
                            <strong>Save</strong>
                          </Button>
                          <Button
                            onClick={() => onCloseClick('view')}
                            className={classes.questionButtonWidth1}
                          >
                            <strong>Cancel</strong>
                          </Button>
                        </ButtonGroup>
                      </Grid>
                    </Box>
                  </>
              }
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </>
  )
}
export default withStyles(stylesheet)(QuestionPSCComponent)
