import React, { useState, useRef, useEffect } from 'react'
import {
  Grid, LinearProgress, MenuItem, DialogTitle, DialogContent, DialogActions, Button,
  Dialog, FormControl, InputLabel, Select, Input, ListItemText, Checkbox,
} from '@mui/material'
import stylesheet from '../detail-container.stylesheet'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { ClassNameMap, withStyles } from '@mui/styles'
import Draggable from "react-draggable"
import DialogTitleHeaderComponent from '../common/dialog-title-header.component';

interface IProps {
  open: boolean,
  item: any,
  handleDialogClose: any,
  handleSubmit: any,
  classes: ClassNameMap
}

const AddProgramDialogComponent: React.FC<IProps> = ({ open, item, handleDialogClose, handleSubmit, classes }) => {
  const isAddPrRef = useRef(null) as any
  const [isLoadingPrg, setIsLoadingPrg] = useState(true)
  const [isSubmit, setIsSubmit] = useState(false)
  const [currprg, setCurrPrg] = useState(item?.programs || '')
  const [prgData, setPrgData] = useState(item?.prgData)

  useEffect(() => {
    setTimeout(function () {
      setIsLoadingPrg(false)
    }, 600);
  })

  const renderList = () => {
    return item.prgData?.map((item: any) =>
      <MenuItem
        key={item}
        className={classes.programMenu}
        disabled={item?.checked}
        value={item?.name} selected={item?.programs === item.name}
      >
        {item?.display_name}
        {item?.checked && <CheckCircleOutlineOutlinedIcon className={classes.programIcon} />}
      </MenuItem>
    )
  }

  const renderPrgList = () => {
    return prgData?.map((item: any) => {
      return <MenuItem key={item?.name}
        disabled={item?.checked && item?.display_name !== currprg}
        value={item?.display_name}
        className={classes.programMenu}
      >
        <Checkbox color='primary' size={'small'} checked={item?.checked} />
        <ListItemText primary={item?.display_name} />
      </MenuItem>
    })
  }

  const handleCtrlChange = (e: any) => {
    setPrgData(
      prgData?.map((m: any) => {
        return {
          ...m,
          checked: m.display_name === currprg
            ? false
            : m.display_name === e?.target?.value
              ? true
              : m.checked
        }
      })
    )
    setCurrPrg(e?.target?.value)
  }

  const renderAddProblem = () => {
    return <FormControl className={classes.formControlStage}>
      <InputLabel id={`${item.title}-label`} className={classes.stageLabel}>{item?.label || 'Select'}</InputLabel>
      <Select
        id={`${item.title}-select`}
        labelId={`${item.title}-label`}
        onChange={(e) => setCurrPrg(e?.target?.value)}
        label={item?.title || 'Select'}
        value={currprg}
        className={classes.programSelect}
        inputProps={{
          classes: {
            icon: classes.icon
          },
        }}
      >
        {renderList()}
      </Select>
    </FormControl>
  }

  const setSubmitData = () => {
    setIsSubmit(true);
    handleSubmit(currprg)
  }

  return (
    <Draggable ref={isAddPrRef} defaultPosition={{ x: 0, y: 0 }} handle='.add-program-dialog'>
      <Dialog
        id={item.title}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={handleDialogClose}
        aria-labelledby={item.title}
        hideBackdrop
        disableScrollLock
        onKeyUp={(e) => {
          if (e.key === 'Enter' && !(currprg === '' || isSubmit))
            setSubmitData()
        }}
        sx={{
          position: "absolute",
          left: '15%',
          top: '20%'
        }}
      >
        <DialogTitle className={classes.addPatientQuestionHeaderSection} classes={{ root: 'add-program-dialog' }}>
          <DialogTitleHeaderComponent
            title={item?.title}
            handleDialogClose={handleDialogClose}
          />
        </DialogTitle>
        <Grid item>
          {isLoadingPrg && <LinearProgress />}
        </Grid>
        <DialogContent className={classes.addPatientDialogContent}>
          <Grid container spacing={2} className={classes.containerPadding}>
            <Grid item md={12} xs={12}>
              {renderAddProblem()}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.containerPadding} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                disabled={currprg === '' || isSubmit}
                onClick={setSubmitData}
                component="label"
              >
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setCurrPrg('')
                  handleDialogClose()
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Draggable>
  );
};

export default withStyles(stylesheet)(AddProgramDialogComponent)
