import React from 'react'
import stylesheet from '../detail-container.stylesheet'
import { Box, Typography } from '@mui/material'
import moment from 'moment'
import { ClassNameMap, withStyles } from '@mui/styles'
import { checkCondition } from './condition-check'
import { INote } from '../../../../model/notes'

interface IProps {
  notes: INote[]
  classes: ClassNameMap
}

const NotesList: React.FC<IProps> = ({ notes, classes }) => {
  return <>
    {notes?.map((n: any) =>
      <Box pb={1} data-testid='notes-list'>
        <Box display="flex" justifyContent="flex-start">
          <Typography variant="subtitle2">
            <b>
              {n.author.name}({n.author.user_role})
            </b>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <small>
              &nbsp;
              {moment
                .unix(n.created_on)
                .format('MMM DD YYYY hh:mm a')}
            </small>
          </Typography>
        </Box>
        <Typography
          className={classes.wrapNote}
          variant="body2"
        >

          {checkCondition(n?.type !== null,
            <b><small>{n.type} : </small></b>,
            <></>
          )}{n.text}
        </Typography>
      </Box>
    )}
  </>
}

export default withStyles(stylesheet)(NotesList)
