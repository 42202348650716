import React, { useState, useEffect, memo, useRef } from 'react'
import { Grid, Typography } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import moment from 'moment'
import { useSelector } from 'react-redux'
import stylesheet from '../detail-container.stylesheet'
import CardLayoutComponent from '../common/card-layout.component'
import { RootState } from '../../../../redux/store'
import { useParams } from 'react-router-dom'
import { PatientService } from '../../../../services/patient.service'
import DeleteCard from '../common/delete-card-component'
import NotesComponent from '../common/notes.component'
import { useReferralAction } from '../../../../utils/referral-hook'
import MonitorDialogComponent from '../common/monitor-dialog.component'
import Helper from '../../../../utils/helper'
import { toast } from 'react-toastify'
import { useUpdateDataAction } from '../../../../utils/update-details-hook'

interface IProps {
  input: any
  classes: ClassNameMap
}
const ProgramMonitorTypeComponent: React.FC<IProps> = ({ input, classes }) => {
  const [data, setData] = useState([] as any)
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const urlParams: any = useParams()
  const patientId: string = urlParams.patientId
  const patientService = new PatientService()
  const [openMonitor, setOpenMonitor] = useState(false)
  const [currMonitor, setCurrMonitor] = useState({})
  const [selectedNotes, setSelectedNotes] = useState([] as number[])
  const { onSetView, onDataSubmit } = useReferralAction()
  const { updatePatientData } = useUpdateDataAction(patientDetail.id)

  useEffect(() => {
    setData(input?.data?.map((e: any) => {
      return { ...e, mode: 'view' }
    }))
  }, [input?.data])

  const handleMonitorClose = () => setOpenMonitor(false)

  const formatDates = (d: any) => {
    const sdate = d.start_date ? 'Start: ' + moment.unix(d.start_date).format('MM/DD/YY') : ''
    const duration = d.referral_interval ? ', Duration: ' + d.referral_interval : ''
    const retVal = sdate && duration ? sdate + duration : (sdate ? sdate : (duration ? duration : ''))
    return retVal
  }

  const formatMedName = (d: any) => {
    let msg = d?.tox_med_name ? 'Toxicity Monitored Due to: ' + d.tox_med_name : ''
    return msg + '\n' + formatDates(d)
  }

  const setOtherIcons = () => {
    return input.type === 'completed'
      ? [
        { iconType: 'edit', callback: onSetDetail },
        { iconType: 'description', callback: onNotesClick },
        { iconType: 'more', callback: outer },
      ]
      : [
        { iconType: 'edit', callback: onSetDetail },
        { iconType: 'delete', callback: onDelIconClick },
        { iconType: 'description', callback: onNotesClick },
        { iconType: 'more', callback: outer },
      ]
  }

  const onNotesClick = (_id: any) => {
    if (selectedNotes.includes(+_id)) {
      setSelectedNotes([...selectedNotes.filter((f) => f !== +_id)])
    } else {
      setSelectedNotes([...selectedNotes, +_id])
    }
  }

  const onDelIconClick = (_id: any) => {
    setData(
      data.map((el: any) => (el.id === _id ? { ...el, mode: 'delete' } : el))
    )
  }

  const outer = (_id: any, _mode: string) => setData(onSetView(_id, _mode, data))

  const confirmDelete = (_id: any) => {
    patientService.deleteMonitorDetails(patientId, _id).then((_response: any) => {
      if (_response?.data === 'success') {
        updatePatientData({ isMeasurement: true, isTreatments: true, isConditions: true })
      }
    }).catch((err: any) => toast.error(Helper.getErrorText(err), Helper.bottom_center()))
  }

  const getMonitorData = (_id: number) => {
    _id && patientService.getMonitorDetails(patientDetail.id, _id).then((res: any) => {
      if (res?.data?.monitor) {
        setCurrMonitor({ ...res?.data?.monitor, type: input.type })
        setOpenMonitor(true)
      }
    }).catch((err: any) => toast.error(Helper.getErrorText(err), Helper.bottom_center()))
  }

  const onSetDetail = (_id: number) => {
    setData(data.map((el: any) => (el.id === _id ? { ...el, mode: 'view' } : el)))
    getMonitorData(_id)
  }

  const closeDelete = (_id: number) => setData(data.map((el: any) => (el.id === _id ? { ...el, mode: 'view' } : el)))

  const handleMonitor = (_id: number) => {
    setCurrMonitor({})
    if (_id) {
      setData(data.filter((el: any) => el.id !== _id))
      updatePatientData({ isMeasurement: true, isTreatments: true })
    } else {
      toast.error('Failed to stop monitor.', Helper.bottom_center())
    }
  }

  const getMonitorList = (data: any) => {
    return data && data
      ?.sort((a: any, b: any) => (a?.display_name).toLowerCase() > (b?.display_name).toLowerCase() ? 1 : -1)
      ?.map((d: any, i: any) => {
        return <React.Fragment key={`prg-monitor-${i}`}>
          {d.mode === 'view' && (
            <>
              <CardLayoutComponent
                id={d.id}
                name={d?.name}
                leftHeader={d.display_name || d?.name}
                rightHeader={''}
                subHeader={formatMedName(d)}
                bottomText={''}
                needs_confirmation={d.needs_confirmation || undefined}
                needs_update={d.needs_update || undefined}
                programs={d.programs || undefined}
                icons={setOtherIcons()}
                onRefClick={() => outer(d.id, 'ref')}
                is_referred={false}
                is_overridden={false}
                entityRef='monitor'
                patientId={patientId}
                category={'medications'}
              />
              {selectedNotes.includes(d.id) && (
                <NotesComponent
                  entityId={d.id}
                  entityRef={'medication'}
                  cancelBtn={true}
                  onCancel={onNotesClick}
                >
                  Notes
                </NotesComponent>
              )}
            </>
          )}
          {d.mode === 'delete' && (
            <DeleteCard
              id={d.id}
              header={d.display_name}
              closeDelete={() => closeDelete(d.id)}
              outerDelete={() => confirmDelete(d.id)}
            />
          )}
        </React.Fragment>
      })
  }
  return (<>
    {getMonitorList(data)}
    {openMonitor &&
      <MonitorDialogComponent
        open={openMonitor}
        handleClose={handleMonitorClose}
        handleAction={handleMonitor}
        input={currMonitor}
      />
    }
  </>)
}

export default withStyles(stylesheet)(memo(ProgramMonitorTypeComponent))