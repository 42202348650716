import React, { useState, useRef, useEffect } from 'react'
import {
  Grid, LinearProgress, DialogTitle, DialogContent, Dialog, Box, CircularProgress,
  Accordion, AccordionDetails, AccordionSummary, DialogActions, Button, Tooltip, IconButton,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useSelector } from 'react-redux'
import { ClassNameMap, withStyles } from '@mui/styles'
import Draggable from "react-draggable"
import stylesheet from './questionnaire.stylesheet';
import QuestionnaireComponent from './questionnaire.component'
import { RootState } from '../../redux/store'
import CardLayoutComponent from '../patients/detail/common/card-layout.component'
import { toast } from 'react-toastify'
import InfoIcon from '@mui/icons-material/Info'
import Helper from '../../utils/helper'
import { PatientService } from '../../services/patient.service'
import { useDispatchAPIs } from '../../utils/use-dispatchApis-hook'
import { useCheckConfirmCount } from '../../utils/use-checkconfirmationcount-hook'
import DialogTitleHeaderComponent from '../patients/detail/common/dialog-title-header.component';

interface IProps {
  open: boolean,
  title: string,
  item: any,
  handleClose: any,
  classes: ClassNameMap
}

const CategoryQuestionnaireComponent: React.FC<IProps> = ({ open, title, item, handleClose, classes }) => {
  const [isLoading, setIsLoading] = useState(true)
  const patientDetail = useSelector((state: RootState) => state.patientDetail.patientDetail)
  const selectedProgram = useSelector((state: RootState) => state.commonData.selectedProgram)
  const [data, setData] = useState(item || [] as any)
  const [, setCount] = useCheckConfirmCount(patientDetail.id)
  const { multipleProgramCounts } = useDispatchAPIs(patientDetail.id)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isCurrQuestionLoading, setIsCurrQuestionLoading] = useState('')
  const [openAccordion, setOpenAccordion] = useState([] as any)
  const patientService = new PatientService()
  const { setProgramConfirmCount } = useDispatchAPIs(patientDetail.id);
  const handleIsSubmit = (flag: boolean) => setIsSubmit(flag)
  const queRefs = useRef([] as any);
  const calRef = useRef(null) as any
  const [queObj, setQueObj] = useState([] as any)
  let currQueObj = [] as any

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
  }, [])

  useEffect(() => {
    renderGroup(title)
    if (isSubmit) {
      setIsSubmit(false)
      setIsLoading(false)
      setOpenAccordion([])
    }
  }, [data])

  const handleSave = (_type: string, res: any) => {
    setIsSubmit(false)
    setIsCurrQuestionLoading(res?.id)
    if (res && res?.id) {
      const tempObj = data.find((f: { id: string }) => f.id === res.id)
      if (_type === 'confirm') {
        if (!tempObj.is_overridden && !tempObj.needs_update) {
          setCount(tempObj?.needs_confirmation, false)
          setProgramConfirmCount(tempObj?.needs_confirmation, false, tempObj?.programs, false, [])
        }
        setData(
          data.map((el: any) =>
            el.id === res.id
              ? {
                ...el,
                needs_confirmation: false,
                is_overridden: false,
                overridden_programs: [],
                mode: 'view'
              }
              : el
          )
        )
      } else {
        setData(data.map((el: any) => (el.id === res.id ? { ...el, ...res, mode: 'view' } : el)))
        setProgramConfirmCount(tempObj?.needs_confirmation, res.needs_confirmation, tempObj?.programs)
      }
      setIsSubmit(false)
      setIsCurrQuestionLoading('')
      closeCurrQuestionnarie(res.id)
    }
  }

  const handleCatDialogClose = () => handleClose(data)

  const closeCurrQuestionnarie = (id: any) => {
    setOpenAccordion(openAccordion.filter((item: any) => item !== id))
  }

  const handleAccordion = (key: any) => {
    if (openAccordion?.findIndex((el: any) => el === key) === -1) {
      setOpenAccordion([...openAccordion, key])
      if (queObj?.findIndex((el: any) => el.id === key) === -1) {
        setQueObj([...queObj, { 'id': key, confirmed: true, 'answer': {} }])
      }
    } else
      closeCurrQuestionnarie(key)
  }

  const handleChange = (qId: any, currData: any, initAnswer: false) => {
    if (initAnswer) {
      currQueObj = [...currQueObj, { id: qId, confirmed: true, answer: currData }]
      if (currQueObj?.length === item?.length)
        setQueObj(currQueObj)
    } else {
      const currQueObj = queObj?.map((item: any) => {
        if (item.id === qId) {
          return { id: qId, confirmed: true, answer: currData }
        } else {
          return item
        }
      })
      setQueObj(currQueObj)
    }
  }

  const setPrgPendingCount = (currData = [] as any, prevData = [] as any) => {
    let pCountArr: any[] = []
    currData?.map((r: any) => {
      const currObj = prevData.find((f: any) => f.id === r.id)
      if (currObj !== undefined) {
        if (!currObj.is_overridden && (!currObj.needs_update || !currObj.needs_confirmation)) {
          pCountArr = [
            ...pCountArr,
            {
              previous: currObj.needs_confirmation,
              current: r.needs_confirmation,
              programs: currObj.programs,
            },
          ]
          setCount(prevData.find((f: any) => f.id === r.id).needs_update, r.needs_update)
        }
      }
    })
    if (pCountArr?.length > 0)
      multipleProgramCounts(pCountArr)
  }

  const handleBulkConfirm = async (type: any) => {
    let currQueObj = queObj?.filter((item: any) => Object.keys(item?.answer)?.length > 0)
    if (Object.keys(currQueObj)?.length > 0) {
      setIsLoading(true)
      setIsSubmit(true)
      if (type !== 'confirm')
        currQueObj = currQueObj?.map((i: any) => { return { ...i, confirmed: false } })

      let isSaveErr: any[] = []
      let isSaveList: any[] = []
      let currUpdatedData = data
      Promise.all(
        currQueObj?.map((currItem: any) => {
          return new Promise((resolve: any) => {
            patientService.updateQuestionnaires(patientDetail.id, { "data": [currItem] })
              .then((res: any) => {
                if (res?.data?.message === "Success") {
                  const updatedItem = res?.data?.result[0] || {}
                  const tempObj = data.find((f: { id: string }) => f.id === currItem.id)
                  currUpdatedData = currUpdatedData.map((el: any) =>
                    el.id === currItem.id
                      ? {
                        ...el,
                        needs_confirmation: updatedItem?.needs_confirmation,
                        is_overridden: updatedItem?.is_overridden,
                        overridden_programs: updatedItem?.overridden_programs,
                        severity: updatedItem?.severity || null,
                        first_response: updatedItem.first_response,
                        created_on: updatedItem.created_on,
                        mode: 'view'
                      }
                      : el
                  )
                  isSaveList.push(getQuestionnarieName(currItem?.id))
                  const latestAnswers = updatedItem?.questionnaire?.answer_set
                  handleChange(currItem.id, latestAnswers, false)
                  resolve()
                } else {
                  isSaveErr.push(getQuestionnarieName(currItem?.id))
                  resolve()
                }
              }).catch((res: any) => {
                isSaveErr.push(getQuestionnarieName(currItem?.id))
                if (res?.response?.data
                  && res?.response?.data?.message === "Data saved but not confirmed due to incomplete response") {
                  const updatedItem = res?.response?.data?.result[0] || {}
                  currUpdatedData = currUpdatedData.map((el: any) =>
                    el.id === currItem.id
                      ? {
                        ...el,
                        needs_confirmation: updatedItem?.needs_confirmation,
                        is_overridden: updatedItem?.is_overridden,
                        overridden_programs: updatedItem?.overridden_programs,
                        severity: updatedItem?.severity || null,
                        first_response: updatedItem.first_response,
                        created_on: updatedItem.created_on,
                        mode: 'view'
                      }
                      : el
                  )
                  const latestAnswers = updatedItem?.questionnaire?.answer_set
                  handleChange(currItem.id, latestAnswers, false)
                  resolve()
                }
              })
          })
        })
      ).then(() => {
        setPrgPendingCount(currUpdatedData, data)
        setIsLoading(false)
        setIsSubmit(false)
        setData(currUpdatedData)
        setOpenAccordion([])
        if (isSaveList.length > 0)
          toast.success(`Data updated successfully for ${isSaveList.join(', ')}.`, Helper.bottom_center())
        if (isSaveErr.length > 0)
          toast.error(`Failed to update ${isSaveErr.join(', ')}.`, Helper.bottom_center())
      })
    } else {
      toast.warning(`No data change to update!`, Helper.bottom_center())
    }
  }

  const getQuestionnarieName = (id: any) => data.find((i: any) => i.id === id)?.display_name || id

  const getSeverity = (severity: any) => {
    return severity && severity?.value !== null ?
      <>
        {`(severity:${severity?.value}${severity?.max_value ? `/${severity?.max_value}` : ''})`}
        <Tooltip
          data-testid='severity-testId'
          title={severity?.question_text ? severity?.question_text : 'Details not available'}
          placement="bottom">
          <IconButton size='small' className={classes.infoIcon} sx={{ padding: '0px' }}>
            <InfoIcon className={classes.infoIconFont} />
          </IconButton>
        </Tooltip>
      </>
      : ''
  }

  const getDiagnosisRes = (d: any) => d?.first_response === 'Yes' ? `(${d?.first_response})` : ''


  const renderGroup = (cat: any) => {
    return data?.map((d: any, i: any) => {
      return <React.Fragment key={`calculator-${cat}-${d.id}`}>
        <Accordion square
          defaultExpanded={false}
          expanded={openAccordion.includes(d?.id) ? true : false}
          className={classes.patientAccrodionBg}
        >
          <AccordionSummary
            ref={(el) => queRefs.current[i] = el}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-content-${cat}-${d.id}`}
            id={`panel-header-${cat}-${d.id}`}
            onClick={() => handleAccordion(d?.id)}
          >
            <Grid container justifyContent="flex-start">
              {d.programs?.filter((f: string) => selectedProgram.includes(f))?.length > 0 && (
                <Grid item className={classes.fullWidth}>
                  {isCurrQuestionLoading === d.id ?
                    <CircularProgress size="2rem" />
                    :
                    <CardLayoutComponent
                      id={d.id}
                      name={d?.name}
                      leftHeader={d?.display_name}
                      rightHeader={''}
                      subHeader={title.includes('Diagnosis and Coding Assessments') ? getDiagnosisRes(d) : getSeverity(d?.severity)}
                      bottomText={''}
                      needs_confirmation={d.needs_confirmation ?? undefined}
                      needs_update={d.needs_update || undefined}
                      icons={[]}
                      programs={d.programs}
                      overridenPrograms={d.overridden_programs}
                      onRefClick={() => null}
                      onOverrideClick={() => { }}
                      is_referred={d.is_referred}
                      is_overridden={d.is_overridden || undefined}
                      entityRef={'calculator'}
                      patientId={patientDetail.id}
                      category={cat === 'diagnosis' ? 'conditions' : 'measurements'}
                    />
                  }
                </Grid>
              )}
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {isCurrQuestionLoading !== d.id &&
              <Grid container justifyContent="center" className={classes.zeroTopPadding}>
                <Grid item className={classes.fullWidth}>
                  <QuestionnaireComponent
                    data={{
                      isBulkEdit: true
                    }}
                    pId={patientDetail.id}
                    qId={d?.questionnaire_id}
                    qu={d.id}
                    isAllQuestionnarie={true}
                    handleClose={() => closeCurrQuestionnarie(d.id)}
                    isSubmit={isSubmit}
                    handleChange={handleChange}
                    handleIsSubmit={handleIsSubmit}
                    handleSave={handleSave}
                    needsConfirmation={d?.needs_confirmation}
                  />
                </Grid>
              </Grid>
            }
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    })
  }

  return (
    <Draggable ref={calRef} handle='.category-questionnarie-dialog'>
      <Dialog
        id={'cal-q-id'}
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleCatDialogClose}
        aria-labelledby="calcluator-questionnarie"
        hideBackdrop
        disableScrollLock
        disableEscapeKeyDown
        onKeyUp={(e) => {
          if (e.key === 'Enter' && !(queObj.length < 1 || isLoading))
            handleBulkConfirm('confirm')
        }}
        sx={{
          position: "absolute",
          left: '15%',
          top: '10%'
        }}
      >
        <DialogTitle
          id="calcluator-questionnarie-title"
          className={classes.calculatorQuestionnarieTitle}
          classes={{ root: 'category-questionnarie-dialog' }}
        >
          <DialogTitleHeaderComponent title={title} handleDialogClose={handleCatDialogClose} />
        </DialogTitle>
        {(isLoading || isSubmit) && <LinearProgress />}
        <DialogContent className={classes.questionnaireContainer}>
          <Box className={classes.questionnaireCardContainer}>
            {!(isLoading || isSubmit) && renderGroup(title)}
          </Box>
        </DialogContent>
        <Box className={classes.boxBorder} />
        <DialogActions className={classes.btnContainer}>
          <Button
            disabled={queObj.length < 1 || isLoading}
            onClick={() => handleBulkConfirm('confirm')}
            variant="outlined"
            size='small'
          >
            <strong>{`Save & Confirm`}</strong>
          </Button>
          <Button
            disabled={queObj.length < 1 || isLoading}
            onClick={() => handleBulkConfirm('save')}
            variant="outlined"
            size='small'
          >
            <strong>{`Save`}</strong>
          </Button>
          <Button onClick={() => handleCatDialogClose()} variant="outlined" size='small'>
            <strong>Cancel</strong>
          </Button>
        </DialogActions>
      </Dialog>
    </Draggable>
  );
};

export default withStyles(stylesheet)(CategoryQuestionnaireComponent)