import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ToolbarComponent from './toolbar.component';
import DrawerComponent from './drawer.component';
import { withStyles, ClassNameMap } from '@mui/styles'
import stylesheet from '../patients/detail/detail-container.stylesheet'

interface IProps extends RouteComponentProps {
  history: any;
  location: any;
  match: any;
  classes: ClassNameMap;
}

const NavbarComponent: React.FC<IProps> = ({ classes }) => {
  const [left, setLeft] = useState(false);

  const toggleDrawer = (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setLeft(false);
  };

  const openDrawer = () => setLeft(true);

  return (
    <div className={classes.navbar}>
      <ToolbarComponent
        openDrawerHandler={openDrawer}
        history={''}
        location={window.location}
        match={''}
        isOktaEnabled={undefined}
      />
      <DrawerComponent
        left={left}
        toggleDrawerHandler={toggleDrawer}
        history={''}
        location={''}
        match={''}
      />
    </div>
  );
};

export default withStyles(stylesheet)(NavbarComponent);
