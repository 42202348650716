import { Box, Grid, Typography, FormControl, MenuItem, Select, InputLabel } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import React from 'react'
import stylesheet from '../detail-container.stylesheet'
import Looks3RoundedIcon from '@mui/icons-material/Looks3Rounded';
import LooksTwoRoundedIcon from '@mui/icons-material/LooksTwoRounded';

interface IProps {
  classes: ClassNameMap
  number: any

}

const InitiateMonitoringComponent: React.FC<IProps> = ({ classes, number }) => {
  return (
    <>

      <Box pb={1} mb={1} className={classes.questionHeaderSection} data-testid='initiate-testId'>
        <Box pb={1} mb={1} className={classes.questionHeaderSection}>
          <Typography variant='body1' color='textSecondary'><small className={classes.questionHeaderSection1}>{number ? <LooksTwoRoundedIcon></LooksTwoRoundedIcon> : <Looks3RoundedIcon></Looks3RoundedIcon>}</small><strong>Initiate monitoring plan</strong>  </Typography>
        </Box>
        <Grid container xs={12} direction="row" justifyContent='flex-start'>
          <Grid item xs={3}>
            <Typography variant='body2' color='textSecondary'><small> Toxicity plan </small> </Typography>
            <Typography variant="subtitle2"> {'Cough'}</Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControl
              className={classes.formControlWidth150}
            >
              <InputLabel id="demo-simple-select-standard-label">Duration</InputLabel>
              <Select
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ 'aria-label': 'With label' }}
                value={'2 weeks'}
                name="Duration"
                label="Duration"
              >

                <MenuItem value='2 weeks'>
                  2 weeks
                </MenuItem>

              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

    </>
  )
}

export default withStyles(stylesheet)(InitiateMonitoringComponent)