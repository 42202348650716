import React, { useState, ReactElement, useRef } from 'react'
import { withStyles, ClassNameMap } from '@mui/styles'
import stylesheet from '../detail-container.stylesheet'
import {
	Dialog, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, Typography,
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Draggable from "react-draggable"
import ContextualHelpDataComponent from './contextual-help-data.component';

interface IProps {
	open: boolean
	handleClose: any
	id: any
	title: string | ReactElement
	programs: string[]
	category: string
	classes: ClassNameMap
}

const ContextualHelpComponent: React.FC<IProps> = ({ open, handleClose, id, title, programs, category, classes }) => {
	const [data, setData] = useState({} as any)
	const [loading, setLoading] = useState(false)
	const conextRef = useRef(null) as any

	return <>
		{open &&
			<Draggable ref={conextRef} defaultPosition={{ x: 80, y: 20 }} handle='.contextual-help-dialog'>
				<Dialog
					id={`education-id-${id}`}
					fullWidth={true}
					maxWidth="md"
					open={open}
					onClose={handleClose}
					hideBackdrop
					disableScrollLock
					sx={{
						position: "absolute",
						left: '10rem',
						top: '8rem'
					}}
				>
					<DialogTitle className={classes.contextualHelpHeader} classes={{ root: 'contextual-help-dialog' }}>
						<Grid container justifyContent="space-between">
							<Grid item>
								<Typography color="textSecondary">
									<small>{`Help / ${category}`}</small>
								</Typography>
								<Typography id="modal-modal-title" variant="body1" className={classes.subtitle2}>
									<strong>{data?.pu_name || title}</strong>
								</Typography>
							</Grid>
							<Grid item>
								<IconButton size="small" onClick={() => handleClose()}>
									<CloseOutlinedIcon />
								</IconButton>
							</Grid>
						</Grid>
					</DialogTitle>
					{loading && <LinearProgress />}
					<DialogContent className={`${classes.contextualHelpContext} ${classes.zeroPadding}`}>
						<ContextualHelpDataComponent
							id={id}
							category={category}
							handleLoading={(flag: boolean) => setLoading(flag)}
						/>
					</DialogContent>
				</Dialog>
			</Draggable>
		}
	</>
}

export default withStyles(stylesheet)(ContextualHelpComponent)