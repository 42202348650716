import React, { useRef, useState } from 'react'
import {
	Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, LinearProgress, Input,
	FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Checkbox, TextField, Typography, Autocomplete, Link,
} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import stylesheet from '../detail-container.stylesheet'
import { ClassNameMap, withStyles } from '@mui/styles'
import Helper from '../../../../utils/helper'
import { PatientService } from '../../../../services/patient.service'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { checkCondition } from '../common/condition-check'
import DialogTitleHeaderComponent from '../common/dialog-title-header.component'
import Draggable from 'react-draggable'

interface IProps {
	openAddPatient: any
	onAddPatientClose: any
	classes: ClassNameMap
}

const AddPatientComponent: React.FC<IProps> = ({
	openAddPatient,
	onAddPatientClose,
	classes,
}) => {
	const defaultValues = { mrn: '', program_name: ['hypertension', 'hypercholesterolemia'] as string[], first_name: '', last_name: '' }
	const [selectedFile, setSelectedFile] = useState(null as any)
	const patientService = new PatientService()
	const [fileLoading, setFileLoading] = useState(false)
	const programsSet = useSelector((state: RootState) => state.metaData.metadata?.PROGRAMS)
	const [rdValue, setRdValue] = React.useState('ccd');
	const [formValues, setFormValues] = React.useState({ ...defaultValues });
	const addPatientRef = useRef(null) as any

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRdValue((event.target as HTMLInputElement).value);
		setFormValues({ ...defaultValues })
	}

	const handleCtrlChange = (event: any) => {
		if ((event.target as HTMLInputElement)?.name) {
			setFormValues({ ...formValues, [(event.target as HTMLInputElement).name]: (event.target as HTMLInputElement).value })
		}
	}

	const handleProgramChange = (prgData: any) => {
		if (Object.keys(prgData)?.length > 0) {
			setFormValues({ ...formValues, 'program_name': prgData?.map((i: any) => i.name) })
		}
	}

	const uploadFile = (e: any) => setSelectedFile(e.target.files[0])

	const showMsg = (id: any, type = 'added') => {
		const msg = <Typography variant="subtitle2">Patient{' '}
			<Link target="_blank" href={`patients/${id}/details`}><strong>{id}</strong></Link>{' '}{type} successfully.
		</Typography>
		toast.success(msg || `Patient ${type} successfully.`, Helper.bottom_center())
	}

	const handleSubmit = () => {
		setFileLoading(true)
		if (rdValue === 'ccd') {
			const formData = new FormData();
			formData.append("file", selectedFile);
			try {
				patientService
					.postNewPatient(formData)
					.then((res: any) => {
						setFileLoading(false)
						showMsg(res.data?.patient_id, 'added')
						onAddPatientClose(true)
					}).catch((err) => {
						toast.error(Helper.getErrorText(err), Helper.bottom_center())
						setFileLoading(false)
					})
			} catch (error) {
				toast.error(Helper.getErrorText(error), Helper.bottom_center())
				setFileLoading(false)
			}
		} else if (rdValue === 'fhir') {
			try {
				patientService.fhirPatientAdd(formValues.mrn, formValues.program_name.join(',')).then((res) => {
					setFileLoading(false)
					showMsg(res?.data?.id, 'added')
					onAddPatientClose(true)
				}).catch((err) => {
					toast.error(Helper.getErrorText(err), Helper.bottom_center())
					setFileLoading(false)
				})
			}
			catch (error) {
				setFileLoading(false)
				toast.error(Helper.getErrorText(error), Helper.bottom_center())
			}
		} else if (rdValue === 'elation') {
			try {
				patientService.elationPatientAdd(formValues.mrn, formValues.program_name.join(',')).then((res) => {
					setFileLoading(false)
					showMsg(res.data?.id, 'added')
					onAddPatientClose(true)
				}).catch((err) => {
					toast.error(Helper.getErrorText(err), Helper.bottom_center())
					setFileLoading(false)
				})
			}
			catch (error) {
				setFileLoading(false)
				toast.error(Helper.getErrorText(error), Helper.bottom_center())
			}
		}
		else if (rdValue === 'addPatient') {
			try {
				patientService.addManualPatient({ ...formValues, program_name: formValues.program_name.join() }).then((res) => {
					setFileLoading(false)
					showMsg(res.data?.patient?.ref_id || '', 'added')
					onAddPatientClose(true)
				}).catch((err) => {
					toast.error(Helper.getErrorText(err), Helper.bottom_center())
					setFileLoading(false)
				})
			}
			catch (error) {
				setFileLoading(false)
				toast.error(Helper.getErrorText(error), Helper.bottom_center())
			}
		} else if (rdValue === 'json') {
			const formData = new FormData();
			formData.append("file", selectedFile);
			patientService.importPatient(formData).then((res: any) => {
				onAddPatientClose(true)
				showMsg(res?.data?.patient_id || '', 'imported')
			}).catch((err) => {
				toast.error(Helper.getErrorText(err), Helper.bottom_center())
				setFileLoading(false)
			})
		}
	}

	const checkDisabledBtn = () => {
		if (rdValue === 'ccd' || rdValue === 'json') {
			return selectedFile === null
		} else if (['fhir', 'elation'].includes(rdValue)) {
			return !(formValues.program_name.length > 0 && formValues.mrn !== '')
		} else if (rdValue === 'addPatient') {
			return !Object.values(formValues).every((s: any) => s.length)
		}
	}

	const sortedProgramsSet = [...programsSet]?.sort((a: any, b: any) => (a.name < b.name) ? -1 : 1)

	const recentProgramsSet = () => {
		return [programsSet[programsSet?.findIndex((i: any) => i.name === 'hypertension')] || '',
		programsSet[programsSet?.findIndex((i: any) => i.name === 'hypercholesterolemia')] || ''
		]
	}

	return (
		<Draggable ref={addPatientRef} handle='.add-patient-dialog'>
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				open={openAddPatient}
				onClose={() => onAddPatientClose(false)}
				aria-labelledby={'Add Import Patient'}
				title={'Add Import Patient'}
				hideBackdrop
				disableScrollLock
				onKeyUp={(e) => {
					if (e.key === 'Enter' && !checkDisabledBtn())
						handleSubmit()
				}}
				sx={{
					position: "absolute",
					left: '15%',
					top: '15%'
				}}
			>
				<DialogTitle className={classes.addPatientQuestionHeaderSection} classes={{ root: 'add-patient-dialog' }}>
					<DialogTitleHeaderComponent title='Add / Import Patient' handleDialogClose={() => onAddPatientClose(false)} />
				</DialogTitle>
				<Grid item>{fileLoading && <LinearProgress />}</Grid>
				<DialogContent className={classes.addPatientDialogContent}>
					<Grid container spacing={2} className={classes.containerPadding}>
						<Grid item md={12} xs={12}>
							<FormControl component="fieldset">
								<FormLabel component="legend"><Typography variant='subtitle1'>Import Type</Typography></FormLabel>
								<RadioGroup row aria-label="import_type" name="import_type" value={rdValue} onChange={handleChange}>
									<FormControlLabel value="ccd" control={<Radio color="primary" />} label="CCD" />
									<FormControlLabel value="fhir" control={<Radio color="primary" />} label="FHIR" />
									<FormControlLabel value="elation" control={<Radio color="primary" />} label="ELATION" />
									<FormControlLabel value="addPatient" control={<Radio color="primary" />} label="Add Patient" />
									<FormControlLabel value="json" control={<Radio color="primary" />} label="JSON" />
								</RadioGroup>
							</FormControl>
						</Grid>
						{checkCondition(rdValue === 'ccd' || rdValue === 'json', <>
							<Grid item>
								<FormControl>
									<Input
										inputProps={{ accept: checkCondition(rdValue === 'json', '.json', '.xlsx, .xls, .csv, .xml') }}
										sx={{ position: 'absolute' }}
										id="upload-file"
										type="file"
										onChange={uploadFile}
									/>
									<label htmlFor="upload-file">
										<Button component="span" variant="outlined" size="small">
											Choose file
										</Button>
									</label>
								</FormControl>
							</Grid>
							<Grid item xs className={classes.selectedFile}>
								{selectedFile !== undefined ? selectedFile?.name : ''}
							</Grid>
						</>
						)}
						{['fhir', 'addPatient', 'elation'].includes(rdValue) && <>
							{rdValue === 'addPatient' && <>
								<Grid item md={12} xs={12} >
									<FormControl className={classes.formControl}>
										<FormLabel id="mrn-lable"><Typography variant='subtitle1'>First Name</Typography></FormLabel>
										<TextField variant="standard" id="mrn" name='first_name' value={formValues.first_name} className={classes.addPatientTextField}
											onChange={handleCtrlChange} />
									</FormControl>
								</Grid>
								<Grid item md={12} xs={12} >
									<FormControl className={classes.formControl}>
										<FormLabel id="mrn-lable"><Typography variant='subtitle1'>Last Name</Typography></FormLabel>
										<TextField variant="standard" id="mrn" name='last_name' value={formValues.last_name} className={classes.addPatientTextField}
											onChange={handleCtrlChange} />
									</FormControl>
								</Grid></>
							}
							<Grid item md={12} xs={12} >
								<FormControl className={classes.formControl}>
									<FormLabel id="mrn-lable"><Typography variant='subtitle1'>MRN</Typography></FormLabel>
									<TextField variant="standard" id="mrn" name='mrn' value={formValues.mrn} className={classes.addPatientTextField}
										onChange={handleCtrlChange} />
								</FormControl>
							</Grid>
							<Grid item md={12} xs={12}>
								<FormControl className={classes.formControl}>
									<FormLabel id="mutiple-checkbox-label"><Typography variant='subtitle1'>Select Programs</Typography></FormLabel>
									<Autocomplete
										multiple
										id='program_name'
										options={sortedProgramsSet}
										getOptionLabel={(option: any) => option.display_name}
										defaultValue={recentProgramsSet()}
										disablePortal={true}
										renderOption={(props, option, { selected }) => (
											<li {...props}>
												<Checkbox
													icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
													checkedIcon={<CheckBoxIcon fontSize="small" />}
													checked={selected}
												/>
												{option.display_name}
											</li>
										)}
										renderInput={(params: any) => (
											<TextField {...params} variant="standard" placeholder="" />
										)}
										onChange={(event: any, newValue: any | null) => handleProgramChange(newValue)}
										sx={{ minWidth: '320px' }}
									/>
								</FormControl>
							</Grid>
						</>}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid container spacing={2} className={classes.containerPadding} justifyContent="flex-end">
						<Grid item>
							<Button
								variant="outlined"
								size="small"
								disabled={fileLoading || checkDisabledBtn()}
								onClick={() => handleSubmit()}
								component="label"
							>
								Submit
							</Button>
						</Grid>
						<Grid item>
							<Button variant="outlined" size="small" onClick={() => onAddPatientClose(false)}>
								Cancel
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		</Draggable>
	)
}

export default withStyles(stylesheet)(AddPatientComponent)