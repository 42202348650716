import React, { memo } from 'react';
import { withStyles, makeStyles } from '@mui/styles';
import stylesheet from "../detail-container.stylesheet";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

interface IProps {
  selectedProgramList: string[];
  overridenProgramList?: string[]
}

const useStyles = makeStyles(() => ({
  dot: {
    height: '0.5rem',
    width: '0.5rem',
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: '0.2rem'
  },
  dotOverrideProgram: {
    height: '0.5rem',
    width: '0.5rem',
    border: '2px solid #ffb573',
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: '0.2rem'
  }
}));

const ColorBubbleComponent: React.FC<IProps> = ({ selectedProgramList = [], overridenProgramList = [] }) => {
  const classes = useStyles();
  const programsList = useSelector(
    (state: RootState) => state.metaData.metadata.PROGRAMS || []
  );

  return (<>
    {selectedProgramList.map((e: any, i: number) => {
      if (!overridenProgramList.includes(e)) {
        return (<span key={`cb-${i}`} data-testid='color-testId' className={classes.dot}
          style={{ backgroundColor: programsList.find((f: { name: any; }) => f.name === e)?.color || '' }}
        />)
      }
      else {
        return (<span key={`cb-${i}`} data-testid='color-testId' className={classes.dotOverrideProgram}
          style={{ backgroundColor: programsList.find((f: { name: any; }) => f.name === e)?.color || '' }}
        />)
      }
    }
    )}
  </>)
}

export default memo(withStyles(stylesheet)(ColorBubbleComponent));