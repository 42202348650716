import { Box, Checkbox, Grid, TextField, Typography } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { RootState } from '../../../../redux/store'
import { PatientService } from '../../../../services/patient.service'
import Helper from '../../../../utils/helper'
import { useDispatchAPIs } from '../../../../utils/use-dispatchApis-hook'
import { checkCondition } from '../common/condition-check'
import { TakingOptions, UpdateMedicine, ViewMedicine } from '../common/medications.component'
import stylesheet from '../detail-container.stylesheet'

interface IProps {
  medicineInfo: any
  outerSubmit: any
  classes: ClassNameMap
}

const DetailPrgMedComponent: React.FC<IProps> = ({ medicineInfo, outerSubmit, classes }) => {
  const uiResource = useSelector((state: RootState) => state.metaData.metadata)
  const initialValues = {
    medicineData: { id: '', name: '' },
    dose: medicineInfo.c_schedule?.dose || '',
    num_units: medicineInfo.pu_order?.num_units || '',
    unit: medicineInfo.pu_order?.unit || '',
    route: medicineInfo.pu_order?.route || '',
    frequency: medicineInfo.pu_order?.frequency || '',
    prescription_taken: medicineInfo.prescription_taken || false,
    schedule_name: medicineInfo?.c_schedule?.name || '',
    intake_regularity: medicineInfo?.intake_regularity || '',
    dosage_info: medicineInfo?.dosage_info || ''
  }
  const [values, setValues] = useState(initialValues as any)
  const [isTaking, setIsTaking] = useState(medicineInfo?.intake_regularity)
  const [dosageInfo, setDosageInfo] = useState(medicineInfo?.dosage_info || [] as any)

  const handleInputChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
    })
  }
  const handleDosageUpdate = (e: any) => {
    const name = e.target.name

    if (name === 'medication_timings') {
      let medicationTimings: string = e.target.value
      setDosageInfo([{ medicationTimings }])
      setValues({ ...values, 'dosage_info': [{ medicationTimings }] })
    }
  }

  useEffect(() => {
    outerSubmit('values', values)
  }, [values])

  const onBtnChange = (_input: string) => {
    setValues({ ...values, 'intake_regularity': _input })
    setIsTaking(_input === 'not taking' ? false : true)
  }

  return (
    <Box p={1}>
      <Box mb={2} pl={1}>
        <Grid container className={classes.medicationsBoxDisplay} direction="row" justifyContent="space-between">
          <Grid item xs={10}>
            <Grid container direction="row" >
              <Grid item xs={1} className={classes.checkBoxShadow}>
                <Checkbox
                  onChange={handleInputChange}
                  checked={values.prescription_taken}
                  name="prescription_taken"
                  color="default"
                  inputProps={{ "aria-label": "checkbox with default color" }}
                  size="small"
                />
              </Grid>
              <Grid item xs={10} className={classes.checkBoxShadow}>
                <Typography variant="subtitle2" color="textSecondary" className={classes.textLabel}>
                  Taken as needed
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box pt={2} pb={2}>
                  <TakingOptions
                    isPrgMed={true}
                    options={['taking', 'not taking', 'taking differently']}
                    onBtnChange={onBtnChange}
                    currentOption={values?.intake_regularity}
                    classes={classes}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                {isTaking ?
                  <UpdateMedicine
                    medicineInfo={medicineInfo}
                    dosageInfo={dosageInfo}
                    handleDosageUpdate={handleDosageUpdate}
                    values={values}
                    classes={classes}
                    uiResource={uiResource}
                    handleInputChange={handleInputChange}
                  /> :
                  <ViewMedicine
                    uiResource={uiResource}
                    medicineInfo={medicineInfo}
                    dosageInfo={dosageInfo}
                    classes={classes}
                  />
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box >
  );
}

export default withStyles(stylesheet)(DetailPrgMedComponent)
